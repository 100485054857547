import React from 'react';
import { WithMainControlsProps } from '../types';

export const useMainControlsContext = (): WithMainControlsProps => {
    const value = React.useContext(MainControlsContext);

    if (value === undefined || value === null) {
        throw new Error(
            'useMainControlsContext must be used within MainControlsProvider',
        );
    }

    return value;
};

export const MainControlsContext =
    React.createContext<WithMainControlsProps | null>(null);

export interface MainControlsProviderProps {
    childContext: WithMainControlsProps;
    children: React.ReactChild;
}
export const MainControlsProvider = ({
    childContext,
    children,
}: MainControlsProviderProps) => (
    <MainControlsContext.Provider value={{ ...childContext }}>
        {children}
    </MainControlsContext.Provider>
);

export default MainControlsProvider;
