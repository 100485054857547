import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import classNames from 'classnames';
import { Icon } from '@eventbrite/eds-icon';
import { PaymentIcon } from '@eventbrite/eds-payment-icon';
import {
    ICON_TYPE_PROP_TYPE,
    ICON_SIZE_PROP_TYPE,
    COLOR_PROP_TYPE,
} from '@eventbrite/eds-icon';
import {
    PAYMENT_TYPE_PROP_TYPE,
    PAYMENT_SIZE_PROP_TYPE,
} from '@eventbrite/eds-payment-icon';
import { VERTICAL_ALIGNMENTS_PROP_TYPE } from './constants';
import { VECTOR_IMAGE_PAYMENT } from '@eventbrite/eds-vector-image';
import { getIconVectorType } from '@eventbrite/eds-icon';

import ItemImage from './ItemImage';

import './listItem.scss';

const ExtraContent = ({ children }) => {
    let component = null;

    if (children) {
        component = (
            <div
                className="eds-list-item__aside eds-list-item__extra-content eds-l-pad-right-4"
                data-spec="list-item-extra-content"
            >
                {children}
            </div>
        );
    }

    return component;
};

const ItemIcon = ({
    iconType,
    iconSize,
    iconColor,
    iconStrokeColor,
    children,
    extraContent,
    isSelected,
    isTentativelySelected,
    useAltTheme,
    isDisabled,
    verticalAlignment,
    displayInline,
}) => {
    const className = classNames('eds-list-item', {
        'eds-list-item--selected': isSelected,
        'eds-list-item--tentatively-selected': isTentativelySelected,
        'eds-list-item--alt-theme': useAltTheme,
        'eds-list-item--disabled': isDisabled,
        [`eds-list-item--align-${verticalAlignment}`]: verticalAlignment,
        'eds-list-item--display-inline': displayInline,
    });

    let icon = (
        <Icon
            type={iconType}
            size={iconSize}
            color={iconColor}
            strokeColor={iconStrokeColor}
        />
    );

    if (getIconVectorType(iconType) === VECTOR_IMAGE_PAYMENT) {
        icon = <PaymentIcon type={iconType} size={iconSize} />;
    }

    return (
        <div className={className} data-spec="eds-list-item">
            <ExtraContent>{extraContent}</ExtraContent>
            <span className="eds-list-item__aside eds-list-item__icon">
                {icon}
            </span>
            {children}
        </div>
    );
};

export default class ListItem extends PureComponent {
    static propTypes = {
        /**
         * Contents of icon list item
         */
        children: PropTypes.node.isRequired,
        /**
         * Generic content for the left side
         */
        extraContent: PropTypes.node,
        /**
         * Icon to include in the list item that when specified will wrap the `children` to include the icon
         * Will not be used if iconTypes is specified
         */
        iconType: PropTypes.oneOfType([
            ICON_TYPE_PROP_TYPE,
            PAYMENT_TYPE_PROP_TYPE,
        ]),
        /**
         * Icons to include in the list item that when specified will wrap the `children` to include the icons
         */
        iconTypes: PropTypes.arrayOf(
            PropTypes.oneOfType([ICON_TYPE_PROP_TYPE, PAYMENT_TYPE_PROP_TYPE]),
        ),
        /**
         * Size of included icon
         */
        iconSize: PropTypes.oneOfType([
            ICON_SIZE_PROP_TYPE,
            PAYMENT_SIZE_PROP_TYPE,
        ]),
        /**
         * Color of included icon
         */
        iconColor: COLOR_PROP_TYPE,
        /**
         * Color of included stroke
         */
        iconStrokeColor: COLOR_PROP_TYPE,
        /**
         * Vertical alignment of children items
         */
        verticalAlignment: VERTICAL_ALIGNMENTS_PROP_TYPE,
        /**
         * URL of image to be displayed with list item content
         */
        imageUrl: PropTypes.string,
        /**
         * Alt text for image to be displayed with list item content
         */
        imageAlt: translationPropType,
        /**
         * Optional square shape image - note you must pass in an imageUrl
         * for an image that is either 1:1 or wider than it is tall.
         */
        isSquareImage: PropTypes.bool,
        /**
         * Whether or not the list item is selected
         */
        isSelected: PropTypes.bool,
        /**
         * Whether or not the text list item is tenatively selected.
         * This comes into play on hover or via arrow key press before an
         * actual selection is made
         */
        isTentativelySelected: PropTypes.bool,
        /**
         * Whether or not the item should use an alternate theme
         */
        useAltTheme: PropTypes.bool,
        /**
         * Whether or not the item should use a progress indicator while the image is loading
         */
        useProgressIndicator: PropTypes.bool,
        /**
         * Whether or not the item is disabled
         */
        isDisabled: PropTypes.bool,
        /**
         * Whether to show a status next to the list item
         * For example, show 'NEW' next to the 'Widgets' link
         * when a new widget is available (EB-61595)
         */
        showStatus: PropTypes.bool,
        /**
         * Text that should be displayed in the status
         */
        statusText: translationPropType,
    };

    static defaultProps = {
        iconSize: 'small',
        verticalAlignment: 'middle',
        isSelected: false,
        useProgressIndicator: false,
        useAltTheme: false,
        isDisabled: false,
        showStatus: false,
        statusText: '',
        isSquareImage: false,
    };

    render() {
        const {
            iconType,
            iconTypes,
            iconSize,
            iconColor,
            iconStrokeColor,
            imageAlt,
            imageUrl,
            isSquareImage,
            children,
            extraContent,
            isTentativelySelected,
            isSelected,
            useAltTheme,
            isDisabled,
            showStatus,
            statusText,
            verticalAlignment,
            useProgressIndicator,
        } = this.props;

        const itemIconProps = {
            iconType,
            iconSize,
            iconColor,
            iconStrokeColor,
            isSelected,
            isTentativelySelected,
            useAltTheme,
            isDisabled,
            verticalAlignment,
        };

        let statusTextFormatted;

        if (showStatus) {
            statusTextFormatted = (
                <div className="eds-list-item__status">
                    <span className="eds-l-pad-hor-1">&middot;</span>
                    <span className="eds-text-bs">{statusText}</span>
                </div>
            );
        }

        let component = (
            <div
                className="eds-list-item__contents"
                data-spec="eds-list-item-contents"
            >
                {children}
                {statusTextFormatted}
            </div>
        );

        if (iconTypes) {
            let iconContent = null;
            let lastIconExtraContent = null;
            const itemIcons = iconTypes.map((icon) => {
                if (icon === iconTypes[iconTypes.length - 1]) {
                    iconContent = (
                        <div
                            className="eds-list-item__contents eds-list-item--display-inline"
                            key="list-item-contents"
                            data-spec="eds-list-item-contents"
                        >
                            {children}
                        </div>
                    );
                    lastIconExtraContent = extraContent;
                }

                return (
                    <ItemIcon
                        key={icon.type.displayName}
                        {...itemIconProps}
                        iconType={icon}
                        extraContent={lastIconExtraContent}
                        displayInline={true}
                    />
                );
            });

            itemIcons.push(iconContent);
            component = (
                <div data-spec="eds-list-items-wrapper">{itemIcons}</div>
            );
        } else if (iconType) {
            component = (
                <ItemIcon {...itemIconProps} extraContent={extraContent}>
                    {component}
                </ItemIcon>
            );
        } else if (imageUrl) {
            component = (
                <ItemImage
                    imageUrl={imageUrl}
                    imageAlt={imageAlt}
                    isSquareImage={isSquareImage}
                    verticalAlignment={verticalAlignment}
                    extraContent={extraContent}
                    progressIndicator={useProgressIndicator}
                >
                    {children}
                    {statusTextFormatted}
                </ItemImage>
            );
        } else {
            const className = classNames('eds-list-item', {
                [`eds-list-item--align-${verticalAlignment}`]:
                    verticalAlignment,
            });

            component = (
                <div className={className} data-spec="eds-list-item">
                    <ExtraContent>{extraContent}</ExtraContent>
                    {component}
                </div>
            );
        }

        return component;
    }
}
