import React from 'react';
import { gettext } from '@eventbrite/i18n';

import { Icon } from '@eventbrite/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { AlertChunky as AlertChunkySvg } from '@eventbrite/eds-iconography';

const HEADER_TEXT = gettext('Oops, something went wrong!');
const SUBTITLE_TEXT = gettext(
    'If you feel that this message is in error, please <a href="/support/contact-us">let us know</a>.',
);
const BUTTON_CREATE_EVENT_CTA = gettext('Create an Event');
const BUTTON_FIND_EVENT_CTA = gettext('Find an Event');

const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    marginTop: '-40px',
};

export const RootErrorFallback = () => {
    return (
        <div style={containerStyles}>
            <Icon type={<AlertChunkySvg />} size="large" color="ui-red" />
            <section className="eds-l-mar-vert-2 eds-text--center">
                <h1 className="eds-text-hl">{HEADER_TEXT}</h1>
                <div className="eds-l-pad-top-1 eds-text-color--gray-600 eds-text-bl">
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: SUBTITLE_TEXT.toString(),
                        }}
                    />
                </div>
            </section>
            <section className="eds-l-pad-vert-1">
                <Button style="fill" type="link" href="/create">
                    {BUTTON_CREATE_EVENT_CTA}
                </Button>
                <span className="eds-l-pad-hor-2" />
                <Button style="neutral" type="link" href="/d/local/">
                    {BUTTON_FIND_EVENT_CTA}
                </Button>
            </section>
        </div>
    );
};
