import { gettext } from '@eventbrite/i18n';
import {
    HELP_LINKS_PROP_TYPE,
    LOCALES_ARRAY_PROP_TYPE,
    LOCALE_INFO_SHAPE_PROP_TYPE,
} from '@eventbrite/site-footer';
import PropTypes from 'prop-types';

const HEADER_ORGANIZE_LINKS_PROP_TYPE = PropTypes.shape({
    home: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        gaLabel: PropTypes.string,
    }).isRequired,

    suggested: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            gaLabel: PropTypes.string,
        }),
    ).isRequired,
});

export const ENV_SHAPE_PROP_TYPE = PropTypes.shape({
    serverUrl: PropTypes.string.isRequired,
    ebDomain: PropTypes.string.isRequired,
    adminServerUrl: PropTypes.string.isRequired,
    signupUrl: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    logoutUrl: PropTypes.string.isRequired,
    searchLocation: PropTypes.shape({
        address: PropTypes.string,
    }).isRequired,
    locales: LOCALES_ARRAY_PROP_TYPE.isRequired,
    localeInfo: LOCALE_INFO_SHAPE_PROP_TYPE.isRequired,
    helpUrls: HELP_LINKS_PROP_TYPE.isRequired,
    organizeUrls: HEADER_ORGANIZE_LINKS_PROP_TYPE.isRequired,

    searchQuery: PropTypes.string,
});

export const REQUEST_SHAPE_PROP_TYPE = PropTypes.shape({
    path: PropTypes.string.isRequired,
});

export const USER_SHAPE_PROP_TYPE = PropTypes.shape({
    canInvite: PropTypes.bool,
    canCreateEvents: PropTypes.bool,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    hasEvents: PropTypes.bool,
    hasOrganizers: PropTypes.bool,
    hasTikibar: PropTypes.bool,
    isAttendee: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    isStaff: PropTypes.bool,
    numOrders: PropTypes.number,
    numSavedEvents: PropTypes.number,
    publicId: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    numFollowing: PropTypes.number,
});

export const DESTINATION_SUGGESTIONS_API_URL =
    '/api/v3/destination/search/autocomplete/';
export const DESTINATION_SUGGESTIONS_RESPONSE_GROUPS = ['event'];
export const USER_LOCATION_COOKIE = 'location';

const UNITED_STATES_PLACE_ID = 85633793;
export const DEFAULT_USER_LOCATION = UNITED_STATES_PLACE_ID;

export const FALLBACK_CATEGORIES = [
    {
        type: 'Category',
        category: gettext('Categories'),
        label: gettext('Arts'),
        source: 'autocomp',
        url: '/d/local/arts--events/',
        value: 'Arts',
    },
    {
        type: 'Category',
        category: gettext('Categories'),
        label: gettext('Business'),
        source: 'autocomp',
        url: '/d/local/business--events/',
        value: 'Business',
    },
    {
        type: 'Category',
        category: gettext('Categories'),
        label: gettext('Charity & Causes'),
        source: 'autocomp',
        url: '/d/local/charity-and-causes--events/',
        value: 'Charity & Causes',
    },
    {
        type: 'Category',
        category: gettext('Categories'),
        label: gettext('Community'),
        source: 'autocomp',
        url: '/d/local/community--events/',
        value: 'Community',
    },
    {
        type: 'Category',
        category: gettext('Categories'),
        label: gettext('Film & Media'),
        source: 'autocomp',
        url: '/d/local/film-and-media--events/',
        value: 'Film & Media',
    },
    {
        type: 'Category',
        category: gettext('Categories'),
        label: gettext('Food & Drink'),
        source: 'autocomp',
        url: '/d/local/food-and-drink--events/',
        value: 'Food & Drink',
    },
    {
        type: 'Category',
        category: gettext('Categories'),
        label: gettext('Music'),
        source: 'autocomp',
        url: '/d/local/music--events/',
        value: 'Music',
    },
    {
        type: 'Category',
        category: gettext('Categories'),
        label: gettext('All Categories'),
        source: 'autocomp',
        url: '/d/local/all-events/',
        value: 'All Categories',
    },
];

export const FALLBACK_SUGGESTIONS = FALLBACK_CATEGORIES.map(
    ({ label, url }) => ({
        content: label,
        value: url,
    }),
);

export const ACTION_CREATE_ATTEMPT = 'CreateAttempt';
export const ACTION_DISCOVER = 'Discover';
export const ACTION_HELP_ARTICLE = 'HelpArticle';
export const ACTION_HELP_LINK_CLICK = 'HelpLinkClicked';
export const ACTION_HELP_LINK_HOVER = 'HelpLinkHovered';
export const ACTION_HOST_AN_EVENT_CLICK = 'HostAnEventLinkClicked';
export const ACTION_HOST_AN_EVENT_HOVER = 'HostAnEventLinkHovered';
export const ACTION_FIND_AN_EVENT_CLICK = 'FindAnEventLinkClicked';
export const ACTION_FIND_AN_EVENT_HOVER = 'FindAnEventLinkHovered';
export const ACTION_ORGANIZE_AN_EVENT_CLICK = 'OrganizeAnEventLinkClicked';
export const ACTION_ORGANIZE_AN_EVENT_HOVER = 'OrganizeAnEventLinkHovered';
export const ACTION_SIGNIN_CLICK = 'SignInClicked';
export const ACTION_LOGIN_HOVER = 'SignInHovered';
export const ACTION_LOGIN_ATTEMPT = 'LoginAttempt';
export const ACTION_SIGNUP_CLICK = 'SignUpClicked';
export const ACTION_LOGO_HOME = 'LogoHome';
export const ACTION_PROFILE_DROPDOWN = 'ProfileDropdown';
export const ACTION_LOGOUT = 'Logout';
export const CATEGORY_HEADER = 'header';
export const LABEL_HELP_CENTER = 'HelpCenter';
export const LABEL_ACCOUNT = 'Account';
export const LABEL_CREATE_EVENT = 'CreateEvent';
export const LABEL_FIND_EVENT = 'FindEvent';
export const LABEL_ORGANIZE_EVENT = 'OrganizeEvent';
export const LABEL_MY_CONTACTS = 'My Contacts';
export const LABEL_MY_EVENTS = 'ManageMyEvents (consumer)';
export const LABEL_ORGANIZER_PROFILE = 'My Organizer Profile';
export const LABEL_SAVED = 'Saved';
export const LABEL_TICKETS = 'Tickets';
export const LABEL_FOLLOWING = 'Following';
export const LABEL_COLLECTIONS = 'Collections';
export const LABEL_INTERESTS = 'Interests';
export const LABEL_SPONSORSHIPS = 'Sponsorships';
export const LABEL_STUDIO = 'Studio';
export const LABEL_CREDITS = 'Credits';
export const LABEL_EMAIL_NAME_ACCOUNT = 'EmailAndNameAccount';

export const GA_SEARCH_AUTOCOMPLETE_SUGGESTION_ACTION =
    'AutocompleteSuggestionClicked';
export const ORGANIZER_NAVBAR = 'organizer';
export const ATTENDEE_NAVBAR = 'attendee';

export const MENU_AVATAR_BACKGROUND = 'control';
export const MENU_AVATAR_COLOR = 'white';
export const ORGANIZATIONS_URL = '/organizations';
export const MANAGE_URL = '/manage';
