import React from 'react';
import _ from 'lodash';
import { flow, map, take } from 'lodash/fp';
import { getNavigatorLastLocation } from '@eventbrite/personalization';
import { Crosshair as CrosshairSvg } from '@eventbrite/eds-iconography';

// Needed to rush strings translation for coronavirus, fix this later
// to not import from feed
import { gettext } from '@eventbrite/i18n';
import { Icon } from '@eventbrite/eds-icon';
import { HAS_WINDOW } from '@eventbrite/feature-detection';

import {
    BROWSING_ONLINE_EVENTS_TEXT,
    HISTORY_SUGGESTIONS_SHOWN,
    ONLINE_EVENTS_LOCATION_SLUG,
    SUGGESTION_TYPE_HISTORY,
} from '../constants';

import { CheckChunky as CheckChunkySvg } from '@eventbrite/eds-iconography';
import { Clock as ClockSvg } from '@eventbrite/eds-iconography';
import { VideoChunky } from '@eventbrite/eds-iconography';
/*
 * @param item -- object -- ${item}: ${content, secondaryContent}
 *      - Object with the values to be shown as a list item
 * @param shouldShowCheck -- bool -- ${shouldShowCheck}: ${true|false}
 *      - Boolean variable to enable or disable the check on the list item
 *      - Default value: false.
 *
 * Function takes in two texts and a boolean variable and returns an html object with
 * a proper formatting and an optional check on the side.
 */
export const addCheckToSuggestion = ({
    item: { content, secondaryContent },
    shouldShowCheck = false,
}) => {
    let currentLocationIcon = null;

    const _secondaryContent = (
        <div className="eds-text-list-item__content-secondary">
            {secondaryContent}
        </div>
    );

    if (shouldShowCheck) {
        currentLocationIcon = (
            <Icon
                type={<CheckChunkySvg />}
                size="small"
                color="vibrant-green"
            />
        );
    }

    return (
        <div className="eds-align--center-vertical eds-align--space-between">
            <div>
                {content}
                {_secondaryContent}
            </div>
            {currentLocationIcon}
        </div>
    );
};

export const parseLocationHistorySuggestions = ({ place } = {}) => {
    const lastLocation = getNavigatorLastLocation();
    const historySuggestions = lastLocation
        ? [JSON.stringify(lastLocation).replace(/,/g, ';')]
        : [];

    return flow(
        map((value, index) => {
            let itemValue = {
                value,
                content: value,
            };

            itemValue = JSON.parse(value.replace(/;/g, ','));
            itemValue.content = addCheckToSuggestion({
                item: itemValue,
                shouldShowCheck:
                    place &&
                    place === itemValue.content + itemValue.secondaryContent,
            });
            itemValue.secondaryContent = null;

            return {
                ...itemValue,
                iconType: <ClockSvg />,
                header: index === 0 ? gettext('Recent') : null,
                showDivider:
                    historySuggestions.length === index + 1 || index === 4,
                dividerVertSpacing: 2,
                suggestionType: SUGGESTION_TYPE_HISTORY,
            };
        }),
        take(HISTORY_SUGGESTIONS_SHOWN),
    )(historySuggestions);
};

export const getOnlineEventsOption = (onlineEventsContent) => ({
    slug: ONLINE_EVENTS_LOCATION_SLUG,
    currentPlace: BROWSING_ONLINE_EVENTS_TEXT.toString(),
    currentPlaceParent: '',
    latitude: null,
    longitude: null,
    value: ONLINE_EVENTS_LOCATION_SLUG,
    content: onlineEventsContent || gettext('Browse online events'),
    placeType: null,
    iconType: <VideoChunky />,
    isOnline: true,
});

const parseLocationSuggestions = ({
    locationSuggestions,
    isTyping = false,
    locationQuery,
}) => {
    const shouldShowHeader = !isTyping || !locationQuery;

    return flow(
        map.convert({ cap: false })((value, index) => ({
            ...value,
            header: index === 0 && shouldShowHeader ? gettext('Popular') : null,
        })),
    )(locationSuggestions);
};

const parseInitialLocationSuggestions = ({
    currentPlace,
    currentPlaceParent,
    isUseCurrentLocationEnabled,
    suggestions: locationSuggestions,
    usingCurrentLocation,
    isOnline,
}) => {
    let _suggestions = parseLocationSuggestions({ locationSuggestions });
    const historySuggestions = parseLocationHistorySuggestions({
        place: currentPlace + currentPlaceParent,
    });

    let useCurrentLocation = [];

    let browseOnlineEvents = [];

    if (isUseCurrentLocationEnabled) {
        let currentPlaceSecondaryContent = '';

        let shouldShowCheck = false;

        if (usingCurrentLocation) {
            currentPlaceSecondaryContent = currentPlace;
            shouldShowCheck = true;
        }

        const currentLocationContent = addCheckToSuggestion({
            item: {
                content: gettext('Use my current location'),
                secondaryContent: currentPlaceSecondaryContent,
            },
            shouldShowCheck,
        });
        const currentLocationOption = {
            value: '',
            content: currentLocationContent,
            iconType: <CrosshairSvg />,
        };

        useCurrentLocation = [currentLocationOption];
    }

    const shouldShowCheck = isOnline;

    const onlineEventsContent = addCheckToSuggestion({
        item: {
            content: gettext('Browse online events'),
            secondaryContent: '',
        },
        shouldShowCheck,
    });

    const onlineEventsOption = getOnlineEventsOption(onlineEventsContent);

    browseOnlineEvents = [onlineEventsOption];

    _suggestions = [
        ...useCurrentLocation,
        ...browseOnlineEvents,
        ...historySuggestions,
        ..._suggestions,
    ];

    return _suggestions;
};

export const getLocationSuggestions = (
    {
        suggestions,
        topSuggestions,
        currentPlace,
        currentPlaceParent,
        usingCurrentLocation = false,
        isOnline = false,
    },
    isUseCurrentLocationEnabled = false,
) => {
    if (_.isEmpty(suggestions)) {
        return {
            suggestions: parseInitialLocationSuggestions({
                currentPlace,
                currentPlaceParent,
                isUseCurrentLocationEnabled,
                suggestions: topSuggestions,
                usingCurrentLocation,
                isOnline,
            }),
            hasSuggestions: false,
        };
    }

    return {
        suggestions,
        hasSuggestions: true,
    };
};
