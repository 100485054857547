import * as React from 'react';

const MenuChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="menu-chunky_svg__eds-icon--menu-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="menu-chunky_svg__eds-icon--menu-chunky_bottom"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 17h16v2H4v-2z"
        />
        <path
            id="menu-chunky_svg__eds-icon--menu-chunky_middle"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 11h16v2H4z"
        />
        <path
            id="menu-chunky_svg__eds-icon--menu-chunky_top"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 5h16v2H4z"
        />
    </svg>
);

MenuChunkySvg.displayName = 'MenuChunkySvg';
export default MenuChunkySvg;
