import * as React from 'react';

const MapPinChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="map-pin-chunky_svg__eds-icon--map-pin-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="map-pin-chunky_svg__eds-icon--map-pin-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6 11.6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-7.6C8.5 4 6 6.5 6 9.6 6 13.8 11.6 20 11.6 20s5.6-6.2 5.6-10.4c0-3.1-2.5-5.6-5.6-5.6z"
        />
    </svg>
);

MapPinChunkySvg.displayName = 'MapPinChunkySvg';
export default MapPinChunkySvg;
