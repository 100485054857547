import { CloseIcon, MenuIcon } from '@eventbrite/marmalade';
import React, { useRef } from 'react';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../../utils/heap-data';
import { DropdownContainerProps } from '../../../../types';
import { Dropdown } from '../Dropdown';
import styles from './MobileDropdown.module.scss';

const MobileDropdown = ({
    dropdownItems,
}: Omit<DropdownContainerProps, 'heapIdentifier' | 'translatedText'>) => {
    const { Teams, Features, Components, Actions } = HeapAttributes;
    const dropdownRef = useRef<HTMLLIElement>(null);

    const pointerHandler = (e: React.PointerEvent) => {
        if (dropdownRef.current)
            if (dropdownRef.current === document.activeElement) {
                /**
                 * The dropdown is opened through css (focus-within)
                 * This logic blurs the element closing the dropdown
                 */
                /**
                 * Prevent any default behavior associated with pointer events.
                 * https://developer.mozilla.org/en-US/docs/Web/API/PointerEvent
                 * If you use pointer events, you should call event.preventDefault() to keep the mouse event from being sent as well.
                 */
                e.preventDefault();
                dropdownRef.current.blur();
            }
    };

    return (
        <li
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.GlobalNav,
                component: Components.Dropdown,
                name: 'mobile',
                action: Actions.Click,
            })}
            className={styles.mobileDropdown}
            tabIndex={0}
            onPointerDown={pointerHandler}
            ref={dropdownRef}
        >
            <MenuIcon size="small" />
            <CloseIcon size="small" />
            <Dropdown dropdownItems={dropdownItems} />
        </li>
    );
};

export default MobileDropdown;
