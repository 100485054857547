// import { STYLE_BASIC } from '@eventbrite/eds-input-field';
const STYLE_BASIC = 'basic';
import {
    FORMATTED_CATEGORY_MAPPING,
    FORMATTED_DATE_MAPPING,
    FORMATTED_EXPERIENCES_MAPPING,
    FORMATTED_FORMAT_MAPPING,
    FORMATTED_PRICE_MAPPING,
    FORMATTED_SUBCATEGORY_MAPPING,
} from '@eventbrite/discover-utils';
import { GenericLazyString, gettext } from '@eventbrite/i18n';

/*Filter Types*/
export const CATEGORY_FILTER = 'category';
export const SUBCATEGORIES_FILTER = 'subcategories';
export const CURRENCY_FILTER = 'currencies';
export const LANGUAGE_FILTER = 'languages';
export const FORMAT_FILTER = 'format';
export const PRICE_FILTER = 'price';
export const DATE_FILTER = 'dates';
export const EXPERIENCES_FILTER = 'experiences';
export const QUERY_FILTER = 'q';
export const SPECIAL_FILTER = 'special';
export const ORGANIZATIONS_FILTER = 'organizations';

export const FOLLOWED_ORGS_FILTER_VALUE = 'organizers_i_follow';

export const FACET_FILTER = 'masterTags';

/* Filter empty state placeholders */
const CATEGORY_PLACEHOLDER_TEXT = gettext('Any category');
const DATE_PLACEHOLDER_TEXT = gettext('Any date');
const EXPERIENCES_PLACEHOLDER_TEXT = gettext('Any experience');
const FORMAT_PLACEHOLDER_TEXT = gettext('Any event type');
const PRICE_PLACEHOLDER_TEXT = gettext('Any price');
const CURRENCY_PLACEHOLDER_TEXT = gettext('Any currency');
const LANGUAGE_PLACEHOLDER_TEXT = gettext('Any language');
const ORGANIZATIONS_PLACEHOLDER_TEXT = gettext('Any organization');

export const CLEAR_PRICE_FILTER = 'clearPrice';

export const SEO_FRIENDLY_FILTERS = [
    CATEGORY_FILTER,
    FORMAT_FILTER,
    PRICE_FILTER,
    DATE_FILTER,
];

/* Filter Values */

//Pulled from core/../locale_defaults.py
export const LANGUAGES = [
    { value: 'en', display: gettext('English') },
    { value: 'de', display: gettext('German') },
    { value: 'es', display: gettext('Spanish') },
    { value: 'fr', display: gettext('French') },
    { value: 'it', display: gettext('Italian') },
    { value: 'nl', display: gettext('Dutch') },
    { value: 'pt', display: gettext('Portuguese') },
    { value: 'sv', display: gettext('Swedish') },
];

//Pulled from core/../currency_defaults.py
export const CURRENCIES = [
    { value: 'USD', display: gettext('U.S. Dollar') },
    { value: 'CAD', display: gettext('Canadian Dollar') },
    { value: 'EUR', display: gettext('Euro') },
    { value: 'GBP', display: gettext('British Pound') },
    { value: 'JPY', display: gettext('Japanese Yen') },
    { value: 'AUD', display: gettext('Australian Dollar') },
    { value: 'NZD', display: gettext('New Zealand Dollar') },
    { value: 'CHF', display: gettext('Swiss Franc') },
    { value: 'HKD', display: gettext('Hong Kong Dollar') },
    { value: 'SGD', display: gettext('Singapore Dollar') },
    { value: 'SEK', display: gettext('Swedish Krona') },
    { value: 'DKK', display: gettext('Danish Krone') },
    { value: 'PLN', display: gettext('Polish Zloty') },
    { value: 'NOK', display: gettext('Norwegian Krone') },
    { value: 'HUF', display: gettext('Hungarian Forint') },
    { value: 'CZK', display: gettext('Czech Koruna') },
    { value: 'MXN', display: gettext('Mexican Pesos') },
    { value: 'ILS', display: gettext('Israeli Shekels') },
    { value: 'ARS', display: gettext('Argentinian Peso') },
    { value: 'BRL', display: gettext('Brazilian Real') },
    { value: 'CLP', display: gettext('Chilean Peso') },
    { value: 'MYR', display: gettext('Malaysian Ringgits') },
    { value: 'PHP', display: gettext('Philippine Pesos') },
    { value: 'TWD', display: gettext('Taiwan New Dollars') },
    { value: 'THB', display: gettext('Thai baht') },
    { value: 'TRY', display: gettext('Turkish Lira') },
];

const formatFilterArray = (array: any[]) =>
    array.reduce((memo, item) => {
        memo[item.value] = item.display;

        return memo;
    }, {});

export const FORMATTED_LANGUAGES = formatFilterArray(LANGUAGES);
export const FORMATTED_CURRENCIES = formatFilterArray(CURRENCIES);

/* Filter Mapping */
export const FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP: {
    [key: string]: {
        type: string;
        id: string;
        label: GenericLazyString;
        values: any;
        style: string;
        supportsMultipleValues?: boolean;
    };
} = {
    [CATEGORY_FILTER]: {
        type: CATEGORY_FILTER,
        id: 'category-select',
        label: gettext('Category'),
        values: [
            {
                display: CATEGORY_PLACEHOLDER_TEXT,
                value: '',
            },
            ...FORMATTED_CATEGORY_MAPPING,
        ],
        style: STYLE_BASIC,
    },
    [DATE_FILTER]: {
        type: DATE_FILTER,
        id: 'date-select',
        label: gettext('Date'),
        values: [
            {
                display: DATE_PLACEHOLDER_TEXT,
                value: '',
            },
            ...FORMATTED_DATE_MAPPING,
        ],
        style: STYLE_BASIC,
    },
    [EXPERIENCES_FILTER]: {
        type: EXPERIENCES_FILTER,
        id: 'experiences-select',
        label: gettext('Experiences'),
        values: [
            {
                display: EXPERIENCES_PLACEHOLDER_TEXT,
                value: '',
            },
            ...FORMATTED_EXPERIENCES_MAPPING,
        ],
        style: STYLE_BASIC,
    },
    [FORMAT_FILTER]: {
        type: FORMAT_FILTER,
        id: 'format-select',
        label: gettext('Format'),
        values: [
            {
                display: FORMAT_PLACEHOLDER_TEXT,
                value: '',
            },
            ...FORMATTED_FORMAT_MAPPING,
        ],
        style: STYLE_BASIC,
    },
    [PRICE_FILTER]: {
        type: PRICE_FILTER,
        id: 'price-select',
        label: gettext('Price'),
        values: [
            {
                display: PRICE_PLACEHOLDER_TEXT,
                value: CLEAR_PRICE_FILTER,
            },
            ...FORMATTED_PRICE_MAPPING,
        ],
        style: STYLE_BASIC,
    },
    [LANGUAGE_FILTER]: {
        type: LANGUAGE_FILTER,
        id: 'language-select',
        label: gettext('Language'),
        values: [
            {
                display: LANGUAGE_PLACEHOLDER_TEXT,
                value: '',
            },
            ...LANGUAGES,
        ],
        style: STYLE_BASIC,
        supportsMultipleValues: true,
    },
    [CURRENCY_FILTER]: {
        type: CURRENCY_FILTER,
        id: 'currency-select',
        label: gettext('Currency'),
        values: [
            {
                display: CURRENCY_PLACEHOLDER_TEXT,
                value: '',
            },
            ...CURRENCIES,
        ],
        style: STYLE_BASIC,
    },
    [ORGANIZATIONS_FILTER]: {
        type: ORGANIZATIONS_FILTER,
        id: 'organizations-select',
        label: gettext('Only show events'),
        values: [
            {
                display: ORGANIZATIONS_PLACEHOLDER_TEXT,
                value: '',
            },
        ],
        style: STYLE_BASIC,
    },
};

type FilterPillsValues = {
    display: GenericLazyString;
    value: boolean | string;
}[];

export const FILTER_PILLS_DISMISS_MAP: Record<
    string,
    { values: FilterPillsValues; label?: GenericLazyString }
> = {
    [CATEGORY_FILTER]: {
        values: [...FORMATTED_CATEGORY_MAPPING] as FilterPillsValues,
        label: gettext('Category'),
    },
    [SUBCATEGORIES_FILTER]: {
        values: [...FORMATTED_SUBCATEGORY_MAPPING] as FilterPillsValues,
        label: gettext('SubCategory'),
    },
    [DATE_FILTER]: {
        values: [...FORMATTED_DATE_MAPPING] as FilterPillsValues,
        label: gettext('Date'),
    },
    [EXPERIENCES_FILTER]: {
        values: [...FORMATTED_EXPERIENCES_MAPPING] as FilterPillsValues,
        label: gettext('Experience'),
    },
    [FORMAT_FILTER]: {
        values: [...FORMATTED_FORMAT_MAPPING] as FilterPillsValues,
        label: gettext('Format'),
    },
    [PRICE_FILTER]: {
        values: [...FORMATTED_PRICE_MAPPING] as FilterPillsValues,
        label: gettext('Price'),
    },
    [LANGUAGE_FILTER]: {
        values: [...LANGUAGES],
        label: gettext('Language'),
    },
    [CURRENCY_FILTER]: {
        values: [...CURRENCIES],
        label: gettext('Currency'),
    },
    [ORGANIZATIONS_FILTER]: {
        values: [{ display: ORGANIZATIONS_PLACEHOLDER_TEXT, value: true }],
    },
    followedOrganizers: {
        values: [{ display: gettext('Followed Organizers'), value: true }],
    },
    online: {
        values: [{ display: gettext('Online'), value: true }],
    },
};

export const FILTER_DRAWER_DISPLAY_ORDER = [
    'dates',
    'price',
    'divider',
    'category',
    'format',
    'divider',
    'languages',
    'currencies',
];
