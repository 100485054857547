import * as React from 'react';

const FilmMediaSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.887 4.013a.5.5 0 01.598.366l1 4a.5.5 0 01-.372.608L9.857 13H27.5a.5.5 0 01.5.5v14a.5.5 0 01-.5.5h-22a.5.5 0 01-.5-.5V13.57l-.985-3.949a.5.5 0 01.372-.608l1.196-.278a.503.503 0 01.2-.047l4.72-1.097a.502.502 0 01.1-.023l15.284-3.555zM5.718 9.73l-.61.142.756 3.03 3.606-.838L5.718 9.73zm5.13 2.013L7.096 9.41l3.484-.81 3.752 2.333-3.484.81zm8.346-1.94l-3.484.81-3.752-2.334 3.484-.81 3.752 2.333zM6 18h1a.499.499 0 00.355-.148L11.223 14h3.583l-3.012 3H10.5a.5.5 0 000 1H27v9H6v-9zm7.21-1h3.584l3.012-3h-3.583l-3.012 3zm8.584 0H18.21l3.012-3h3.583l-3.012 3zm1.417 0H27v-3h-.777l-3.012 3zM6 17h.793l3.013-3H6v3zm10.82-9.852l3.485-.81 3.751 2.333-3.484.81-3.752-2.333zm8.614 1.203l-3.751-2.334 3.951-.918.758 3.03-.957.222zM8 20.5a.5.5 0 01.5-.5h16a.5.5 0 010 1h-16a.5.5 0 01-.5-.5zm.5 2.5a.5.5 0 000 1h16a.5.5 0 100-1h-16z"
            fill="#0D0029"
        />
    </svg>
);

FilmMediaSvg.displayName = 'FilmMediaSvg';
export default FilmMediaSvg;
