import { getQueryClient } from '@eventbrite/get-create-query-client';
import { QueryClientProvider } from '@tanstack/react-query';
import React, { useContext, useMemo } from 'react';
import {
    ErrorDisplayProvider,
    LocationInputProvider,
    LocationProvider,
    SearchDropdownContext,
    SearchDropdownProvider,
    SearchInputProvider,
} from '../../context';
import { CamelCaseLocation } from '../../types';
import SearchBar from './SearchBar/SearchBar';

export interface SearchBarContainerProps {
    /**
     * Location object to be used for the search bar
     */
    location?: CamelCaseLocation;
    id?: string;
    onSelect?: (value: string) => void;
    onChange?: (value: string) => void;
    args?: any[];
    initialQuery?: string;
}

const client = getQueryClient();

export const SearchBarProviders: React.FunctionComponent<
    SearchBarContainerProps
> = ({ location, initialQuery, children }) => {
    const { initialized } = useContext(SearchDropdownContext);
    const wrappedChildren = useMemo(
        () =>
            initialized ? (
                children
            ) : (
                <SearchDropdownProvider>{children}</SearchDropdownProvider>
            ),
        [initialized, children],
    );

    return (
        <QueryClientProvider client={client}>
            <ErrorDisplayProvider>
                <LocationProvider location={location}>
                    <LocationInputProvider>
                        <SearchInputProvider initialQuery={initialQuery}>
                            {wrappedChildren}
                        </SearchInputProvider>
                    </LocationInputProvider>
                </LocationProvider>
            </ErrorDisplayProvider>
        </QueryClientProvider>
    );
};

export const SearchBarContainerWithProviders: React.FunctionComponent<
    SearchBarContainerProps
> = ({ location, initialQuery }) => (
    <SearchBarProviders location={location} initialQuery={initialQuery}>
        <SearchBar />
    </SearchBarProviders>
);
