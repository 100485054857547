import React from 'react';

import PropTypes from 'prop-types';

import Circular from './Circular';
import Linear from './Linear';

import * as constants from './constants';

const SHAPES_COMPONENT_MAP = {
    [constants.SHAPE_CIRCULAR]: Circular,
    [constants.SHAPE_LINEAR]: Linear,
};

const AccessibleIndicator = ({ progress, type }) => {
    let value = null;
    let max = null;

    if (type === constants.TYPE_DETERMINATE) {
        value = progress;
        max = 100;
    }

    return (
        <progress
            className="eds-is-hidden-accessible"
            value={value}
            max={max}
        />
    );
};

export default class ProgressIndicator extends React.PureComponent {
    static propTypes = {
        /**
         * size and stroke of the loader
         * (small-thin, small-chunky, large-thin, large-chunky)
         */
        size: PropTypes.oneOf(constants.SIZE_NAMES),

        /**
         * shape of the loader (linear | circular)
         */
        shape: PropTypes.oneOf(constants.SHAPES),

        /**
         * Type of indicator for the loader (indeterminate | determinate)
         */
        type: PropTypes.oneOf(constants.TYPES),

        /**
         * Style of the loader (dark | light | gradient | progress)
         */
        style: PropTypes.oneOf(constants.STYLES),

        /**
         * Progress bar if the chosen Type is determinate
         */
        progress: PropTypes.number,
    };

    static defaultProps = {
        size: constants.SIZE_LARGE_CHUNKY,
        shape: constants.SHAPE_CIRCULAR,
        type: constants.TYPE_INDETERMINATE,
        style: constants.STYLE_DARK,
        progress: 0,
    };

    render() {
        const { size, shape, type, style, progress } = this.props;

        const ProgressIndicatorShape = SHAPES_COMPONENT_MAP[shape];

        return (
            <div>
                <ProgressIndicatorShape
                    size={size}
                    shape={shape}
                    type={type}
                    style={style}
                    progress={progress}
                />
                <AccessibleIndicator progress={progress} type={type} />
            </div>
        );
    }
}
