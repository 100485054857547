import { gettext } from '@eventbrite/i18n';
import { getSelectPropValues } from '@eventbrite/eds-select';
import { PRIVACY_REGIME_CPRA, PRIVACY_SIGNAL_GPC } from './constants';
import { HAS_WINDOW } from '@eventbrite/feature-detection';

export const DEVELOPER_URL = 'https://www.eventbrite.com/platform/';
export const INVESTOR_URL =
    'https://investor.eventbrite.com/overview/default.aspx';
export const BLOG_URL = 'https://www.eventbrite.com/blog/';
export const CAREER_URL = 'https://www.eventbritecareers.com/';
export const STATUS_URL = 'https://www.eventbritestatus.com/';

const _HTTP_REGEX = /http:/;

export const getLocales = (locales) =>
    getSelectPropValues(locales, 'locale_name', 'locale');

const _getImpressumLinks = (impressumUrl, tld) => {
    let links = [];

    if (tld === '.de') {
        links = [
            {
                url: impressumUrl,
                content: 'Impressum',
            },
        ];
    }

    return links;
};
const _getCookiesLinks = (serverUrl, localeInfo) => {
    const language = localeInfo.help_center_language;
    const links = [
        {
            url: `${serverUrl}/help/${language}/articles/666792/eventbrite-cookie-policy/`,
            content: gettext('Cookies'),
            rel: 'nofollow',
        },
    ];

    return links;
};

const _getPrivacyLinks = (serverUrl, localeInfo) => {
    const language = localeInfo.help_center_language;
    const links = [
        {
            url: `${serverUrl}/help/${language}/articles/460838/eventbrite-privacy-policy/`,
            content: gettext('Privacy'),
            rel: 'nofollow',
        },
    ];

    return links;
};

export const _getContactSalesLinks = (serverUrl, localeInfo) => {
    const { tld, locale } = localeInfo;
    let links = [];
    const ContactSalesDomainsMap = new Map([
        ['.com', true], // United States
        ['.co.uk', true], // United Kingdom
        ['.com.au', true], // Australia
        ['.co.nz', true], // New Zealand
        ['.de', true], // Deutschland
        ['.es', true], // Spain
        ['.ie', true], // Ireland
        ['.sg', true], // Singapore
    ]);
    const supportSalesContacts =
        ContactSalesDomainsMap.has(tld) ||
        (tld === '.ca' && locale === 'en_CA');

    if (supportSalesContacts) {
        links = [
            {
                url: `${serverUrl}/l/contact-eventbrite-sales/`,
                content: gettext('Contact Sales'),
            },
        ];
    }
    return links;
};

export const _getEuDSAStatementLinks = (serverUrl, tld) => {
    let links = [];
    const DSAStatementDomainsMap = new Map([
        ['.be', true], // België and Belgique
        ['.dk', true], // Denmark
        ['.de', true], // Deutschland
        ['.es', true], // España
        ['.fi', true], // Finland
        ['.fr', true], // France
        ['.ie', true], // Ireland
        ['.it', true], // Italia
        ['.nl', true], // Nederland
        ['.at', true], // Österreich
        ['.pt', true], // Portugal
        ['.ch', true], // Suisse and Schweiz
        ['.se', true], // Sverige
        ['.co.uk', true], // United Kingdom
    ]);

    if (DSAStatementDomainsMap.has(tld)) {
        links = [
            {
                url: 'https://www.eventbrite.com/help/en-us/articles/640798/',
                content: 'EU DSA Statement',
            },
        ];
    }
    return links;
};

export const MANAGE_COOKIE_CONSENT = {
    url: '#',
    content: gettext('Manage Cookie Preferences'),
    onClick: (event) => {
        event.preventDefault();
        window?.transcend?.showConsentManager?.({
            viewState: 'CompleteOptions',
        });
    },
};

export const getCookieManagementLink = () => {
    return MANAGE_COOKIE_CONSENT;
};

export const CPRA_DO_NOT_SELL_LINK = {
    url: '#',
    content: gettext('Do Not Sell or Share My Personal Information'),
    onClick: (event) => {
        event.preventDefault();
        window?.transcend?.showConsentManager?.({
            viewState: 'DoNotSellExplainer',
        });
    },
};

export const getPrivacyLink = () => {
    if (!HAS_WINDOW) return false;

    return CPRA_DO_NOT_SELL_LINK;
};

export const getMinimalGeneralLinks = (serverUrl, localeInfo) => [
    {
        url: `${serverUrl}/l/legalterms/`,
        content: gettext('Terms'),
        rel: 'nofollow',
    },
    ..._getPrivacyLinks(serverUrl, localeInfo),
    ..._getCookiesLinks(serverUrl, localeInfo),
];

export const getGeneralLinks = (serverUrl, localeInfo, helpLinks) => [
    {
        url: `${serverUrl}${
            localeInfo.uses_language_subdirectory ? `/${localeInfo.locale}` : ''
        }/how-it-works`,
        content: gettext('How It Works'),
        additionalClassName:
            'eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw',
    },
    {
        url: `${serverUrl}/organizer/pricing/`,
        content: gettext('Pricing'),
        additionalClassName:
            'eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw',
    },
    {
        url: `${serverUrl}/help/${localeInfo.help_center_language}/contact-us/`,
        content: gettext('Contact Support'),
        additionalClassName:
            'eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw',
    },
    {
        url: `${serverUrl}${
            localeInfo.uses_language_subdirectory ? `/${localeInfo.locale}` : ''
        }/about/`,
        content: gettext('About'),
    },
    {
        url: BLOG_URL,
        content: gettext('Blog'),
    },
    {
        url: `${serverUrl}/help/${localeInfo.help_center_language}/`,
        content: gettext('Help'),
    },
    {
        url: CAREER_URL,
        content: gettext('Careers'),
    },
    {
        url: `${serverUrl}${localeInfo['press_blog_url']}`,
        content: gettext('Press'),
    },
    {
        url: 'https://www.eventbrite.com/l/impact/',
        content: gettext('Impact'),
    },
    {
        url: INVESTOR_URL,
        content: gettext('Investors'),
    },
    {
        url: `${serverUrl}${
            localeInfo.uses_language_subdirectory ? `/${localeInfo.locale}` : ''
        }/security/`,
        content: gettext('Security'),
        rel: 'nofollow',
    },
    {
        url: `${serverUrl}/platform/`,
        content: gettext('Developers'),
    },
    {
        url: STATUS_URL,
        content: gettext('Status'),
    },
    {
        url: `${serverUrl}${
            localeInfo.uses_language_subdirectory ? `/${localeInfo.locale}` : ''
        }/l/legalterms/`,
        content: gettext('Terms'),
        rel: 'nofollow',
    },
    ..._getPrivacyLinks(serverUrl, localeInfo),
    {
        url: `${serverUrl}/l/accessibility/`,
        content: gettext('Accessibility'),
    },
    ..._getCookiesLinks(serverUrl, localeInfo),
    ..._getEuDSAStatementLinks(serverUrl, localeInfo.tld),
    ..._getImpressumLinks(helpLinks.impressum, localeInfo.tld),
];

export const getSecondarySections = (isUserAuthenticated, countries) => {
    let secondarySections;

    if (isUserAuthenticated) {
        secondarySections = [
            {
                title: gettext('Country'),

                links: countries.map(
                    ({ href, locale, locale_name: content }) => {
                        const url = href.replace(_HTTP_REGEX, 'https:');

                        return {
                            url,
                            content,
                            'data-automation': `footer-${locale}`,
                        };
                    },
                ),
            },
        ];
    }

    return secondarySections;
};

const _getMainLinks = (links) =>
    links.map(({ link, title }) => ({
        url: link,
        content: title,
    }));

const _getSocialLinks = (serverUrl, localeInfo) => [
    {
        url: `${serverUrl}/help/${localeInfo.help_center_language}/contact-us/`,
        content: gettext('Contact Support'),
    },
    ..._getContactSalesLinks(serverUrl, localeInfo),
    {
        url: `https://${localeInfo['twitter_page']}`,
        content: 'X',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        url: `https://${localeInfo['facebook_page']}`,
        content: gettext('Facebook'),
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        url: `https://${localeInfo['linked_in_page']}`,
        content: gettext('LinkedIn'),
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        url: `https://${localeInfo['instagram_page']}`,
        content: gettext('Instagram'),
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        url: `https://${localeInfo['tiktok_page']}`,
        content: gettext('TikTok'),
        target: '_blank',
        rel: 'noopener noreferrer',
    },
];

export const getMainSections = (
    serverUrl,
    localeInfo,
    popularSearches,
    eventsToPlan,
    thirdFooterColumn,
    thirdFooterColumnName,
) => [
    {
        title: gettext('Use Eventbrite'),
        links: _getMainLinks(popularSearches),
    },
    {
        title: gettext('Plan Events'),
        links: _getMainLinks(eventsToPlan),
    },
    {
        title: thirdFooterColumnName,
        links: _getMainLinks(thirdFooterColumn),
    },
    {
        title: gettext('Connect With Us'),
        links: _getSocialLinks(serverUrl, localeInfo),
    },
];
