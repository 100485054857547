import * as React from 'react';

const GlobeChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="globe-chunky_svg__eds-icon--globe-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="globe-chunky_svg__eds-icon--globe-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8m5.9 7h-2c-.2-1.9-1.1-3.6-2.5-4.8 2.4.6 4.1 2.5 4.5 4.8m-4 0h-3.8c.2-1.3.9-2.6 1.9-3.5 1 .9 1.7 2.2 1.9 3.5m-3.4-4.8C9.2 7.4 8.3 9.1 8.1 11h-2c.4-2.3 2.1-4.2 4.4-4.8M6.1 13h2c.2 1.9 1.1 3.6 2.5 4.8-2.4-.6-4.1-2.5-4.5-4.8m4 0h3.8c-.2 1.3-.9 2.6-1.9 3.5-1-.9-1.7-2.2-1.9-3.5m3.4 4.8c1.3-1.2 2.2-2.9 2.5-4.8h2c-.5 2.3-2.2 4.2-4.5 4.8"
        />
    </svg>
);

GlobeChunkySvg.displayName = 'GlobeChunkySvg';
export default GlobeChunkySvg;
