import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { AnalyticsOptions } from '../types';

export const trackSearchTakeoverEvent = (
    analyticsOptions: AnalyticsOptions[] | AnalyticsOptions,
): void => {
    if (Array.isArray(analyticsOptions)) {
        for (const action of analyticsOptions) {
            trackAnalyticsEvent({
                category: 'search-takeover',
                ...action,
            });
        }
    } else {
        trackAnalyticsEvent({
            category: 'search-takeover',
            ...analyticsOptions,
        });
    }
};
