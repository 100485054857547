import Cookies from 'cookies-js';
import get from 'lodash/get';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { USER_LOCATION_COOKIE, DEFAULT_USER_LOCATION } from '../constants';

const _getUserLocationCookie = () =>
    HAS_WINDOW ? Cookies.get(USER_LOCATION_COOKIE) : {};

const _hasLatLng = (searchLocation) =>
    searchLocation &&
    get(searchLocation, ['latitude']) &&
    get(searchLocation, ['longitude']);

export const getUserLocationFromRequest = (searchLocation) => {
    const userLocationCookie = _getUserLocationCookie();

    if (get(userLocationCookie, ['place_id'])) {
        return {
            placeId: get(userLocationCookie, ['place_id']),
        };
    } else if (_hasLatLng(searchLocation)) {
        return {
            latitude: get(searchLocation, ['latitude']),
            longitude: get(searchLocation, ['longitude']),
        };
    } else {
        return {
            placeId: DEFAULT_USER_LOCATION,
        };
    }
};
