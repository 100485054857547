import React from 'react';
import PropTypes from 'prop-types';
import { ITEMS_PROP_TYPE as NAV_ITEMS_PROP_TYPE } from '@eventbrite/eds-nav-list';
import { TEXT_ITEMS_PROP_TYPE } from '@eventbrite/eds-text-list';
import { ICON_TYPE_PROP_TYPE } from '@eventbrite/eds-icon';
import { ROUTER_LINK, ANCHOR_LINK } from '@eventbrite/eds-nav-list-item';

import { LogoWordmarkBrand } from '@eventbrite/eds-iconography';

export const DEFAULT_ID = 'global-header';

export const DEFAULT_LOGO_TYPE = <LogoWordmarkBrand />;
export const DEFAULT_LOGO_COLOR = 'ui-orange';
export const DEFAULT_LOGO_SIZE = {
    width: '99px',
    height: '20px',
};

export const DEFAULT_LOGO_TITLE = 'Eventbrite';

export const DEFAULT_SEARCH_METHOD = 'GET';
export const DEFAULT_SEARCH_FIELD_NAME = 'q';

export const LINK_PROP_TYPE = PropTypes.shape({
    // if type is ROUTER_LINK `url` must be an absolute path
    url: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    narrowIconType: ICON_TYPE_PROP_TYPE,
    isActive: PropTypes.bool,
    // if type is ROUTER_LINK we use react-router's Link
    type: PropTypes.oneOf([ROUTER_LINK, ANCHOR_LINK]),
    onClick: PropTypes.func,
});

export const LINKS_PROP_TYPE = PropTypes.arrayOf(LINK_PROP_TYPE);

export const DROPDOWN_PROP_TYPE = PropTypes.shape({
    label: PropTypes.node.isRequired,
    fallbackUrl: PropTypes.string.isRequired,
    textItems: TEXT_ITEMS_PROP_TYPE,
    navItems: NAV_ITEMS_PROP_TYPE,
    iconType: ICON_TYPE_PROP_TYPE,
    imageUrl: PropTypes.string,
});

export const STYLE_DEFAULT = 'default';
export const STYLE_ORGANIZER = 'organizer';
export const STYLES = [STYLE_DEFAULT, STYLE_ORGANIZER];
export const CREATOR_AFFILIATE_CODE = 'oddtdtcreator';
