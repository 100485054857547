import { Actions, Components, Features, Teams } from './heap-attributes';
import { HeapDataAttribute } from './heap-types';

const getHeapDataAttribute = (params: {
    team: Teams;
    feature: Features;
    component: Components;
    name: string;
    action: Actions;
}): HeapDataAttribute => {
    const { team, feature, component, name, action } = params;

    return `${team}-${feature}-${component}-${name}-${action}`;
};

export { getHeapDataAttribute };
