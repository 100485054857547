import { HAS_WINDOW } from '@eventbrite/feature-detection';

export const getBrowserLocationPermission = () => {
    let promise;

    if (HAS_WINDOW && window.navigator && window.navigator.permissions) {
        promise = navigator.permissions.query({ name: 'geolocation' }).then(
            (result) => result.state,
            () => 'Error fetching browser geolocation permissions',
        );
    } else {
        promise = new Promise((_, reject) =>
            reject('No geolocation permissions object'),
        );
    }

    return promise;
};

export const getBrowserLocation = () => {
    let promise;

    if (HAS_WINDOW && window.navigator && window.navigator.geolocation) {
        const locationPromise = new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const timeOut = new Promise((_, reject) => {
            setTimeout(reject, 6000, 'error');
        });

        promise = Promise.race([locationPromise, timeOut]);
    } else {
        promise = new Promise((_, reject) => reject('No geolocation object'));
    }

    return promise;
};
