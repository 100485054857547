import { getWindowObject, HAS_WINDOW } from '@eventbrite/feature-detection';
import { DEFAULT_INSTANCE_NAME, MESSAGE_EVENT_TO_LISTEN } from './constants';
import Store from './store';
import {
    Callback,
    IntercomCallback,
    IntercomContext,
    MessageObject,
    ShallowObj,
} from './types';

/**
 * Main class of the package, responsible for storing data, allows to modify it and dispatch all the callbacks
 * to react to any change
 */
export default class Intercom {
    /**
     * this attribute represents the global store where all the shared data will live
     */
    private _store: Store;
    /**
     *
     * @param initialData: ShallowObj
     */
    private constructor(initialData: ShallowObj) {
        this._store = new Store(initialData);
    }
    /**
     *
     * @param initialData: ShallowObj       Data to initialize store
     * @returns {*}: Intercom | never
     */
    static getInstance(initialData?: ShallowObj): Intercom | never {
        if (HAS_WINDOW) {
            if (!getWindowObject(DEFAULT_INSTANCE_NAME)) {
                window[DEFAULT_INSTANCE_NAME] = new Intercom(initialData || {});
            }
            return window[DEFAULT_INSTANCE_NAME];
        } else {
            throw new Error('window object not reachable, shutting things off');
        }
    }
    /**
     * allows applications to emit a message in order to change the stored values
     * @param eventName:string          The event name to dispatch
     * @param payload: ShallowObj       The data for store updating
     */
    public publish(eventName: string, payload: Partial<ShallowObj>) {
        this._store.update(payload);
        const newState = this.createMessageWrapper(
            eventName,
            this._store.getStore(),
        );
        window.postMessage(newState, '*');
    }
    /**
     * allows applications to emit a message in order to trigger a callback somewhere including context,
     * THIS METHOD DOES NOT MODIFY THE STORE
     * @param eventName: string             The event name to subscribe
     * @param context: IntercomContext      Context needed to trigger correctly the callback
     */
    public quietPublish(eventName: string, context: IntercomContext) {
        const newState = this.createMessageWrapper(eventName, context);
        window.postMessage(newState, '*');
    }
    /**
     * subscribe to events happening in the instance
     * @param eventName: string             The event name to subscribe
     * @param callback: IntercomCallback    The real function to execute when the event is dispatched
     *
     * @returns {*} modified handler, used for unsubscribe
     */
    public subscribe(
        eventName: string,
        callback: IntercomCallback,
    ): [() => void, ShallowObj] {
        const finalHandler = this.createEventHandler(eventName, callback);
        window.addEventListener(MESSAGE_EVENT_TO_LISTEN, finalHandler);

        return [
            this.createUnsubscribeFunction(finalHandler),
            this._store.getStore(),
        ];
    }
    /**
     * remove specific event listener
     * @param callback: Callback    The registered function we need to unsubscribe from an event
     * @returns {*}: Function       A function to execute when need to unsubscribe from the event
     */
    private createUnsubscribeFunction(callback: Callback) {
        return () => {
            window.removeEventListener(MESSAGE_EVENT_TO_LISTEN, callback);
        };
    }
    /**
     *
     * @param type: string          The event dispatched
     * @param payload: ShallowObj   The update data from an event
     * @returns
     */
    private createMessageWrapper(
        type: string,
        payload: Partial<ShallowObj>,
    ): MessageObject<ShallowObj> {
        return {
            type,
            payload,
        };
    }
    /**
     * creates a wrapper over the given callback to only call it if the event
     * subscribed is the one dispatched
     * @param event: string                 The event to handle
     * @param callback: IntercomCallback    The funciton to exceute when event is fired
     * @returns {*}: Callback
     */
    private createEventHandler(
        event: string,
        callback: IntercomCallback,
    ): Callback {
        return ({ data: { type, payload } }) => {
            if (event === type) {
                callback.call(window, payload);
            }
        };
    }
}
