import { Icon } from '@eventbrite/eds-icon';
import { STYLE_LIGHT, STYLE_DARK } from '@eventbrite/eds-progress-indicator';

export const TYPE_BUTTON = 'button';
export const TYPE_SUBMIT = 'submit';
export const TYPE_LINK = 'link';

export const TYPES = [TYPE_BUTTON, TYPE_SUBMIT, TYPE_LINK];

export const STYLE_NONE = 'none';
export const STYLE_ANCHOR = 'anchor';

// new styles
export const STYLE_LINK = 'link';
export const STYLE_DANGER = 'danger';
export const STYLE_NEUTRAL = 'neutral';
export const STYLE_FILL = 'fill';
export const STYLE_INVERSE = 'inverse';
export const STYLE_FOLLOW = 'follow';
export const STYLE_APPLE_PAY = 'apple_pay';
export const STYLE_REBRANDING_FILL = 'rebranding-fill';
export const STYLE_REBRANDING_NEUTRAL = 'rebranding-neutral';

// Patch button styles so we don't break things
export const STYLES = [
    STYLE_NONE,
    STYLE_LINK,
    STYLE_DANGER,
    STYLE_NEUTRAL,
    STYLE_FILL,
    STYLE_INVERSE,
    STYLE_ANCHOR,
    STYLE_FOLLOW,
    STYLE_APPLE_PAY,
    STYLE_REBRANDING_FILL,
    STYLE_REBRANDING_NEUTRAL,
];

export const BUTTON_PROGRESS_STYLE = {
    [STYLE_NONE]: STYLE_DARK,
    [STYLE_LINK]: STYLE_DARK,
    [STYLE_DANGER]: STYLE_DARK,
    [STYLE_NEUTRAL]: STYLE_DARK,
    [STYLE_FILL]: STYLE_LIGHT,
    [STYLE_INVERSE]: STYLE_LIGHT,
    [STYLE_ANCHOR]: STYLE_DARK,
    [STYLE_APPLE_PAY]: STYLE_LIGHT,
    [STYLE_REBRANDING_FILL]: STYLE_LIGHT,
    [STYLE_REBRANDING_NEUTRAL]: STYLE_DARK,
};

export const SIZE_RESPONSIVE = 'responsive';
export const SIZE_BLOCK = 'block';

export const SIZES = [SIZE_RESPONSIVE, SIZE_BLOCK];
export const CHILD_COMPONENT_WHITELIST = [Icon];
