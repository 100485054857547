/**
 * We are using the 'roving tabindex' method for focus-management, which specifies the
 * following procedures for moving focus from element to another:
 * 1) programmatically apply focus to the new element,
 * 2) update the tabindex of the focused element to "0", and
 * 3) update the tabindex of the previously focused element to "-1".
 *
 * Read the guidance below as to why positive tab indices are discouraged.
 * Importing tabindex from this constants.js file enforces uniformity.
 * http://webaim.org/techniques/keyboard/tabindex
 */
export const TABBABLE_INDEX = 0;
export const UNTABBABLE_INDEX = -1;

/**
 * Import constants below to hotKeys.js files within component folders as needed.
 * If it is a new keyboard action, append to this list with the action name.
 * Keep keyboard-action names generic but descriptive.
 */
export const HIDE_CHILD = 'hideChild';
export const SHOW_CHILD = 'showChild';
export const MOVE_TO_NEXT = 'moveToNext';
export const MOVE_TO_PREV = 'moveToPrev';
export const MOVE_TO_FIRST = 'moveToFirst';
export const MOVE_TO_LAST = 'moveToLast';
export const ACTIVATE_ITEM = 'activateItem';
export const RESET_STATE = 'resetState';
