const getMountingPoint = (
    domNodeId?: string,
    domNode?: Element,
): Element | boolean => {
    if (domNode) {
        return domNode;
    }

    if (domNodeId) {
        return document.getElementById(domNodeId) as Element;
    }

    return false;
};

export default getMountingPoint;
