import * as React from 'react';

const InfoChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="info-chunky_svg__eds-icon--info-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="info-chunky_svg__eds-icon--info-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 6c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6zm0 14c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8z"
        />
        <path
            id="info-chunky_svg__eds-icon--info-chunky_dot"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 8h2v2h-2z"
        />
        <path
            id="info-chunky_svg__eds-icon--info-chunky_line"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 11h2v5h-2z"
        />
    </svg>
);

InfoChunkySvg.displayName = 'InfoChunkySvg';
export default InfoChunkySvg;
