import some from 'lodash/some';

/*
 * This util is used for performing data transformation from a higher level component using Select
 * Given any array of objects, the method converts it to a Select-friendly array with display and value
 * If an array of numbers or strings are passed, it'll return the same value and display
 * @param {array} values, usually passed in as a prop, to be converted into select 'values' prop
 * @param {string} key to be used for display, defaults to 'display'
 * @param {string} key to be used for value, defaults to 'value'
 */
export const getSelectPropValues = (
    values,
    displayKey = 'display',
    valueKey = 'value',
) => {
    const hasNonObjectTypes = some(
        values,
        (value) => typeof value !== 'object',
    );
    let selectValues;

    if (hasNonObjectTypes) {
        selectValues = values.map((value) => {
            const display = String(value);

            return { display, value: display };
        });
    } else {
        selectValues = values.map(
            ({ [displayKey]: display, [valueKey]: value }) => ({
                display,
                value,
            }),
        );
    }

    return selectValues;
};
