import * as React from 'react';

const BugSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg className="bug_svg__eds-icon--bug_svg" viewBox="0 0 24 24" {...props}>
        <path
            className="bug_svg__eds-icon--bug_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 16v-1h-3v-2h1.4l1.4-1.4-.7-.7-1.2 1.1H18V9h-1.6v-.5c0-1.9-1.1-3.5-2.8-4.2L15.1 3h.9V2h-1.5.1l-2 2h-1.2l-2-2h.1H8v1h.9l1.3 1.3C8.6 5 7.5 6.6 7.5 8.5V9H6v3h-.9L4 10.8l-.7.7L4.6 13H6v2H3v1h3c0 .7.1 1.4.3 2H4.6l-1.4 1.4.7.7L5.1 19h1.7c1 1.8 3 3 5.2 3s4.2-1.2 5.2-3h1.7l1.2 1.2.7-.7-1.4-1.5h-1.7c.2-.6.3-1.3.3-2h3zM8.5 8.9v-.4C8.5 6.6 10.1 5 12 5s3.5 1.6 3.5 3.5V9h-7v-.1zM7 16v-6h4.5v11C9 20.7 7 18.6 7 16zm5.5 5V10H17v6c0 2.6-2 4.7-4.5 5z"
        />
    </svg>
);

BugSvg.displayName = 'BugSvg';
export default BugSvg;
