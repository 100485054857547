/**
 * Helper function that returns the string to be displayed in the Badge
 */
export const getCount = (currentCount, thresholdCount) => {
    let count = currentCount;

    if (currentCount > thresholdCount) {
        count = `${thresholdCount}+`;
    }

    return count;
};
