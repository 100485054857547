import { logEvent } from '@eventbrite/statsig';
import { InteractionsLogger } from './interactionsLogger';
import {
    EventInteraction,
    EventInteractionContext,
    EventInteractions,
} from './types';

type InteractionStatsigLoggerData = {
    name: string;
    metadata: {
        app: string;
        rank: string;
        isOnline: string;
        page: string;
        isPromoted: string;
        hasUrgencySignals: string;
        isFree: string;
    };
};

export class InteractionsStatsigLogger extends InteractionsLogger<InteractionStatsigLoggerData> {
    ORIGIN = 'web';

    protected allowedEvents: EventInteractions[] = [
        EventInteractions.Impression,
        EventInteractions.Click,
    ];
    protected filterEventsBy(
        i: EventInteraction<EventInteractionContext>,
    ): boolean {
        return i.event.isPromoted;
    }
    protected transformInteraction(
        i: EventInteraction<EventInteractionContext>,
    ): InteractionStatsigLoggerData {
        const urgencySignals = i.displayContext?.urgencySignals || [];
        const hasUrgencySignals = urgencySignals.length > 0;

        return {
            name: this.getStatsigEventName(i),
            metadata: {
                app: this.ORIGIN,
                rank: `${i.place.position}`,
                isOnline: `${i.event.isOnline}`,
                page: `${i.place.page}`,
                isPromoted: `${i.event.isPromoted}`,
                hasUrgencySignals: `${hasUrgencySignals}`,
                isFree: `${i.event.isFree}`,
            },
        };
    }

    log(interactions: InteractionStatsigLoggerData[]) {
        interactions.forEach((event) =>
            logEvent(event.name, undefined, event.metadata),
        );
    }

    private getStatsigEventName(i: EventInteraction<EventInteractionContext>) {
        const dataPoints = [
            i.place.name,
            i.place.subInterface?.name,
            i.action,
            'Promoted',
            'Event(s)',
        ].filter((str) => !!str) as string[];
        return dataPoints.map(snakeToTitle).join(' - ');
    }
}

function snakeToTitle(snakeCaseText: string): string {
    const words = snakeCaseText.split('_');
    const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1),
    );
    return capitalizedWords.join(' ');
}
