import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { translationPropType } from '@eventbrite/i18n';
import FieldWrapper from './FieldWrapper';
import { getValue } from './utils';
import { getAdditionalProps } from '@eventbrite/eds-utils';
import omit from 'lodash/omit';

import * as constants from './constants';

import './inputField.scss';

const InputElement = ({
    disabled,
    hasError,
    id,
    required,
    maxLength,
    name,
    placeholder,
    role,
    shouldHideRole,
    type,
    value,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    inputRef,
    dataAutomation,
    dataTestid,
    suffix,
    formikFieldValues,
    ...extraAttrs
}) => {
    const handleKeyPressForNumberTypes = (e) => {
        const pressedKey = e.key;
        const isDigitKey = /^\d+$/.test(pressedKey);

        if (!isDigitKey) {
            return e.preventDefault();
        }
        return null;
    };
    const onInput = (e) => {
        const newValue = e.target.value;

        // Unicode categories defined at https://www.unicode.org/reports/tr44/#GC_Values_Table
        const normalizedValue = newValue
            .replace(/\p{C}/gu, '') // Remove invisible control characters and unused code points
            .replace(/\n\r/g, '\n') // Normalize newlines
            .replace(/\p{Zl}/gu, '\n') // Normalize U+2028 LINE SEPARATOR to a newline
            .replace(/\p{Zp}/gu, '\n') // Normalize U+2029 PARAGRAPH SEPARATOR to a newline
            .replace(/\p{Zs}/gu, ' '); // Normalize non-zero space separators to space

        if (normalizedValue !== newValue) {
            e.target.value = normalizedValue;
        }
    };
    let inputType = type;
    const extraAttrsRef = omit(extraAttrs, ['field', 'isResizable', 'isValid']);
    const classNames = classnames('eds-field-styled__input', {
        'eds-field-styled__input--has-suffix': suffix,
    });

    if (type === 'number') {
        inputType = 'tel';
        extraAttrsRef.onKeyPress = handleKeyPressForNumberTypes;
    }

    const conditionalAttrs = shouldHideRole ? {} : { role };

    return (
        <input
            data-spec="input-field-input-element"
            aria-invalid={hasError}
            aria-required={required}
            aria-disabled={disabled}
            className={classNames}
            data-automation={dataAutomation}
            data-testid={dataTestid}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onInput={onInput}
            placeholder={placeholder?.toString?.() || placeholder}
            type={inputType}
            value={value}
            ref={inputRef}
            {...conditionalAttrs}
            {...extraAttrsRef}
            {...formikFieldValues}
            onBlur={onBlur} // Needs to be after the formikFieldValues because formik overrides onBlur
        />
    );
};

const AreaElement = ({
    disabled,
    hasError,
    id,
    required,
    name,
    placeholder,
    role,
    shouldHideRole,
    type,
    value,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    inputRef,
    dataAutomation,
    dataTestid,
    suffix,
    formikFieldValues,
    isResizable,
    ...extraAttrs
}) => {
    const conditionalAttrs = shouldHideRole ? {} : { role };
    const extraAttrsRef = omit(extraAttrs, 'field');

    const classNames = classnames(
        'eds-field-styled__input eds-field-styled__input--multiline',
        {
            'eds-field-styled__input--multiline-resizable': isResizable,
        },
    );

    return (
        <textarea
            data-spec="input-field-area-element"
            aria-invalid={hasError}
            aria-required={required}
            className={classNames}
            data-automation={dataAutomation}
            data-testid={dataTestid}
            data-suffix={suffix}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            placeholder={placeholder?.toString?.() || placeholder}
            type={type}
            value={value}
            ref={inputRef}
            {...conditionalAttrs}
            {...extraAttrsRef}
            {...formikFieldValues}
            onBlur={onBlur} // Needs to be after the formikFieldValues because formik overrides onBlur
        />
    );
};

export default class InputField extends PureComponent {
    static propTypes = {
        /**
         * The custom annotation element associated to FieldAnnotation component
         */
        annotationTag: PropTypes.string,
        /**
         * Sets the id for the input element and htmlFor attribute for the label.
         */
        id: PropTypes.string.isRequired,

        /**
         * Content to be displayed as the label of the input element.
         * Required for accessibility reasons but it can be hidden.
         */
        label: PropTypes.node.isRequired,

        /**
         * Content to be displayed as annotation below the input element.
         */
        annotationNote: PropTypes.node,

        /**
         * The amount of spacing for the bottom
         */
        bottomSpacing: PropTypes.number,

        /**
         * Default value of input element.
         * This is the initial value.
         * After the text input has been rendered the first time,
         * if the default value changes, it will *not* update the input.
         */
        defaultValue: PropTypes.string,

        /**
         * Sets disabled attribute to the input element.
         */
        disabled: PropTypes.bool,

        /**
         * whether clickable or not
         * false by default
         */
        isReadOnly: PropTypes.bool,

        /**
         * Field style chosen for the input. It can be one of: 'dynamic', 'static' or 'basic'.
         * Dynamic: the label is covering the placeholder of the input and moves up when you click the input.
         * Static: the label and the placeholder are always in place and visible.
         * Basic: the label is hidden but still present for accessibility purposes.
         */
        style: PropTypes.oneOf(constants.STYLES),

        /**
         * Field border type chosen for the input. It can be one of: 'default', 'simple' or 'none'.
         * Default: the border changes from grey on idle to a coloured state on focus.
         * Simple: the border preserves its grey colour and has no rounded corners.
         * None: the border is hidden and is replaced by a line below the input to point the writtable area.
         */
        borderType: PropTypes.oneOf(constants.BORDER_TYPES),

        /**
         * Sets error state (adds error classes and aria-invalid).
         */
        hasError: PropTypes.bool,

        /**
         * Changes the input field for a textarea
         */
        isMultiline: PropTypes.bool,

        /**
         * Marks the input as required (adds * and aria-required).
         */
        required: PropTypes.bool,

        /**
         * Sets the max length of the character counter and also limits the
         * number of characters you can input.
         */
        maxLength: PropTypes.number,

        /**
         * Name of input element.
         */
        name: PropTypes.string,

        /**
         * {function} onBlur
         * By default sets isActive state to false and delegates the control to
         * the onBlur function passed as prop.
         */
        onBlur: PropTypes.func,

        /**
         * {function} onChange
         * By default sets the input value to the state and delegates
         * the control to the onChange function passed as prop.
         * WARNING: Not triggered if using Formik
         */
        onChange: PropTypes.func,

        /**
         * {function} onFocus
         * By default sets isActive state to true and delegates the control to
         * the onFocus function passed as prop.
         */
        onFocus: PropTypes.func,

        /**
         * {function} onKeyDown
         * By default triggers the function passed when the user press enter on
         * the field with an object with {event, value}.
         */
        onKeyDown: PropTypes.func,

        /**
         * {function} onKeyUp
         * By default triggers the function passed when the user press enter on
         * the field with an object with {event, value}.
         */
        onKeyUp: PropTypes.func,

        /**
         * Placeholder text used to show type of data.
         */
        placeholder: translationPropType,

        /**
         * Node to be displayed to the left of the input
         */
        prefix: PropTypes.node,

        /**
         * Sets the role attribute of the input element.
         */
        role: PropTypes.string,

        /**
         * Not including the role for the input, like for password field
         */
        shouldHideRole: PropTypes.bool,

        /**
         * Node to be displayed to the right of the input.
         */
        suffix: PropTypes.node,

        /**
         * Set input type for the input element. Ignored if `isMultiline` is true.
         * Options: text, password, email, url, tel, search, date or number.
         */
        type: PropTypes.oneOf(constants.TYPES),

        /**
         * Value of text input
         * The value can override the default value if specified
         * After the text input has been rendered the first time,
         * if the value changes, it *will* update the input.
         */
        value: PropTypes.string,

        /**
         * Needed for inputs that use an aside element (either prefix or suffix)
         * that is bigger than the normal text size. If the content (maybe a button or icon)
         * is getting cropped on the sides, this lowers the margin allowing it to
         * center within the aside again.
         *
         * NOTE: This property is deprecated in favor of minAsideSpacing.
         */
        hasMinAsideSpacing: PropTypes.bool,

        /**
         * Needed for inputs that use an aside element (either prefix, suffix or both)
         * that is bigger than the normal text size. If the content (maybe a button or icon)
         * is getting cropped on the sides, this lowers the margin allowing it to
         * center within the aside again.
         */
        minAsideSpacing: constants.MIN_ASIDE_SPACING_PROP_TYPES,

        /**
         * Identifier for tests.
         */
        'data-spec': PropTypes.string,
        /**
         * Identifier for automation tests
         */
        'data-automation': PropTypes.string,

        /**
         * Toggles a display of the current character count against the maximum characters allowed in the field.
         */
        hasCharacterCount: PropTypes.bool,

        /**
         * Text for the link to display.
         */
        linkText: PropTypes.string,

        /**
         * Source url of the link to display.
         */
        linkSrc: PropTypes.string,

        /**
         * Default active state of the input field
         */
        defaultIsActive: PropTypes.bool,

        /**
         * Whether or not we should focus the input field
         */
        shouldFocus: PropTypes.bool,
        /**
         * The dragstart event is fired when the user starts dragging an element or text selection.
         * https://developer.mozilla.org/en-US/docs/Web/API/Document/dragstart_event
         *
         * NOTE: This property is usually defined and passed to your form elements by `redux-form`.
         */
        onDragStart: PropTypes.func,
        /**
         * The drop event is fired when an element or text selection is dropped on a valid drop target.
         * https://developer.mozilla.org/en-US/docs/Web/API/Document/drop_event
         *
         * NOTE: This property is usually defined and passed to your form elements by `redux-form`.
         */
        onDrop: PropTypes.func,
        /**
         * Transforms the input field value
         */
        normalizer: PropTypes.func,
        /**
         * Replaces the input field with the normalized value
         */
        normalizeField: PropTypes.func,

        /**
         * Changes the input field for a textarea that auto-grows when the user types
         * Until it reaches 10 lines
         */
        isResizable: PropTypes.bool,

        /**
         * Converts aside into clickable element that focuses on input field onclick
         */
        isAsideClickable: PropTypes.bool,

        /**
         * Tag list to display.
         */
        tagListComponent: PropTypes.element,

        /**
         * True if the app rebrand is active
         */
        isAppRebrandActive: PropTypes.bool,

        /**
         * Node to be rendered along with the annotation note
         */
        annotationDecoration: PropTypes.node,
    };

    static defaultProps = {
        bottomSpacing: 2,
        type: constants.TYPE_TEXT,
        role: 'textbox',
        defaultValue: '',
        isMultiline: false,
        style: constants.STYLE_DYNAMIC,
        borderType: constants.BORDER_DEFAULT,
        'data-spec': 'input-field',
        hasCharacterCount: false,
        defaultIsActive: false,
        shouldHideRole: false,
        isResizable: false,
        isReadOnly: false,
    };

    constructor(props) {
        super(props);

        const value = getValue(props);
        const { shouldFocus } = props;

        this.state = {
            isActive: props.defaultIsActive || !!shouldFocus,
            value,
        };
    }

    componentDidMount() {
        const { defaultIsActive, shouldFocus } = this.props;

        if (defaultIsActive || shouldFocus) {
            this._focus();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const value = getValue(nextProps);
        const { shouldFocus } = nextProps;

        const nextState = {};
        const fieldValue = this._getFieldValue();

        if (value !== fieldValue) {
            nextState.value = value;
        }

        if (
            typeof shouldFocus === 'boolean' &&
            shouldFocus !== this.state.isActive
        ) {
            nextState.isActive = shouldFocus;
        }

        this.setState(nextState);
    }

    componentDidUpdate() {
        const { isActive, value } = this.state;
        const { normalizeField, normalizer, name } = this.props;

        if (isActive) {
            this._focus();
        }

        const shouldNormalize =
            normalizeField && normalizer && value !== normalizer(value);

        if (!isActive && shouldNormalize) {
            normalizeField(name, value, normalizer);
        }
    }

    _getRef(ref) {
        this._input = ref;

        return this._input;
    }

    _focus() {
        this._input.focus();
    }

    // Field is a prop coming from Formik only
    // Formik overrides the onChange event so we don't store the value in the state.
    _getFieldValue = () => this.props.field?.value ?? this.state.value;

    _handleBlur() {
        this.setState({ isActive: false }, () => {
            if (this.props.onBlur) {
                this.props.onBlur(this._getFieldValue());
            }
        });
    }

    _handleChange(e) {
        const value = e.target.value;

        this.setState({ value });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _handleFocus() {
        this.setState({ isActive: true }, () => {
            if (this.props.onFocus) {
                this.props.onFocus(this._getFieldValue());
            }
        });
    }

    _handleKeyDown(event) {
        const { onKeyDown } = this.props;

        if (onKeyDown) {
            onKeyDown({
                event,
                value: this._getFieldValue(),
            });
        }
    }
    _handleKeyUp(event) {
        const { onKeyUp } = this.props;

        if (onKeyUp) {
            onKeyUp({
                event,
                value: this._getFieldValue(),
            });
        }
    }

    render() {
        const {
            annotationTag,
            annotationNote,
            annotationDecoration,
            bottomSpacing,
            disabled,
            isReadOnly,
            style,
            borderType,
            hasError,
            id,
            isAppRebrandActive,
            isMultiline,
            label,
            required,
            maxLength,
            name,
            placeholder,
            prefix,
            role,
            shouldHideRole,
            suffix,
            type,
            hasMinAsideSpacing,
            minAsideSpacing,
            'data-spec': dataSpec,
            'data-automation': dataAutomation,
            'data-testid': dataTestid,
            hasCharacterCount,
            linkText,
            linkSrc,
            field: formikFieldValues,
            isResizable,
            isAsideClickable,
            tagListComponent,
        } = this.props;

        const { isActive } = this.state;
        const value = this._getFieldValue();
        const extraAttrs = getAdditionalProps(this);
        const Input = isMultiline ? AreaElement : InputElement;
        const wrapperDataAutomation = dataAutomation
            ? `${dataAutomation}-wrapper`
            : 'input-field-wrapper';
        const wrapperDataTestid = dataTestid
            ? `${dataTestid}-wrapper`
            : 'input-field-wrapper';

        const wrapperClassName = isAppRebrandActive
            ? 'eds-field-styled--rebranding-active'
            : undefined;

        return (
            <FieldWrapper
                annotationTag={annotationTag}
                annotationNote={annotationNote}
                annotationDecoration={annotationDecoration}
                bottomSpacing={bottomSpacing}
                containerCustomClassName={wrapperClassName}
                disabled={disabled}
                style={style}
                borderType={borderType}
                hasError={hasError}
                id={id}
                isActive={isActive}
                isMultiline={isMultiline}
                label={label}
                required={required}
                prefix={prefix}
                suffix={suffix}
                value={value}
                hasMinAsideSpacing={hasMinAsideSpacing}
                minAsideSpacing={minAsideSpacing}
                dataSpec={dataSpec}
                dataAutomation={wrapperDataAutomation}
                dataTestid={wrapperDataTestid}
                maxLength={maxLength}
                hasCharacterCount={hasCharacterCount}
                linkText={linkText}
                linkSrc={linkSrc}
                placeholder={value ? null : placeholder}
                isResizable={isResizable}
                isAsideClickable={isAsideClickable}
            >
                <>
                    {tagListComponent}
                    <Input
                        {...extraAttrs}
                        dataAutomation={dataAutomation}
                        dataTestid={dataTestid}
                        disabled={disabled}
                        readOnly={isReadOnly}
                        hasError={hasError}
                        id={id}
                        required={required}
                        maxLength={maxLength}
                        name={name}
                        onBlur={this._handleBlur.bind(this)}
                        onChange={this._handleChange.bind(this)}
                        onFocus={this._handleFocus.bind(this)}
                        onKeyDown={this._handleKeyDown.bind(this)}
                        onKeyUp={this._handleKeyUp.bind(this)}
                        placeholder={value ? null : placeholder}
                        shouldHideRole={shouldHideRole}
                        role={role}
                        type={type}
                        value={value}
                        inputRef={this._getRef.bind(this)}
                        suffix={suffix}
                        formikFieldValues={formikFieldValues}
                        isResizable={isResizable}
                    />
                </>
            </FieldWrapper>
        );
    }
}
