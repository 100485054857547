import { BREAKPOINTS } from '@eventbrite/eds-breakpoints-json';

import { HAS_MATCHMEDIA } from './dom';

const rules = {
    smallNarrowDown: `(max-width: ${BREAKPOINTS['small-narrow-width']})`,
    smallDown: `(max-width: ${BREAKPOINTS['small-width']})`,
    smallWideDown: `(max-width: ${BREAKPOINTS['small-wide-width']})`,
    mediumNarrowDown: `(max-width: ${BREAKPOINTS['medium-narrow-width']})`,
    mediumDown: `(max-width: ${BREAKPOINTS['medium-width']})`,
    mediumWideDown: `(max-width: ${BREAKPOINTS['medium-wide-width']})`,
    largeNarrowDown: `(max-width: ${BREAKPOINTS['large-narrow-width']})`,
    largeDown: `(max-width: ${BREAKPOINTS['large-width']})`,
    largeWideDown: `(max-width: ${BREAKPOINTS['large-wide-width']})`,
};

const _matchesScreen = (breakpoint) =>
    HAS_MATCHMEDIA && window.matchMedia(rules[breakpoint]).matches;

export const isSmallNarrowDown = () => _matchesScreen('smallNarrowDown');
export const isSmallDown = () => _matchesScreen('smallDown');
export const isSmallWideDown = () => _matchesScreen('smallWideDown');
export const isMediumNarrowDown = () => _matchesScreen('mediumNarrowDown');
export const isMediumDown = () => _matchesScreen('mediumDown');
export const isMediumWideDown = () => _matchesScreen('mediumWideDown');
export const isLargeNarrowDown = () => _matchesScreen('largeNarrowDown');
export const isLargeDown = () => _matchesScreen('largeDown');
export const isLargeWideDown = () => _matchesScreen('largeWideDown');
