import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

export default class SelectSuffix extends PureComponent {
    static propTypes = {
        suffix: PropTypes.node,
    };

    render() {
        const { suffix } = this.props;

        if (!suffix) {
            return null;
        }

        return (
            <span
                className="eds-field-styled__select-suffix"
                data-spec="eds-field-styled__select-suffix"
            >
                {suffix}
            </span>
        );
    }
}
