import * as React from 'react';

const SportsSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.034 9.61a4.49 4.49 0 00-.875-3.54 4.986 4.986 0 013.96-.994 4.987 4.987 0 013.38 2.289 4.488 4.488 0 00-2.033 3.026 4.49 4.49 0 00.875 3.539 5.03 5.03 0 01-.981.604 1.998 1.998 0 00-3.039.38 4.986 4.986 0 01-3.32-2.279 4.48 4.48 0 002.034-3.026zm-1.926-3.952l.002-.001a5.992 5.992 0 015.182-1.566A5.991 5.991 0 0125.6 7.284c.01.016.02.032.028.05a5.975 5.975 0 01.534 3.708 5.967 5.967 0 01-1.804 3.332 6.027 6.027 0 01-1.455 1.008c.063.195.097.402.097.618v.268A2 2 0 0126 18v1h.014l.089.003h.014A2 2 0 0126 23v1a2 2 0 01-3 1.733V26a2 2 0 01-4 0v-3h-6v3a2 2 0 01-4 0v-.267A2 2 0 016 24v-1h-.014l-.089-.003h-.014a2 2 0 010-3.994h.014L5.986 19H6v-1a2 2 0 013-1.732V16a2 2 0 014 0v3h6v-3c0-.044.001-.088.004-.13a5.993 5.993 0 01-4.132-3.208 5.945 5.945 0 01-.53-3.704 5.956 5.956 0 011.766-3.3zm-.782 3.474a4.96 4.96 0 011.107-2.363 3.486 3.486 0 01.62 2.654l-.004.013a3.482 3.482 0 01-1.492 2.295 4.952 4.952 0 01-.231-2.6zM9.25 8a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zM6.5 9.75a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zM22 16a1 1 0 00-2 0v10a1 1 0 102 0v-1.977V22.5a.5.5 0 011 0v1.501l.002.065A1 1 0 0025 24v-6a1 1 0 00-2-.01v1.51a.5.5 0 11-1 0V16zm-9 4h6v2h-6v-2zm-1 2.522V19.49 16a1 1 0 00-2 0v10a1 1 0 102 0v-3.478zm-3 1.483v.005A1 1 0 017 24v-1.5V19.522 18a1 1 0 011.998-.064l.002.059v6.01zM27 21a1 1 0 01-1 1v-2l.066.002A1 1 0 0127 21zM6 20l-.066.002a1 1 0 000 1.996L6 22v-2zm17.454-9.436a3.487 3.487 0 011.491-2.294c.297.803.392 1.693.232 2.598a4.968 4.968 0 01-1.107 2.363 3.49 3.49 0 01-.616-2.667z"
            fill="#585163"
        />
    </svg>
);

SportsSvg.displayName = 'SportsSvg';
export default SportsSvg;
