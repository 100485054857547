import logger from '@eventbrite/client-logger';
import { saveEvent as saveEventApi, unSaveEvent } from './api';
import { loggedOutSaveRedirect } from './utils';

/**
 *
 * @param object
 *
 * Keys:
 *
 * `eventId`: the event to save
 *
 * `isAuthenticated`: if user is logged in
 *
 * `savedByYou`: the current save status of the event
 *
 * returns `{id, savedByYou}`
 */
export const saveEvent = async ({
    eventId,
    isAuthenticated,
    savedByYou,
}: {
    eventId: string;
    isAuthenticated: boolean;
    savedByYou: boolean;
}): Promise<{ id?: string; newSaveState?: boolean } | undefined> => {
    try {
        if (!isAuthenticated) {
            loggedOutSaveRedirect(eventId);
            return;
        }

        const apiAction = savedByYou ? unSaveEvent : saveEventApi;
        await apiAction(eventId);

        return { id: eventId, newSaveState: !savedByYou };
    } catch (error: any) {
        // Sometimes the backend provides events that are incorreclty
        // marked as not saved. This addresses that by simply swallowing
        // the error and allow the local state to be updated hoping that
        // the next reload will behave properly.
        if (error?.parsedError?.error === 'BOOKMARK_ALREADY_EXISTS') {
            logger.warn('Bookmark already exists:', {
                error: error.message ? error.message : error,
            });

            return { id: eventId, newSaveState: !savedByYou };
        }

        throw error;
    }
};
