import { Icon } from '@eventbrite/eds-icon';
import React from 'react';
import { useGlobalNavContext } from '../../../../state';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../utils/heap-data';
import logoStyle from './Logo.module.scss';

const Logo = () => {
    const { env, logo } = useGlobalNavContext();
    const { ariaLabel, desktopLogo, mobileLogo } = logo ?? {};
    const { Teams, Features, Components, Actions } = HeapAttributes;

    const url = new URL(
        `${
            env?.localeInfo?.uses_language_subdirectory
                ? `/${env?.localeInfo?.locale}`
                : ''
        }${logo?.path}`,
        env?.serverUrl ?? '',
    ).href;
    return (
        <>
            {/* Desktop Logo */}
            <a
                href={url}
                aria-label={ariaLabel?.toString()}
                style={{ backgroundColor: desktopLogo?.backgroundColor }}
                className={logoStyle.desktop}
                tabIndex={0}
                data-heap-id={getHeapDataAttribute({
                    team: Teams.SEO,
                    feature: Features.GlobalNav,
                    component: Components.Logo,
                    name: 'desktop',
                    action: Actions.Click,
                })}
            >
                <Icon
                    type={desktopLogo?.iconType}
                    color={desktopLogo?.iconColor}
                    title={desktopLogo?.iconTitle}
                    isBlockLevel={true}
                    height={desktopLogo?.iconHeight}
                    width={desktopLogo?.iconWidth}
                />
            </a>
            {/* Mobile Logo */}
            <a
                href={url}
                aria-label={ariaLabel?.toString()}
                style={{ backgroundColor: mobileLogo?.backgroundColor }}
                className={logoStyle.mobile}
                tabIndex={0}
                data-heap-id={getHeapDataAttribute({
                    team: Teams.SEO,
                    feature: Features.GlobalNav,
                    component: Components.Logo,
                    name: 'mobile',
                    action: Actions.Click,
                })}
            >
                <Icon
                    type={mobileLogo?.iconType}
                    color={mobileLogo?.iconColor}
                    title={mobileLogo?.iconTitle}
                    isBlockLevel={true}
                    height={mobileLogo?.iconHeight}
                    width={mobileLogo?.iconWidth}
                />
            </a>
        </>
    );
};

export default Logo;
