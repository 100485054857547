import { CoreApplicationContext } from '@eventbrite/context-gen';
import {
    ConsumerHeaderSearchBar,
    type searchBarProps,
} from '@eventbrite/global-nav';
import { gettext } from '@eventbrite/i18n';
import {
    SearchDropdownContext,
    SearchDropdownProvider,
} from '@eventbrite/search-takeover';
import { getLogoInfo, getUserMenuInfo } from '@eventbrite/site-header';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { FeatureFlags, RequestShape, UserShape } from '../../types';
import './ConsumerHeaderIndex.scss';
import {
    ConsumerHeaderDesktopLinks,
    ConsumerHeaderMobileLinks,
} from './ConsumerHeaderLinks';
import GlobalHeaderLogo from './GlobalHeaderLogo';
import { GlobalHeaderMenus } from './GlobalHeaderNavigationBar';
import { isDetachInterestsEnabled } from './utils';

interface ConsumerHeaderProps {
    env: CoreApplicationContext['env'] & {
        launchNewSignInSignUpLinks?: string;
        isManageOverviewActive?: boolean;
    };
    request: RequestShape;
    user: UserShape;
    onAdditionalMenuTextItemSelect?: Function;
    isHomePage?: boolean;
    id?: string;
    featureFlags: FeatureFlags;
    hasBreadcrumbs?: boolean;
    searchBar?: searchBarProps;
}
const ConsumerHeader: React.FunctionComponent<ConsumerHeaderProps> = (
    props,
) => {
    const { user, env, id, featureFlags, hasBreadcrumbs, request, searchBar } =
        props;

    const { serverUrl, adminServerUrl, logoutUrl } = env;
    const { path: requestPath } = request;
    const serverUrlLanguageSubdirectoy = env?.localeInfo
        ?.uses_language_subdirectory
        ? `${serverUrl}/${env.localeInfo.locale}`
        : `${serverUrl}`;

    const { setShowDropdown, setShowOverlay } = useContext(
        SearchDropdownContext,
    );

    const userMenuInfo = getUserMenuInfo({
        adminServerUrl,
        featureFlags: {
            ...featureFlags,
            isDetachInterestsEnabled: isDetachInterestsEnabled(),
        },
        logoutUrl,
        requestPath,
        serverUrl,
        showBrowseEvents: true,
        user,
    });

    const headerClassnames = classNames(
        'consumer-header with-search-dropdown',
        {
            'consumer-header__has-breadcrumbs': hasBreadcrumbs,
        },
    );

    return (
        <header
            id={id || 'global-header'}
            className={headerClassnames}
            data-spec="consumer-header"
        >
            <a
                className="consumer-header__skip-links eds-is-hidden-accessible"
                href="#skip-heading"
            >
                {gettext('Skip Main Navigation')}
            </a>
            <div className="consumer-header__main" data-role="header">
                <GlobalHeaderLogo
                    info={getLogoInfo(
                        serverUrlLanguageSubdirectoy,
                        requestPath,
                        {
                            iconWidth: '110px',
                        },
                    )}
                />
                <ConsumerHeaderSearchBar
                    user={props.user}
                    env={props.env}
                    searchBar={searchBar}
                />
                <div
                    className="consumer-header__desktop"
                    data-spec="consumer-header-desktop"
                >
                    <ConsumerHeaderDesktopLinks
                        user={user}
                        env={env}
                        featureFlags={featureFlags}
                        requestPath={requestPath}
                        isHomePage={props.isHomePage}
                        onAdditionalMenuTextItemSelect={
                            props.onAdditionalMenuTextItemSelect
                        }
                    />
                    <GlobalHeaderMenus userMenuInfo={userMenuInfo} />
                </div>
                <div
                    className="consumer-header__mobile"
                    data-spec="consumer-header-mobile"
                >
                    <ConsumerHeaderMobileLinks
                        user={user}
                        env={env}
                        isHomePage={props.isHomePage}
                        featureFlags={featureFlags}
                        requestPath={requestPath}
                        onClickDropdown={() => {
                            setShowDropdown(false);
                            setShowOverlay(false);
                        }}
                    />
                </div>
            </div>
            <span id="skip-heading" className="eds-is-hidden-accessible">
                {gettext('Page Content')}
            </span>
        </header>
    );
};

const ConsumerHeaderWithProviders: React.FunctionComponent<
    ConsumerHeaderProps
> = (headerProps) => (
    <SearchDropdownProvider>
        <ConsumerHeader {...headerProps} />
    </SearchDropdownProvider>
);

export default ConsumerHeaderWithProviders;
