import { useUserLocation } from '@eventbrite/location-autocomplete';
import { transformGeoPlaceObject } from '@eventbrite/redux-destination';
import { useEffect } from 'react';
import {
    ERROR_DENIED_USER,
    ERROR_LOCATION_DENIED,
    ERROR_LOCATION_NOT_SUPPORTED,
    ERROR_LOCATION_UNRESOLVED_BROWSER,
    ERROR_LOCATION_UNRESOLVED_PLACE,
    ERROR_UNRESOLVED_BROWSER,
    ERROR_UNRESOLVED_PLACE,
} from '../constants';
import { useErrorDisplayContext } from '../context';
import { CamelCaseLocation, LocationAction } from '../types';
import { trackSearchTakeoverEvent } from '../utils';

const setUsersCurrentLocation = ({
    dispatch,
    newRawLocation,
    currentPlace,
}: {
    dispatch: React.Dispatch<LocationAction>;
    newRawLocation: any;
    currentPlace: string;
}) => {
    const newLocation: CamelCaseLocation =
        transformGeoPlaceObject(newRawLocation);

    trackSearchTakeoverEvent({
        action: 'LocChange',
        label: `${currentPlace}--${newLocation.currentPlace}`,
    });

    trackSearchTakeoverEvent({
        action: 'UseCurrentLocation',
        label: newLocation.currentPlace,
    });

    dispatch({
        type: 'setUserLocation',
        payload: newLocation,
    });
};

/**
 * @name useHandleUserCurrentLocation
 * @description encapsulates error states and display logic for the search takeover
 * location provider, using the application's error context to surface the appropriate messaging
 * set to the appropriate severity to make unexpected application behavior clearer to the user.
 * @use used for errors stemming from currently set user location or attempts to set it
 * @param data location service data
 * @param error location error type string
 * @param dispatch dispatched location action
 * @param shouldUseUserLocation boolean indicating whether user location should be used
 */
export const useHandleUserCurrentLocation = ({
    dispatch,
    shouldUseUserLocation,
    location,
}: {
    dispatch: React.Dispatch<LocationAction>;
    shouldUseUserLocation: boolean;
    location?: CamelCaseLocation;
}) => {
    const { data, error } = useUserLocation({
        enabled: shouldUseUserLocation,
    });

    const { setError } = useErrorDisplayContext();
    const currentPlace = location?.currentPlace || '';

    useEffect(() => {
        if (data?.place && shouldUseUserLocation) {
            setUsersCurrentLocation({
                dispatch,
                newRawLocation: data?.place,
                currentPlace,
            });
        } else if (shouldUseUserLocation && error && error.status) {
            // Only set errors if we're searching for the current location,
            // otherwise we'll show the error in the location input for other search
            // types
            if (
                error.status === ERROR_LOCATION_DENIED ||
                error.status === ERROR_LOCATION_NOT_SUPPORTED
            ) {
                setError?.(ERROR_DENIED_USER);
            } else if (error.status === ERROR_LOCATION_UNRESOLVED_BROWSER) {
                setError?.(ERROR_UNRESOLVED_BROWSER);
            } else if (error.status === ERROR_LOCATION_UNRESOLVED_PLACE) {
                setError?.(ERROR_UNRESOLVED_PLACE);
            }

            dispatch({ type: 'setLocation' });
        }
    }, [data, error, dispatch, shouldUseUserLocation, setError, currentPlace]);
};
