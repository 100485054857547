import { sdkRequest } from '@eventbrite/http';
import {
    DatadogAdsEvents,
    DatadogInteractionsTracker,
} from './datadogInteractionsTracker';
import { InteractionsLogger } from './interactionsLogger';
import {
    EventInteraction,
    EventInteractionContext,
    EventInteractions,
} from './types';

type InteractionLoggerV1Data = {
    identity: {
        user_id?: string;
        locale?: string;
        tracking_code: null;
        device_latitude: null;
        device_longitude: null;
    };
    interface_context: {
        app: string;
        interface: string;
        placement_id?: string;
        rank: number;
        absolute_rank?: number;
        event_id?: string;
        ad_id?: string;
        action?: string;
        tab_key?: string;
        campaign_id: null;
        page: number;
        sub_interface: string;
        position: string;
    };
    retrieval_context?: any;
    display_context?: {
        has_open_promotion: boolean;
        has_bogo_label: boolean;
        urgency_signals: any[];
    };
    venue_context?: {
        venue_id: string | null;
    };
    feedback_context?: {
        email?: string;
        report_reason?: string;
        report_description?: string;
    };
};

export class InteractionsLoggerV1 extends InteractionsLogger<InteractionLoggerV1Data> {
    ORIGIN = 'web';

    protected excludedEvents: EventInteractions[] = [EventInteractions.Mounted];

    log(interactions: InteractionLoggerV1Data[]) {
        sdkRequest('/api/v3/log_engagement/', {
            method: 'POST',
            body: JSON.stringify(interactions),
            headers: { 'EB-version': 'custom-events-next-version' },
        });

        this.sendDatadogMetrics(interactions);
    }

    // FIXME: This should be done elsewhere, to not mix concerns
    private sendDatadogMetrics(interactions: InteractionLoggerV1Data[]) {
        const datadogTracker = new DatadogInteractionsTracker();
        const impressions = interactions.filter(
            (i) =>
                i.interface_context.action === EventInteractions.Impression &&
                !!i.interface_context.placement_id,
        );
        impressions.map((i) => {
            datadogTracker.sendInteractionEvent(
                i.interface_context.interface,
                DatadogAdsEvents.RequestedImpression,
                1,
            );
        });
    }

    protected transformInteraction(
        i: EventInteraction<EventInteractionContext>,
    ): InteractionLoggerV1Data {
        return {
            identity: {
                user_id: i.user.id,
                locale: i.user.locale,
                tracking_code: null,
                device_latitude: null,
                device_longitude: null,
            },
            interface_context: {
                app: this.ORIGIN,
                interface: i.place.name,
                placement_id: i.place.placementId,
                rank: i.place.position,
                absolute_rank: i.place.absoluteRank,
                event_id: i.id,
                ad_id: i.adId,
                action: i.action,
                tab_key: i.place.tabKey,
                campaign_id: null,
                page: i.place.page,
                // log_engagement endpoint breaks if sub_interface / position are not sent
                sub_interface: i.place.subInterface?.name || '',
                position: i.place.subInterface?.position || '',
            },
            retrieval_context: i.place.context,
            display_context: {
                has_open_promotion: Boolean(i.displayContext?.hasOpenPromotion),
                has_bogo_label: Boolean(i.displayContext?.hasBOGOLabel),
                urgency_signals: i.displayContext?.urgencySignals || [],
            },
            venue_context: {
                venue_id: i.venueId || null,
            },
            feedback_context: this.getReportFeedback(i.feedbackContext),
        };
    }

    private getReportFeedback(
        context: EventInteractionContext['feedbackContext'],
    ) {
        if (!context) {
            return undefined;
        }

        return {
            email: context.email,
            report_reason: context.reason,
            report_description: context.comment,
        };
    }
}
