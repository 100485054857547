import { useEffect, useState } from 'react';
import Intercom from './intercom';
import {
    Iinitialize,
    Ipublish,
    IquietPublish,
    Isubscribe,
    IuseSubscribe,
    ShallowObj,
} from './types';

export const initialize: Iinitialize = (payload) => {
    Intercom.getInstance(payload);
};

export const publish: Ipublish = (eventName, payload) => {
    const IntercomInstance = Intercom.getInstance();

    IntercomInstance.publish(eventName, payload);
};

export const quietPublish: IquietPublish = (eventName, context) => {
    const IntercomInstance = Intercom.getInstance();

    IntercomInstance.quietPublish(eventName, context);
};

export const subscribe: Isubscribe = (eventName, callback) => {
    const IntercomInstance = Intercom.getInstance();

    return IntercomInstance.subscribe(eventName, callback);
};

export const useSubscribe: IuseSubscribe = (eventName) => {
    const [data, setData] = useState<ShallowObj>({});

    useEffect(() => {
        const [unsubscribe, initialStore] = subscribe(
            eventName,
            (updatedData) => {
                setData(updatedData);
            },
        );

        setData(initialStore);
        return unsubscribe;
    }, [data, setData, eventName]);

    return data;
};

export * from './types';
