import { Icon, Typography } from '@eventbrite/marmalade';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import React, { useMemo } from 'react';
import styles from './EventCardPromoCodeLabel.module.scss';

export type EventCardPromoCodeLabelProps = React.PropsWithChildren<{
    className?: string;
}>;

export function EventCardPromoCodeLabel({
    className,
    children,
}: EventCardPromoCodeLabelProps) {
    return (
        <aside className={classNames(className, styles.root)}>
            <Icon size="xsmall">
                <PromoCodeIcon />
            </Icon>
            <Typography className={styles.label} variant="body-sm-bold">
                {children}
            </Typography>
        </aside>
    );
}

const PromoCodeIcon = () => {
    /*
     * linearGradients and defs from SVG should have unique ids
     * If you have two SVGs in a HTML and one of them has a display: none it can create collisions with
     * other instances of the SVGs and create visual bugs
     */
    const linearGradientId = useMemo(
        () => uniqueId('promo-linear-gradient'),
        [],
    );
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.97919 3.47916C2.67237 3.78596 2.5 4.20206 2.5 4.63594V8.00162C2.50009 8.43546 2.67252 8.85151 2.97937 9.15823L7.3422 13.5207C7.49413 13.6727 7.6745 13.7932 7.87302 13.8754C8.07153 13.9577 8.2843 14 8.49917 14C8.71405 14 8.92682 13.9577 9.12533 13.8754C9.32385 13.7932 9.50422 13.6727 9.65614 13.5207L13.021 10.1551C13.3277 9.84828 13.5 9.43225 13.5 8.99846C13.5 8.56466 13.3277 8.14863 13.021 7.84184L8.65815 3.47933C8.35153 3.17264 7.93568 3.00024 7.50199 3H4.13606C3.70215 3 3.28601 3.17236 2.97919 3.47916ZM5.5 7C6.05228 7 6.5 6.55228 6.5 6C6.5 5.44772 6.05228 5 5.5 5C4.94772 5 4.5 5.44772 4.5 6C4.5 6.55228 4.94772 7 5.5 7Z"
                fill={`url(#${linearGradientId})`}
            />
            <defs>
                <linearGradient
                    id={linearGradientId}
                    x1="8"
                    y1="3"
                    x2="8"
                    y2="14"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF1D1D" />
                    <stop offset="1" stopColor="#FFC267" />
                </linearGradient>
            </defs>
        </svg>
    );
};
