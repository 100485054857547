import * as React from 'react';

const VideoChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="video-chunky_svg__eds-icon--video-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <g id="video-chunky_svg__eds-icon--video-chunky_base">
            <path d="M19 4v1H5V4H3v16h2v-1h14v1h2V4h-2zm0 13H5V7h14v10z" />
        </g>
        <path
            id="video-chunky_svg__eds-icon--video-chunky_play"
            d="M10 15l5-3-5-3z"
        />
    </svg>
);

VideoChunkySvg.displayName = 'VideoChunkySvg';
export default VideoChunkySvg;
