import PropTypes from 'prop-types';
import { COLORS_PROP_TYPE } from '@eventbrite/eds-color';
import { SIZES_JSON } from '@eventbrite/eds-iconography';

export const SIZES = Object.keys(SIZES_JSON).map((size) =>
    size.replace('icon-', ''),
);

export const SVG_PROP_TYPE = PropTypes.node;
export const COLOR_PROP_TYPE = COLORS_PROP_TYPE;
export const SIZE_PROP_TYPE = PropTypes.oneOf(SIZES);

export const VECTOR_IMAGE_ICON = 'icon';
export const VECTOR_IMAGE_BRAND = 'brand';
export const VECTOR_IMAGE_PAYMENT = 'payment';
export const VECTOR_IMAGE_GRAPHIC = 'graphic';
export const VECTOR_IMAGE_ILLUSTRATION = 'illustration';

const RESET_TYPES = [VECTOR_IMAGE_BRAND, VECTOR_IMAGE_ILLUSTRATION];

export const SHOULD_USE_RESET_PATTERN = new RegExp(
    `(${RESET_TYPES.join('|')})$`,
);
