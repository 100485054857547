import { CamelCaseLocation, searchBarProps } from '@eventbrite/global-nav';
import { gettext } from '@eventbrite/i18n';
import { getExperiment } from '@eventbrite/statsig';
import { HEADER_HEIGHT, MOBILE_BREAKPOINT } from '../../constants';
export const getSearchPageHeaderOffset = (cookieHeaderOffset: number) => {
    let offset = HEADER_HEIGHT;

    if (typeof window === 'object') {
        const mediaQuery = window.matchMedia(
            `(min-width: ${MOBILE_BREAKPOINT})`,
        );

        if (mediaQuery.matches) {
            offset = cookieHeaderOffset
                ? HEADER_HEIGHT + cookieHeaderOffset
                : HEADER_HEIGHT;
        }
    }

    return offset;
};

const AUTO_CREATE_EVENT_FOR_EXISTING_CREATORS =
    'auto_create_event_for_existing_creators';
const AUTO_CREATE_EVENT_FOR_EXISTING_CREATORS_PARAM = 'auto_create_variant';

export const isEventCreationModalEnabled = () => {
    const value = getExperiment(
        AUTO_CREATE_EVENT_FOR_EXISTING_CREATORS,
        AUTO_CREATE_EVENT_FOR_EXISTING_CREATORS_PARAM,
        false,
    ) as boolean | null;
    return value;
};

const CONSUMER_ONBOARDING_DETACH_INTERESTS =
    'consumer_onboarding_detach_interests';
const DETACH_INTERESTS_PARAM = 'detach_interests';

export const isDetachInterestsEnabled = () => {
    const value = getExperiment(
        CONSUMER_ONBOARDING_DETACH_INTERESTS,
        DETACH_INTERESTS_PARAM,
        false,
    ) as boolean | null;
    return value;
};

const SEARCH_EVENTS = gettext('Search events');
const SEARCH = gettext('Search');

export interface ConsumerHeaderSearchBarProps {
    searchQuery?: string;
    location?: CamelCaseLocation;
    isUsingCurrentLocation?: boolean;
}
export function getSearchBarProps({
    searchQuery,
    location,
    isUsingCurrentLocation,
}: ConsumerHeaderSearchBarProps = {}): searchBarProps {
    return {
        renderSearchBar: true,
        desktopLabel: searchQuery || SEARCH_EVENTS,
        mobileLabel: searchQuery || SEARCH,
        searchQuery: searchQuery || '',
        location,
        isUsingCurrentLocation: Boolean(isUsingCurrentLocation),
    };
}
