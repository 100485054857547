import { searchPromotedEvents } from '@eventbrite/ads';
import { isEmpty } from 'lodash';
import type { DateType } from '../constants/dates';
import { computeLocationInfo, getGeoPoint } from '../utils/location';
import {
    searchOrganicEvents,
    type OrganicEventSearchApiPayload,
    type OrganicEventSearchApiResponse,
    type OrganicEventSearchFiltersApiPayload,
} from './searchOrganicEvents';

export type EventSearchInterfaceName =
    | 'home_category'
    | 'homepage'
    | 'search'
    | 'home_category_nightlife';

type AllEventsSearchPayload = OrganicEventSearchApiPayload & {
    /**
     * Name of the frontend surface that is requesting the promoted events.
     */
    interfaceName: EventSearchInterfaceName;
    /**
     * Slots: number of promoted events to retrieve from backend.
     */
    slots: number;
    /**
     * Determines if the BFF should be used to fetch the data.
     */
    useBff?: boolean;
};

export async function searchAllEvents(
    search: AllEventsSearchPayload,
): Promise<OrganicEventSearchApiResponse> {
    // eslint-disable-next-line
    const { interfaceName, slots, useBff, ...organicSearch } = search;

    // TODO: remove this workaround when server data query came without this field from SSR flow.
    (search as any).event_search.include_promoted_events_for = undefined;

    // TODO: Currently, search_service is receiving a deprecated flag.
    // We are unsure where this flag is coming for, so we are including this delete to check if errors decrease
    // Remove when investigation ends.
    // https://eventbrite.atlassian.net/browse/EB-207789
    delete (search as any).event_search.include_promoted_events;

    const [organicEventsSearchResponse, promotedEventsSearchResponse] =
        await Promise.allSettled([
            searchOrganicEvents(organicSearch, { useBff }),
            searchPromotedEvents({
                slots: search.slots,
                interface: search.interfaceName,
                ...transformToPromotedEventSearchApiPayload(
                    search.event_search,
                ),
            }),
        ]);

    if (organicEventsSearchResponse.status === 'rejected') {
        const reason = organicEventsSearchResponse.reason;
        if (reason instanceof Error || reason.name === 'AbortError') {
            throw organicEventsSearchResponse.reason;
        } else {
            throw new Error(JSON.stringify(organicEventsSearchResponse.reason));
        }
    }

    if (promotedEventsSearchResponse.status === 'fulfilled') {
        organicEventsSearchResponse.value.events.promoted_results =
            promotedEventsSearchResponse.value;
    }

    return organicEventsSearchResponse.value;
}

export function transformToPromotedEventSearchApiPayload(
    search: AllEventsSearchPayload['event_search'],
) {
    const { latitude, longitude, place_id } = computeLocationInfo(search);
    const specialFilters = search.special || [];
    const tags =
        search.tags && search.tags.length > 0 && !isEmpty(search.tags[0])
            ? search.tags
            : undefined;

    return {
        page: search.page || 1,
        search: {
            dates: transformToDates(search.dates),
            date_range: search.date_range,
            currencies: search.currencies,
            q: search.q,
            price: search.price,
            languages: search.languages,
            tags: tags,
            place: {
                is_online: search.online_events_only || false,
                place_id,
                geo_point:
                    longitude && latitude && !search.online_events_only
                        ? getGeoPoint({ longitude, latitude })
                        : undefined,
            },
            start_time_range: transformHourRange(search.start_hour_range),
            organizers_i_follow: specialFilters.includes('organizers_i_follow'),
        },
    };
}

function transformToDates(dates?: DateType | DateType[]): DateType[] {
    if (!dates) {
        return ['current_future'];
    }
    // TODO: remove this workaround, here per retro-compatibility
    if (typeof dates === 'string') {
        return [dates];
    }
    return dates;
}

function transformHourRange(
    hourRange: OrganicEventSearchFiltersApiPayload['start_hour_range'],
) {
    if (!hourRange) {
        return undefined;
    }

    return {
        from: hourRange.start_hour,
        to: hourRange.end_hour,
    };
}
