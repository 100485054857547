import React from 'react';

type SearchInputProviderContextThing = SearchInputProviderShape | undefined;

interface SearchInputProviderShape {
    query: string;
    setQuery: Function;
}

interface SearchInputProviderProps {
    initialQuery?: string;
}

export const useSearchInputContext = () => {
    const value = React.useContext(SearchInputProviderContext);

    if (value === undefined) {
        throw new Error(
            'useSearchInputContext must be used within SearchInputProvider',
        );
    }

    return value;
};

export const SearchInputProviderContext =
    React.createContext<Partial<SearchInputProviderContextThing>>(undefined);

export const SearchInputProvider: React.FunctionComponent<
    SearchInputProviderProps
> = (props) => {
    const [query, setQuery] = React.useState(props.initialQuery || '');

    return (
        <SearchInputProviderContext.Provider value={{ query, setQuery }}>
            {props.children}
        </SearchInputProviderContext.Provider>
    );
};

export default SearchInputProvider;
