import * as React from 'react';

const FoodDrinkSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={40} height={41} fill="none" {...props}>
        <g id="food-drink_svg__icon_selection">
            <path
                id="food-drink_svg__primary_fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.76 5.014a.625.625 0 01.721.462l1.444 5.777h5.45a.625.625 0 01.623.67l-1.659 22.5a.625.625 0 01-.623.58H21.66a.625.625 0 01-.624-.58l-.033-.457-.23.085c-1.667.595-3.928.951-6.397.951-2.468 0-4.73-.356-6.397-.951-.831-.297-1.548-.665-2.068-1.105C5.392 32.51 5 31.94 5 31.252c0-.528.234-.99.575-1.37l8.234-17.644a.625.625 0 011.132 0l5.295 11.345-.86-11.66a.625.625 0 01.624-.67h7.637L26.41 6.35l-9.42 1.766a.625.625 0 11-.231-1.228l10-1.875zm1.19 7.489h-7.277l.23 3.125H22.5a.625.625 0 110 1.25h-1.505l.72 9.768a.629.629 0 010 .104l1.461 3.133c.341.38.574.842.574 1.37 0 .686-.392 1.256-.91 1.693a4.752 4.752 0 01-.628.443l.027.363h9.896l1.245-16.874h-7.13a.625.625 0 110-1.25h2.48l-.78-3.125zm5.752 0l-.23 3.125H30c0-.05-.006-.101-.019-.152l-.743-2.973h4.464zM7.275 29.195L6.666 30.5a.626.626 0 01-.116.17c-.234.241-.3.437-.3.583 0 .177.098.429.466.74.365.307.93.613 1.682.881 1.5.536 3.613.88 5.977.88 2.364 0 4.477-.344 5.977-.88.753-.268 1.317-.574 1.682-.882.368-.31.466-.562.466-.739 0-.146-.066-.341-.299-.583a.625.625 0 01-.116-.17l-1.24-2.656a3.765 3.765 0 01-2.55.13 3.765 3.765 0 01-.631-6.946l-3.289-7.046-.626 1.341a3.763 3.763 0 01-3.046 6.526l-.932 1.999a3.77 3.77 0 012.778.418 3.763 3.763 0 11-5.274 4.93zm.857-1.838a2.514 2.514 0 002.508 2.664 2.512 2.512 0 10-1.727-4.336l-.78 1.672zm10.062-5.195l2.121 4.546a2.503 2.503 0 01-1.656.068 2.513 2.513 0 01-.465-4.614zm-6.924-1.527a2.512 2.512 0 001.913-4.1l-1.913 4.1z"
                fill="#39364F"
            />
        </g>
    </svg>
);

FoodDrinkSvg.displayName = 'FoodDrinkSvg';
export default FoodDrinkSvg;
