import React from 'react';
import { useOverlayVisibility } from '../hooks';

interface OverlayVisible {
    isVisible: boolean;
    setVisible: Function;
}

export const OverlayVisibilityContext = React.createContext<
    Partial<OverlayVisible>
>({});

interface OverlayProviderProps {
    isVisible: boolean;
    onClose?: () => void;
    showOverlay?: Function;
}

const useStableCallback = (fn?: () => unknown) => {
    //eslint-disable-next-line
    return React.useCallback(() => fn?.(), []);
};

export const OverlayProvider: React.FunctionComponent<OverlayProviderProps> = (
    props,
) => {
    const { isVisible: isVisibleFromProps, onClose } = props;

    const stableOnClose = useStableCallback(onClose);
    const [isVisible, setVisible] = useOverlayVisibility(
        isVisibleFromProps,
        stableOnClose,
    );

    return (
        <OverlayVisibilityContext.Provider
            value={{
                isVisible,
                setVisible,
            }}
        >
            {props.children}
        </OverlayVisibilityContext.Provider>
    );
};

export default OverlayProvider;
