import PropTypes from 'prop-types';
import {
    LINKS_DATA_SHAPE_PROP_TYPE,
    COUNTRIES_ARRAY_PROP_TYPE,
} from '@eventbrite/site-footer';

export {
    ENV_SHAPE_PROP_TYPE,
    REQUEST_SHAPE_PROP_TYPE,
    USER_SHAPE_PROP_TYPE,
} from '@eventbrite/site-header';

export const FOOTER_LINKS_SHAPE_PROP_TYPE = PropTypes.shape({
    popularSearches: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
    eventsToPlan: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
    thirdFooterColumn: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
    countries: COUNTRIES_ARRAY_PROP_TYPE.isRequired,
    showMinimalLinks: PropTypes.bool,
    thirdFooterColumnName: PropTypes.string.isRequired,
});
