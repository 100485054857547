import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import some from 'lodash/some';
import { HotKeys } from 'react-hotkeys';
import { NavListItem } from '@eventbrite/eds-nav-list-item';
import { Icon } from '@eventbrite/eds-icon';
import { ITEMS_PROP_TYPE } from './constants';

import {
    TABBABLE_INDEX,
    UNTABBABLE_INDEX,
    MOVE_TO_NEXT,
    MOVE_TO_PREV,
    MOVE_TO_FIRST,
    MOVE_TO_LAST,
    ACTIVATE_ITEM,
} from '@eventbrite/eds-hot-keys';
import {
    focusNewPosition,
    filterNonEmptyItems,
} from '@eventbrite/eds-hot-keys';
import { ACTION_KEY_MAP } from './hotKeys';

import './navList.scss';

import { ChevronUpChunky } from '@eventbrite/eds-iconography';
import { ChevronDownChunky } from '@eventbrite/eds-iconography';

const _getSymbolType = (isExpanded) =>
    isExpanded ? <ChevronUpChunky /> : <ChevronDownChunky />;
const _getSymbolColor = (isDisabled) => (isDisabled ? 'grey-300' : 'grey-400');
const _isItemExpanded = ({ active, items }) =>
    !!active || some(items, _isItemExpanded);
const _getExpandedItemsLookup = (items) =>
    mapValues(items, (item) => _isItemExpanded(item));

class NavList extends React.PureComponent {
    static propTypes = {
        /**
         * Array of list items. Available types are ANCHOR_LINK, ROUTER_LINK, DIVIDER
         */
        items: ITEMS_PROP_TYPE.isRequired,
        /**
         * Display the list inline (Horizontally aligned)
         */
        displayInline: PropTypes.bool,
        /**
         * Whether or not the list is active
         */
        active: PropTypes.bool,
        /**
         * Use alternate theme navList
         */
        useAltTheme: PropTypes.bool,
        /**
         * Shows a symbol / icon next to the menu items with sub items
         */
        showSymbols: PropTypes.bool,
        /**
         * Default position (zero-based) within item list - received from DropdownMenu
         */
        defaultFocusPosition: PropTypes.number,
        /**
         * Whether the navigation for items should be un-wrapped (i.e. no first to last, last to first)
         */
        preventFocusWrap: PropTypes.bool,
        /**
         * Hide dropdown
         */
        hideDropdown: PropTypes.func,
        /**
         * Use secondary alternate theme navList
         */
        useSecondaryTheme: PropTypes.bool,
    };

    static defaultProps = {
        displayInline: false,
        useAltTheme: false,
        showSymbols: false,
        preventFocusWrap: false,
        useSecondaryTheme: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            expandedItemsLookup: _getExpandedItemsLookup(props.items),
            focusPosition: props.defaultFocusPosition,
        };
    }

    UNSAFE_componentWillReceiveProps({ items }) {
        this.setState({ expandedItemsLookup: _getExpandedItemsLookup(items) });
    }

    hotKeyHandlers = {
        [MOVE_TO_NEXT]: (e) => this._changeFocusedNavListItem(e, MOVE_TO_NEXT),
        [MOVE_TO_PREV]: (e) => this._changeFocusedNavListItem(e, MOVE_TO_PREV),
        [MOVE_TO_FIRST]: (e) =>
            this._changeFocusedNavListItem(e, MOVE_TO_FIRST),
        [MOVE_TO_LAST]: (e) => this._changeFocusedNavListItem(e, MOVE_TO_LAST),
        [ACTIVATE_ITEM]: (e) => e.stopPropagation(),
    };

    _changeFocusedNavListItem(e, direction) {
        const { items, preventFocusWrap } = this.props;
        const { focusPosition } = this.state;
        const nonEmptyItems = filterNonEmptyItems(items);
        const boundFocusNewPosition = focusNewPosition.bind(
            null,
            nonEmptyItems,
            focusPosition,
            e,
            preventFocusWrap,
        );

        this.setState(boundFocusNewPosition(direction));
    }

    _handleLinkClick(index, onActivate) {
        const { expandedItemsLookup } = this.state;

        this.setState({
            expandedItemsLookup: {
                ...expandedItemsLookup,
                [index]: !expandedItemsLookup[index],
            },
        });

        if (onActivate) {
            onActivate();
        }
    }

    // NOTE: Merging keyboard event with mouse events so that in the case of a mouseOver,
    // the focusPosition is changed from the one determined from keyboard navigation
    // to the one determined by the mouseOver. This removes multiple highlights within
    // the same list. We use position rather than index because NavListItem's can be
    // dividers and we don't want to set  focus on those.
    _handleItemTentativeSelect(focusPosition) {
        // NOTE: adding this condition to avoid recurrent calls, freezing browser tab
        // this is a quick fix until we find what is causing that behaviour
        // JIRA: https://jira.evbhome.com/browse/EB-150617
        if (this.state.focusPosition !== focusPosition) {
            this.setState({ focusPosition });
        }
    }

    _getNavListItems(items, showSymbols, useAltTheme, defaultFocusPosition) {
        const { expandedItemsLookup, focusPosition } = this.state;
        const { hideDropdown, useSecondaryTheme } = this.props;
        let position = -1;

        return items.map(
            (
                {
                    active,
                    content,
                    isDisabled,
                    isNavLinkTextEmphasized = true,
                    items: nestedItems,
                    onActivate,
                    onClick: onItemClick,
                    path,
                    secondaryContent,
                    type,
                    ...unknownItemProps
                },
                index,
            ) => {
                let onTentativeSelect;
                const hasNestedItems = !isEmpty(nestedItems);
                const isExpanded = expandedItemsLookup[index];
                let isTentativelySelected;
                let nestedListComponent;
                let symbolComponent;
                let onLinkClick;
                let tabIndex;
                let secondaryContentComponent;

                const onClick = (event) => {
                    if (onItemClick) onItemClick(event, hideDropdown);
                };

                // For anchor-link NavListItems, which are used in dropdownMenu,
                // (vs router-link NavListItems), assign incremental position. This excludes
                // any dividers that are used, as we don't want to set tabIndex
                // or focus on those.
                if (type === 'anchor' && defaultFocusPosition !== undefined) {
                    position++;
                    isTentativelySelected = position === focusPosition;
                    tabIndex = isTentativelySelected
                        ? TABBABLE_INDEX
                        : UNTABBABLE_INDEX;
                    onTentativeSelect = this._handleItemTentativeSelect.bind(
                        this,
                        position,
                    );
                }

                if (hasNestedItems) {
                    onLinkClick = this._handleLinkClick.bind(
                        this,
                        index,
                        onActivate,
                    );

                    if (isExpanded) {
                        nestedListComponent = (
                            <div className="eds-nav-list__sub-list">
                                <NavList
                                    data-spec="eds-subnav-list"
                                    items={nestedItems}
                                    useAltTheme={useAltTheme}
                                />
                            </div>
                        );
                    }

                    if (showSymbols) {
                        symbolComponent = (
                            <div className="eds-nav-list__item__symbol eds-fx--fade-in">
                                <Icon
                                    type={_getSymbolType(isExpanded)}
                                    color={_getSymbolColor(isDisabled)}
                                />
                            </div>
                        );
                    }
                }

                if (secondaryContent) {
                    secondaryContentComponent = (
                        <div className="eds-nav-list__item__secondary-contents">
                            {secondaryContent}
                        </div>
                    );
                }
                const hasTabRole = items.some((item) => item.role === 'tab');

                return (
                    <li
                        className="eds-nav-list__item"
                        key={path || index}
                        data-spec="eds-nav-list-item"
                        // Set role to "presentation" to intentionally hide semantics from assistive technologies
                        // When the <li> element should not be part of the a11y tree, we want to hide the implied
                        // "listitem" role of the <li> element
                        // role="presentation"
                        {...(hasTabRole && { role: 'presentation' })}
                        onMouseOver={onTentativeSelect}
                        onFocus={onTentativeSelect}
                    >
                        <NavListItem
                            {...unknownItemProps}
                            active={active}
                            isDisabled={isDisabled}
                            isTentativelySelected={isTentativelySelected}
                            onActivate={onLinkClick}
                            onClick={onClick}
                            path={path}
                            tabIndex={tabIndex}
                            type={type}
                            useAltTheme={useAltTheme}
                            useSecondaryTheme={useSecondaryTheme}
                        >
                            <div className="eds-nav-list__item__contents">
                                <div
                                    className={`eds-nav-list__item__text ${
                                        isNavLinkTextEmphasized
                                            ? 'eds-nav-list__emphazised'
                                            : ''
                                    }`}
                                >
                                    {content}
                                </div>
                                {symbolComponent}
                            </div>
                            {secondaryContentComponent}
                        </NavListItem>
                        {nestedListComponent}
                    </li>
                );
            },
        );
    }

    render() {
        const {
            items,
            active,
            displayInline,
            useAltTheme,
            showSymbols,
            defaultFocusPosition,
        } = this.props;
        const className = classNames('eds-nav-list', {
            'eds-nav-list--active': active,
            'eds-nav-list--inline': displayInline,
            'eds-nav-list--show-symbols': showSymbols,
        });
        const hasTabRole = items.some((item) => item.role === 'tab');

        return (
            <HotKeys keyMap={ACTION_KEY_MAP} handlers={this.hotKeyHandlers}>
                <ul
                    data-spec="eds-nav-list"
                    className={className}
                    {...(hasTabRole && { role: 'tablist' })}
                >
                    {this._getNavListItems(
                        items,
                        showSymbols,
                        useAltTheme,
                        defaultFocusPosition,
                    )}
                </ul>
            </HotKeys>
        );
    }
}

export default NavList;
