import { snakeToCamel } from '@eventbrite/string-utils';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import toPairs from 'lodash/toPairs';
import {
    normalize,
    NormalizeInput,
    NormalizeOptions,
    NormalizeOutput,
    SchemaValue,
} from 'normalizr';

const _getKey = <
    Key extends keyof FieldsNameMap & string,
    FieldsNameMap extends Partial<Record<string, string>>,
>(
    key: Key,
    fieldsNameMap: FieldsNameMap,
): string => snakeToCamel(fieldsNameMap[key] ?? key);

/**
 * Get a value from a given set of fields
 */
const _getValue = <
    InputObject extends Record<string, any>,
    Key extends keyof InputObject & string,
    Fields extends Array<Key>,
    FieldsNameMap extends Partial<Record<Key, string>>,
>(
    value: InputObject | Array<InputObject>,
    fields: Fields,
    fieldsNameMap: FieldsNameMap,
    isWhiteList: boolean,
): any => {
    if (isArray(value)) {
        const arrayOfValues = value as Array<InputObject>;
        return arrayOfValues.map((v) =>
            // TODO: the as any here needs to be addressed
            _getValue(v, fields, fieldsNameMap as any, isWhiteList),
        );
    }

    if (isObject(value)) {
        /*eslint-disable no-use-before-define*/
        // TODO: the as any here needs to be addressed
        return _nestedObjectHelper(
            value,
            fields,
            fieldsNameMap as any,
            isWhiteList,
        );
    }

    return value;
};

/**
 * Pick or omit keys from an object
 */
const _selectKeysFromResponseObject = <
    InputObject extends Record<string, unknown>,
    Key extends keyof InputObject,
>(
    obj: InputObject,
    fields: Array<Key>,
    isWhiteList: boolean,
): Partial<InputObject> => {
    if (isEmpty(fields)) {
        return obj;
    }

    const result = isWhiteList ? pick(obj, fields) : omit(obj, fields);

    return result as Partial<InputObject>;
};

/**
 * Recursively traverses the passed in object turning each key to camelCase and will
 * exclude/include any key located in the fields
 */
const _nestedObjectHelper = <
    InputObject extends Record<string, any>,
    InputObjectKey extends keyof InputObject & string,
    Fields extends Array<InputObjectKey>,
    FieldsNameMap extends Partial<Record<InputObjectKey, string>>,
>(
    obj: InputObject,
    fields: Fields,
    fieldsNameMap: FieldsNameMap,
    isWhiteList: boolean,
) => {
    const prunedObj = _selectKeysFromResponseObject(obj, fields, isWhiteList);
    const prunedPairs = toPairs(prunedObj) as Array<[InputObjectKey, any]>;
    const transformedPairs = prunedPairs.map(([key, val]) => [
        _getKey(key, fieldsNameMap),
        _getValue(val, fields, fieldsNameMap, isWhiteList),
    ]);
    const transformedObj = fromPairs(transformedPairs);

    return transformedObj;
};

/**
 * Determines if a key should be removed
 */
const _shouldRemoveKey = <Key>(
    isWhiteList: boolean,
    key: Key,
    fields: Array<Key>,
): boolean => {
    const isKeyInFields = fields.indexOf(key) > -1;

    return isWhiteList ? !isKeyInFields : isKeyInFields;
};

/**
 * This function is a default behavior that defaults the assignEntity process for the
 * main normalize function call.
 *
 * The key processes it allows are:
 *     1. transition of snake_case to camelCase for variables at any depth in the response object.
 *     2. removal/inclusion of any keys listed in fields at any depth in the response object.
 *
 *
 * If you do not want this function to be called at all simply send null as the parameter value.
 *
 * If you need extra functionality beyond what this provides you are welcome to write your own, examples on
 * the API and usage cases can be found at the normalizr repo: https://github.com/paularmstrong/normalizr
 *
 * @param obj The transformed response value being built as input is being stepped over by the normalize function
 * @param key The current key of the input object being iterated over
 * @param val The value of the current key at the input object
 * @param fields check param of transformUtil
 * @param fieldsNameMap check param of transformUtil
 * @param isWhiteList
 */
const _defaultFunc = <
    InputObject extends Record<string, any>,
    Key extends keyof InputObject & string,
>(
    obj: any,
    key: Key,
    val: InputObject,
    fields: Array<Key>,
    fieldsNameMap: Record<Key, string>,
    isWhiteList: boolean,
): void => {
    /* Because of the way normalizr handles arrays it is necessary to do a key by key check here */
    if (!isEmpty(fields) && _shouldRemoveKey(isWhiteList, key, fields)) {
        return;
    }

    /* This is required to match the functionality of the assignEntity function provided by normalizr */
    /*eslint-disable no-param-reassign*/
    obj[_getKey(key, fieldsNameMap)] = _getValue(
        val,
        fields,
        fieldsNameMap,
        isWhiteList,
    );
};

type PropertiesOf<T> = Omit<T, keyof Record<string, unknown>>;
type PartialPropertiesOf<T> = Extract<Partial<PropertiesOf<T>>, string>;

/**
 * A transformUtil for receiving responses from the backend that modifies the objects into
 * maps by ID, and many other useful features as detailed on the normalizr repo (link).
 *
 * This wrapper is provided that adds the functionality change all keys from snake_case to camelCase
 * and remove any unwanted fields from the response.
 *
 * The only required fields are the response and the schema object, which is created in the standard
 * normalizr fasion. Supports overwriting the default assignEntity function if you wish extra
 * custom behavior.
 *
 * @param options
 * @param options.response JSON Object which is the API response
 * @param options.schema The normalizr Schema object that you would like to apply to the response object
 * @param [options.options] The options block that will be passed to the normalize call. Will be merged with default options so any overwriting is possible.
 * @param [options.fields] Array of key values to either white list or black list from the normalized object
 *                 IMPORTANT: For whitelisted parameters you must include the entire path to the key
 *                 you want to keep. The normalize function operates one depth at a time
 * @param [options.fieldsNameMap] Object in where the `key` will be renamed with the name of the `value`
 * @param [options.isWhiteList] - Boolean determining whether to whitelist or blacklist the keys in `fields` param.
 */
export const transformUtil = <
    Response extends NormalizeInput,
    FieldsMap extends Record<PartialPropertiesOf<Response>, string>,
    Fields extends Array<
        Omit<Partial<keyof Response>, keyof Record<string, unknown>>
    > &
        Array<string>,
>({
    response,
    schema,
    options = {},
    fields,
    fieldsNameMap,
    isWhiteList = false,
}: {
    response: Response;
    schema: SchemaValue;
    options?: NormalizeOptions;
    fields?: Fields;
    fieldsNameMap?: FieldsMap;
    isWhiteList?: boolean;
}): NormalizeOutput | null => {
    if (!schema) {
        return null;
    }

    const defaultOptions = {
        assignEntity: (obj: any, key: string, val: any) => {
            _defaultFunc(
                obj,
                key,
                val,
                fields || [],
                fieldsNameMap || {},
                isWhiteList,
            );
        },
    };

    return normalize(response, schema, {
        ...defaultOptions,
        ...options,
    });
};
