import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import { deep } from './deep';

const keysToCamel = <InputObject extends object>(obj: InputObject) =>
    mapKeys(obj, (_value, key) => camelCase(key));

/**
 * Deeply converts all the object or array of object's keys to camelCase
 */
export const deepKeysToCamel = <
    InputValue extends object,
    ReturnValue = InputValue,
>(
    obj: InputValue | Array<InputValue>,
): ReturnValue => deep(obj, keysToCamel) as unknown as ReturnValue;
