import { Divider } from '@eventbrite/eds-divider';
import { Typography } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import { LocationInput } from '../../../containers/LocationInput';
import { SearchInput } from '../../../containers/SearchInput';
import { SearchDropdownContext, useSearchInputContext } from '../../../context';
import { useRunSearch } from '../../../hooks';
import { DropdownOverlay } from '../DropdownOverlay';
import { SearchButton } from '../SearchButton';
import { SearchDropdown } from '../SearchDropdown';
import styles from './SearchBar.module.scss';

const SearchInputContainer = ({
    handleSearchButtonClick,
}: {
    handleSearchButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
    const { showDropdown, setShowDropdown, setShowOverlay } = useContext(
        SearchDropdownContext,
    );

    const handleSearchClick = useCallback(() => {
        setShowDropdown(true);
        setShowOverlay(true);
    }, [setShowDropdown, setShowOverlay]);

    const handleSearchClickOutside = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            const target = e.target as HTMLElement;
            if (target?.id === 'search-autocomplete-input') {
                return;
            } else if (
                target?.id === 'location-autocomplete' ||
                target?.id === 'search-dropdown-overlay'
            ) {
                setShowDropdown(false);
            } else {
                setShowDropdown(false);
                setShowOverlay(false);
            }
        },
        [setShowDropdown, setShowOverlay],
    );

    return (
        <div
            className={styles.searchLabelContainer}
            data-heap-id="seo-global-nav-search-bar-search-bar-click"
        >
            <div className={styles.searchLabel}>
                <SearchInput
                    variant="header"
                    dropdownVisible={showDropdown}
                    handleSearchClick={handleSearchClick}
                    handleBackButtonClick={handleSearchClickOutside}
                />
            </div>
            <div className={styles.mobileSearchBarRightSide}>
                <SearchButton onClick={handleSearchButtonClick} />
            </div>
            {showDropdown && (
                <SearchDropdown onClickOutside={handleSearchClickOutside} />
            )}
        </div>
    );
};

const LocationInputContainer = () => {
    const { setShowOverlay } = useContext(SearchDropdownContext);

    const handleLocationClick = useCallback(() => {
        setShowOverlay(true);
    }, [setShowOverlay]);

    const handleLocationSelect = () => {
        if (window?.innerWidth >= 660) {
            setShowOverlay(false);
        }
    };

    return (
        <div className={styles.dividerAndLocationContainer}>
            <div className={styles.dividerContainer}>
                <Divider direction="vertical" color="ui-500" />
            </div>
            <div
                className={styles.locationContainer}
                onClick={handleLocationClick}
            >
                <div className={styles.locationLabel}>
                    <LocationInput
                        onLocationSelect={handleLocationSelect}
                        variant="header"
                    />
                </div>
            </div>
        </div>
    );
};

const SearchBar = () => {
    const { showOverlay, setShowOverlay } = useContext(SearchDropdownContext);
    const { query } = useSearchInputContext();
    const handleSearch = useRunSearch();

    const onOverlayClose = useCallback(() => {
        setShowOverlay(false);
    }, [setShowOverlay]);

    const handleSearchButtonClick = useCallback(() => {
        handleSearch({
            search: { q: query || '' },
            analytics: { action: 'RunSearch', label: query },
        });
    }, [handleSearch, query]);

    const searchBarClassnames = classNames(styles.searchBarWithLocation, {
        [styles.overlayOpen]: showOverlay,
    });

    return (
        <>
            <div className={searchBarClassnames} data-testid="header-search">
                <Typography
                    variant="body-md"
                    as="span"
                    color="neutral-700"
                    className={styles.searchBarContent}
                >
                    <SearchInputContainer
                        handleSearchButtonClick={handleSearchButtonClick}
                    />
                    <LocationInputContainer />
                    <div className={styles.desktopSearchBarRightSide}>
                        <SearchButton onClick={handleSearchButtonClick} />
                    </div>
                </Typography>
                <DropdownOverlay
                    domNodeId="root"
                    onClose={onOverlayClose}
                    isVisible={showOverlay}
                />
            </div>
        </>
    );
};

export default SearchBar;
