import * as React from 'react';

const CrossChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="cross-chunky_svg__eds-icon--cross-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="cross-chunky_svg__eds-icon--cross-chunky_base"
            d="M13.4 12l3.5-3.5-1.4-1.4-3.5 3.5-3.5-3.5-1.4 1.4 3.5 3.5-3.5 3.5 1.4 1.4 3.5-3.5 3.5 3.5 1.4-1.4z"
        />
    </svg>
);

CrossChunkySvg.displayName = 'CrossChunkySvg';
export default CrossChunkySvg;
