export const getHeapPageArea = (affCode) => {
    if (typeof window === 'undefined') {
        return null; // Return null for non-browser environments.
    }
    const pathname = window.location.pathname;

    if (pathname === '/') {
        return 'homepage';
    }
    if (pathname.includes('/d/')) {
        if (pathname.includes('/events/')) {
            return 'city-browse-page';
        } else {
            return 'search-results-page';
        }
    }
    if (pathname.startsWith('/e/') && affCode === 'erelpanelorg') {
        return 'listing-more-events-from-organizer';
    }
    if (pathname.startsWith('/e/') && affCode === 'erellivmlt') {
        return 'listing-other-events-you-may-like';
    }
    if (pathname.startsWith('/e/')) {
        return 'listing-hero-content';
    }
    if (pathname.includes('/b/')) {
        return 'category-browse-page';
    }
    if (pathname.includes('/cc/')) {
        return 'creator-collections-page';
    }

    if (pathname.includes('/mytickets')) {
        return 'my-tickets';
    }

    if (pathname.includes('/u/')) {
        if (pathname.includes('/favorites/events')) {
            return 'likes-page';
        }

        if (pathname.includes('/following')) {
            return 'profile-following';
        }

        return 'profile-likes';
    }

    if (pathname.includes('/checkout-external')) {
        return 'checkout-confirmation';
    }

    if (pathname.includes('/o/')) {
        return 'organizer-profile';
    }

    return null;
};
