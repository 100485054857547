import {
    EventInteraction,
    EventInteractionContext,
    EventInteractions,
} from './types';

export class InteractionsLogger<
    TransformType = EventInteraction<EventInteractionContext>,
> {
    protected ORIGIN = '';

    /**
     * Basic allow / exclude event list for interactions
     */
    protected allowedEvents: EventInteractions[] =
        Object.values(EventInteractions);
    protected excludedEvents: EventInteractions[] = [];

    /**
     * Filter with custom logic the interactions.
     * @param interactions - The interactions to filter.
     * @returns If the interaction should be logged or not
     */
    protected filterEventsBy(_: EventInteraction<EventInteractionContext>) {
        return true;
    }

    /**
     * Internal method to combine all event filters
     * @param interactions - Interactions received
     * @returns Filtered interactions to log
     */
    private getInteractionsToLog(
        interactions: EventInteraction<EventInteractionContext>[],
    ) {
        return interactions
            .filter(
                ({ action }) =>
                    this.allowedEvents.includes(action) &&
                    !this.excludedEvents.includes(action),
            )
            .filter(this.filterEventsBy);
    }

    /**
     * Transform the interactions to the format expected by the logging system
     * @param interaction - The interaction to transform
     * @returns The transformed interaction
     */
    protected transformInteraction(
        interaction: EventInteraction<EventInteractionContext>,
    ): TransformType {
        return interaction as unknown as TransformType;
    }

    /**
     * Log the interactions
     * @param interactions - The interactions to log
     * @throws If the method is not implemented
     */
    protected log(_: TransformType[]) {
        throw new Error('Not implemented');
    }

    /**
     * Track the interactions
     * This is an internal method used by the eventInteractionsTracker to trigger the logging system
     * @param interactions - The interactions to track
     */
    public run(interactions: EventInteraction<EventInteractionContext>[]) {
        const interactionsToLog = this.getInteractionsToLog(interactions);
        if (interactionsToLog.length === 0) {
            return;
        }

        const transformedInteractions = interactionsToLog.map(
            this.transformInteraction.bind(this),
        );
        this.log(transformedInteractions);
    }
}
