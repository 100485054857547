/** Defines the span type for the `type`prop of Label. */
export const TYPE_SPAN = 'span';

/** Defines the label type for the `type` prop of Label. */
export const TYPE_LABEL = 'label';

/** List of available types for the `type` prop of Label. */
export const TYPES = [TYPE_SPAN, TYPE_LABEL];

/** Defines the right location for the `requiredIndicatorLocation` prop of Label. */
export const LOCATION_RIGHT = 'right';

/** Defines the end location for the `requiredIndicatorLocation` prop of Label. */
export const LOCATION_END_OF_TEXT = 'end';

/** List of available locations for the `requiredIndicatorLocation` prop of Label. */
export const REQUIRED_INDICATOR_LOCATION = [
    LOCATION_RIGHT,
    LOCATION_END_OF_TEXT,
];
