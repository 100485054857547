import * as React from 'react';

const ChevronDownChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="chevron-down-chunky_svg__eds-icon--chevron-down-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="chevron-down-chunky_svg__eds-icon--chevron-down-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 10.2l5 5 5-5-1.4-1.4-3.6 3.6-3.6-3.6z"
        />
    </svg>
);

ChevronDownChunkySvg.displayName = 'ChevronDownChunkySvg';
export default ChevronDownChunkySvg;
