import React, { useEffect, useState } from 'react';

export const useHashListener = () => {
    const [currentHash, setCurrentHash] = useState<null | string>(null);

    const popStateListener = React.useCallback(() => {
        if (currentHash !== window.location.hash) {
            setCurrentHash(window.location.hash);
        }
    }, [currentHash]);

    useEffect(() => {
        window.addEventListener('popstate', popStateListener);
        () => {
            window.removeEventListener('popstate', popStateListener);
        };
    }, [popStateListener]);

    return currentHash;
};
