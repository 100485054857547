import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import classNames from 'classnames';
import { NOTIFICATION_OPTIONS_PROP_TYPES } from '@eventbrite/eds-notification';
import { SIZES as BUTTON_SIZES } from '@eventbrite/eds-button';
import { ICON_PROP_TYPE } from '@eventbrite/eds-icon-button';
import noop from 'lodash/noop';
import FocusTrap from 'focus-trap-react';
import {
    HEAP_SHARE_EVENT_CLOSE_MODAL,
    onClickOutsideHOC,
    reTrackHeapEvent,
} from '@eventbrite/eds-utils';
import {
    addWindowListener,
    removeWindowListener,
    HAS_DOCUMENT,
} from '@eventbrite/eds-utils';
import CloseButton from './CloseButton';
import * as constants from './constants';
import CollapsiblePaneLayout from './CollapsiblePaneLayout';
import ModalBody from './ModalBody';
import { gettext } from '@eventbrite/i18n';

import './modal.scss';

const DecoratedModalBody = onClickOutsideHOC(
    ({
        children,
        onClose,
        headerActionIconType,
        headerActionTitle,
        onHeaderAction,
        title,
        secondaryTitle,
        illustration,
        pane,
        isClosable,
        isPaneOpen,
        onPaneClickOutside,
        paneInteraction,
        primarySize,
        primaryType,
        primaryText,
        primaryAttributes,
        primaryIsDisabled,
        onPrimaryAction,
        secondaryType,
        secondaryText,
        secondaryAttributes,
        secondaryIsDisabled,
        onSecondaryAction,
        tertiaryNode,
        type,
        onTertiaryAction,
        dangerouslySetContainerClassName,
        modalBodyIsEmpty,
        notificationOptions,
        buttonBarMessage,
        ariaLabel,
        ariaLabelledby,
        ariaDescribedby,
        ariaModal,
        primaryIsLoading,
        secondaryIsLoading,
        closeButtonClassName,
        closeButtonCustomIcon,
    }) => {
        let dangerousClassName;

        if (dangerouslySetContainerClassName) {
            dangerousClassName = dangerouslySetContainerClassName.__className;
        }

        const className = classNames('eds-modal__body', {
            'eds-fx--fade-in': type !== constants.TYPE_TAKEOVER,
            [`eds-modal--${type}`]: type,
            [dangerousClassName]: dangerousClassName,
        });

        const bodyClassName = classNames('eds-modal__content', {
            'eds-modal__body--fade-in eds-modal__body--takeover-padding':
                type === constants.TYPE_TAKEOVER,
        });

        let modalBody = (
            <CollapsiblePaneLayout
                isPaneOpen={isPaneOpen}
                onPaneClickOutside={onPaneClickOutside}
                pane={pane}
                paneInteraction={paneInteraction}
            >
                <ModalBody
                    headerActionIconType={headerActionIconType}
                    headerActionTitle={headerActionTitle}
                    onHeaderAction={onHeaderAction}
                    title={title}
                    secondaryTitle={secondaryTitle}
                    illustration={illustration}
                    primarySize={primarySize}
                    primaryType={primaryType}
                    primaryText={primaryText}
                    primaryAttributes={primaryAttributes}
                    primaryIsDisabled={primaryIsDisabled}
                    primaryIsLoading={primaryIsLoading}
                    onPrimaryAction={onPrimaryAction}
                    secondaryType={secondaryType}
                    secondaryText={secondaryText}
                    secondaryAttributes={secondaryAttributes}
                    secondaryIsDisabled={secondaryIsDisabled}
                    secondaryIsLoading={secondaryIsLoading}
                    onSecondaryAction={onSecondaryAction}
                    tertiaryNode={tertiaryNode}
                    onTertiaryAction={onTertiaryAction}
                    notificationOptions={notificationOptions}
                    buttonBarMessage={buttonBarMessage}
                    ariaLabel={ariaLabel}
                    ariaLabelledby={ariaLabelledby}
                    ariaDescribedby={ariaDescribedby}
                    ariaModal={ariaModal}
                >
                    {children}
                </ModalBody>
            </CollapsiblePaneLayout>
        );

        if (modalBodyIsEmpty) {
            modalBody = children;
        }

        const childrenEventId = children?.props?.shareOptions?.eventId || null;
        const childrenAffCode = children?.props?.shareOptions?.affCode || null;

        const onCloseHandler = onClose
            ? (e) => {
                  if (childrenEventId) {
                      reTrackHeapEvent({
                          affCode: childrenAffCode,
                          heapEventName: HEAP_SHARE_EVENT_CLOSE_MODAL,
                          eventId: childrenEventId,
                      });
                  }
                  onClose(e);
              }
            : undefined;

        return (
            <div
                className={className}
                aria-hidden="false"
                data-spec="eds-modal-body"
            >
                <div className={bodyClassName}>
                    {modalBody}

                    {isClosable && (
                        <CloseButton
                            customClassName={closeButtonClassName}
                            customIcon={closeButtonCustomIcon}
                            hasBackgroundColor={
                                type === constants.ModalType.aperture
                            }
                            onClose={onCloseHandler}
                            type={type}
                        />
                    )}
                </div>
            </div>
        );
    },
);

export default class Modal extends React.PureComponent {
    static propTypes = {
        /**
         * The contents to display in the model
         */
        children: constants.MODAL_CHILDREN_PROPTYPE.isRequired,
        /**
         * The type of modal that should be shown
         */
        type: PropTypes.oneOf(constants.TYPES),
        /**
         * A function with the desired behavior on mount (componentDidMount)
         */
        onMount: PropTypes.func,
        /**
         * Is the dialog currently shown?
         */
        isShown: PropTypes.bool,
        /**
         * Removes min height for simple Modals
         */
        noMinHeight: PropTypes.bool,
        /**
         * Is the dialog closable?
         */
        isClosable: PropTypes.bool,
        /**
         * Removes padding on the modal container
         */
        noPadding: PropTypes.bool,
        /**
         * Add a class to the modals wrapper <dialog>
         */
        dangerouslySetContainerClassName: PropTypes.shape({
            __className: PropTypes.string,
        }),
        /**
         * A function with the desired behavior on close
         */
        onClose: PropTypes.func,
        /**
         * The icon to use for the secondary header action
         */
        headerActionIconType: ICON_PROP_TYPE,
        /**
         * The title for the secondary header action icon
         */
        headerActionTitle: translationPropType,
        /**
         * A function with the desired behavior on clicking the secondary header action icon
         */
        onHeaderAction: PropTypes.func,
        /**
         * The title displayed above the content
         */
        title: constants.MODAL_TITLE_PROPTYPE,
        /**
         * The title displayed above the content
         */
        secondaryTitle: PropTypes.node,
        /**
         * The illustration displayed inside the modal
         */
        illustration: PropTypes.node,
        /**
         * The type of button that should be shown for primary button
         */
        primaryType: PropTypes.string,
        /**
         * The text that should be shown across the primary button
         */
        primaryText: PropTypes.node,
        /**
         * Additional attributes to place on the primary button element
         */
        primaryAttributes: PropTypes.object,
        /**
         * The size of the primary button
         */
        primarySize: PropTypes.oneOf([...BUTTON_SIZES]),
        /**
         * Whether the primary button should be disabled
         */
        primaryIsDisabled: PropTypes.bool,
        /**
         * The function that should be triggered on primary button click
         */
        onPrimaryAction: PropTypes.func,
        /**
         * The type of button that should be shown for secondary button
         */
        secondaryType: PropTypes.string,
        /**
         * The text that should be shown across the secondary button
         */
        secondaryText: PropTypes.node,
        /**
         * Additional attributes to place on the secondary button element
         */
        secondaryAttributes: PropTypes.object,
        /**
         * Whether the secondary button should be disabled
         */
        secondaryIsDisabled: PropTypes.bool,
        /**
         * The function that should be triggered on secondary button click
         */
        onSecondaryAction: PropTypes.func,
        /**
         * The node that will act as a tertiary action
         */
        tertiaryNode: PropTypes.node,
        /**
         * The function that should be triggered on tertiary node click
         */
        onTertiaryAction: PropTypes.func,
        /**
         * A message to show above the button bar
         */
        buttonBarMessage: PropTypes.oneOf([
            translationPropType,
            PropTypes.string,
            PropTypes.node,
        ]),
        /**
         * True if the modal body should be empty, i.e. not include the header, footer, or body containers
         */
        modalBodyIsEmpty: PropTypes.bool,
        /**
         * Disables the ability to close the modal by clicking the outside overlay
         */
        disableCloseOnClickOutside: PropTypes.bool,
        /**
         * Used to render notifications inside the modal header.
         */
        notificationOptions: PropTypes.oneOfType([
            NOTIFICATION_OPTIONS_PROP_TYPES,
            PropTypes.arrayOf(NOTIFICATION_OPTIONS_PROP_TYPES),
        ]),
        /**
         * Changes the role attribute to dialog
         */
        roleIsDialog: PropTypes.bool,
        /**
         * The content to display next to the pane
         */
        content: PropTypes.node,
        /**
         * The content to display inside the pane, if any
         */
        pane: PropTypes.node,
        /**
         * Whether pane is open or closed
         */
        isPaneOpen: PropTypes.bool,
        /**
         * Callback when user clicks outside the pane content
         */
        onPaneClickOutside: PropTypes.func,
        /**
         * Callback function that runs when the pane changes its state
         */
        paneInteraction: PropTypes.oneOf(
            constants.PANE_LAYOUT_INTERACTION_TYPES,
        ),
        /**
         *  Defines a string value that labels the current element
         */
        ariaLabel: PropTypes.string,
        /**
         *  Identifies the element (or elements) that labels the current element
         */
        ariaLabelledby: PropTypes.string,
        /**
         *  Identifies the element (or elements) that describes the the current element
         */
        ariaDescribedby: PropTypes.string,
        /**
         *  Indicates whether an element is modal when displayed
         */
        ariaModal: PropTypes.bool,
        /**
         * Whether the primary button should be displayed with a spinner
         */
        primaryIsLoading: PropTypes.bool,
        /**
         * Whether the secondary button should be displayed with a spinner
         */
        secondaryIsLoading: PropTypes.bool,
        /**
         * When true, the modal will be rendered inside of a Portal
         * The default host of the Portal is the root node of the project
         * However a custom node can be specified via the [modalHostNodeSelector] property
         * Optional.
         */
        isPortal: PropTypes.bool,
        /**
         * The selector of the host node in the DOM that will hold the Modal
         * Optional.
         */
        modalHostNodeSelector: PropTypes.string,
        /**
         * Custom/additional class to be applied to modal
         */
        __containerClassName: PropTypes.string,
        focusTrapOptions: PropTypes.object,
        /**
         * Custom CSS class to be applied to the close button
         */
        closeButtonClassName: PropTypes.string,
        /**
         * Custom icon to be used for the close button instead of CrossChunky
         */
        closeButtonCustomIcon: PropTypes.node,
        /**
         * True to disable the focus trap
         */
        disableFocusTrap: PropTypes.bool,
    };

    static defaultProps = {
        type: constants.TYPE_SIMPLE,
        isClosable: true,
        isShown: false,
        noPadding: false,
        roleIsDialog: false,
        modalHostNodeSelector: constants.ROOT_NODE_SELECTOR,
    };

    componentDidMount() {
        const { isShown } = this.props;

        this._boundHandleEscKey = this._handleEscKey.bind(this);
        this._checkShownState(isShown);
    }

    UNSAFE_componentWillReceiveProps({ isShown }) {
        this._checkShownState(isShown);
    }

    componentWillUnmount() {
        this._unattachWindowListeners();
        this._lockModalViewport(false);
    }

    // used to lock the viewport so that the user can scroll under the modal
    _lockModalViewport(isLocked) {
        if (HAS_DOCUMENT) {
            const lockClass = 'eds-modal--hidden-overflow';

            if (isLocked) {
                document.body?.classList.add(lockClass);
            } else {
                document.body?.classList.remove(lockClass);
            }
        }
    }

    _checkShownState(isShown) {
        this._lockModalViewport(isShown);

        if (isShown) {
            this._attachWindowListeners();
        } else {
            this._unattachWindowListeners();
        }
    }

    _attachWindowListeners() {
        addWindowListener('keydown', this._boundHandleEscKey);
    }

    _unattachWindowListeners() {
        removeWindowListener('keydown', this._boundHandleEscKey);
    }

    _handleClose() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    _handleEscKey(event) {
        if (event.key === 'Escape') {
            this._handleClose();
        }
    }

    _handleClickOutside() {
        const { disableCloseOnClickOutside } = this.props;

        if (!disableCloseOnClickOutside) {
            this._handleClose();
        }
    }

    render() {
        const {
            children,
            type,
            isShown,
            noMinHeight,
            title,
            secondaryTitle,
            illustration,
            pane,
            isClosable,
            isPaneOpen,
            onPaneClickOutside,
            paneInteraction,
            isPortal,
            headerActionIconType,
            headerActionTitle,
            onHeaderAction,
            primarySize,
            primaryType,
            primaryText,
            primaryAttributes,
            primaryIsDisabled,
            onPrimaryAction,
            secondaryType,
            secondaryText,
            secondaryAttributes,
            secondaryIsDisabled,
            onSecondaryAction,
            tertiaryNode,
            onTertiaryAction,
            onClose,
            noPadding,
            dangerouslySetContainerClassName,
            modalBodyIsEmpty,
            modalHostNodeSelector,
            notificationOptions,
            roleIsDialog,
            ariaLabel,
            ariaLabelledby,
            ariaDescribedby,
            ariaModal,
            buttonBarMessage,
            primaryIsLoading,
            secondaryIsLoading,
            __containerClassName,
            closeButtonClassName,
            closeButtonCustomIcon,
            disableFocusTrap,
        } = this.props;

        if (!isShown) {
            return null;
        }

        const modalClasses = classNames('eds-modal', __containerClassName, {
            'eds-fx--fade-in': type !== constants.TYPE_TAKEOVER,
            [`eds-modal--${type}`]: type,
            'eds-modal--active': isShown,
            'eds-modal--has-padding': !noPadding,
            'eds-modal--set-height':
                !noMinHeight && type !== constants.TYPE_TAKEOVER,
        });

        // We need to set a fallbackFocus in case there was no inital foucsed element
        // https://github.com/davidtheclark/focus-trap/blob/master/index.js#L156
        const focusTrapOptions = {
            fallbackFocus: () => noop,
            ...this.props.focusTrapOptions,
        };
        let accessibilityProps = {
            'aria-label': ariaLabel,
            'aria-labelledby': ariaLabelledby,
            'aria-describedby': ariaDescribedby,
            'aria-modal': ariaModal,
            role: 'alertdialog',
        };

        // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-labelledby_attribute
        if (title && !ariaLabelledby) {
            accessibilityProps = {
                ...accessibilityProps,
                'aria-labelledby': constants.MODAL_TITLE_ID,
            };
        }

        // Default to alertdialog role type unless specifically dialog type is requested
        // https://www.w3.org/TR/wai-aria-1.1/#alertdialog & https://www.w3.org/TR/wai-aria-1.1/#dialog
        if (roleIsDialog) {
            accessibilityProps = { ...accessibilityProps, role: 'dialog' };
        }

        const modalContent = (
            <div
                className={modalClasses}
                data-spec="eds-modal"
                {...accessibilityProps}
                aria-label={
                    ariaLabel || gettext('Date Picker Dialog').toString()
                }
            >
                <DecoratedModalBody
                    isClosable={isClosable}
                    onClose={onClose}
                    primarySize={primarySize}
                    primaryType={primaryType}
                    primaryText={primaryText}
                    primaryAttributes={primaryAttributes}
                    primaryIsDisabled={primaryIsDisabled}
                    primaryIsLoading={primaryIsLoading}
                    onPrimaryAction={onPrimaryAction}
                    secondaryType={secondaryType}
                    secondaryText={secondaryText}
                    secondaryAttributes={secondaryAttributes}
                    secondaryIsDisabled={secondaryIsDisabled}
                    secondaryIsLoading={secondaryIsLoading}
                    tertiaryNode={tertiaryNode}
                    type={type}
                    onTertiaryAction={onTertiaryAction}
                    onSecondaryAction={onSecondaryAction}
                    headerActionIconType={headerActionIconType}
                    headerActionTitle={headerActionTitle}
                    onHeaderAction={onHeaderAction}
                    title={title}
                    secondaryTitle={secondaryTitle}
                    illustration={illustration}
                    pane={pane}
                    isPaneOpen={isPaneOpen}
                    onPaneClickOutside={onPaneClickOutside}
                    paneInteraction={paneInteraction}
                    onClickOutside={this._handleClickOutside.bind(this)}
                    dangerouslySetContainerClassName={
                        dangerouslySetContainerClassName
                    }
                    modalBodyIsEmpty={modalBodyIsEmpty}
                    notificationOptions={notificationOptions}
                    buttonBarMessage={buttonBarMessage}
                    closeButtonClassName={closeButtonClassName}
                    closeButtonCustomIcon={closeButtonCustomIcon}
                >
                    {children}
                </DecoratedModalBody>
            </div>
        );

        const modalComponent = disableFocusTrap ? (
            modalContent
        ) : (
            <FocusTrap
                focusTrapOptions={focusTrapOptions}
                data-spec="focus-trap-component"
            >
                {modalContent}
            </FocusTrap>
        );

        const portalHostNode =
            isPortal && document.querySelector(modalHostNodeSelector);

        return portalHostNode
            ? ReactDOM.createPortal(modalComponent, portalHostNode)
            : modalComponent;
    }
}
