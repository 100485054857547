import { useMutation } from '@tanstack/react-query';
import Cookies from 'cookies-js';
import { useMemo } from 'react';
import { useMount } from 'react-use';
import { formatUrl } from 'url-lib';
import { useEventClient } from '../../hooks';
import { saveEvent } from './handlers';

export const EVENT_COOKIE = 'ebEventId';

export const useAffCode = (eventUrl: string, affCode: string) => {
    const formattedUrl = useMemo(() => {
        if (affCode) {
            return formatUrl(eventUrl, {
                aff: affCode,
            });
        } else {
            return eventUrl;
        }
    }, [eventUrl, affCode]);

    return formattedUrl;
};

/**
 * On mount, check if cookie is saved for the given Event
 * and parse.
 *
 * @param handleSave
 * @param eventId
 * @param isAuthenticated
 */
export const useShouldSaveFromLoggedOutRedirect = (
    handleSave: Function,
    eventId: string,
    isAuthenticated: boolean,
) => {
    return useMount(() => {
        const eventIdFromCookie = Cookies.get(EVENT_COOKIE);

        if (
            eventIdFromCookie &&
            eventIdFromCookie === eventId &&
            isAuthenticated
        ) {
            Cookies.expire(EVENT_COOKIE);

            return handleSave({
                eventId,
                isAuthenticated,
                savedByYou: false,
            });
        }
    });
};

/**
 * @param setSaved Stateful callback to manage local state of the eventcard
 * @param onCompleted Callback on successful API call
 *
 * React query mutation hook which updates the event local state as we don;t
 * currently have access to the events via the querycache.
 *
 * Leverages the patterns showcased here with the useMutation hook to
 * handle optimistcally updating the response:
 * https://react-query.tanstack.com/docs/guides/optimistic-updates
 */
export const useSaveEvent = ({
    setSaved,
    onCompleted,
}: {
    setSaved: Function;
    onCompleted: Function;
}) => {
    useEventClient();

    const { mutateAsync: handleSaveEvent } = useMutation(saveEvent, {
        onMutate: ({
            isAuthenticated,
            savedByYou,
        }: {
            isAuthenticated: boolean;
            savedByYou: boolean;
        }) => {
            if (isAuthenticated) {
                setSaved(!savedByYou);
            }

            return { previousState: savedByYou };
        },
        onError: (err: Error, _data, context) => {
            setSaved(context?.previousState);
            throw err;
        },
        onSuccess: () => {
            return onCompleted();
        },
    });

    return handleSaveEvent;
};
