import { getWindowObject, HAS_WINDOW } from '@eventbrite/feature-detection';
import type { GenericLazyString } from '@eventbrite/i18n';
import { gettext } from '@eventbrite/i18n';
import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInMonths,
    differenceInYears,
    format,
    intlFormat,
    isMatch,
    parse,
    parseISO,
    toDate,
} from 'date-fns';
import {
    format as formatTz,
    formatInTimeZone,
    getTimezoneOffset,
    toDate as toDateTz,
    utcToZonedTime,
} from 'date-fns-tz';
import {
    DATE_FNS_FORMAT_CONFIG,
    DEFAULT_LANGUAGE,
    DEFAULT_TIMEZONE,
    DEFAULT_TIME_FORMAT,
    FULL_DATE_FORMAT,
    FULL_DATE_WEEK_DAY_FORMAT,
    ISO_8601_DATE_FORMAT,
    ISO_8601_FORMAT,
    ISO_8601_SET,
    LONG_MONTH_DATE,
    TIME_FORMAT_SET,
} from './constants';
import Locales from './locales';
import DEtzAid from './timezone-data/de-tz-mapping.json';
import ENtzAid from './timezone-data/en-tz-mapping.json';
import EStzAid from './timezone-data/es-tz-mapping.json';
import FRtzAid from './timezone-data/fr-tz-mapping.json';
import ITtzAid from './timezone-data/it-tz-mapping.json';
import NLtzAid from './timezone-data/nl-tz-mapping.json';
import PTtzAid from './timezone-data/pt-tz-mapping.json';
import SVtzAid from './timezone-data/sv-tz-mapping.json';
import type {
    AidType,
    CalculateDurationArgs,
    DateAPIResponse,
    DateFnsGranularity,
    DiffCalculatorPicker,
    FormattedDate,
    SupportedLanguages,
} from './types';

const tzAid: Record<SupportedLanguages, AidType> = {
    en: ENtzAid,
    es: EStzAid,
    de: DEtzAid,
    fr: FRtzAid,
    it: ITtzAid,
    nl: NLtzAid,
    pt: PTtzAid,
    sv: SVtzAid,
};

/**
 * Returns a string representing a singular datetime formatted for a given
 * timezone. If no timezone is provided, use the default timezone. If showTimezone
 * is false, do not display the timezone as part of the datetime.
 *
 * @param  {string} dateTime
 * @param  {Boolean} showTimezone
 * @param  {string} timezone
 * @param  {string} locale
 * @param  {string} dateTimeFormat
 *
 */

export const getFormattedDateTime = (
    dateTime?: string | null,
    showTimezone = false,
    timezone: string = DEFAULT_TIMEZONE,
    locale: string = getWindowLocale(),
    dateTimeFormat?: string,
) => {
    let formattedDateTime: GenericLazyString | string = '';

    if (!dateTime) {
        // [Deprecated] To match behaviour of our JS version of getFormattedDateTime:
        return '';
    }
    const parsedDate = parseISO(dateTime);
    const dateTimeAsDateFns = !dateTimeFormat
        ? intlFormat(
              parsedDate,
              {
                  ...DATE_FNS_FORMAT_CONFIG,
                  timeZone: timezone,
              },
              {
                  locale: getDateFnsLocale(locale).code,
              },
          )
        : formatTz(utcToZonedTime(parsedDate, timezone), dateTimeFormat, {
              locale: getDateFnsLocale(locale),
              timeZone: timezone,
          });

    // Example return value: 'Tue, Feb 28, 7:00 PM EST' or 'Tue, Feb 28, 7:00 PM'
    if (!showTimezone) {
        formattedDateTime = gettext('%(formattedDateTime)s', {
            formattedDateTime: dateTimeAsDateFns,
        });
    } else {
        formattedDateTime = gettext('%(formattedDateTime)s %(timezone)s', {
            formattedDateTime: dateTimeAsDateFns,
            timezone: getFormattedTimezone(timezone, dateTime),
        });
    }

    return formattedDateTime.toString();
};

/**
 * Returns a string representing a formatted timezone.
 *
 * @param {string} timeZone -- required
 * @param {string} dateTime
 **/
export const getFormattedTimezone = (timeZone: string, dateTime: string) => {
    const REGEX = /UTC|GMT([+-]\d+)?/;
    const dateFnsTimezone = formatInTimeZone(dateTime, timeZone, 'z', {
        locale: getDateFnsLocale(),
    });
    return REGEX.test(dateFnsTimezone)
        ? getCorrectTzShortname(
              timeZone,
              toDateTz(dateTime, { timeZone }),
              dateFnsTimezone,
          )
        : dateFnsTimezone;
};

/**
 * Transforms a date string or object into the string format expected by DatePicker.
 * NOTE: This will strip any time information.
 *
 * @param {Date} date
 * @return {string}
 */
export const formatDate = (date: Date | string): string => {
    const parsedDate = date instanceof Date ? date : parseISO(date);
    return format(toDate(parsedDate), ISO_8601_DATE_FORMAT);
};

/**
 * A transformation for date & time into the string format that the V3 API expects for dates.
 *
 * @param {Date | string} date
 * @param {string} time
 * @return {string}
 */
export const formatForAPI = (
    date: Date | string,
    time: string,
    timeZone: string,
): string => {
    const [year, month, day] =
        typeof date === 'string'
            ? date.split('-')
            : [date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDay()];

    const isoTime = format(
        parse(time, getTimeFormatForTimeString(time), new Date()),
        'HH:mm:ss',
    );
    const formattedDate = toDateTz(`${year}-${month}-${day}T${isoTime}`, {
        timeZone,
    });
    const formattedString = formatInTimeZone(
        formattedDate,
        'UTC',
        "yyyy-MM-dd'T'HH:mm:ss",
    );

    // due to a bug in the lib, Z cannot be escaped...
    return `${formattedString}Z`;
};

/**
 * A transformation that takes a UTC dateTime string from the API, and converts
 * it to the specified time zone.
 *
 * If timeZone isn't specified, it assumes utc. Otherwise, momentTimezone would throw
 * an error which breaks the consumers (EB-215110)
 *
 *
 * @param {string} dateTimeString
 * @param {string | undefined | null} timeZone
 * @return {string}
 */
export const formatFromAPI = (
    dateTimeString: string,
    timeZone?: string,
): string => {
    // Handle null timeZone by setting to default value
    const resolvedTimezone = timeZone ?? DEFAULT_TIMEZONE;
    return format(
        utcToZonedTime(dateTimeString, resolvedTimezone),
        ISO_8601_FORMAT,
    );
};

/**
 * Takes an string describing the locale to use, e.g: fr_FR and looks up for the correct locale
 * from date-fns locale list
 *
 * @param {string} locale
 * @return {Locale}
 */
export const getDateFnsLocale = (
    locale: string = getWindowLocale(),
): Locale => {
    const localeFound =
        Locales[locale.substring(0, 2) + locale.substring(3, 5)] ??
        Locales[locale.substring(0, 2)] ??
        Locales.enUS;
    return localeFound as Locale;
};
/**
 * extract language code from locale string
 * @param locale
 * @returns
 */
export const getLangFromLocale = (locale = getWindowLocale()) =>
    locale.substring(0, 2) || 'en';

/**
 * Separates an ISO_8601 string to its individual date and time parts
 *
 * @param {string} isoString
 * @return {Object}
 */
export const iso8601ToDateTime = (
    isoString: string,
): { date: string; time: string } => {
    const date = parse(
        isoString,
        ISO_8601_SET.find((_format) => isMatch(isoString, _format)) ||
            ISO_8601_FORMAT,
        new Date(isoString),
    );
    return {
        date: format(date, ISO_8601_DATE_FORMAT),
        time: format(date, DEFAULT_TIME_FORMAT),
    };
};

declare global {
    interface Window {
        __i18n__?: {
            locale: string;
        };
    }
}

/**
 * Safely Looks up for the local value in window object
 *
 * @returns {string}
 */
const getWindowLocale = (): string =>
    getWindowObject('__i18n__')?.locale || DEFAULT_LANGUAGE;

/**
 * Converts a date object returned in an API response into several useful date and time
 * formats by parsing the datetime string into the current locale.
 *
 * Current Locale if undefined will be parsed from the `window` object.
 *
 * @param {DateAPIResponse}
 * @returns
 */
export const getAPIDateTimeFormats = ({
    utc,
    timezone,
    locale: initialLocale,
}: DateAPIResponse): FormattedDate => {
    // Handle null timeZone by setting to default value
    const resolvedTimezone = timezone ?? DEFAULT_TIMEZONE;
    const locale = getDateFnsLocale(initialLocale);
    const parsedDate = utcToZonedTime(new Date(utc), resolvedTimezone);

    const time = format(parsedDate, DEFAULT_TIME_FORMAT, { locale });
    const shortDate = format(parsedDate, LONG_MONTH_DATE, { locale });
    const fullDate = intlFormat(parsedDate, FULL_DATE_FORMAT, {
        locale: locale.code,
    });
    const fullDateWeekday = intlFormat(parsedDate, FULL_DATE_WEEK_DAY_FORMAT, {
        locale: locale.code,
    });
    const numeralDate = format(parsedDate, 'P', {
        locale: locale,
    });
    const formattedTimezone = getFormattedTimezone(resolvedTimezone, utc);

    // Translators: This shows a time with its timezone. For example "7:00 PM PDT"
    const timeWithTimezone = gettext('%(time)s %(timezone)s', {
        time,
        timezone: formattedTimezone,
    }).toString();

    // Translators: This is a date and time together. For example: "Oct 21, 8:00 PM PST"
    const shortDateTime = gettext('%(date)s, %(time)s', {
        time: timeWithTimezone,
        date: shortDate,
    }).toString();

    return {
        fullDate,
        fullDateWeekday,
        numeralDate,
        shortDate,
        shortDateTime,
        shortTime: time,
        time: timeWithTimezone,
    };
};

/**
 * date-fns implementation for getDateTimeAsMoment, only covers date object creation
 * by using date and time params, returns a Date Object
 * @param {string | Date} date
 * @param {string | Date} time
 * @returns {Date}
 */
export const parseDateTime = (
    date: string | Date,
    time: string | Date,
): Date => {
    const finalDate =
        date instanceof Date ? format(date, ISO_8601_DATE_FORMAT) : date;
    const finalTime =
        time instanceof Date ? format(time, DEFAULT_TIME_FORMAT) : time;

    // this is for allowing time string as 12:12 pm and 12:12pm
    const flexibleTimeFormat = getTimeFormatForTimeString(finalTime);

    const finalDateTime = parse(
        `${finalDate} ${finalTime}`,
        `${ISO_8601_DATE_FORMAT} ${flexibleTimeFormat}`,
        new Date(),
    );

    return finalDateTime;
};

/**
 * calculateDuration
 *
 * Calculate event duration in the specified unit, defaults as minutes
 *
 * @param {Object} options
 * @param {Date | string} options.startDate
 * @param {Date | string} options.startTime
 * @param {Date | string} options.endDate
 * @param {Date | string} options.endTime
 * @param {string} options.unit
 * @return {Number}, an event's duration as minutes
 */
export const calculateDuration = ({
    startDate,
    startTime,
    endDate,
    endTime,
    unit = 'minutes',
}: CalculateDurationArgs): number => {
    const startDateTimeParsed = parseDateTime(startDate, startTime);
    const endDateTimeParsed = parseDateTime(endDate, endTime);

    if (!startDateTimeParsed || !endDateTimeParsed) {
        return 0;
    }

    return diffCalculator(endDateTimeParsed, startDateTimeParsed, unit);
};

/**
 * calculates the difference between two date instances in the given unit
 * it only supports hours, days, months, years, minutes and seconds to keep
 * the same API as the moment version
 * @param {number | Date} leftDate
 * @param {number | Date} rightDate
 * @param {DateFnsGranularity} unit
 * @returns
 */
const diffCalculator: DiffCalculatorPicker = (
    leftDate: number | Date,
    rightDate: number | Date,
    unit: DateFnsGranularity,
) => {
    switch (unit) {
        case 'hours':
            return differenceInHours(leftDate, rightDate);
        case 'days':
            return differenceInDays(leftDate, rightDate);
        case 'months':
            return differenceInMonths(leftDate, rightDate);
        case 'years':
            return differenceInYears(leftDate, rightDate);
        case 'minutes':
        default:
            return differenceInMinutes(leftDate, rightDate);
    }
};

/**
 * given an string in the `h:mm a` format, parses it to an actual date and format it
 * again using the global locale configuration
 * @param time
 * @returns
 */
export const formatTimeForLocales = (time: string) => {
    const matchedFormat = getTimeFormatForTimeString(time);

    return format(parse(time, matchedFormat, new Date()), DEFAULT_TIME_FORMAT, {
        locale: getDateFnsLocale(),
    });
};

/**
 * Takes a date string like "yyyy-MM-d" and returns an object with moment formatted dates as such:
 * @param  {String} date        date string of yyy-MM-d
 * @param  {String} dayFormat   optional parameter which allows for customization of the returned format
 * @param  {String} monthFormat optional parameter which allows for customization of the returned format
 * @param  {String} yearFormat  optional parameter which allows for customization of the returned format
 * @return {Object}             Object with day, month and year as keys. Default params {month: 'Apr', day: '10', year: '1999'}
 */
export const parseDate = (
    date: string,
    dayFormat = 'd',
    monthFormat = 'MMM',
    yearFormat = 'yyyy',
) => {
    const parsedDate = parseISO(date);
    const day = format(parsedDate, dayFormat);
    const month = format(parsedDate, monthFormat);
    const year = format(parsedDate, yearFormat);

    return { day, month, year };
};

/**
 * Get timezone shortname for those that date-fns-tz don't resolve correctly
 * This function is to emulate what momentjs does internally
 * @param timezone
 * @param localeString
 * @returns
 */
export const getCorrectTzShortname = (
    timezone: string,
    date: Date,
    defaultValue: string,
): string => {
    let result;
    const index = isDST(date, timezone) ? 0 : 1;

    try {
        result = tzAid[getLangFromLocale()][timezone][index];
    } catch (_) {
        return defaultValue;
    }
    return result;
};

/**
 * Return if the date has Daylight saving in effect or not
 * @param {Date} date
 * @returns {boolean}
 */
export const isDST = (date: Date = new Date(), timeZone: string): boolean => {
    const january = getTimezoneOffset(
        timeZone,
        new Date(date.getFullYear(), 0, 1),
    );
    const july = getTimezoneOffset(
        timeZone,
        new Date(date.getFullYear(), 6, 1),
    );
    return Math.max(january, july) !== getTimezoneOffset(timeZone, date);
};

/**
 * Find the correct format for a given time string
 * @param time
 * @returns
 */
export const getTimeFormatForTimeString = (time: string): string =>
    TIME_FORMAT_SET.find((_format) => isMatch(time, _format)) ||
    DEFAULT_TIME_FORMAT;

/**
 * Method that only runs on the client in order to leverage
 * the user's browser to auto-fill resolved timezone option.
 *
 * The timezone option returned from `resolveOptions` will
 * pass back the _runtimes_ default option which is why
 * running server side would make no sense and only cause
 * conflicts.
 *
 * @returns timezone string | undefined
 */
let CACHED_TIMEZONE: null | string = null;
export const guessUserTimezone = (shouldNotCache?: boolean) => {
    if (HAS_WINDOW) {
        if (typeof CACHED_TIMEZONE === 'string') {
            return CACHED_TIMEZONE;
        }

        try {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            if (shouldNotCache) {
                return timezone;
            }

            CACHED_TIMEZONE = timezone;
            return timezone;
        } catch {
            return undefined;
        }
    }
};
