import { track } from '@eventbrite/datalayer-library';
import { getHeapPageArea } from './heapPageArea';

export const reTrackHeapEvent = ({
    affCode = null,
    eventId,
    heapEventName,
    shareMethod = null,
}) => {
    if (eventId && heapEventName) {
        const pageArea = getHeapPageArea(affCode) || 'uncategorized';
        const eventData = {
            eventId: eventId,
            pageArea: pageArea,
            shareMethod: shareMethod,
        };

        track({
            eventName: heapEventName,
            eventData,
        });
    }
};
