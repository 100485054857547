import { ClockChunky } from '@eventbrite/eds-iconography';
import { TextList } from '@eventbrite/eds-text-list';
import { getNavigatorLastRecentSearchTerms } from '@eventbrite/personalization';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ItemContent } from '../../components/ItemContent';
import { useSearchInputContext } from '../../context';
import { useRunSearch, useSearchAutocompleteQuery } from '../../hooks';
import './SearchSuggestions.scss';

interface QueryItemProps {
    isRecent?: boolean;
}

interface QueryData {
    content: JSX.Element | Element | null;
    value: string;
}

interface SearchQuerySuggestionsProps {
    query?: string;
}

const formatQueryData = ({
    query,
    userQuery,
    config,
}: {
    query: string[];
    userQuery: string;
    config: QueryItemProps;
}) => {
    let queryData: QueryData[] = [];
    if (query) {
        queryData = query?.map((item: string) => {
            return {
                content: (
                    <ItemContent
                        {...config}
                        content={item}
                        userQuery={userQuery}
                    />
                ),
                iconType: config.isRecent ? <ClockChunky /> : null,
                showDivider: false,
                value: config.isRecent ? `recent--${item}` : item,
            };
        });
    }
    return queryData;
};

const useQueryResponse = () => {
    const { data, debouncedQuery } = useSearchAutocompleteQuery();

    const formattedItems = React.useMemo(() => {
        let items: QueryData[] = [];

        if (data?.query.length && data?.query.length !== 0) {
            items = formatQueryData({
                query: data?.query || [''],
                userQuery: debouncedQuery,
                config: { isRecent: false },
            });
        }

        return items.slice(0, 24);
    }, [data?.query, debouncedQuery]);

    return formattedItems;
};

const useCachedQueryResponse = () => {
    const { query } = useSearchInputContext();
    const historySuggestions = getNavigatorLastRecentSearchTerms();
    const userQuery = query || '';

    const items = React.useMemo(() => {
        let items: QueryData[] = [];
        const filteredSuggestions = historySuggestions
            .filter((str: string) => str.includes(userQuery))
            .map((str: string) => str.trim())
            .slice(0, 5) || [''];

        if (!isEmpty(historySuggestions)) {
            items = formatQueryData({
                query: filteredSuggestions,
                userQuery: userQuery,
                config: { isRecent: true },
            });
        }
        return items;
    }, [userQuery, historySuggestions]);

    return items;
};

/**
 * Render appropriate Suggestion components for when
 * SearchQuery is focused or truthy.
 */
export const SearchQuerySuggestions: React.FunctionComponent<
    SearchQuerySuggestionsProps
> = () => {
    const items = useQueryResponse();
    const recentItems = useCachedQueryResponse();
    const handleSearch = useRunSearch();

    const mergedItems = [...recentItems, ...items];

    const handleItemClick = (val: string) => {
        if (val.includes('recent--')) {
            const [, q] = val.split('recent--');

            return handleSearch({
                search: { q },
                analytics: [
                    { action: 'RunSearch', label: q },
                    { action: 'AutocompleteSuggestionClicked', label: q },
                ],
            });
        }

        handleSearch({
            search: { q: val },
            analytics: [
                { action: 'RunSearch', label: val },
                { action: 'AutocompleteSuggestionClicked', label: val },
            ],
        });
    };

    return (
        <div
            className="search-suggestions"
            data-testid="search-takeover__query-suggestions"
        >
            <TextList
                id={'search-autocomplete-suggestions'}
                items={mergedItems}
                onItemSelect={(val: string) => handleItemClick(val)}
            />
        </div>
    );
};
