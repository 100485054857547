import { GenericLazyString, gettext } from '@eventbrite/i18n';
import appearance_link from '../static/images/formats/appearance.jpg';
import attraction_link from '../static/images/formats/attraction.jpg';
import classes_link from '../static/images/formats/classes.jpg';
import conference_link from '../static/images/formats/conference.jpg';
import convention_link from '../static/images/formats/convention.jpg';
import expo_link from '../static/images/formats/expo.jpg';
import festival_link from '../static/images/formats/festival.jpg';
import gala_link from '../static/images/formats/gala.jpg';
import game_link from '../static/images/formats/game.jpg';
import networking_link from '../static/images/formats/networking.jpg';
import party_link from '../static/images/formats/party.jpg';
import performance_link from '../static/images/formats/performance.jpg';
import race_link from '../static/images/formats/race.jpg';
import rally_link from '../static/images/formats/rally.jpg';
import retreat_link from '../static/images/formats/retreat.jpg';
import screening_link from '../static/images/formats/screening.jpg';
import seminar_link from '../static/images/formats/seminar.jpg';
import tour_link from '../static/images/formats/tour.jpg';
import tournament_link from '../static/images/formats/tournament.jpg';

//Eventbrite Format ID Mappings
const CONFERENCE = 1;
const SEMINAR = 2;
const EXPO = 3;
const CONVENTION = 4;
const FESTIVAL = 5;
const PERFORMANCE = 6;
const SCREENING = 7;
const GALA = 8;
const CLASS = 9;
const NETWORKING = 10;
const PARTY = 11;
const RALLY = 12;
const TOURNAMENT = 13;
const GAME = 14;
const RACE = 15;
const TOUR = 16;
const ATTRACTION = 17;
const RETREAT = 18;
const APPEARANCE = 19;

export const EVENTBRITE_FORMAT = 'EventbriteFormat';
export const CONFERENCE_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${CONFERENCE}`;
export const SEMINAR_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${SEMINAR}`;
export const EXPO_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${EXPO}`;
export const CONVENTION_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${CONVENTION}`;
export const FESTIVAL_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${FESTIVAL}`;
export const PERFORMANCE_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${PERFORMANCE}`;
export const SCREENING_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${SCREENING}`;
export const GALA_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${GALA}`;
export const CLASS_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${CLASS}`;
export const NETWORKING_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${NETWORKING}`;
export const PARTY_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${PARTY}`;
export const RALLY_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${RALLY}`;
export const TOURNAMENT_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${TOURNAMENT}`;
export const GAME_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${GAME}`;
export const RACE_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${RACE}`;
export const TOUR_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${TOUR}`;
export const ATTRACTION_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${ATTRACTION}`;
export const RETREAT_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${RETREAT}`;
export const APPEARANCE_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${APPEARANCE}`;

/**
 * Mapping of Eventbrite format IDs to their respective pluralized display names
 */
export const EVENTBRITE_FORMAT_MAPPING: { [key: string]: GenericLazyString } = {
    [CONFERENCE_EVENTBRITE_FORMAT]: gettext('Conference'),
    [SEMINAR_EVENTBRITE_FORMAT]: gettext('Seminar'),
    [EXPO_EVENTBRITE_FORMAT]: gettext('Expo'),
    [CONVENTION_EVENTBRITE_FORMAT]: gettext('Convention'),
    [FESTIVAL_EVENTBRITE_FORMAT]: gettext('Festival'),
    [PERFORMANCE_EVENTBRITE_FORMAT]: gettext('Performance'),
    [SCREENING_EVENTBRITE_FORMAT]: gettext('Screening'),
    [GALA_EVENTBRITE_FORMAT]: gettext('Gala'),
    [CLASS_EVENTBRITE_FORMAT]: gettext('Class'),
    [NETWORKING_EVENTBRITE_FORMAT]: gettext('Networking'),
    [PARTY_EVENTBRITE_FORMAT]: gettext('Party'),
    [RALLY_EVENTBRITE_FORMAT]: gettext('Rally'),
    [TOURNAMENT_EVENTBRITE_FORMAT]: gettext('Tournament'),
    [GAME_EVENTBRITE_FORMAT]: gettext('Game'),
    [RACE_EVENTBRITE_FORMAT]: gettext('Race'),
    [TOUR_EVENTBRITE_FORMAT]: gettext('Tour'),
    [ATTRACTION_EVENTBRITE_FORMAT]: gettext('Attraction'),
    [RETREAT_EVENTBRITE_FORMAT]: gettext('Retreat'),
    [APPEARANCE_EVENTBRITE_FORMAT]: gettext('Appearance'),
};

/**
 * Mapping of Eventbrite format IDs to their respective pluralized display names
 */
export const EVENTBRITE_FORMAT_MAPPING_PLURAL: {
    [key: string]: GenericLazyString;
} = {
    [CONFERENCE_EVENTBRITE_FORMAT]: gettext('Conferences'),
    [SEMINAR_EVENTBRITE_FORMAT]: gettext('Seminars'),
    [EXPO_EVENTBRITE_FORMAT]: gettext('Expos'),
    [CONVENTION_EVENTBRITE_FORMAT]: gettext('Conventions'),
    [FESTIVAL_EVENTBRITE_FORMAT]: gettext('Festivals'),
    [PERFORMANCE_EVENTBRITE_FORMAT]: gettext('Performances'),
    [SCREENING_EVENTBRITE_FORMAT]: gettext('Screenings'),
    [GALA_EVENTBRITE_FORMAT]: gettext('Galas'),
    [CLASS_EVENTBRITE_FORMAT]: gettext('Classes'),
    [NETWORKING_EVENTBRITE_FORMAT]: gettext('Networking'),
    [PARTY_EVENTBRITE_FORMAT]: gettext('Parties'),
    [RALLY_EVENTBRITE_FORMAT]: gettext('Rallies'),
    [TOURNAMENT_EVENTBRITE_FORMAT]: gettext('Tournaments'),
    [GAME_EVENTBRITE_FORMAT]: gettext('Games'),
    [RACE_EVENTBRITE_FORMAT]: gettext('Races'),
    [TOUR_EVENTBRITE_FORMAT]: gettext('Tours'),
    [ATTRACTION_EVENTBRITE_FORMAT]: gettext('Attractions'),
    [RETREAT_EVENTBRITE_FORMAT]: gettext('Retreats'),
    [APPEARANCE_EVENTBRITE_FORMAT]: gettext('Appearances'),
};

/**
 * Mapping of Eventbrite format IDs to their respective pluralized lowercase display names
 * For use within the middle of a sentence
 */
export const EVENTBRITE_FORMAT_LOWERCASE_MAPPING_PLURAL = {
    [CONFERENCE_EVENTBRITE_FORMAT]: gettext('conferences'),
    [SEMINAR_EVENTBRITE_FORMAT]: gettext('seminars'),
    [EXPO_EVENTBRITE_FORMAT]: gettext('expos'),
    [CONVENTION_EVENTBRITE_FORMAT]: gettext('conventions'),
    [FESTIVAL_EVENTBRITE_FORMAT]: gettext('festivals'),
    [PERFORMANCE_EVENTBRITE_FORMAT]: gettext('performances'),
    [SCREENING_EVENTBRITE_FORMAT]: gettext('screenings'),
    [GALA_EVENTBRITE_FORMAT]: gettext('galas'),
    [CLASS_EVENTBRITE_FORMAT]: gettext('classes'),
    [NETWORKING_EVENTBRITE_FORMAT]: gettext('networking'),
    [PARTY_EVENTBRITE_FORMAT]: gettext('parties'),
    [RALLY_EVENTBRITE_FORMAT]: gettext('rallies'),
    [TOURNAMENT_EVENTBRITE_FORMAT]: gettext('tournaments'),
    [GAME_EVENTBRITE_FORMAT]: gettext('games'),
    [RACE_EVENTBRITE_FORMAT]: gettext('races'),
    [TOUR_EVENTBRITE_FORMAT]: gettext('tours'),
    [ATTRACTION_EVENTBRITE_FORMAT]: gettext('attractions'),
    [RETREAT_EVENTBRITE_FORMAT]: gettext('retreats'),
    [APPEARANCE_EVENTBRITE_FORMAT]: gettext('appearances'),
};

/**
 * Mapping of Eventbrite format IDs to their respective image links
 */
export const EVENTBRITE_FORMAT_IMAGE_MAPPING: {
    [key: string]: { link: string; full: string };
} = {
    [CONFERENCE_EVENTBRITE_FORMAT]: {
        link: conference_link,
        full: '',
    },
    [SEMINAR_EVENTBRITE_FORMAT]: {
        link: seminar_link,
        full: '',
    },
    [EXPO_EVENTBRITE_FORMAT]: {
        link: expo_link,
        full: '',
    },
    [CONVENTION_EVENTBRITE_FORMAT]: {
        link: convention_link,
        full: '',
    },
    [FESTIVAL_EVENTBRITE_FORMAT]: {
        link: festival_link,
        full: '',
    },
    [PERFORMANCE_EVENTBRITE_FORMAT]: {
        link: performance_link,
        full: '',
    },
    [SCREENING_EVENTBRITE_FORMAT]: {
        link: screening_link,
        full: '',
    },
    [GALA_EVENTBRITE_FORMAT]: {
        link: gala_link,
        full: '',
    },
    [CLASS_EVENTBRITE_FORMAT]: {
        link: classes_link,
        full: '',
    },
    [NETWORKING_EVENTBRITE_FORMAT]: {
        link: networking_link,
        full: '',
    },
    [PARTY_EVENTBRITE_FORMAT]: {
        link: party_link,
        full: '',
    },
    [RALLY_EVENTBRITE_FORMAT]: {
        link: rally_link,
        full: '',
    },
    [TOURNAMENT_EVENTBRITE_FORMAT]: {
        link: tournament_link,
        full: '',
    },
    [GAME_EVENTBRITE_FORMAT]: {
        link: game_link,
        full: '',
    },
    [RACE_EVENTBRITE_FORMAT]: {
        link: race_link,
        full: '',
    },
    [TOUR_EVENTBRITE_FORMAT]: {
        link: tour_link,
        full: '',
    },
    [ATTRACTION_EVENTBRITE_FORMAT]: {
        link: attraction_link,
        full: '',
    },
    [RETREAT_EVENTBRITE_FORMAT]: {
        link: retreat_link,
        full: '',
    },
    [APPEARANCE_EVENTBRITE_FORMAT]: {
        link: appearance_link,
        full: '',
    },
};

/**
 * Mapping of Eventbrite format IDs to their URL-friendly refinement values
 *
 * Do not use these for user-facing strings. They are not translated.
 */
export const EVENTBRITE_FORMAT_TO_REFINEMENT: { [key: string]: string } = {
    [CONFERENCE_EVENTBRITE_FORMAT]: 'conferences',
    [SEMINAR_EVENTBRITE_FORMAT]: 'seminars',
    [EXPO_EVENTBRITE_FORMAT]: 'expos',
    [CONVENTION_EVENTBRITE_FORMAT]: 'conventions',
    [FESTIVAL_EVENTBRITE_FORMAT]: 'festivals',
    [PERFORMANCE_EVENTBRITE_FORMAT]: 'performances',
    [SCREENING_EVENTBRITE_FORMAT]: 'screenings',
    [GALA_EVENTBRITE_FORMAT]: 'galas',
    [CLASS_EVENTBRITE_FORMAT]: 'classes',
    [NETWORKING_EVENTBRITE_FORMAT]: 'networking',
    [PARTY_EVENTBRITE_FORMAT]: 'parties',
    [RALLY_EVENTBRITE_FORMAT]: 'rallies',
    [TOURNAMENT_EVENTBRITE_FORMAT]: 'tournaments',
    [GAME_EVENTBRITE_FORMAT]: 'games',
    [RACE_EVENTBRITE_FORMAT]: 'races',
    [TOUR_EVENTBRITE_FORMAT]: 'tours',
    [ATTRACTION_EVENTBRITE_FORMAT]: 'attractions',
    [RETREAT_EVENTBRITE_FORMAT]: 'retreats',
    [APPEARANCE_EVENTBRITE_FORMAT]: 'appearances',
};
