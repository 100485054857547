import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { v4 as UUID } from 'uuid';
import { useGlobalNavContext } from '../../../../../state';
import { DropdownProps } from '../../../../types';
import { NavLink } from '../../Links';
import { NestedDropdownItem } from '../NestedDropdownItem';
import styles from './Dropdown.module.scss';

export const Dropdown = ({ dropdownItems }: DropdownProps) => {
    const { env } = useGlobalNavContext();

    return (
        <ul
            className={styles.dropdown}
            aria-label={gettext('submenu').toString()}
            data-testid="global-nav-dropdown"
        >
            {dropdownItems?.map(
                ({
                    dropdownItems,
                    heapIdentifier,
                    translatedText,
                    path = '',
                    iconType,
                    textColor,
                    className = '',
                    iconPosition,
                }) =>
                    dropdownItems ? (
                        <NestedDropdownItem
                            heapIdentifier={heapIdentifier}
                            translatedText={translatedText}
                            path={path}
                            iconType={iconType}
                            dropdownItems={dropdownItems}
                            key={UUID()}
                        />
                    ) : (
                        <li
                            key={UUID()}
                            data-testid="link-container__level1"
                            className={styles.navLinkContainer}
                        >
                            <NavLink
                                path={new URL(path, env?.serverUrl ?? '').href}
                                heapIdentifier={heapIdentifier}
                                translatedText={translatedText}
                                textColor={textColor}
                                iconType={iconType}
                                iconPosition={iconPosition}
                                className={
                                    className ||
                                    'nav-link__dropdown nav-link__dropdpown__level1'
                                }
                            />
                        </li>
                    ),
            )}
        </ul>
    );
};
