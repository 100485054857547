import { Icon } from '@eventbrite/eds-icon';
import { MagnifyingGlassChunky } from '@eventbrite/eds-iconography';
import { InputField } from '@eventbrite/eds-input-field';
import { gettext } from '@eventbrite/i18n';
import { ArrowLeftIcon } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React, { SyntheticEvent } from 'react';
import { useEffectOnce } from 'react-use';
import { useSearchInputContext } from '../../context';
import { useRunSearch } from '../../hooks';
import './SearchInput.scss';

type Props = {
    dropdownVisible?: boolean;
    handleSearchClick?: (e: React.MouseEvent<HTMLElement>) => void;
    handleBackButtonClick?: (e: React.MouseEvent<HTMLElement>) => void;
    variant?: 'takeover' | 'header';
};

const placeHolderTextQuery = gettext('Search events');

//TODO: Create shared base "inputfield" component to share between location and search
export const SearchInput: React.FunctionComponent<Props> = ({
    dropdownVisible = false,
    handleSearchClick = () => 0,
    handleBackButtonClick = () => 0,
    variant = 'takeover',
}) => {
    const { query, setQuery } = useSearchInputContext();
    const handleRunSearch = useRunSearch();

    const className = classNames(`search-input--${variant}`);

    useEffectOnce(() => {
        if (typeof window !== 'undefined') {
            const serverData = window?.__SERVER_DATA__;
            const eventSearch = serverData?.search_data?.event_search;
            const previousQuery = eventSearch?.q;

            if (setQuery !== undefined && previousQuery !== undefined) {
                setQuery(previousQuery);
            }
        }
    });

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        handleRunSearch({
            search: { q: query },
            analytics: { action: 'RunSearch', label: query },
        });
    };

    const prefix =
        variant === 'header' && dropdownVisible ? (
            <button type="button" onClick={handleBackButtonClick}>
                <ArrowLeftIcon />
            </button>
        ) : (
            <Icon type={<MagnifyingGlassChunky />} />
        );

    return (
        <form className={className} onSubmit={(e) => handleSubmit(e)}>
            <InputField
                autoComplete="off"
                id="search-autocomplete-input"
                label={gettext('Search events')}
                style="basic"
                name="search-autocomplete-input"
                value={query}
                onChange={setQuery}
                onClick={handleSearchClick}
                placeholder={placeHolderTextQuery}
                borderType="hidden"
                type="search"
                enterkeyhint="search"
                prefix={prefix}
                role=""
            />
        </form>
    );
};
