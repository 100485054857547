import { getWindowObject } from '@eventbrite/feature-detection';
import { CaseOptions, flattenObject } from '@eventbrite/transformation-utils';
import {
    CheckoutPageProps,
    DatalayerPayload,
    EventAction,
    SearchAndDiscoveryPageProps,
    TrackEventPayload,
} from './types';

/**
 * DataLayer event types defined by the Data Platform team.
 * Each EventAction will trigger a different GTM tag when received in the dataLayer.
 *
 * These constants are defined within GTM. Any changes to these values should be
 * reflected in GTM.
 *
 * Note: Exported for testing purposes.
 */
export const GTMName: Record<EventAction, string> = {
    [EventAction.Track]: 'track',
    [EventAction.UpdatePageProps]: 'Update Page Props',
    [EventAction.UpdateUserProps]: 'Update User Props',
};

function getDataLayer(): { push: Function } {
    const dataLayer = getWindowObject('dataLayer');
    if (!dataLayer?.push || typeof dataLayer.push !== 'function') {
        return {
            push: (...args: any[]) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(
                        "@eventbrite/datalayer-library push error. Either dataLayer doesn't exist, or it doesn't have a push function it can call",
                        { arguments: args, dataLayer },
                    );
                }
            },
        };
    }

    return dataLayer;
}

/**
 * Send data to the dataLayer to be processed by Google Tag Manager.
 *
 * @see {@link  https://developers.google.com/tag-platform/tag-manager/web/datalayer#use_a_data_layer_with_event_handlers}
 */
const pushEventAction = (action: EventAction, data: DatalayerPayload): void => {
    getDataLayer().push({
        event: GTMName[action],
        sendToHeap: true,
        ...data,
    });
};

/**
 * Set a variables to a specific value in the data layer.
 *
 * This is the preferred convenience method in place of using `dataLayer.push` directly.
 *
 * @see {@link  https://support.google.com/tagmanager/answer/6164391?hl=en}
 *
 * @param {any} variableValue - Value of the variable to set. Use `undefined` to clear a variable's value.
 */

export const setVariable = (variableName: string, variableValue: any): void => {
    getDataLayer().push({ [variableName]: variableValue });
};

/**
 * Sends page properties to the Data Layer to be set for events on the current page.
 *
 * This is a preferred convenience method in place of using `dataLayer.push` directly.
 */
export const updatePageProps = (
    data: {
        page: Object | SearchAndDiscoveryPageProps | CheckoutPageProps;
        eventName?: string;
    },
    flattenStategy: 'dot' | 'snake' | 'camel' | undefined = undefined,
): void => {
    data.page = flattenObject(data.page, {
        case: flattenStategy || CaseOptions.dot,
    });
    pushEventAction(EventAction.UpdatePageProps, data);
};

/**
 * Set the given properties for the current user.
 *
 * This is a preferred convenience method in place of using `dataLayer.push` directly.
 */
export const updateUserProps = (data: Object): void => {
    const flattenedData = flattenObject(data, { case: CaseOptions.dot });
    pushEventAction(EventAction.UpdateUserProps, { user: flattenedData });
};

/**
 * Send a custom track event with the provided data.
 *
 * This is a preferred convenience method in place of using `dataLayer.push` directly.
 */
export const track = (data: TrackEventPayload): void => {
    pushEventAction(EventAction.Track, data);
};
