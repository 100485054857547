import React from 'react';
import { ErrorMessage } from '../types';

export const ErrorContext = React.createContext(undefined);

interface ErrorDisplayProviderProps {
    error?: ErrorMessage;
}

export interface ErrorContextShape extends ErrorDisplayProviderProps {
    setError: Function;
}

export const ErrorDisplayContext = React.createContext<
    Partial<ErrorContextShape>
>({});

export const useErrorDisplayContext = () => {
    const value = React.useContext(ErrorDisplayContext);

    if (value === undefined) {
        throw new Error(
            'useErrorDisplayContext must be used within ErrorDisplayProvider',
        );
    }

    return value;
};

export const ErrorDisplayProvider: React.FunctionComponent<
    ErrorDisplayProviderProps
> = (props) => {
    const [error, setError] = React.useState(props.error);

    return (
        <ErrorDisplayContext.Provider
            value={{
                error,
                setError,
            }}
        >
            {props.children}
        </ErrorDisplayContext.Provider>
    );
};
