import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { gettext } from '@eventbrite/i18n';

import {
    STYLES,
    STYLE_GRADIENT,
    STYLE_PROGRESS,
    STYLE_CONFIG,
    TYPES,
    TYPE_INDETERMINATE,
    TYPE_DETERMINATE,
} from './constants';

import { getStyleByProgress } from './utils';

import './linear.scss';

const Indeterminate = ({ style, stopColor1, railColor }) => {
    const indicatorStyles = {
        backgroundColor: stopColor1,
    };
    const railStyles = {
        backgroundColor: railColor,
    };

    const className = classNames(
        'eds-progress-indicator--linear__line',
        'eds-progress-indicator--linear-indeterminate__line',
        {
            'eds-bg-color--ui-orange': style === STYLE_GRADIENT,
        },
    );

    return (
        <div
            className="eds-progress-indicator--linear__rail"
            style={railStyles}
            aria-valuetext={gettext('In progress')}
        >
            <div className={className} style={indicatorStyles} />
        </div>
    );
};

const Determinate = ({ style, stopColor1, railColor, progress }) => {
    const indicatorStyles = {
        width: `${progress}%`,
        backgroundColor: stopColor1,
    };
    const railStyles = {
        backgroundColor: railColor,
    };

    const className = classNames(
        'eds-progress-indicator--linear__line',
        'eds-progress-indicator--linear-determinate__line',
        {
            'eds-bg-color--vibrant-green': style === STYLE_GRADIENT,
        },
    );

    return (
        <div
            className="eds-progress-indicator--linear__rail"
            style={railStyles}
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            aria-label="progressbar"
        >
            <div className={className} style={indicatorStyles} />
        </div>
    );
};

const PROGRESS_INDICATOR_TYPE_MAP = {
    [TYPE_INDETERMINATE]: Indeterminate,
    [TYPE_DETERMINATE]: Determinate,
};

export default class Linear extends React.PureComponent {
    static propTypes = {
        /**
         * Type of indicator for the loader (indeterminate | determinate)
         */
        type: PropTypes.oneOf(TYPES),

        /**
         * Color of the loader (dark | light | gradient)
         */
        style: PropTypes.oneOf(STYLES),

        /**
         * Progress bar if the chosen Type is determinate
         */
        progress: PropTypes.number,
    };

    render() {
        const { style, type, progress } = this.props;

        const { stopColor1, stopColor2, railColor } =
            style === STYLE_PROGRESS
                ? getStyleByProgress(progress)
                : STYLE_CONFIG[style];

        const Component = PROGRESS_INDICATOR_TYPE_MAP[type];

        return (
            <Component
                style={style}
                stopColor1={stopColor1}
                stopColor2={stopColor2}
                railColor={railColor}
                progress={progress}
            />
        );
    }
}
