import styled from '@emotion/styled';
import {
    ACTION_EVENT_CREATE_ATTEMPT,
    PROPERTY_SOURCE_AUTOCREATE,
    PROPERTY_SOURCE_MANUAL,
} from '@eventbrite/event-create-analytics';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { setWindowLocation } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import { MagicWandIcon, PencilIcon } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import React, { useState } from 'react';
import {
    AUTO_CREATE_EVENT_HREF,
    CREATE_EVENT_HREF,
    CREATE_NEW_EVENT,
    CREATE_NEW_EVENT_SUBTITLE,
    CREATE_NEW_EVENT_WITH_AI,
    CREATE_NEW_EVENT_WITH_AI_SUBTITLE,
    STATSIG_EVENT_AUTO_CREATE_NEXT_EVENT,
} from './constants';

const track = (data: { eventName: string; eventData: Record<string, any> }) => {
    HAS_WINDOW &&
        (window as any)?.dataLayer.push({
            event: 'track',
            sendToHeap: true,
            ...data,
        });
};

export const CreateEventAlternatives = () => {
    const [isAutoCreateLoading, setIsAutoCreateLoading] = useState(false);
    const [isCreateLoading, setIsCreateLoading] = useState(false);

    return (
        <CreateEventAlternativesContainer
            role="complementary"
            arial-label="create-event-alternatives"
        >
            <AlternativeContainer
                aria-labelledby="create-title"
                data-testid="next-event-create"
                onClick={() => {
                    !isAutoCreateLoading && setIsCreateLoading(true);
                    // New event tracking
                    track({
                        eventName: ACTION_EVENT_CREATE_ATTEMPT,
                        eventData: {
                            eventCreateSource: PROPERTY_SOURCE_MANUAL,
                            pageArea: 'StartFromScratchInAttendeeHeader',
                            hasPublish:
                                (HAS_WINDOW &&
                                    window.__SERVER_DATA__?.hasPublish) ||
                                false,
                            hasPaidPublish:
                                (HAS_WINDOW &&
                                    window.__SERVER_DATA__?.hasPaidPublish) ||
                                false,
                        },
                    });
                    setWindowLocation(CREATE_EVENT_HREF);
                }}
                isLoading={isCreateLoading}
            >
                {isCreateLoading ? (
                    <LoadingWrapper>
                        <Progress />
                    </LoadingWrapper>
                ) : (
                    <React.Fragment key="create-event">
                        <AlternativeIconContainer>
                            <PencilIcon
                                size="medium"
                                aria-hidden="true"
                                color="#3659E3"
                            ></PencilIcon>
                        </AlternativeIconContainer>
                        <AlternativeHeader
                            id="create-title"
                            aria-label="create-title"
                        >
                            {CREATE_NEW_EVENT}
                        </AlternativeHeader>
                        <AlternativeDescription aria-label="create-subtitle">
                            {CREATE_NEW_EVENT_SUBTITLE}
                        </AlternativeDescription>
                    </React.Fragment>
                )}
            </AlternativeContainer>
            <AlternativeContainer
                aria-labelledby="auto-create-title"
                data-testid="next-event-auto-create"
                onClick={() => {
                    !isCreateLoading && setIsAutoCreateLoading(true);
                    logEvent(STATSIG_EVENT_AUTO_CREATE_NEXT_EVENT);
                    // New event tracking
                    track({
                        eventName: ACTION_EVENT_CREATE_ATTEMPT,
                        eventData: {
                            eventCreateSource: PROPERTY_SOURCE_AUTOCREATE,
                            pageArea:
                                'CreateMyEventFasterWithAiInAttendeeHeader',
                            hasPublish:
                                (HAS_WINDOW &&
                                    window.__SERVER_DATA__?.hasPublish) ||
                                false,
                            hasPaidPublish:
                                (HAS_WINDOW &&
                                    window.__SERVER_DATA__?.hasPaidPublish) ||
                                false,
                        },
                    });

                    setWindowLocation(AUTO_CREATE_EVENT_HREF);
                }}
                isLoading={isAutoCreateLoading}
            >
                {isAutoCreateLoading ? (
                    <LoadingWrapper>
                        <Progress />
                    </LoadingWrapper>
                ) : (
                    <React.Fragment key="auto-create-event">
                        <AlternativeIconContainer>
                            <MagicWandIcon
                                size="medium"
                                aria-hidden="true"
                                color="#3659E3"
                            />
                        </AlternativeIconContainer>
                        <AlternativeHeader
                            id="auto-create-title"
                            aria-label="auto-create-title"
                        >
                            {CREATE_NEW_EVENT_WITH_AI}
                        </AlternativeHeader>
                        <AlternativeDescription aria-label="auto-create-subtitle">
                            {CREATE_NEW_EVENT_WITH_AI_SUBTITLE}
                        </AlternativeDescription>
                    </React.Fragment>
                )}
            </AlternativeContainer>
        </CreateEventAlternativesContainer>
    );
};

const Progress = () => (
    <ProgressAnimation
        viewBox="0 0 100 100"
        role="progressbar"
        aria-valuetext={gettext('In progress').toString()}
        width="96px"
        height="96px"
    >
        <defs>
            <linearGradient id="stroke-large-thin-gradient-indeterminate">
                <stop offset="0%" stopColor="#4BE1A0"></stop>
                <stop offset="50%" stopColor="#4BE1A0"></stop>
                <stop offset="100%" stopColor="#4BE1A0" stopOpacity="0"></stop>
            </linearGradient>
        </defs>
        <path
            d="M93.5,50C93.5,74,74,93.5,50,93.5S6.5,74,6.5,50S26,6.5,50,6.5"
            stroke="url(#stroke-large-thin-gradient-indeterminate)"
            strokeWidth="2"
            strokeLinecap="round"
            shapeRendering="geometricPrecision"
            fill="none"
        ></path>
    </ProgressAnimation>
);

const CreateEventAlternativesContainer = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 1.5rem;

    @media (min-width: 687px) {
        flex-wrap: nowrap;
        column-gap: 2rem;
        row-gap: 0;
    }
`;

interface IAlternativeContainer extends React.ComponentPropsWithoutRef<'div'> {
    isLoading: boolean;
}

const AlternativeContainer = styled.div<IAlternativeContainer>`
    background: #ffffff;
    padding: 24px 40px;
    text-align: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: ${({ isLoading }) =>
        isLoading ? '2px solid #3659e3' : '2px solid #efedf2'};

    &:hover {
        border: 2px solid #3659e3;
        cursor: ${({ isLoading }) => (!isLoading ? 'pointer' : 'auto')};
    }

    @media (min-width: 687px) {
        width: 50%;
    }
`;

const AlternativeHeader = styled.h3`
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #1e0a3c;
    margin-bottom: 0.5rem;
`;

const AlternativeDescription = styled.p`
    font-size: 14px;
    color: #39364f;
    text-align: center;
    margin-bottom: 1rem;
`;

const AlternativeIconContainer = styled.div`
    background: #f8f7fa;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0.75rem;
    box-sizing: border-box;
`;
export const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;
LoadingWrapper.displayName = 'LoadingWrapper';

const ProgressAnimation = styled.svg`
    animation: rotate 800ms linear infinite forwards;
`;
