import { LegacyRef, useCallback, useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

const DEFAULT_ROOT_MARGIN = '0px 0px 200px 0px';

export const useHasEnteredViewport = (
    ref: LegacyRef<HTMLDivElement>,
    options?: { visibleInitial: boolean; rootMargin: string },
) => {
    const isVisibleInitial = !!options?.visibleInitial;
    const rootMargin = options?.rootMargin
        ? options?.rootMargin
        : DEFAULT_ROOT_MARGIN;

    const [hasEnteredViewport, setHasEnteredViewport] =
        useState(isVisibleInitial);
    const trackRef = hasEnteredViewport ? { current: null } : ref;

    const intersection = useIntersection(trackRef, {
        root: null,
        rootMargin: rootMargin,
        threshold: 0.01,
    });

    const reset = useCallback(() => {
        setHasEnteredViewport(isVisibleInitial);
    }, [isVisibleInitial]);

    if (
        intersection &&
        intersection.intersectionRatio > 0 &&
        !hasEnteredViewport
    ) {
        setHasEnteredViewport(true);
    }

    return { hasEnteredViewport, reset };
};

/**
 * Hook to determine if the current environment is a browser.
 *
 * Useful for forcing rendering of the logged-out experience during
 * server-side rendering to avoid caching user-specific content.
 */
export const useIsBrowser = () => {
    const [isBrowser, setIsBrowser] = useState(false);
    useEffect(() => {
        setIsBrowser(true);
    }, []);
    return isBrowser;
};
