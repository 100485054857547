import React from 'react';
import classNames from 'classnames';

import {
    COLLAPSIBLE_PANE_LAYOUT_PROP_TYPES,
    PaneLayoutInteractionType,
} from './constants';

import './collapsible_pane_layout.scss';

export default class CollapsiblePaneLayout extends React.PureComponent {
    static propTypes = COLLAPSIBLE_PANE_LAYOUT_PROP_TYPES;

    static defaultProps = {
        paneInteraction: PaneLayoutInteractionType.asCollapsible,
        offsetBottom: 0,
    };

    constructor() {
        super();
        this.paneContent = React.createRef();
    }

    handlePaneClick(e) {
        if (e.target.contains(this.paneContent.current)) {
            this.props.onPaneClickOutside?.(e);
        }
    }

    componentDidUpdate() {
        this.setState({
            offsetBottom: this.props.offsetBottom,
        });
    }

    render() {
        const {
            children,
            customWrapperClassname,
            customPaneClassname,
            customPaneContentClassname,
            isPaneOpen,
            pane,
            paneAriaHidden,
            paneInteraction,
        } = this.props;

        const paneClasses = classNames(
            'eds-collapsible-pane-layout__pane',
            {
                'eds-collapsible-pane-layout__pane--open': isPaneOpen,
                'eds-collapsible-pane-layout__pane--as-modal':
                    paneInteraction === PaneLayoutInteractionType.asModal,
            },
            customPaneClassname,
        );

        const paneContentClasses = classNames(
            'eds-collapsible-pane-layout-pane__content',
            {
                'eds-collapsible-pane-layout-pane__content-offset':
                    !!this.props.offsetBottom &&
                    paneInteraction === PaneLayoutInteractionType.asModal,
            },
            customPaneContentClassname,
        );

        const paneWrapper = pane ? (
            <div
                className={paneClasses}
                data-spec="eds-collapsible-pane-layout-pane"
                onClick={this.handlePaneClick.bind(this)}
            >
                <div
                    className={paneContentClasses}
                    ref={this.paneContent}
                    style={{ bottom: this.state?.offsetBottom }}
                >
                    {pane}
                </div>
            </div>
        ) : null;

        const contentClasses = classNames(
            'eds-collapsible-pane-layout__content',
            {
                'eds-collapsible-pane-layout__content--has-pane': pane,
            },
        );

        return (
            <div
                className={classNames(
                    'eds-collapsible-pane-layout',
                    customWrapperClassname,
                )}
                aria-hidden={paneAriaHidden}
            >
                <div className={contentClasses}>{children}</div>
                {paneWrapper}
            </div>
        );
    }
}
