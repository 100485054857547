import * as React from 'react';

const UserChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="user-chunky_svg__eds-icon--user-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="user-chunky_svg__eds-icon--user-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 18c-1.2 0-2.4-.3-3.5-.7.6-1.3 2-2.2 3.5-2.2s2.9.9 3.5 2.2c-1.1.4-2.3.7-3.5.7zm6.5-2.9c-.4.4-.8.8-1.3 1.1a5.989 5.989 0 00-10.6 0c-.5-.3-.9-.7-1.3-1.1L4 16.5c2.1 2.3 5 3.5 8 3.5s5.9-1.3 8-3.5l-1.5-1.4z"
        />
        <path
            id="user-chunky_svg__eds-icon--user-chunky_circle"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4C9.7 4 7.8 5.9 7.8 8.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2S14.3 4 12 4zm0 6.4c-1.2 0-2.2-1-2.2-2.2C9.8 7 10.8 6 12 6s2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2z"
        />
    </svg>
);

UserChunkySvg.displayName = 'UserChunkySvg';
export default UserChunkySvg;
