// TODO: Fix types of event search to ensure bbox has the correct typing
export function computeLocationInfo(eventSearch: any) {
    const placeId = Array.isArray(eventSearch.places)
        ? eventSearch.places[0]
        : null;

    if (eventSearch.onlineEventsOnly) {
        return {
            is_online: true,
        };
    }

    if (eventSearch.bbox) {
        const { latitude, longitude } = getBboxCenterCoordinates(
            eventSearch.bbox,
        );
        return {
            is_online: false,
            latitude,
            longitude,
            place_id: placeId,
        };
    }

    return {
        is_online: false,
        place_id: placeId,
    };
}

export function getBboxCenterCoordinates(bbox: string) {
    try {
        const [swLng, swLat, neLng, neLat] = bbox.split(',').map(Number);
        const latitude = `${(neLat + swLat) / 2}`;
        const longitude = `${(neLng + swLng) / 2}`;
        return {
            latitude,
            longitude,
        };
    } catch (e) {
        return { latitude: undefined, longitude: undefined };
    }
}

export function getGeoPoint({
    longitude,
    latitude,
}: {
    longitude: string;
    latitude: string;
}) {
    return {
        lon: Number(longitude),
        lat: Number(latitude),
        radius: 50,
    };
}
