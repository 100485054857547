import { gettext } from '@eventbrite/i18n';
import moment from 'moment';
import React from 'react';
import { EventOpenDiscountData } from '../../types/events';
import { getLocalizedFormattedPriceString } from '../EventCardPrice/utils';
import { EventCardPromoCodeLabel } from './EventCardPromoCodeLabel';

export type EventCardPromoCodeProps = {
    className?: string;
    openDiscount: EventOpenDiscountData | undefined;
    locale?: string;
};

export function EventCardPromoCode({
    className,
    openDiscount,
    locale = 'en_US',
}: EventCardPromoCodeProps) {
    if (!isPromoCodeActive(openDiscount)) {
        return null;
    }

    const label = getEventCardPromoCodeLabel(openDiscount, locale);

    return (
        <EventCardPromoCodeLabel className={className}>
            {label}
        </EventCardPromoCodeLabel>
    );
}

export function isPromoCodeActive(
    openDiscount: EventOpenDiscountData | undefined,
): openDiscount is EventOpenDiscountData {
    if (!openDiscount) {
        return false;
    }

    const { endDate, quantityAvailable, quantitySold } = openDiscount;
    const isOnSale = moment().isSameOrBefore(endDate);
    const hasTicketsAvailable =
        quantityAvailable === 0 || quantitySold < quantityAvailable;

    return isOnSale && hasTicketsAvailable;
}

function getEventCardPromoCodeLabel(
    { amountOff, percentOff, currency }: EventOpenDiscountData,
    locale: string,
) {
    if (amountOff) {
        return gettext('%(amount)s off select tickets', {
            amount: getLocalizedFormattedPriceString({
                locale,
                currency,
                // Currency needs to be formatted to int, asumming the last two positions are decimals
                value: Number(amountOff) * 100,
            }),
        });
    }

    if (percentOff) {
        return gettext('%(percent)s% off select tickets', {
            percent: Number(percentOff),
        });
    }
}
