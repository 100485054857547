import {
    EventInteraction,
    EventInteractionContext,
    EventInteractions,
} from '../types';
import { addCustomRUMEvent, isMobile } from './datadogUtils';

export enum DatadogAdsEvents {
    Impression = 'ads-impressions',
    Mounted = 'ads-renders',
    CSRObtained = 'ads-obtained-csr',
    SSRObtained = 'ads-obtained-ssr',
    RequestedImpression = 'ads-requested-log-impressions',
}

export class DatadogInteractionsTracker {
    private allowedEvents = [
        EventInteractions.Impression,
        EventInteractions.Mounted,
    ];

    private interactions_history: Set<string> = new Set();

    trackEventCardInteraction({
        id,
        place,
        action,
    }: Pick<
        EventInteraction<EventInteractionContext>,
        'id' | 'place' | 'action'
    >) {
        const interactionUniqueId = `${id}-${place?.placementId}-${action}`;

        if (
            !this.allowedEvents.includes(action) ||
            this.interactions_history.has(interactionUniqueId)
        ) {
            return;
        }

        this.interactions_history.add(interactionUniqueId);
        const datadogEvent = this.getDatadogEvent(action);
        if (datadogEvent) {
            this.sendInteractionEvent(place.name, datadogEvent);
        }
    }

    private getDatadogEvent(action: EventInteractions) {
        switch (action) {
            case EventInteractions.Impression:
                return DatadogAdsEvents.Impression;
            case EventInteractions.Mounted:
                return DatadogAdsEvents.Mounted;
            default:
                return undefined;
        }
    }

    sendInteractionEvent(interfaceName: string, action: string, count = 1) {
        addCustomRUMEvent(action, {
            value: count,
            app: 'web',
            is_mobile: isMobile(),
            interface: interfaceName,
        });
    }
}
