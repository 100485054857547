import * as React from 'react';

const BrandSuperCropIllustrationSvg = (
    props: React.SVGProps<SVGSVGElement>,
) => (
    <svg viewBox="0 309 1280 283" {...props}>
        <defs>
            <linearGradient
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                id="brand-super-crop-illustration_svg__brand-supercrop-linearGradient-3"
            >
                <stop stopColor="#37C5ED" offset="0%" />
                <stop stopColor="#4DDDB9" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(0 309)">
                <mask
                    id="brand-super-crop-illustration_svg__brand-supercrop-mask-2"
                    fill="#fff"
                >
                    <path d="M0 0h1280v284H0z" />
                </mask>
                <g
                    mask="url(#brand-super-crop-illustration_svg__brand-supercrop-mask-2)"
                    fill="url(#brand-super-crop-illustration_svg__brand-supercrop-linearGradient-3)"
                >
                    <g>
                        <path d="M549.355 284.444c18.679-43.85 31.994-83.776 31.994-114.181 0-58.462-27.399-86.858-78.507-86.858-67.658 0-132.675 56.133-232.627 67.876 12.96-78.252-15.023-150.28-85.574-150.28-80.664 0-111.385 125.698-50.872 199.906-13.853 25.404-40.862 47.443-93.056 47.443-14.653 0-28.042-3.41-40.713-9.118v45.212h203.15c10.01-10.086 18.962-21.042 26.845-32.593 9.654 1.352 19.776 2.208 30.647 2.208 84.153 0 163.065-40.034 194.404-58.09-9.253 28.32-22.657 57.905-37.684 88.475h131.993zm623.604.035c-.024-1.024-.049-2.05-.049-3.071 0-98.51 128.248-113.276 184.387 3.07h2.835V120.22C1244.944 40.216 1086 103.092 1086 281.658c0 .945.022 1.879.028 2.82h86.931z" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

BrandSuperCropIllustrationSvg.displayName = 'BrandSuperCropIllustrationSvg';
export default BrandSuperCropIllustrationSvg;
