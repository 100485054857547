import includes from 'lodash/includes';
import * as constants from './constants';
import classNames from 'classnames';

// TextInput component can render some components inside the form input, such as icons.
export const isIconComponent = ({ type }) =>
    includes(constants.CHILD_COMPONENT_WHITELIST, type);

export const getButtonClassNames = ({
    type,
    style,
    size,
    isFollowing,
    isLoading,
    isSuccessful,
    isNotSuccessful,
    isFullWidth,
    disabled,
    phab,
    hasIconsOnly,
    hasIconsAndOtherChildren,
    keepDisabledStyleWhileLoading,
    __containerClassName,
}) => {
    const className = classNames(
        {
            'eds-btn': !includes(
                [constants.STYLE_ANCHOR, constants.STYLE_NONE],
                style,
            ),
            [`eds-btn--${type}`]: type,
            [`eds-btn--${style}`]: style,
            [`eds-btn--${size}`]: size,
            'eds-btn--following': isFollowing,
            'eds-btn--loading': isLoading,
            'eds-btn--successful': isSuccessful,
            'eds-btn--not-successful': isNotSuccessful,
            'eds-btn--disabled':
                disabled && (keepDisabledStyleWhileLoading || !isLoading),
            'eds-btn--phab': phab,
            'eds-link': style === constants.STYLE_ANCHOR,
            'eds-btn--icon-only': hasIconsOnly,
            'eds-btn--icon-with-other-children': hasIconsAndOtherChildren,
            'eds-btn--full-width': isFullWidth,
        },
        __containerClassName,
    );

    return className;
};
