import { chain } from 'lodash';
import get from 'lodash/get';
import * as constants from '../constants';
import { formatUrl } from 'url-lib';

const _getFallbackSuggestions = () =>
    Promise.resolve(constants.FALLBACK_SUGGESTIONS);

const _parseDestinationSuggestions = (response) => {
    let suggestions = [];

    if (response.event && response.event.length > 0) {
        suggestions = response.event.map(({ url, name }) => ({
            value: url,
            content: name,
        }));
    }

    return suggestions;
};

const _fetchDestinationSuggestions = (query, userLocation) =>
    fetch(
        formatUrl(constants.DESTINATION_SUGGESTIONS_API_URL, {
            q: query,
            //eslint-disable-next-line
            completion_types: 'event',
            //eslint-disable-next-line
            place_id: get(userLocation, ['placeId']),
            latitude: get(userLocation, ['latitude']),
            longitude: get(userLocation, ['longitude']),
        }),
        {
            method: 'GET',
        },
    )
        .then((resp) => resp.json())
        .then(_parseDestinationSuggestions);

/**
 * @typedef SearchSuggestion
 * @property {string} value
 * @property {string} content
 */

/**
 * @typedef UserLocation
 * @property {string} placeId
 * @property {string} latitude
 * @property {string} longitude
 */

/**
 * Gets search suggestions based on specified query
 * @param {string} query Current search query
 * @param {object} UserLocation Place identifier for users current location
 * @returns Promise<SearchSuggestion[]>
 */
export const getDestinationSuggestions = (query, userLocation) =>
    // less than 2 is because SOLR won't match on 1 letter
    !query || query.length < 2
        ? _getFallbackSuggestions()
        : _fetchDestinationSuggestions(query, userLocation);
