import { Dropdown } from '@eventbrite/eds-containers';
import {
    clearNavigatorRecentSearchTerms,
    getNavigatorLastRecentSearchTerms,
} from '@eventbrite/personalization';
import React, { useState } from 'react';
import SearchQuerySuggestions from '../../../containers/SearchSuggestions';
import { useSearchInputContext } from '../../../context';
import { useSearchAutocompleteQuery } from '../../../hooks';
import RecentSearches from './RecentSearches/RecentSearches';
import './SearchDropdown.scss';
import TrendingSearches from './TrendingSearches/TrendingSearches';

type Props = {
    onClickOutside: (e: React.MouseEvent<HTMLElement>) => void;
};

const getDropdownContent = ({
    autosuggestCount,
    handleClearRecentSearches,
    recentSearches,
    query,
}: {
    autosuggestCount?: number;
    handleClearRecentSearches: () => void;
    recentSearches: string[];
    query?: string;
}) => {
    if (query && !!autosuggestCount) {
        return <SearchQuerySuggestions />;
    } else if (query && !autosuggestCount) {
        return null;
    } else if (recentSearches.length > 0) {
        return (
            <RecentSearches
                recentSearches={recentSearches}
                handleClearRecentSearches={handleClearRecentSearches}
            />
        );
    }

    return <TrendingSearches />;
};

const SearchDropdown: React.FunctionComponent<Props> = ({ onClickOutside }) => {
    const [recentSearches, setRecentSearches] = useState(
        getNavigatorLastRecentSearchTerms(5),
    );

    const handleClearRecentSearches = React.useCallback(() => {
        clearNavigatorRecentSearchTerms();
        setRecentSearches([]);
    }, [setRecentSearches]);

    const { query } = useSearchInputContext();
    const { data: autocompleteData } = useSearchAutocompleteQuery();

    const content = React.useMemo(
        () =>
            getDropdownContent({
                autosuggestCount: autocompleteData?.query.length,
                handleClearRecentSearches,
                recentSearches,
                query,
            }),
        [
            autocompleteData?.query.length,
            handleClearRecentSearches,
            query,
            recentSearches,
        ],
    );

    if (!content) {
        return null;
    }

    return (
        <Dropdown onClickOutside={onClickOutside}>
            <div
                className="search-takeover-wrapper"
                data-testid="searchbar-dropdown"
            >
                <div className="search-takeover-content">{content}</div>
            </div>
        </Dropdown>
    );
};

export default SearchDropdown;
