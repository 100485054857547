import {
    AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    BUSINESS_EVENTBRITE_CATEGORY,
    CHARITY_EVENTBRITE_CATEGORY,
    COMMUNITY_EVENTBRITE_CATEGORY,
    EVENTBRITE_CATEGORY_MAPPING,
    EVENTBRITE_FORMAT_MAPPING,
    FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    FASHION_EVENTBRITE_CATEGORY,
    FILM_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    FORMATTED_SUBCATEGORY_MAP,
    HEALTH_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    OTHER_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    POLITICS_EVENTBRITE_CATEGORY,
    RELIGION_EVENTBRITE_CATEGORY,
    SCHOOL_EVENTBRITE_CATEGORY,
    SCIENCE_EVENTBRITE_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
    TRAVEL_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { gettext } from '@eventbrite/i18n';

export const EVENT_TYPE = 'event';
export const EVENTS_TYPE = 'events';
export const ALL_EVENTS_TYPE = 'all events';

/****************************** CATEGORY MAPPING ********************************/

export const CATEGORY_TAG_TYPE = 'Category';
export const SUBCATEGORY_TAG_TYPE = 'SubCategory';

// Translators: these are going to be used as part of a sentence
export const EVENTBRITE_CATEGORY_LOWERCASE_MAPPING = {
    [BUSINESS_EVENTBRITE_CATEGORY]: gettext('business'),
    [SCIENCE_EVENTBRITE_CATEGORY]: gettext('science & tech'),
    [MUSIC_EVENTBRITE_CATEGORY]: gettext('music'),
    [FILM_EVENTBRITE_CATEGORY]: gettext('film & media'),
    [PERFORMING_EVENTBRITE_CATEGORY]: gettext('arts'),
    [FASHION_EVENTBRITE_CATEGORY]: gettext('fashion'),
    [HEALTH_EVENTBRITE_CATEGORY]: gettext('health'),
    [SPORTS_EVENTBRITE_CATEGORY]: gettext('sports & fitness'),
    [TRAVEL_EVENTBRITE_CATEGORY]: gettext('travel & outdoor'),
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: gettext('food & drink'),
    [CHARITY_EVENTBRITE_CATEGORY]: gettext('charity & causes'),
    [POLITICS_EVENTBRITE_CATEGORY]: gettext('government'),
    [COMMUNITY_EVENTBRITE_CATEGORY]: gettext('community'),
    [RELIGION_EVENTBRITE_CATEGORY]: gettext('spirituality'),
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: gettext('family & education'),
    [SEASONAL_EVENTBRITE_CATEGORY]: gettext('holiday'),
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: gettext('home & lifestyle'),
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: gettext('auto, boat & air'),
    [HOBBIES_EVENTBRITE_CATEGORY]: gettext('hobbies'),
    [SCHOOL_EVENTBRITE_CATEGORY]: gettext('school activities'),
    [OTHER_EVENTBRITE_CATEGORY]: gettext('other'),
};

/****************************** DATES MAPPING ****************************/

export const TODAY = 'today';
export const TOMORROW = 'tomorrow';
export const THIS_MONTH = 'this_month';
export const THIS_WEEK = 'this_week';
export const THIS_WEEKEND = 'this_weekend';
export const NEXT_WEEK = 'next_week';
export const NEXT_MONTH = 'next_month';

export const DATE_TAG = 'date';

// only used as default value, DON'T use it on the dropdowns (don't add it to DATES_MAPPING)
export const CURRENT_FUTURE = 'current_future';

export const CUSTOM_DATE = 'custom_date';

export const DATES_MAPPING = {
    [TODAY]: gettext('Today'),
    [TOMORROW]: gettext('Tomorrow'),
    [THIS_WEEKEND]: gettext('This weekend'),
    [THIS_WEEK]: gettext('This week'),
    [NEXT_WEEK]: gettext('Next week'),
    [THIS_MONTH]: gettext('This month'),
    [NEXT_MONTH]: gettext('Next month'),
    [CUSTOM_DATE]: gettext('Pick a date...'),
};

export const DATES_UPPERCASE_MAPPING = {
    [TODAY]: gettext('Today'),
    [TOMORROW]: gettext('Tomorrow'),
    [THIS_WEEKEND]: gettext('This Weekend'),
    [THIS_WEEK]: gettext('This Week'),
    [NEXT_WEEK]: gettext('Next Week'),
    [THIS_MONTH]: gettext('This Month'),
    [NEXT_MONTH]: gettext('Next Month'),
    [CUSTOM_DATE]: gettext('Pick a date...'),
};

// Translators: these are going to be used as part of a sentence
export const DATES_LOWERCASE_MAPPING = {
    [TODAY]: gettext('today'),
    [TOMORROW]: gettext('tomorrow'),
    [THIS_WEEKEND]: gettext('this weekend'),
    [THIS_WEEK]: gettext('this week'),
    [NEXT_WEEK]: gettext('next week'),
    [THIS_MONTH]: gettext('this month'),
    [NEXT_MONTH]: gettext('next month'),
};

export const DATES_TO_REFINEMENT = {
    [TODAY]: 'today',
    [TOMORROW]: 'tomorrow',
    [THIS_WEEKEND]: 'this-weekend',
    [THIS_WEEK]: 'this-week',
    [NEXT_WEEK]: 'next-week',
    [THIS_MONTH]: 'this-month',
    [NEXT_MONTH]: 'next-month',
};

/****************************** EXPERIENCES MAPPING ****************************/
export const NIGHTLIFE_EXPERIENCE = 'nightlife'; // another NIGHTLIFE variable is defined lower in the file
export const KID_FRIENDLY_EXPERIENCE = 'kid-friendly';
export const GREAT_FOR_GROUPS_EXPERIENCE = 'great-for-groups';
export const DATE_NIGHT_EXPERIENCE = 'date-night';
export const BUDGET_FRIENDLY_EXPERIENCE = 'budget-friendly';

export const EXPERIENCES_MAPPING = {
    [NIGHTLIFE_EXPERIENCE]: gettext('Nightlife'),
    [BUDGET_FRIENDLY_EXPERIENCE]: gettext('Budget-friendly'),
    [KID_FRIENDLY_EXPERIENCE]: gettext('Kid-friendly'),
    [GREAT_FOR_GROUPS_EXPERIENCE]: gettext('Great for groups'),
    [DATE_NIGHT_EXPERIENCE]: gettext('Date night'),
};

export const EXPERIENCES_MAPPING_SEARCH = {
    [NIGHTLIFE_EXPERIENCE]: EXPERIENCES_MAPPING[NIGHTLIFE_EXPERIENCE],
    [KID_FRIENDLY_EXPERIENCE]: EXPERIENCES_MAPPING[KID_FRIENDLY_EXPERIENCE],
    [GREAT_FOR_GROUPS_EXPERIENCE]:
        EXPERIENCES_MAPPING[GREAT_FOR_GROUPS_EXPERIENCE],
    [DATE_NIGHT_EXPERIENCE]: EXPERIENCES_MAPPING[DATE_NIGHT_EXPERIENCE],
    [BUDGET_FRIENDLY_EXPERIENCE]:
        EXPERIENCES_MAPPING[BUDGET_FRIENDLY_EXPERIENCE],
};

/****************************** FORMAT MAPPING ****************************/

export const FORMAT_TAG_TYPE = 'Format';

/****************************** PRICE MAPPING ****************************/

const FREE = 'free';
const PAID = 'paid';

export const PRICE_MAPPING = {
    [FREE]: gettext('Free'),
    [PAID]: gettext('Paid'),
};

/****************************** SORT MAPPING ****************************/

export const QUALITY = 'quality';

/****************************** SELECT FIELD CONSTANTS ********************************/
/*
 Receives a data map where the keys correlate to actual value, and the
 value correlates to what should be displayed. Reformats into a structure
 suitable for the <SelectField />

 @param dataMap - object
 {'this_week': 'This week', 'this_month': 'This month'}

 @return array
 [
    {value: 'this_week', display: 'This week'},
    {value: 'this_month', display: 'This month'}
]
*/
const _formatMapper = (
    dataMap: { [key: string]: string },
    valueKey: string,
    displayKey: string,
) => {
    return Object.keys(dataMap).reduce((memo: object[], key: string) => {
        const val = dataMap[key];

        return [...memo, { [valueKey]: key, [displayKey]: val }];
    }, []);
};

const _formatMappingForSelectField = (dataMap: any) =>
    _formatMapper(dataMap, 'value', 'display');

export const FORMATTED_SUBCATEGORY_MAPPING = _formatMappingForSelectField(
    Object.fromEntries(
        Object.entries(FORMATTED_SUBCATEGORY_MAP).map(([key, subcategory]) => {
            return [key, subcategory.title];
        }),
    ),
);
export const FORMATTED_CATEGORY_MAPPING = _formatMappingForSelectField(
    EVENTBRITE_CATEGORY_MAPPING,
);
export const FORMATTED_FORMAT_MAPPING = _formatMappingForSelectField(
    EVENTBRITE_FORMAT_MAPPING,
);
export const FORMATTED_DATE_MAPPING =
    _formatMappingForSelectField(DATES_MAPPING);
export const FORMATTED_EXPERIENCES_MAPPING =
    _formatMappingForSelectField(EXPERIENCES_MAPPING);
export const FORMATTED_PRICE_MAPPING =
    _formatMappingForSelectField(PRICE_MAPPING);
