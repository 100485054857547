import { DropdownMenu, DropLink } from '@eventbrite/eds-dropdown-menu';
import React, { useEffect, useState } from 'react';

export default function DropdownMenuFallback(props: any) {
    const [isInBrowser, setIsInBrowser] = useState(false);

    useEffect(() => setIsInBrowser(true), []);

    return isInBrowser ? (
        <DropdownMenu {...props}>{props.children}</DropdownMenu>
    ) : (
        <DropLink>{props.children}</DropLink>
    );
}
