import mapKeys from 'lodash/mapKeys';
import { HAS_WINDOW } from '@eventbrite/feature-detection';

export const themeToCssVars = (theme) =>
    mapKeys(theme || {}, (value, key) => `--eds-${key}`);

export const setRootTheme = (theme) => {
    if (!HAS_WINDOW) {
        return;
    }

    const properties = themeToCssVars(theme);

    Object.entries(properties).forEach(([name, value]) => {
        document.documentElement.style.setProperty(name, value);
    });
};

export const removeRootTheme = (theme) => {
    if (!HAS_WINDOW) {
        return;
    }

    const properties = themeToCssVars(theme);

    Object.keys(properties).forEach((name) => {
        document.documentElement.style.removeProperty(name);
    });
};

// In the future this will have more sophistication (EB-96195)
export const genTheme = (baseTheme, themeOverrides) => ({
    ...baseTheme,
    ...themeOverrides,
});
