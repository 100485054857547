import React from 'react';

export const LocationContext = React.createContext(undefined);

interface LocationInputContextShape {
    setContainerRendered(status: boolean): void;
    isLocationSuggestionContainerRendered: boolean;
}

export const LocationInputContext = React.createContext<
    Partial<LocationInputContextShape>
>({});

export const useLocationInputContext = () => {
    const value = React.useContext(LocationInputContext);

    if (
        value.isLocationSuggestionContainerRendered === undefined ||
        value.setContainerRendered === undefined
    ) {
        throw new Error(
            'useLocationInputContext must be used within LocationInputProvider',
        );
    }

    return value;
};

export const LocationInputProvider: React.FunctionComponent = (props) => {
    const [isLocationSuggestionContainerRendered, setContainerRendered] =
        React.useState(false);

    return (
        <LocationInputContext.Provider
            value={{
                isLocationSuggestionContainerRendered,
                setContainerRendered,
            }}
        >
            {props.children}
        </LocationInputContext.Provider>
    );
};

export default LocationInputProvider;
