import * as React from 'react';

const AlertChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="alert-chunky_svg__eds-icon--alert-chunky_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="alert-chunky_svg__eds-icon--alert-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6zm0-14c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
        />
        <path
            className="alert-chunky_svg__eds-icon--alert-chunky_dot"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 14h2v2h-2z"
        />
        <path
            className="alert-chunky_svg__eds-icon--alert-chunky_line"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 8h2v5h-2z"
        />
    </svg>
);

AlertChunkySvg.displayName = 'AlertChunkySvg';
export default AlertChunkySvg;
