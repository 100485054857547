import { Icon } from '@eventbrite/eds-icon';
import {
    ChevronDownChunky,
    ChevronUpChunky,
} from '@eventbrite/eds-iconography';
import React, { useRef } from 'react';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../../utils/heap-data';
import { DropdownContainerProps } from '../../../../types';
import { Dropdown } from '../Dropdown';
import styles from './DesktopDropdown.module.scss';

const DesktopDropdown = ({
    dropdownItems,
    heapIdentifier,
    translatedText,
}: DropdownContainerProps) => {
    const { Teams, Features, Components, Actions } = HeapAttributes;
    const dropdownRef = useRef<HTMLDivElement>(null);

    const pointerHandler = (e: React.PointerEvent) => {
        if (dropdownRef.current === document.activeElement) {
            /**
             * Prevent Default is used to prevent the browser from doing
             * it's default action on pointerDown
             */
            e.preventDefault();
            dropdownRef?.current?.blur();
        }
    };

    return (
        <div
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.GlobalNav,
                component: Components.Dropdown,
                name: heapIdentifier.replaceAll(' ', '-').toLowerCase(),
                action: Actions.Click,
            })}
            className={styles.desktopDropdown}
            tabIndex={0}
            ref={dropdownRef}
            onPointerDown={pointerHandler}
        >
            <div className={styles.dropdownTitle}>
                <span>{translatedText}</span>
                <span className={styles.arrowIcons}>
                    <Icon type={<ChevronUpChunky />} />
                    <Icon type={<ChevronDownChunky />} />
                </span>
            </div>
            <Dropdown dropdownItems={dropdownItems} />
        </div>
    );
};

export default DesktopDropdown;
