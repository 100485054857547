import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { QueryClient } from '@tanstack/react-query';

const FIVE_MINUTES = 5 * 60 * 1000;
const DEFAULT_QUERYCLIENT_OPTIONS = {
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            staleTime: FIVE_MINUTES,
        },
    },
};

let queryClientLocal;

declare global {
    interface Window {
        queryClient: any;
    }
}

export const getQueryClient = () => {
    if (HAS_WINDOW) {
        window.queryClient =
            window.queryClient || new QueryClient(DEFAULT_QUERYCLIENT_OPTIONS);
        queryClientLocal = window.queryClient;
    } else {
        queryClientLocal = new QueryClient(DEFAULT_QUERYCLIENT_OPTIONS);
    }

    return queryClientLocal;
};
