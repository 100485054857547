import { Icon } from '@eventbrite/eds-icon';
import { MapPinChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import {
    getLocationSuggestions,
    LocationAutocomplete,
} from '@eventbrite/location-autocomplete';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import {
    EMPTY_SEARCH_LOCATION,
    ERROR_SOMETHING_WENT_WRONG,
    ERROR_ZERO_RESULTS,
} from '../../constants';
import {
    useErrorDisplayContext,
    useLocationContext,
    useLocationInputContext,
} from '../../context';
import { CamelCaseLocation } from '../../types';
import { trackSearchTakeoverEvent } from '../../utils';
import './LocationInput.scss';

const placeHolderTextLocation = gettext('Choose a location');

const trackLocationSelect = (
    newLocation: CamelCaseLocation,
    oldLocation?: CamelCaseLocation,
): void => {
    trackSearchTakeoverEvent({
        action: 'LocChange',
        label: `${oldLocation?.currentPlace}--${newLocation.currentPlace}`,
    });

    if (newLocation?.value?.includes('recent-')) {
        trackSearchTakeoverEvent({
            action: 'RecentLocation',
            label: `${newLocation.currentPlace}`,
        });
    }
};

type Props = {
    onLocationSelect?: () => void;
    variant?: 'takeover' | 'header';
};

export const LocationInput: React.FunctionComponent<Props> = ({
    onLocationSelect,
    variant = 'takeover',
}) => {
    //Contexts
    const { setContainerRendered } = useLocationInputContext();
    const { setError } = useErrorDisplayContext();

    const {
        location,
        waitForLocation,
        setLocation,
        waitingForCurrentLocation,
        isUsingUserLocation,
        getCurrentLocation,
    } = useLocationContext();

    //Local state
    const [renderingSuggestions, setRenderingSuggestions] =
        React.useState(false);
    const [showDropdownFunc, setShowDropdownFunc] = React.useState<{
        fn?: () => void;
    }>({});

    //Update context for when dropdown is visible vs not
    useEffect(() => {
        setContainerRendered?.(renderingSuggestions);
    }, [renderingSuggestions, setContainerRendered]);

    const handleLocationClick = useCallback(() => {
        waitForLocation?.();
        onLocationSelect?.();
    }, [waitForLocation, onLocationSelect]);

    const handleLocationSelect = (newLocation: CamelCaseLocation) => {
        trackLocationSelect(newLocation, location);
        setLocation?.(newLocation);
    };

    const handleSomethingWentWrongError = () => {
        setError?.(ERROR_SOMETHING_WENT_WRONG);
        setLocation?.(EMPTY_SEARCH_LOCATION);
    };

    const handleZeroResultsError = () => {
        setError?.(ERROR_ZERO_RESULTS);
        setLocation?.(EMPTY_SEARCH_LOCATION);
    };

    const clearError = () => {
        setError?.(null);
    };

    const className = classNames(`location-input--${variant}`);

    return (
        <div className={className}>
            <LocationAutocomplete
                handleLocationSelect={handleLocationSelect}
                placeholder={placeHolderTextLocation}
                currentPlace={location?.currentPlace}
                currentPlaceParent={location?.currentPlaceParent}
                isOnline={location?.isOnline}
                /* Get current location props */
                isUseCurrentLocationEnabled={true}
                isWaitingForLocation={waitingForCurrentLocation}
                isUsingCurrentLocation={isUsingUserLocation}
                clearError={clearError}
                onLocationSelect={handleLocationClick}
                handleUseCurrentLocation={() => getCurrentLocation?.()}
                handleSomethingWentWrongError={handleSomethingWentWrongError}
                handleZeroResultsError={handleZeroResultsError}
                showItemDivider={false}
                locationSuggestions={getLocationSuggestions(
                    {
                        currentPlace: location?.currentPlace,
                        currentPlaceParent: location?.currentPlaceParent,
                        suggestions: [],
                    },
                    true,
                )}
                prefix={<Icon type={<MapPinChunky />} />}
                //Custom logic to enforce that
                //the dropdown disappears appropriately but
                //after any item has been selected.
                onFocus={() => setShowDropdownFunc({ fn: undefined })}
                autocompleteProps={{
                    preventInputValuePrefill: true,
                    borderType: 'content',
                    id: 'location-autocomplete',
                    shouldShowDropdown: showDropdownFunc.fn,
                    isRenderingSuggestions: (val: boolean) =>
                        setRenderingSuggestions(val),
                }}
            />
        </div>
    );
};
