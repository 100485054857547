import { sdkRequest } from '@eventbrite/http';
import { TrendingSearchesResponse } from '../types';

const TRENDING_SEARCH_URL = '/api/v3/trending/';
export const trendingSearches = ({
    slug,
}: {
    slug: string;
}): Promise<TrendingSearchesResponse> => {
    return sdkRequest(`${TRENDING_SEARCH_URL}?location=${slug}&timeframe=week`);
};
