import {
    FetchInfiniteQueryOptions,
    InfiniteData,
    QueryFunction,
    QueryKey,
    useInfiniteQuery,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from '@tanstack/react-query';
import { getEventsById } from '../../api';
import { FormattedEvent, FormattedEventResponse } from '../../types';
import { getMergedEvents } from './utils';

const FIVE_MINUTES = 5 * 60 * 1000;
const DEFAULT_QUERY_OPTIONS = {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    staleTime: FIVE_MINUTES,
};

interface SharedUseEventsOptions {
    experiment?: string;
    expandEventsOnMount?: boolean;
    customExpandFunc?(): Promise<{ events: FormattedEvent[] }>;
}

interface UseEventsOptions
    extends UseQueryOptions<FormattedEventResponse>,
        SharedUseEventsOptions {}

interface UseInfiniteEventsOptions
    extends FetchInfiniteQueryOptions<FormattedEventResponse>,
        SharedUseEventsOptions {}

const expandEvents = (
    data: FormattedEventResponse,
): Promise<{ events: FormattedEvent[] }> => {
    const { events } = data;
    const eventIds = events.map(({ id }) => id);

    return getEventsById(eventIds);
};

export const useEventClient = () => {
    const queryClient = useQueryClient();

    if (!queryClient) {
        throw new Error(
            'useEvent hooks must be used within an EventClientProvider',
        );
    }

    return queryClient;
};

/**
 *
 * @param key
 * @param data
 * @param options
 *
 * Util which handles leverages useQuery to expand
 * the passed in events against the destination
 * API to ensure fully fledged event cards.
 */
const useExpandEventsOnMount = (
    key: QueryKey,
    data?: FormattedEventResponse,
    options?: UseEventsOptions,
) => {
    const customExpandFunc = options?.customExpandFunc;
    const expandFunc = customExpandFunc || expandEvents;

    const queryData = useQuery(
        [...key, 'mount-expand'] as QueryKey,
        () => expandFunc(data as FormattedEventResponse),
        {
            ...DEFAULT_QUERY_OPTIONS,
            ...options,
        },
    );

    return queryData;
};

/**
 *
 * @param key
 * @param ids
 * @param options
 *
 * Thin wrapper around `useQuery`
 * https://react-query.tanstack.com/docs/api#usequery
 *
 * Defaults to calling `destination/events/` with preconfigured
 * expansions.
 *
 */
export const useEventsById = (
    key: QueryKey,
    ids: string[],
    options?: UseEventsOptions,
) => {
    useEventClient();

    const queryData = useQuery(key, () => getEventsById(ids), {
        ...DEFAULT_QUERY_OPTIONS,
        ...options,
    });

    return queryData;
};

/**
 *
 * @param key
 * @param asyncFunction
 * @param options
 *
 * Thin wrapper around `useQuery`
 * https://react-query.tanstack.com/docs/api#usequery
 *
 */
export const useEvents = (
    key: QueryKey,
    asyncFunction: QueryFunction<FormattedEventResponse>,
    options?: UseEventsOptions,
) => {
    const queryClient = useEventClient();
    const expandEventOnMount = options?.expandEventsOnMount;

    const updateQueryCacheData = (
        key: QueryKey,
        expandedEvents: FormattedEventResponse,
    ) => {
        const currentData =
            queryClient.getQueryData<FormattedEventResponse>(key);

        const mergedEvents = getMergedEvents(currentData, expandedEvents);

        const newData = {
            ...currentData,
            events: mergedEvents,
        };
        queryClient.setQueryData(key, newData);

        return newData;
    };

    const { data, ...otherValues } = useQuery(key, asyncFunction, {
        ...DEFAULT_QUERY_OPTIONS,
        ...options,
    });

    useExpandEventsOnMount(key, data, {
        enabled:
            !!options?.initialData &&
            !otherValues.isFetchedAfterMount &&
            expandEventOnMount,
        onSuccess: (expandedEvents) => {
            updateQueryCacheData(key, expandedEvents);
        },
        customExpandFunc: options?.customExpandFunc,
    });

    return {
        data,
        ...otherValues,
    };
};

/**
 * Same as usePaginatedEvents but returns all
 * previously returned pages along with the next one
 *
 * Useful for the "show more" or "infinite loading" patterns as opposed to
 * class "next page" approach
 *
 * Thin wrapper around `useInfiniteQuery`
 * https://react-query.tanstack.com/docs/api#useinfinitequery
 */
export const useInfiniteEvents = (
    key: QueryKey,
    asyncFunction: QueryFunction<FormattedEventResponse>,
    options?: UseInfiniteEventsOptions,
) => {
    const queryClient = useEventClient();

    const updateInfiniteQueryCache = (
        key: QueryKey,
        expandedEvents: FormattedEventResponse,
    ) => {
        //sets "successful fetch" internal variable
        //thereby triggering onSettled/onSuccess handlers
        queryClient.setQueryData<InfiniteData<FormattedEventResponse>>(
            key,
            (oldData) => {
                const latestPageData = oldData?.pages.pop();

                const mergedEvents = getMergedEvents(
                    latestPageData,
                    expandedEvents,
                );

                return {
                    pages: [
                        ...(oldData?.pages || []),
                        {
                            ...latestPageData,
                            events: mergedEvents,
                        },
                    ],
                    pageParams: [...(oldData?.pageParams || [])],
                };
            },
        );
    };

    const { data, ...otherValues } = useInfiniteQuery(key, asyncFunction, {
        ...DEFAULT_QUERY_OPTIONS,
        ...options,
    });

    useExpandEventsOnMount(key, data?.pages?.[0], {
        enabled:
            !!options?.initialData &&
            !otherValues.isFetchedAfterMount &&
            options?.expandEventsOnMount,
        onSuccess: (events) => {
            updateInfiniteQueryCache(key, events);
        },
        customExpandFunc: options?.customExpandFunc,
    });

    return {
        data,
        ...otherValues,
    };
};
