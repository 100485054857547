import React from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { Structure } from '@eventbrite/eds-structure';
import { publish } from '@eventbrite/intercom';

import { SiteHeader } from '@eventbrite/site-header';
import { SiteFooter } from '@eventbrite/site-footer';
import { setWindowLocation } from '@eventbrite/http';

import {
    ENV_SHAPE_PROP_TYPE,
    REQUEST_SHAPE_PROP_TYPE,
    USER_SHAPE_PROP_TYPE,
    FOOTER_LINKS_SHAPE_PROP_TYPE,
} from './constants';

const _getHeader = ({
    env,
    request,
    user,
    featureFlags,
    globalHeader,
    additionalHeaderContent,
    breadcrumbs,
    hideSearchBar,
    onSearchFocus,
    onMouseEnterGlobalHeader,
}) => {
    let header = globalHeader;

    if (env.isEmbeddedView) {
        return null;
    }

    // NOTE: We need to support retrieving the `user` object via an API
    // request in order to support varnish-cached pages for logged in users.
    // See EB-39986 for more details
    if (isUndefined(header)) {
        header = (
            <SiteHeader
                env={env}
                request={request}
                user={user}
                onSearchSelect={setWindowLocation}
                hideSearchBar={hideSearchBar}
                onSearchFocus={onSearchFocus}
                featureFlags={featureFlags}
                onMouseEnterGlobalHeader={onMouseEnterGlobalHeader}
            />
        );
    }
    if (additionalHeaderContent || breadcrumbs) {
        header = (
            <div>
                {header}
                {additionalHeaderContent}
                {breadcrumbs}
            </div>
        );
    }

    return header;
};

const _getFooter = ({
    env,
    user,
    featureFlags,
    footer,
    footerLinks,
    showBrand,
    showMarketingLinks,
}) => {
    if (env.isEmbeddedView) {
        return null;
    }

    if (isUndefined(footer) && footerLinks) {
        const serverUrl = env.serverUrl;

        const _handleLocaleChange = (locale) => {
            setWindowLocation(
                `${serverUrl}/international/change_locale/?locale=${locale}`,
            );
        };

        footer = (
            <SiteFooter
                featureFlags={featureFlags}
                serverUrl={env.serverUrl}
                locales={env.locales}
                localeInfo={env.localeInfo}
                isUserAuthenticated={user.isAuthenticated}
                popularSearches={footerLinks.popularSearches}
                eventsToPlan={footerLinks.eventsToPlan}
                thirdFooterColumn={footerLinks.thirdFooterColumn}
                thirdFooterColumnName={footerLinks.thirdFooterColumnName}
                countries={footerLinks.countries}
                showBrand={showBrand}
                showMinimalLinks={footerLinks.showMinimalLinks}
                showMarketingLinks={
                    isUndefined(showMarketingLinks)
                        ? !user.isAuthenticated
                        : showMarketingLinks
                }
                helpLinks={env.helpUrls}
                onLocaleChange={_handleLocaleChange}
            />
        );
    }

    return footer;
};
const onToggleExistingSideDrawer = () => {
    publish('layout:visibility', {
        showEventLevelNav: true,
    });
};
const SiteStructure = (props) => {
    let { ...structurePassThruProps } = props;

    if (props.env.isEmbeddedView) {
        structurePassThruProps = {
            ...props,
            pageTitle: undefined,
            navItems: undefined,
            sideDrawerDescription: undefined,
        };
    }

    if (props.hideHeader && !props.hideMenuToggle) {
        structurePassThruProps = {
            ...structurePassThruProps,
            navItems: null,
            existingSideDrawer: true,
            onToggleExistingSideDrawer,
        };
    }

    const footer = props.hideFooter ? null : _getFooter(props);
    const header = props.hideHeader ? null : _getHeader(props);

    // structurePassThruProps has to be placed last
    // in case of a specific page's need to override
    // header and/or footer
    return (
        <Structure
            header={header}
            footer={footer}
            {...structurePassThruProps}
        />
    );
};

export default SiteStructure;

SiteStructure.propTypes = {
    ...Structure.propTypes,
    env: ENV_SHAPE_PROP_TYPE.isRequired,
    request: REQUEST_SHAPE_PROP_TYPE.isRequired,
    user: USER_SHAPE_PROP_TYPE.isRequired,
    globalHeader: PropTypes.node,
    additionalHeaderContent: PropTypes.node,
    footerLinks: FOOTER_LINKS_SHAPE_PROP_TYPE,
    showBrand: PropTypes.bool,
    showMarketingLinks: PropTypes.bool,
    hideSearchBar: PropTypes.bool,
    onSearchFocus: PropTypes.func,
    onMouseEnterGlobalHeader: PropTypes.func,
    mainBackgroundColor: PropTypes.string,
    hideFooter: PropTypes.bool,
};
