import React, { PureComponent } from 'react';

// original author: dcarter
import PropTypes from 'prop-types';

import classNames from 'classnames';

import * as constants from './constants';

import './formField.scss';

/**
 * Helper component that conditionally renders an annotation note
 * @prop {string} annotationNote - string to be displayed in annotation
 * @example
 * // <FormFieldNote annotationNote="This field is required" />
 */
const FormFieldNote = ({
    annotationNote,
    annotationDecoration,
    annotationCustomClassName,
}) => {
    let component = null;

    if (annotationNote) {
        const asideClassname = classNames(
            'eds-field-annotation__note eds-l-pad-top-1 eds-fx--fade-in',
            {
                'eds-field-annotation__note-with-decoration':
                    !!annotationDecoration,
            },
            annotationCustomClassName,
        );
        component = (
            <div
                data-automation="eds-field-annotation"
                className={asideClassname}
                role="alert"
            >
                {annotationDecoration}
                {annotationNote}
            </div>
        );
    }
    return component;
};

/**
 * Class that represents a general form-field wrapper.
 * @extends PureComponent
 * @example
 * // <FormField label="My Input" required={true}>
 * //     <Input id="my-input" type="text" />
 * // </FormField>
 **/
export default class FormField extends PureComponent {
    static propTypes = {
        /**
         * FormField should be provided with a single form element.
         */
        children: PropTypes.element.isRequired,
        /**
         * Whether this field is required
         */
        required: PropTypes.bool,
        /**
         * Whether the label should be displayed or hidden
         */
        hideLabel: PropTypes.bool,
        /**
         * The type of annotation message that should be shown
         */
        annotationType: PropTypes.oneOf(constants.ANNOTATION_TYPES),
        /**
         * Text shown in annotation message
         */
        annotationNote: PropTypes.node,
        /**
         * The amount of spacing for the bottom
         */
        bottomSpacing: PropTypes.number,
        /**
         * Node to be rendered along with the annotation note
         */
        annotationDecoration: PropTypes.node,
        /**
         * CSS class to be applied to the annotation component
         */
        annotationCustomClassName: PropTypes.string,
    };

    static defaultProps = {
        required: false,
        hideLabel: false,
        annotationType: constants.ANNOTATION_TYPE_INFO,
        bottomSpacing: 5,
    };

    render() {
        const {
            annotationType,
            annotationNote,
            annotationDecoration,
            annotationCustomClassName,
            bottomSpacing,
            children,
        } = this.props;
        const annotationWrapClassname = classNames({
            'eds-field-annotation': annotationNote,
            'eds-field-annotation--success':
                annotationType === constants.ANNOTATION_TYPE_SUCCESS,
            'eds-field-annotation--error':
                annotationType === constants.ANNOTATION_TYPE_ERROR,
        });

        return (
            <div style={{ marginBottom: `${bottomSpacing * 4}px` }}>
                <div className={annotationWrapClassname}>
                    {children}
                    <FormFieldNote
                        annotationNote={annotationNote}
                        annotationDecoration={annotationDecoration}
                        annotationCustomClassName={annotationCustomClassName}
                    />
                </div>
            </div>
        );
    }
}
