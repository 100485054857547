import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import { deep } from './deep';

const keysToSnake = <InputObject extends object>(obj: InputObject) =>
    mapKeys(obj, (_value, key) => snakeCase(key));

/**
 * Deeply converts all the object or array of object's keys to snake_case
 */
export const deepKeysToSnake = <InputValue extends object>(
    obj: InputValue | Array<InputValue>,
) => deep(obj, keysToSnake);
