import PropTypes from 'prop-types';

export const VERTICAL_ALIGNMENTS_PROP_TYPE = PropTypes.oneOf([
    'top',
    'middle',
    'bottom',
]);
export const IMAGE_LOADER_STATUSES = {
    LOADING_STATE: 'loading',
    LOADED_STATE: 'loaded',
    FAILED_STATE: 'failed',
};
