import { UserChunky, VerticalDotsChunky } from '@eventbrite/eds-iconography';
import { ANCHOR_LINK, DIVIDER } from '@eventbrite/eds-nav-list-item';
import { gettext } from '@eventbrite/i18n';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

const DIVIDER_MENU_ITEM = {
    type: DIVIDER,
};

const _quickLinkToMenuItem = ({ content, url }) => ({
    type: ANCHOR_LINK,
    path: url,
    content,
});
const _menuInfoToMenuItem = ({ label, fallbackUrl }) => ({
    type: ANCHOR_LINK,
    path: fallbackUrl,
    content: label,
});

const _filterOutItemWithNarrowIcon = ({ narrowIconType }) => !narrowIconType;

const _getUserNarrowMenuInfo = (userMenuInfo, additionalMenuItems) => {
    const newUserMenu = userMenuInfo?.label
        ? getMobileMenu(userMenuInfo)
        : userMenuInfo;
    let navItems = newUserMenu.navItems;

    if (!isEmpty(additionalMenuItems)) {
        navItems = [...navItems, DIVIDER_MENU_ITEM, ...additionalMenuItems];
    }

    return {
        iconType: <UserChunky />,
        iconColor: 'grey-700',
        className: 'eds-global-header__user-menu',
        ...newUserMenu,
        navItems,
    };
};

const _getFallbackNarrowMenuInfo = (
    additionalMenuItems,
    nonUserQuickLinks,
    callToActionInfo,
) => {
    let navItems = additionalMenuItems;
    let menuInfo;

    if (!isEmpty(nonUserQuickLinks)) {
        const nonUserQuickLinksAsMenuItems =
            nonUserQuickLinks.map(_quickLinkToMenuItem);

        navItems = [
            ...navItems,
            DIVIDER_MENU_ITEM,
            ...nonUserQuickLinksAsMenuItems,
        ];
    }

    if (callToActionInfo) {
        navItems = [
            ...navItems,
            DIVIDER_MENU_ITEM,
            _quickLinkToMenuItem(callToActionInfo),
        ];
    }

    if (!isEmpty(navItems)) {
        menuInfo = {
            fallbackUrl: '#',
            label: gettext('Menu'),
            iconType: <VerticalDotsChunky />,
            navItems,
        };
    }

    return menuInfo;
};

export const getMobileMenu = (userMenuInfo) => {
    const newMenu = { ...userMenuInfo, navItems: [...userMenuInfo.navItems] };
    if (newMenu.label.length > 30) {
        newMenu.label = newMenu.label.slice(0, 25) + '...';
    }
    newMenu.navItems.splice(9, 0, {
        type: 'anchor',
        content: newMenu.label,
        isDisabled: true,
    });
    return newMenu;
};

export const getNarrowMenuInfo = (
    userMenuInfo,
    additionalMenus = [],
    nonUserQuickLinks,
    callToActionInfo,
    quickLinks,
    shouldCollapseToSingleMenu = true,
) => {
    let narrowMenuInfo;

    // Some headers require flexiblility with the number of menus they display depending if the user is signed in or not
    // here we are checking if this is a case where a single menu is desired and show the appropriate menu depending
    // on the status of authentication (if `userMenuInfo` is supplied)
    // If the headers navigation links and menus `shouldCollapseToSingleMenu` then we will return an Object
    // Otherwise, based on the presence of `userMenuInfo`, we will either return a single Object or an Array of Objects for
    // the case in which we need to display two menus on narrow screens with the 'User Menu' containing ONLY the `userMenuInfo`
    if (shouldCollapseToSingleMenu) {
        const additionalMenusAsMenuItems = additionalMenus
            .filter(_filterOutItemWithNarrowIcon)
            .map(_menuInfoToMenuItem);

        narrowMenuInfo = userMenuInfo
            ? _getUserNarrowMenuInfo(userMenuInfo, additionalMenusAsMenuItems)
            : _getFallbackNarrowMenuInfo(
                  additionalMenusAsMenuItems,
                  nonUserQuickLinks,
                  callToActionInfo,
              );
    } else {
        // We want to be able to display quickLinks and nonUserQuickLinks in the narrow menu
        // For this to work with _getFallbackNarrowMenuInfo's API we pass an
        // empty array to take the place of additionalMenus and combine quickLinks and nonUserQuickLinks
        narrowMenuInfo = _getFallbackNarrowMenuInfo(
            [],
            [...(quickLinks || []), ...(nonUserQuickLinks || [])],
        );

        if (userMenuInfo) {
            narrowMenuInfo = [
                narrowMenuInfo,
                _getUserNarrowMenuInfo(userMenuInfo),
            ];
        }
    }

    return narrowMenuInfo;
};
