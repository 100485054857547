import PropTypes from 'prop-types';

export const ROUTER_LINK = 'routerLink';
export const ANCHOR_LINK = 'anchor';
export const DIVIDER = 'divider';
export const EXPAND_LINK = 'expandLink';
export const SECTION_HEADER = 'sectionHeader';

export const TYPES = [
    ROUTER_LINK,
    ANCHOR_LINK,
    EXPAND_LINK,
    DIVIDER,
    SECTION_HEADER,
];
export const TYPE_PROP_TYPE = PropTypes.oneOf(TYPES);
