import { gettext } from '@eventbrite/i18n';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDebounce } from 'react-use';
import {
    searchAutocomplete as searchAutocompleteApi,
    trendingSearches as trendingSearchesApi,
} from '../api';
import {
    useErrorDisplayContext,
    useLocationContext,
    useSearchInputContext,
} from '../context';

export const useSearchAutocompleteQuery = () => {
    const { query } = useSearchInputContext();
    const { location } = useLocationContext();
    const [debouncedQuery, setDebouncedQuery] = useState(
        query && query.length >= 1 ? query : '',
    );
    const { setError } = useErrorDisplayContext();

    useDebounce(
        () => {
            if (query && query.length >= 1 && query !== debouncedQuery) {
                setDebouncedQuery(query);
            }
        },
        400,
        [query],
    );

    const placeId = location?.placeId || '';
    const onlineEvents = location?.isOnline;
    const latitude = location?.latitude || null;
    const longitude = location?.longitude || null;
    const isLocationSet =
        !!placeId || !!onlineEvents || (!!latitude && !!longitude);

    const queryResponse = useQuery(
        ['search-takeover', debouncedQuery, placeId, latitude, longitude],
        () => {
            return searchAutocompleteApi({
                query: debouncedQuery,
                placeId,
                latitude,
                longitude,
            }).catch(() => {
                setError?.({
                    message: gettext(
                        'Looks like we had a problem running your search.',
                    ),
                    severity: 'error',
                });
            });
        },
        {
            enabled: !!debouncedQuery && isLocationSet,
            keepPreviousData: true,
        },
    );

    return {
        ...queryResponse,
        debouncedQuery,
    };
};

export const useTrendingSearchQuery = () => {
    const { location } = useLocationContext();
    const locationSlug = location?.slug || '';

    return useQuery(
        ['search-takeover', locationSlug],
        () => {
            return trendingSearchesApi({ slug: locationSlug }).catch(() => ({
                trending: {
                    active_timeframe: '',
                    trending_searches: [],
                },
            }));
        },
        { enabled: !!locationSlug },
    );
};
