import * as React from 'react';

const CocktailSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="cocktail_svg__eds-icon--cocktail_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="cocktail_svg__eds-icon--cocktail_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M19.5 7H21V6h-5l.6-.7c.2.1.4.1.6.1.9 0 1.7-.8 1.7-1.7 0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7 0 .4.1.7.3 1L14.6 6H3v1h1.1l7.4 8.6V21H9v1h6v-1h-2.5v-5.4l7-8.6zm-2.4-4c.4 0 .7.3.7.7 0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7zm-5.8 8.4l1.2-1.4h3.2l-3.9 4.6L7.9 10h3.2l-.6.7.8.7zM18.2 7l-1.7 2.1V9h-3.2l1.8-2h3.1zm-4.5 0L12 9H7.1L5.4 7h8.3z"
        />
    </svg>
);

CocktailSvg.displayName = 'CocktailSvg';
export default CocktailSvg;
