import { DestinationEvent } from '@eventbrite/event-renderer';
import { sdkRequest } from '@eventbrite/http';
import {
    getNavigatorLastRecentSearchTerms,
    getNavigatorLastRecentViewsList,
} from '@eventbrite/personalization';
import {
    DatadogAdsEvents,
    DatadogInteractionsTracker,
} from '../tracking/datadogInteractionsTracker';

export const GET_PROMOTED_EVENTS_SLA_MILLISECONDS = 5000;

export async function searchPromotedEvents(
    payload: PromotedEventsSearchApiPayload,
): Promise<DestinationEvent[]> {
    const datadogTracker = new DatadogInteractionsTracker();
    const controller = new AbortController();
    const id = setTimeout(() => {
        controller.abort();
    }, GET_PROMOTED_EVENTS_SLA_MILLISECONDS);

    try {
        const response = await sdkRequest('/api/v3/promoted/events', {
            method: 'POST',
            signal: controller.signal,
            body: JSON.stringify({
                ...payload,
                app: 'web',
                personalized_options: {
                    recent_searches: getNavigatorLastRecentSearchTerms(10),
                    recent_views: getNavigatorLastRecentViewsList(10),
                },
                search: {
                    ...payload.search,
                    client_timezone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            }),
        });

        if (response?.length) {
            datadogTracker.sendInteractionEvent(
                payload.interface,
                DatadogAdsEvents.CSRObtained,
                response.length,
            );
        }

        return response;
    } catch (e) {
        console.error(e);
        return [];
    } finally {
        clearTimeout(id);
    }
}

export interface PromotedEventsSearchApiPayload {
    slots: number;
    page: number;
    interface: string;
    sub_interface?: string;
    search: PromotedEventsSearchFiltersApiPayload;
}

export interface PromotedEventsSearchFiltersApiPayload {
    dates?: DateType[];
    date_range?: {
        from: string;
        to: string;
    };
    q?: string;
    price?: string;
    languages?: string[];
    tags?: string[];
    related_event?: {
        id: number;
        name: string;
        summary: string;
    };
    place?: {
        place_id?: string;
        is_online?: boolean;
        geo_point?: {
            lon: number;
            lat: number;
            radius: number;
        };
        venue_id?: string;
    };
}

export type DateType =
    | 'all'
    | 'past'
    | 'current_future'
    | 'today'
    | 'tomorrow'
    | 'this_week'
    | 'this_month'
    | 'this_weekend'
    | 'this_4_weeks'
    | 'next_month';
