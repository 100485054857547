import { TrendingIcon } from '@eventbrite/marmalade';
import React from 'react';
import { useRunSearch, useTrendingSearchQuery } from '../../../../hooks';

import '../RecentSearches/RecentSearches.scss';
import { Spinner } from './Spinner';

interface TrendingSearchProps {
    term: string;
    handleItemClick: (term: string) => void;
}

const TrendingSearch = ({ term, handleItemClick }: TrendingSearchProps) => {
    return (
        <li>
            <button
                onClick={() => {
                    handleItemClick(term);
                }}
                type="button"
            >
                <TrendingIcon width={24} height={24} />
                <span className="searches-term">{term}</span>
            </button>
        </li>
    );
};

const TrendingSearches = () => {
    const { data, isLoading } = useTrendingSearchQuery();
    const trendingSearches = data?.trending.trending_searches.slice(0, 5) || [];
    const handleSearch = useRunSearch();

    const handleItemClick = React.useCallback(
        (term: string) => {
            handleSearch({
                search: { q: term },
                analytics: { action: 'RecentSearchClicked', label: term },
            });
        },
        [handleSearch],
    );

    return (
        <div>
            <div className="recent-searches__header">
                <h1>Trending Searches</h1>
            </div>
            <ul className="recent-searches__list">
                {isLoading ? (
                    <Spinner />
                ) : (
                    trendingSearches.map(({ term }) => (
                        <TrendingSearch
                            key={term}
                            term={term}
                            handleItemClick={handleItemClick}
                        />
                    ))
                )}
            </ul>
        </div>
    );
};

export default TrendingSearches;
