import { useQuery } from '@tanstack/react-query';
import { getBrowserLocationPermission, getBrowserLocation } from './browser';
import { getPlaceFromCoordinates } from '../api';

export const getUserLocation = async () => {
    const locationPermission = await getBrowserLocationPermission().catch(
        () => 'permissionsNotSupported',
    );

    if (
        locationPermission === 'denied' ||
        locationPermission === 'permissionNotSupported'
    ) {
        return Promise.reject({ status: locationPermission });
    }

    const browserLocation = await getBrowserLocation().catch((e) => {
        return Promise.reject({
            status: 'unresolvedBrowserLocation',
            error: e,
        });
    });

    const { latitude, longitude } = browserLocation.coords;

    try {
        const { place } = await getPlaceFromCoordinates({
            latitude,
            longitude,
        });

        if (Object.keys(place).length === 0) {
            return Promise.reject({
                status: 'unresolvedPlaceObject',
            });
        }

        return Promise.resolve({
            place,
            status: 'success',
        });
    } catch (e) {
        return Promise.reject({ status: 'unresolvedPlaceObject', error: e });
    }
};

//Due to the heavy nature of the async
//call, this is going to be a conservative
//default setting that attempts to only
//perform this lookup once.
const DEFAULT_QUERY_CONFIGURATION = {
    enabled: false,
    staleTime: 5 * 60 * 1000,
};

/**
 * Interacts with the browser API to return the
 * current location of the user.
 *
 * Will return a react-query data response
 * shape that once resolved will be the current
 * location of the user or error with an appropriate
 * message and code.
 *
 * @queryConfig the configuration options that can be
 * passed into reactquery.
 * NOTE: `enabled` is defaulted to FALSE
 *
 * @key a simple val (non-array) that can be used as a query key
 * to trigger a fresh fetch.
 *
 */
export const useUserLocation = ({ key, ...queryConfig }) => {
    return useQuery(
        ['user-current-user-location', key ? key : ''],
        getUserLocation,
        {
            ...DEFAULT_QUERY_CONFIGURATION,
            ...queryConfig,
        },
    );
};
