import { CoreApplicationContext } from '@eventbrite/context-gen';
import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import { AlertChunky as AlertChunkySvg } from '@eventbrite/eds-iconography';
import { SentryErrorBoundary } from '@eventbrite/error-boundary';
import {
    RenderGlobalNavOrUndefined,
    searchBarProps,
} from '@eventbrite/global-nav';
import { gettext } from '@eventbrite/i18n';
import { SiteStructure } from '@eventbrite/site-structure';
import { $FixMe } from '@eventbrite/ts-utils';
import React from 'react';
import { useMount } from 'react-use';
import { ConsumerHeader } from '../../components/ConsumerHeader';
import { useSSRSafeUser } from '../../hooks';
import {
    FeatureFlags,
    FooterLinksShape,
    RequestShape,
    UserShape,
} from '../../types/index';

const HEADER_TEXT = gettext('Oops, something went wrong!');
const SUBTITLE_TEXT = gettext(
    'If you feel that this message is in error, please <a href="/support/contact-us">let us know</a>.',
);
const BUTTON_CREATE_EVENT_CTA = gettext('Create an Event');
const BUTTON_FIND_EVENT_CTA = gettext('Find an Event');

const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    marginTop: '-40px',
};

const RootErrorFallback = () => {
    return (
        <div style={containerStyles as React.CSSProperties}>
            <Icon type={<AlertChunkySvg />} size="large" color="ui-red" />
            <section className="eds-l-mar-vert-2 eds-text--center">
                <h1 className="eds-text-hl">{HEADER_TEXT}</h1>
                <div className="eds-l-pad-top-1 eds-text-color--gray-600 eds-text-bl">
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: SUBTITLE_TEXT.toString(),
                        }}
                    />
                </div>
            </section>
            <section className="eds-l-pad-vert-1">
                <Button style="fill" type="link" href="/create">
                    {BUTTON_CREATE_EVENT_CTA}
                </Button>
                <span className="eds-l-pad-hor-2" />
                <Button style="neutral" type="link" href="/d/local/">
                    {BUTTON_FIND_EVENT_CTA}
                </Button>
            </section>
        </div>
    );
};

interface BasePageProps {
    env: CoreApplicationContext['env'];
    footerLinks: FooterLinksShape;
    request: RequestShape;
    user?: UserShape;
    shouldStopRenderEarly?: boolean;
    isHomePage?: boolean;
    children?: React.ReactNode;
    featureFlags?: FeatureFlags;
    seasonalContent?: $FixMe;
    removeOverflowSettings?: boolean;
    breadcrumbs?: Array<{
        title: string;
        [key: string]: any;
    }>;
    searchBar?: searchBarProps;
}
export const BasePage = ({
    env,
    request,
    user,
    footerLinks,
    isHomePage,
    featureFlags,
    removeOverflowSettings,
    breadcrumbs,
    children,
    searchBar,
}: BasePageProps) => {
    useMount(() => {
        // Accessibility improvement on a component with (still) unknown location
        const userMenuItem = document.querySelector(
            '.consumer-header__user-menu.consumer-header__menu',
        );

        if (userMenuItem) {
            const userMenuLink = userMenuItem.querySelector('a');

            if (userMenuLink) {
                userMenuLink.setAttribute(
                    'aria-label',
                    gettext('User menu').toString(),
                );
            }
        }
    });

    // Force logged-out for server-side rendering
    const _user = useSSRSafeUser(user);

    let globalHeader;
    if (!_user?.isAuthenticated) {
        globalHeader = RenderGlobalNavOrUndefined({
            config: {
                env: env,
                user: _user,
                searchBar,
            },
        });
    }
    globalHeader ??= (
        <ConsumerHeader
            env={env}
            request={request}
            user={_user}
            isHomePage={isHomePage}
            hasBreadcrumbs={breadcrumbs && breadcrumbs.length > 0}
            featureFlags={featureFlags || {}}
            searchBar={searchBar}
        />
    );

    return (
        <SiteStructure
            env={env}
            request={request}
            user={_user}
            featureFlags={featureFlags}
            removeOverflowSettings={removeOverflowSettings}
            fullScreenMain={true}
            isUserAuthenticated={_user?.isAuthenticated}
            showMarketingLinks={!_user?.isAuthenticated}
            footerLinks={footerLinks}
            breadcrumbs={breadcrumbs}
            hideMenuToggle={true}
            globalHeader={globalHeader}
        >
            {/* Sitestructure has an error boundary built in around its children, so errors that sentry
                should be grabbing are potentially being swallowed. This ensures that the error is caught
                and not piped to the default error system. */}
            <SentryErrorBoundary fallback={<RootErrorFallback />}>
                {children}
            </SentryErrorBoundary>
        </SiteStructure>
    );
};
export default BasePage;
