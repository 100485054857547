import flowRight from 'lodash/flowRight';

import { addOverlayControls } from '@eventbrite/eds-structure';
import { addMainControls } from '@eventbrite/eds-structure';
import { addFocusDrawerControls } from '@eventbrite/eds-structure';

/**
 * Enhances a component (typically `SiteStructure`) with useful HOC controls.
 * Also useful for enhancing mock components in unit tests.
 * @param {import('react').Component} component The component to enhance
 * @returns {import('react').Component} Enhanced component
 */
export const enhance = flowRight(
    addOverlayControls,
    addMainControls,
    addFocusDrawerControls,
);
