// TODO: Consider modernizing this further.  Copied from `js-utils/prop-types`
// with minimal modification for stability (amd support was removed)
// Note pattern originally adapted from:
// https://github.com/umdjs/umd/blob/master/templates/returnExports.js

import PropTypes from 'prop-types';
import { GenericLazyString } from './genericLazyString';

// We defer to using custom prop type function instead of oneOfType
// because PropTypes.instanceOf(String) doesn't work with lazyString, which doesn't
// directly inherit from String class, which is available in ES6
export const translationPropType = PropTypes.oneOfType([
    PropTypes.string,
    function (props: any, propName: string, componentName: string) {
        if (props[propName]) {
            if (!GenericLazyString.isValid(props[propName])) {
                return new Error(
                    `Invalid prop \`${propName}\` supplied to` +
                        ` \`${componentName}\`. Expected a GenericLazyString object.`,
                );
            }
        }

        return null;
    },
]);
