import { InteractionsLogger } from './interactionsLogger';
import { InteractionsLoggerV1 } from './interactionsLoggerV1';
import {
    EventInteraction,
    EventInteractionContext,
    EventInteractions,
    PLACE_EVENT_INTERACTION,
    Subscribable,
} from './types';

export class EventsInteractionsTracker {
    private LOG_PERIODICITY_MILLISECONS = 1250;
    private interactionsLoggers: InteractionsLogger<any>[];
    private interactions: EventInteraction<EventInteractionContext>[] = [];
    private interactions_history: Set<string> = new Set();
    private element: Subscribable;

    constructor(
        element: Subscribable,
        interactionsLoggers: InteractionsLogger<any>[] = [
            new InteractionsLoggerV1(),
        ],
    ) {
        this.element = element;
        this.interactionsLoggers = interactionsLoggers;
    }

    listen() {
        this.element.addEventListener(PLACE_EVENT_INTERACTION, ({ detail }) => {
            const interactionUniqueId = `${detail.id}-${detail.place?.placementId}-${detail.action}`;

            if (
                uniqueInteractions.includes(detail.action) &&
                this.interactions_history.has(interactionUniqueId)
            ) {
                return;
            }

            this.interactions_history.add(interactionUniqueId);
            this.interactions.push(detail);
        });
    }

    track() {
        setInterval(() => {
            this.logStoredInteractions();
        }, this.LOG_PERIODICITY_MILLISECONS);

        this.element.addEventListener('beforeunload', () =>
            this.logStoredInteractions(),
        );
    }

    private logStoredInteractions() {
        const logsLimit = 10;
        for (let i = 0; i < this.interactions.length; i += logsLimit) {
            const logsBlock = this.interactions.slice(i, i + logsLimit);
            this.interactionsLoggers.forEach((il) => {
                il.run(logsBlock);
            });
        }
        this.interactions = [];
    }
}

// This interactions should be only logged once
const uniqueInteractions = [
    EventInteractions.Impression,
    EventInteractions.Mounted,
];
