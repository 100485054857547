import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { GlobalNav } from '../modules/GlobalNav';
import { GlobalNavProps, NavItemType } from '../modules/types';

export interface RenderGlobalNavOrUndefined {
    config: GlobalNavProps;
    featureFlag?: boolean;
}
export const RenderGlobalNavOrUndefined = ({
    config,
}: RenderGlobalNavOrUndefined) => {
    const { positionSticky, searchBar, logo, navigation, user, env } = config;
    if (!user.isAuthenticated) {
        return (
            <GlobalNav
                positionSticky={positionSticky}
                searchBar={searchBar}
                logo={logo}
                navigation={navigation}
                user={user}
                env={env}
            />
        );
    }
};

export const alternativePath = (path: string, tld: string) => {
    const mapByTld: any = {
        '.ca': {
            'l/sell-tickets/': 'l/sell-tickets-online/',
        },
        '.co.uk': {
            'l/conferences/': 'l/conference-planning/',
        },
    };

    return mapByTld?.[tld]?.[path] || path;
};

export const getSigninOrSignupUrl = (serverUrl?: string) => {
    if (
        !CREATOR_INTENTED_PAGES.some((path) =>
            decodeURIComponent(serverUrl ?? '').includes(path),
        )
    )
        return serverUrl ?? '/signin/';

    if (
        isReferrerMLP(serverUrl) &&
        isUrlCreatorOnboarding(serverUrl) &&
        serverUrl
    ) {
        const mlp_signup_url = new URL(
            serverUrl ?? 'https://www.eventbrite.com',
        );
        mlp_signup_url.searchParams.delete('bust_wagtail_cache');
        mlp_signup_url.searchParams.delete('referrer');
        return mlp_signup_url.toString();
    }

    const AUTH_PATH = serverUrl?.includes('/signup')
        ? '/signin/signup/'
        : '/signin/';
    const url = new URL(serverUrl ?? 'https://www.eventbrite.com');

    url.pathname = AUTH_PATH;
    url.searchParams.set('referrer', '/manage/events/create/');
    url.searchParams.delete('bust_wagtail_cache');

    return url.toString();
};

export const onEnterKey = (
    event: React.KeyboardEvent,
    callback: () => void,
) => {
    if (event.key === 'Enter') {
        callback();
    }
};

export const isReferrerMLP = (serverUrl?: string) => {
    return MARKETING_LANDING_PAGES_PATHS.some((path) =>
        decodeURIComponent(serverUrl ?? '').includes(path),
    );
};

export const isUrlCreatorOnboarding = (serverUrl?: string) => {
    return serverUrl?.includes('/creator/onboarding/onboarding-redirect');
};

export const updateQueryStringParameter = (
    url: string,
    key: string,
    value: string,
): string => {
    const queryStart = url.indexOf('?');
    const queryString = queryStart !== -1 ? url.substring(queryStart + 1) : '';
    const baseUrl = queryStart !== -1 ? url.substring(0, queryStart) : url;
    const params = new URLSearchParams(queryString);
    params.set(key, value);
    return baseUrl + '?' + params.toString();
};

export const creatorDesktopItems = (desktop?: NavItemType[]) => {
    const creatorDesktop = [...(desktop || [])];
    creatorDesktop.splice(0, 1, {
        path: 'l/contact-eventbrite-sales/',
        translatedText: gettext('Contact Sales'),
        heapIdentifier: 'Contact Sales',
    });
    return creatorDesktop;
};

const CREATOR_INTENTED_PAGES = ['/l/', '/organizer/', '/boost', '/reconvene'];
const MARKETING_LANDING_PAGES_PATHS = ['/l/', '/organizer/'];
