import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';

import { VectorImage } from '@eventbrite/eds-vector-image';

import { VECTOR_IMAGE_PAYMENT } from '@eventbrite/eds-vector-image';
import * as constants from './constants';

import { getPaymentIconSizeHeight, getPaymentIconSizeWidth } from './util';

export default class PaymentIcon extends PureComponent {
    static propTypes = {
        /**
         * The type of payment icon that you want to display e.g. Visa
         */
        type: constants.PAYMENT_TYPE_PROP_TYPE.isRequired,
        /**
         * The size of the icon
         */
        size: PropTypes.oneOf(constants.PAYMENT_ICON_SIZES),
        /**
         * Add a title attribute to the to icons markup
         */
        title: translationPropType,
    };

    static defaultProps = {
        size: 'medium',
    };

    render() {
        const { type, size, title } = this.props;

        const INLINE_STYLES = {
            height: getPaymentIconSizeHeight(size),
            width: getPaymentIconSizeWidth(size),
        };

        const svg = React.cloneElement(type, { style: INLINE_STYLES });

        return (
            <VectorImage type={VECTOR_IMAGE_PAYMENT} svg={svg} title={title} />
        );
    }
}
