import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { getWindowObject } from '@eventbrite/feature-detection';
import loadable from '@loadable/component';
import { setWindowLocation } from '@eventbrite/http';
import { BaseGlobalHeader } from '@eventbrite/eds-global-header';

const LazySearchTakeover = loadable.lib(() =>
    import('@eventbrite/search-takeover'),
);

import {
    getHelpMenu,
    getOrganizeMenu,
    getLogoInfo,
    getSearchInfo,
    getQuickLinks,
    getNarrowMenuLinks,
    getUserMenuInfo,
    getNonUserQuickLinks,
    getCreateEventCTA,
    isHomepage,
} from './utils';
import {
    ENV_SHAPE_PROP_TYPE,
    REQUEST_SHAPE_PROP_TYPE,
    USER_SHAPE_PROP_TYPE,
    FALLBACK_SUGGESTIONS,
    GA_SEARCH_AUTOCOMPLETE_SUGGESTION_ACTION,
} from './constants';
import { getDestinationSuggestions } from './utils/search';
import { getUserLocationFromRequest } from './utils/userLocation';
const SEARCH_SUGGESTIONS_THROTTLE_AMOUNT = 300;
const getDestinstionSuggestionsThrottled = throttle(
    getDestinationSuggestions,
    SEARCH_SUGGESTIONS_THROTTLE_AMOUNT,
);

export default class SiteHeader extends React.Component {
    static propTypes = {
        env: ENV_SHAPE_PROP_TYPE.isRequired,
        request: REQUEST_SHAPE_PROP_TYPE.isRequired,
        user: USER_SHAPE_PROP_TYPE.isRequired,
        onSearchSelect: PropTypes.func,
        hideSearchBar: PropTypes.bool,
        onSearchFocus: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            searchQuery: props.env.searchQuery || '',
            searchSuggestions: FALLBACK_SUGGESTIONS,
            isSearchTakeoverVisible: null,
        };
    }

    componentDidMount() {
        this._setUserLocationOnState();
    }

    _setUserLocationOnState = () => {
        const {
            env: { searchLocation },
        } = this.props;

        this.setState({
            userLocation: getUserLocationFromRequest(searchLocation),
        });
    };

    _handleSearchChange = (searchQuery) => {
        if (!this.props.onSearchFocus) {
            getDestinstionSuggestionsThrottled(
                searchQuery,
                this.state.userLocation,
            ).then((searchSuggestions) => {
                this.setState({ searchQuery, searchSuggestions });
            });
        }
    };

    _handleSearchSubmit = (query) => {
        if (!query) {
            setWindowLocation(`/d/local/all-events`);
        } else {
            setWindowLocation(`/d/?q=${query}`);
        }
    };

    _handleSearchSelect = (query) => {
        const { onSearchSelect } = this.props;

        trackAnalyticsEvent({
            category: getWindowObject('__SERVER_DATA__').app_name,
            label: query,
            action: GA_SEARCH_AUTOCOMPLETE_SUGGESTION_ACTION,
        });

        if (onSearchSelect) onSearchSelect();
    };

    render() {
        const {
            env,
            request,
            user,
            onSearchSelect,
            hideSearchBar,
            onSearchFocus,
            featureFlags,
            onMouseEnterGlobalHeader,
        } = this.props;
        const { searchQuery, searchSuggestions, isSearchTakeoverVisible } =
            this.state;
        const {
            serverUrl,
            adminServerUrl,
            loginUrl,
            logoutUrl,
            signinUrl,
            signupUrl,
            helpUrls,
            organizeUrls,
            ebDomain,
            isManageOverviewActive,
            launchNewSignInSignUpLinks,
        } = env;
        const { path: requestPath } = request;
        const { isAuthenticated, canCreateEvents } = user;
        const additionalMenus = [
            getOrganizeMenu(organizeUrls, requestPath),
            getHelpMenu(helpUrls, requestPath),
        ];

        const signinPath = launchNewSignInSignUpLinks ? signinUrl : loginUrl;
        const nonUserQuickLinks = getNonUserQuickLinks(
            requestPath,
            isAuthenticated,
            signinPath,
            signupUrl,
            { launchNewSignInSignUpLinks },
        );
        const showCreateEventCTA = canCreateEvents || isHomepage(requestPath);
        const callToActionInfo = getCreateEventCTA(
            serverUrl,
            ebDomain,
            requestPath,
            isAuthenticated,
            null,
            { showCreateEventCTA },
        );
        // This is a work around that we would like to improve at some point.
        const narrowMenuLinks = getNarrowMenuLinks({
            serverUrl,
            requestPath,
            nonUserQuickLinks,
            launchNewSignInSignUpLinks,
        });

        return (
            <>
                <BaseGlobalHeader
                    logoInfo={getLogoInfo(serverUrl, requestPath)}
                    // Search Bar
                    searchInfo={getSearchInfo(
                        serverUrl,
                        searchQuery,
                        searchSuggestions,
                        hideSearchBar,
                    )}
                    // Quick links to show in desktop and mobile header
                    quickLinks={getQuickLinks(serverUrl, ebDomain, requestPath)}
                    // Links for logged in users
                    userMenuInfo={getUserMenuInfo({
                        serverUrl,
                        adminServerUrl,
                        logoutUrl,
                        requestPath,
                        user,
                        isManageOverviewActive,
                        showBrowseEvents: hideSearchBar,
                        featureFlags,
                    })}
                    // Dropdown menus
                    additionalMenus={additionalMenus}
                    // The signin link for logged out users
                    nonUserQuickLinks={nonUserQuickLinks}
                    // If the new navbar is not active, show a create event button for logged in users
                    callToActionInfo={callToActionInfo}
                    // Links we want in the mobile header
                    narrowMenuLinks={narrowMenuLinks}
                    onSearchChange={this._handleSearchChange}
                    onSearchSelect={this._handleSearchSelect}
                    onSearchFocus={onSearchFocus}
                    onSearchSubmit={this._handleSearchSubmit}
                    useNarrowBreakpoint={false}
                    onUserDropdownMouseEnter={onMouseEnterGlobalHeader}
                    //Props added for SearchTakeover logic
                    onSearchClick={() =>
                        this.setState({ isSearchTakeoverVisible: true })
                    }
                />
                {isSearchTakeoverVisible !== null ? (
                    <LazySearchTakeover>
                        {({ SearchTakeover }) => (
                            <SearchTakeover
                                domNodeId="root"
                                onClose={() =>
                                    this.setState({
                                        isSearchTakeoverVisible: false,
                                    })
                                }
                                id="search-takeover"
                                isVisible={isSearchTakeoverVisible}
                            />
                        )}
                    </LazySearchTakeover>
                ) : null}
            </>
        );
    }
}
