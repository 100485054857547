import type { IntlFormatOptions } from './types';

export const DEFAULT_TIMEZONE = 'UTC';
export const DEFAULT_LANGUAGE = 'en_US';
export const ISO_8601_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const FULL_ISO_8601_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";
export const ISO_8601_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_TIME_FORMAT = 'h:mm a';
export const TIME_FORMAT_24HS = 'HH:mm';
export const ALTERNATIVE_TIME_FORMAT = 'h:mma';
export const FULL_TIME_FORMAT = 'HH:mm:ss';
export const LONG_MONTH_DATE = 'MMM d';
export const ISO_8601_SET = [ISO_8601_FORMAT, FULL_ISO_8601_FORMAT];
export const TIME_FORMAT_SET = [
    FULL_TIME_FORMAT,
    ALTERNATIVE_TIME_FORMAT,
    DEFAULT_TIME_FORMAT,
    TIME_FORMAT_24HS,
];

// intlFormat constants
export const DATE_FNS_FORMAT_CONFIG: IntlFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

export const FULL_DATE_FORMAT: IntlFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
};

export const FULL_DATE_WEEK_DAY_FORMAT: IntlFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
};

export const DATE_FORMATS: { [key: string]: string } = {
    de: 'EEEE, d. MMMM',
    de_AT: 'EEEE, d. MMMM',
    de_CH: 'EEEE, d. MMMM',
    de_DE: 'EEEE, d. MMMM',
    en: 'EEEE, MMMM d',
    en_AU: 'EEEE, d MMMM',
    en_IE: 'EEEE, d MMMM',
    en_SG: 'EEEE, d MMMM',
    en_HK: 'EEEE, d MMMM',
    en_DK: 'EEEE, d MMMM',
    en_FI: 'EEEE, d MMMM',
    en_GB: 'EEEE, d MMMM',
    en_CA: 'EEEE, MMMM d',
    en_NZ: 'EEEE, d MMMM',
    en_US: 'EEEE, MMMM d',
    es: "EEEE, d 'de' MMMM",
    es_ES: "EEEE, d 'de' MMMM",
    es_AR: "EEEE, d 'de' MMMM",
    es_CL: "EEEE, d 'de' MMMM",
    es_CO: "EEEE, d 'de' MMMM",
    es_PE: "EEEE, d 'de' MMMM",
    es_MX: "EEEE, d 'de' MMMM",
    fr: 'EEEE d MMMM',
    fr_BE: 'EEEE d MMMM',
    fr_CH: 'EEEE d MMMM',
    fr_FR: 'EEEE d MMMM',
    fr_CA: 'EEEE d MMMM',
    it: 'EEEE d MMMM',
    it_IT: 'EEEE d MMMM',
    nl: 'EEEE d MMMM',
    nl_BE: 'EEEE d MMMM',
    nl_NL: 'EEEE d MMMM',
    pt: "EEEE, d 'de' MMMM",
    pt_PT: "EEEE, d 'de' MMMM",
    pt_BR: "EEEE, d 'de' MMMM",
    sv: 'EEEE d MMMM',
};
