import { EventsInteractionsTracker } from './eventInteractionsTracker';
import { InteractionsLoggerV1 } from './interactionsLoggerV1';
import { InteractionsStatsigLogger } from './interactionsStatsigLogger';

let eventInteractionTracker: EventsInteractionsTracker;

export function startEventCardTracking() {
    const IS_SSR = typeof document === 'undefined';

    if (IS_SSR || eventInteractionTracker) {
        return;
    }

    eventInteractionTracker = new EventsInteractionsTracker(document, [
        new InteractionsLoggerV1(),
        new InteractionsStatsigLogger(),
    ]);

    eventInteractionTracker.listen();
    eventInteractionTracker.track();
}
