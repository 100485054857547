import { DESTINATION_CITY_BROWSE_SLA_MILLISECONDS } from '@eventbrite/discover-utils';
import { sdkRequest } from '@eventbrite/http';

export type CityBrowseRequestBody = {
    place_id: string;
    buckets_list: string[];
};

export type CityBrowseResponseBody = {
    description: string;
    branding: Object;
    buckets?: Bucket[];
    more_buckets: boolean;
    search_id: string;
};

export type Bucket = {
    key_min: string;
    name: string;
    places?: CityBrowsePlace[];
    see_more: Object;
    key: string;
    type: string;
    template: string;
};

export type CityBrowsePlace = {
    id: string;
    _type: string;
    subhead: string;
    name: string;
    url: string;
    params: {
        dates: string[];
        places: string[];
    };
};

/**
 * Fetch Nearby Cites for a placeId using the city-browse endpoint.
 *
 * Ideally, this function would use the Search API and places_locality_centroid agg.
 * However, the search API returns slightly different results than city browse, so using this approach for now.
 *
 * @param placeId the place id for which to fetch nearby cities; corresponds to the location of the current page
 */
export const getNearbyCities = async (
    placeId?: string,
): Promise<CityBrowseResponseBody> => {
    if (!placeId) {
        return Promise.resolve({} as CityBrowseResponseBody);
    }

    const requestBody: CityBrowseRequestBody = {
        place_id: placeId,
        buckets_list: ['nearby_cities'],
    };

    const controller = new AbortController();
    const id = setTimeout(() => {
        controller.abort();
    }, DESTINATION_CITY_BROWSE_SLA_MILLISECONDS);
    try {
        return sdkRequest('/api/v3/destination/city-browse/', {
            method: 'POST',
            signal: controller.signal,
            body: JSON.stringify(requestBody),
        });
    } catch (e) {
        console.error(e.response);
        throw e.response;
    } finally {
        clearTimeout(id);
    }
};
