import PropTypes from 'prop-types';

export const ALIGN_FULL = 'full';
export const ALIGN_LEFT = 'left';
export const ALIGN_CENTER_LEFT = 'center-left';
export const ALIGN_RIGHT = 'right';
export const ALIGN_OPTIONS = [
    ALIGN_FULL,
    ALIGN_LEFT,
    ALIGN_CENTER_LEFT,
    ALIGN_RIGHT,
];

export const ALIGN_PROP_TYPE = PropTypes.oneOf(ALIGN_OPTIONS);
