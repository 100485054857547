import { transformUtil } from '@eventbrite/transformation-utils';
import { Schema, arrayOf } from 'normalizr';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

export const formatTransformedEventsWithCollection = (events, eventIds) => {
    let formattedEvents = {};

    forEach(eventIds, (eventId) => {
        const { myCollections: { collections = [] } = {}, ...eventData } =
            events[eventId];

        const collectionIds = collections.map(({ id }) => id);

        formattedEvents = {
            ...formattedEvents,
            [eventId]: {
                eventCollections: collectionIds,
                ...eventData,
            },
        };
    });

    return formattedEvents;
};

/*
 * @param eventResponse -- {events: [{event_object}], pagination: {paginationObject}}
 *
 * returns
 * {
 *   events: {
 *     eventId: {eventObject},
 *     eventId: {}...
 *   },
 *   ids: [string],
 *   continuation: number,
 *   pageCount: number,
 *   totalItems: number,
 * }
 */
export const transformForRedux = (eventResponse = {}) => {
    const { events = {}, pagination = {} } = eventResponse;

    const content = new Schema('events');

    /* Parse the events into by ID entities */
    const {
        entities: { events: normalizedEvents } = {},
        result: { events: normalizedEventIds },
    } = transformUtil({
        response: { events },
        schema: {
            events: arrayOf(content),
        },
    });

    const normalizedEventsWithFormattedCollection =
        formatTransformedEventsWithCollection(
            normalizedEvents,
            normalizedEventIds,
        );

    /* Build pagination object */
    const {
        has_more_items: hasMoreItems,
        object_count: totalItems,
        continuation,
    } = pagination;

    return {
        events: normalizedEventsWithFormattedCollection,
        ids: isEmpty(normalizedEventIds) ? [] : normalizedEventIds,
        hasMoreItems,
        continuation,
        totalItems,
    };
};
