import { GREYS_JSON } from '@eventbrite/eds-color';
import React from 'react';

export interface InitialsProps {
    x: React.SVGAttributes<SVGTextElement>['x'];
    y: React.SVGAttributes<SVGTextElement>['y'];
    fontSize: React.SVGAttributes<SVGTextElement>['fontSize'];
    textColor: keyof typeof GREYS_JSON;
}

export const Initials: React.FunctionComponent<InitialsProps> = ({
    x,
    y,
    fontSize,
    textColor,
    children,
}) => (
    <text
        x={x}
        y={y}
        fontSize={fontSize}
        fontWeight="600"
        textAnchor="middle"
        fill={GREYS_JSON[textColor]}
    >
        {children}
    </text>
);
