import { useCallback, useEffect, useState } from 'react';
import { HISTORY_HASH } from '../constants';
import { useHashListener } from './index';

const toggleHash = (isVisible: boolean) => {
    if (isVisible) {
        //trigger popstate and let
        //useEffect handle logic below
        window.location.hash = HISTORY_HASH;
    } else if (!isVisible && window.location.hash === HISTORY_HASH) {
        // HACK: if we set location.hash to an empty string, the page will scroll to the top when the Search Takeover is closed
        // Setting it as an arbitrary string before setting it to null allows the page to stay at the same scroll position
        window.location.hash = '##';
        history.replaceState(
            null,
            document.title,
            window.location.pathname + window.location.search,
        );
    }
};

export const useOverlayVisibility = (
    initialState: boolean,
    onClose: () => void,
) => {
    const [isVisible, setOverlayVisibility] = useState(initialState);
    const currentHash = useHashListener();

    //Logic for updating the visibility
    //state of the takeover and appropriate
    //callbacks.
    const toggleVisibility = useCallback(
        (shouldRender) => {
            setOverlayVisibility(shouldRender);
            if (!shouldRender) {
                onClose?.();
            }
        },
        [onClose],
    );

    //Hash listener logic. When popstate events occur, update
    //the visibility state of the takeover.
    useEffect(() => {
        if (currentHash !== null) {
            const shouldRender = currentHash === HISTORY_HASH;
            toggleVisibility(shouldRender);
        }
    }, [currentHash, toggleVisibility]);

    //Function exported to children of the component
    //that will allow for programmatic closure of the modal
    const setVisible = useCallback((visible: boolean) => {
        toggleHash(visible);
    }, []);

    //Listen for changes to the initial state
    //and update accordingly. So that consuming
    //apps can control visibility themselves.
    useEffect(() => {
        setVisible(initialState);
    }, [initialState, setVisible]);

    return [isVisible, setVisible] as const;
};
