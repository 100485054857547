import * as React from 'react';

const DatingSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={43} height={40} fill="none" {...props}>
        <path
            id="dating_svg__Vector"
            d="M18.498 20.1a.655.655 0 01-.686 0c-.913-.56-5.472-3.524-5.53-6.857-.027-1.568.75-2.879 2.028-3.422 1.232-.524 2.66-.221 3.845.782C19.34 9.6 20.767 9.297 22 9.821c1.277.543 2.054 1.854 2.028 3.422-.057 3.333-4.616 6.296-5.53 6.856zm4.186-6.882c.017-.999-.43-1.789-1.195-2.114-.896-.38-1.96-.035-2.848.924a.661.661 0 01-.486.214.662.662 0 01-.486-.215c-.649-.702-1.394-1.075-2.098-1.075-.257 0-.51.05-.75.152-.765.325-1.212 1.115-1.195 2.114.035 2.021 2.718 4.29 4.53 5.47 1.81-1.18 4.494-3.449 4.529-5.47zM43 9.71v22.4c0 1.896-1.493 3.438-3.327 3.438h-3.035v3.758a.691.691 0 01-.482.666.656.656 0 01-.754-.29l-2.585-4.135H17.148c-1.835 0-3.327-1.542-3.327-3.437v-2.836h-3.637L7.6 33.41a.667.667 0 01-.754.29.691.691 0 01-.482-.666v-3.758H3.327C1.493 29.275 0 27.733 0 25.838V3.437C0 1.54 1.493 0 3.327 0h29.798c1.835 0 3.327 1.542 3.327 3.437v2.836h3.22C41.507 6.273 43 7.815 43 9.71zm-9.875 18.177c1.094 0 1.983-.92 1.983-2.05v-22.4c0-1.13-.89-2.049-1.983-2.049H3.327c-1.094 0-1.983.92-1.983 2.049v22.4c0 1.13.89 2.05 1.983 2.05h3.707c.371 0 .672.31.672.693v2.099l1.548-2.475a.667.667 0 01.564-.317h23.307zM41.656 9.71c0-1.13-.89-2.05-1.983-2.05h-3.22v18.178c0 1.895-1.493 3.437-3.328 3.437h-17.96v2.836c0 1.13.889 2.048 1.983 2.048h16.034c.228 0 .44.12.564.318l1.547 2.475v-2.099c0-.383.301-.694.672-.694h3.707c1.094 0 1.984-.919 1.984-2.048V9.71z"
            fill="#504264"
        />
    </svg>
);

DatingSvg.displayName = 'DatingSvg';
export default DatingSvg;
