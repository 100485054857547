import React from 'react';
import './LoadingSkeleton.scss';

interface LoadingSkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
    width?: React.CSSProperties['width'];
    height?: React.CSSProperties['height'];
    opacity?: React.CSSProperties['opacity'];
    borderRadius?: React.CSSProperties['borderRadius'];
}
export const LoadingSkeleton = ({
    width = '100%',
    height = '100%',
    opacity,
    borderRadius = '2px',
    ...rest
}: LoadingSkeletonProps) => (
    <div
        style={{ width, height, opacity, borderRadius }}
        className="shimmer-effect"
        {...rest}
    />
);
