import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { format } from 'date-fns';
import { gettext, translationPropType } from '@eventbrite/i18n';

import { FormField } from '@eventbrite/eds-form-field';
import { SelectField } from '@eventbrite/eds-input-field';

import { LINKS_PROP_TYPE } from './constants';

import './globalFooter.scss';

const CURRENT_YEAR = format(new Date(), 'Y');

const GlobalFooterLink = ({ url, children, ...linkAttrs }) => (
    <a
        {...linkAttrs}
        href={url}
        aria-label={children ? undefined : 'eb-footer'}
        className="eds-global-footer__link"
    >
        {children}
    </a>
);

const GlobalFooterLinks = ({ links }) => {
    const linkComponents = links.map(
        ({ url, content, additionalClassName, ...linkAttrs }, index) => {
            const isLast = index === links.length - 1;
            let itemClassName = classNames(
                'eds-global-footer__link-bullet',
                'eds-global-footer__horizontal-link-item',
                additionalClassName,
            );
            const key = `${url}${content}`;

            if (isLast) {
                itemClassName = itemClassName.replace(
                    'eds-global-footer__link-bullet ',
                    '',
                );
            }

            return (
                <li
                    key={key}
                    className={itemClassName}
                    data-spec="global-footer-link-item"
                >
                    <GlobalFooterLink {...linkAttrs} url={url}>
                        {content}
                    </GlobalFooterLink>
                </li>
            );
        },
    );

    return (
        <ul className="eds-l-mar-all-0 eds-l-pad-hor-0">{linkComponents}</ul>
    );
};

const GlobalFooterGeneralLinks = ({ generalLinks }) => {
    if (isEmpty(generalLinks)) {
        return null;
    }

    return (
        <div
            className="eds-global-footer__general-links eds-g-cell eds-g-cell-12-12 eds-g-cell-mn-8-12 eds-text--center"
            data-spec="global-footer-general-links"
        >
            <GlobalFooterLinks links={generalLinks} />
        </div>
    );
};

const GlobalFooterCopyright = () => (
    <div className="eds-global-footer__copyright eds-g-cell eds-g-cell-12-12 eds-g-cell-mn-2-12 eds-l-pad-vert-4">
        &copy; {`${CURRENT_YEAR} Eventbrite`}
    </div>
);

const GlobalFooterRegionSelector = ({
    regions,
    currentRegion,
    onRegionChange,
}) => {
    if (isEmpty(regions) || !currentRegion) {
        return null;
    }

    return (
        <div className="eds-global-footer__region eds-g-cell eds-g-cell-12-12 eds-g-cell-mn-2-12 eds-l-pad-top-4 eds-l-pad-bot-3">
            <div className="eds-global-footer__region-selector">
                <FormField bottomSpacing={0}>
                    <SelectField
                        bottomSpacing={0}
                        label={gettext('Locale')}
                        id="global-footer-select"
                        name="global-footer-select"
                        values={regions}
                        defaultValue={currentRegion}
                        onChange={onRegionChange}
                        style="basic"
                        role={null}
                    />
                </FormField>
            </div>
        </div>
    );
};

const GlobalFooterBase = ({
    generalLinks,
    locales,
    currentLocale,
    onLocaleChange,
}) => (
    <div className="eds-global-footer__base eds-g-grid eds-l-pad-hor-6">
        <GlobalFooterCopyright />
        <GlobalFooterGeneralLinks generalLinks={generalLinks} />
        <GlobalFooterRegionSelector
            regions={locales}
            currentRegion={currentLocale}
            onRegionChange={onLocaleChange}
        />
    </div>
);

export default class GlobalFooter extends React.PureComponent {
    static propTypes = {
        /**
         * List of links shown at the bottom of the footer.
         * Typically these are the "corp links" that link to various
         * business-related pages.
         */
        generalLinks: LINKS_PROP_TYPE,
        /**
         * List of supported locales to show in a locale selector
         */
        locales: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                display: translationPropType.isRequired,
            }),
        ),
        /**
         * The current locale (for setting the initially selected locale
         * in the locale seleector)
         */
        currentLocale: PropTypes.string,
        /**
         * All callback function invoked when the locale changes in the
         * locale selector
         */
        onLocaleChange: PropTypes.func,
        /**
         * React node for brand section
         */
        brandSection: PropTypes.node,
        /**
         * React node for flexible section (SEO)
         */
        flexibleSection: PropTypes.node,
    };

    render() {
        const {
            generalLinks,
            locales,
            currentLocale,
            onLocaleChange,
            brandSection,
            flexibleSection,
        } = this.props;

        return (
            <footer
                className="eds-global-footer eds-g-grid l-mar-top-2"
                aria-label={gettext('Eventbrite footer')}
            >
                <h2 className="eds-is-hidden-accessible">
                    {gettext('Site Navigation')}
                </h2>
                {brandSection}
                {flexibleSection}
                <GlobalFooterBase
                    generalLinks={generalLinks}
                    locales={locales}
                    currentLocale={currentLocale}
                    onLocaleChange={onLocaleChange}
                />
            </footer>
        );
    }
}
