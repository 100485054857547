// NOTE: The workspace does not need to match with the one in the SplitIO,
// it is only used to store the client object. However, we encourage you to write
// your workspace name to avoid overrides with other teams' clients.

export const EVENT_PROMOTION_API_KEY_CONFIG = {
    workspace: 'event-promotion',
    prodKey: 'npfbpvf0pqksu5aen170sqcjjbhkimr5oesr',
    qaKey: 'm8lsfvb0kht69lh2nmqf4uut16thu1vrssau',
    devKey: 'mr6oqt6ovar6d8va9bv6kg1ro01lluri9686',
};

export const CORE_TICKETING_INVENTORY_API_KEY_CONFIG = {
    workspace: 'core-ticketing_inventory',
    prodKey: '4rmcht6v32u1l5pdaadqr10cri8o7h4ffk0o',
    stageKey: 'h19fbjo50a2016n05gbp41n2civrq6vssbr1',
    qaKey: 'e8jskc7ig20avdkjn06s0ciftpen8bee7bco',
    devKey: '73skcekk2djh1ot11vp39b2u421b559c7kar',
};

export const CORE_PAYMENTS_ONBOARDING_API_KEY_CONFIG = {
    workspace: 'core-payments-onboarding',
    prodKey: '6sdb3qfi2i1akkcqjmq60d2vhvo86sjl9k79',
    stageKey: 'r5s7rpaocala3mq0j90mroqest07273uif5o',
    qaKey: 'qkpk4bcjh7ocrh5utceu9d8kmrcs195237u',
    devKey: '21gjggfk54drg6ifdphdcpgsu7tfhhat9q7m',
};

export const CORE_PAYOUTS_BILLING_API_KEY_CONFIG = {
    workspace: 'core-payouts-billing',
    prodKey: 'o9b5b5uo4b8gqfdok4kitd4pqnqnpbb246rp',
    qaKey: 'lg21ieha846qeadnqetu0fe89db78ivu2kej',
    devKey: '7g3aupnvte4nbm2g0sgi3r935s7j55sp55m5',
};

export const CORE_MARKETING_AUTOMATION_API_KEY_CONFIG = {
    workspace: 'core-marketing-automation',
    prodKey: '577u3hkcjmcln6m2anirub97k35e8hbufo03',
    qaKey: 'jj9uu6ho5esvb61av4kaumgin4co1qkk92nd',
    devKey: 'aff56ctp7vup71bvf9jf928afq1a0ej053fi',
    stageKey: '3ve1ji1t45ua3hrpk37hgtdol3sokphdbujp',
};

export const CORE_CREATOR_ACTIVATION_API_KEY_CONFIG = {
    workspace: 'core-creator-activation',
    prodKey: 'ma0pdd2qob7r02h84spcjloq8f85evaoglvb',
    stageKey: 'bti14crfvmitn9iiao2b4so9vkb6jd2urpop',
    qaKey: 'fmsfqvhcio38j51k88ljb2a2appfj1mkdo4q',
    devKey: 'f7ncflg2fclqmh7ggl37rmalbh25j6tgs7sg',
};

export const CORE_SEO_API_KEY_CONFIG = {
    workspace: 'core-seo',
    prodKey: 'uf98cv3kft5m73o7f02an71sbjb5ii3u4o0g',
    stageKey: 'cf565578ub8u2vkmv18pfj0hs313l2sejdge',
    qaKey: 'gpkeqatsdd93j5uii10soho48cknbtha4o0b',
    devKey: 'caak829695474tni8jf2b034rsjvjde9v7p0',
};

export const CORE_TAX_API_KEY_CONFIG = {
    workspace: 'core-tax',
    prodKey: '4crg4t9k6qb3k0n3lhah9auaac1knsq19kg',
    stageKey: 'eglpr7der36hl347npe31r1niq63c6f13l5d',
    qaKey: 'p725l73mnf78gk0u47u13v9q14n2ajt27bou',
    devKey: 'nn52jc8fob5ell7j78hn1det8hhs9490au6v',
};

export const MESSAGE_API_KEY_CONFIG = {
    workspace: 'message',
    prodKey: '5hjv82kufr8g3g11cu2qcnua22bvu3f019ao',
    stageKey: 'tvj2imn44u4bvsv36qb8ace4rhodd9lt9v34',
    qaKey: 'hqpefof3isulbb1oohgnqsl3f3fg7gialist',
    devKey: 'nse4k08338lcng67f55vtrhi0emiidesu00m',
};

export const CORE_EVENT_CREATION_API_KEY_CONFIG = {
    workspace: 'core-listing_build',
    prodKey: 'rbm9t15e27l4ivc3p213p90b7luhkqo7f0fi',
    stageKey: 'u7lfhp1arrd32dcc4e9jukf40rvn9mkp4n1h',
    qaKey: '3utom2ipdatr4t2tug14h39uebnm4eqf44ic',
    devKey: '5d69rt39sms86il1287q2dso6quufem2c1pi',
};

export const TRUST_API_KEY_CONFIG = {
    workspace: 'trust',
    prodKey: 'tvj8m0v2sen8phvcfp3jefdd548mgoqejc50',
    stageKey: 'a99agrko9g6m8ab0j934s282f7ofbq4ffego',
    qaKey: 'rokgqp78f00r5o2b99b6mve5t0udaf9irvk3',
    devKey: '831i6g22nmrgr9ck0a9fbd23b2u219dorohj',
};

export const SEARCH_API_KEY_CONFIG = {
    workspace: 'search',
    prodKey: 'gh9r2g16j5e87uo8li5kp445pa91kvmij698',
    stageKey: 'gtnb721sd859psh2hl4rb75ks389mej7eqeq',
    qaKey: 'rqethrg16iniso6ga9lu3j082i4q3120mdrl',
    devKey: '629el2a8rdkniuusknp9o90srhrjfab9d042',
};

export const CORE_MARKETING_TOOLS_API_KEY_CONFIG = {
    workspace: 'core-zonda',
    prodKey: 'b3pst0bk3cdqtlapqnndic3786tfti1coiqq',
    stageKey: 'dtcd44ifis3tnbrenlr9bkoupigo3t6dessb',
    qaKey: 'ip18gp4hhv4jfo9144jga8f2n7ihehl1rq97',
    devKey: 'f1cjj3eidqthopliglo1tr2cftdpebfbd4an',
};

export const CORE_TICKETACCESS_API_KEY_CONFIG = {
    workspace: 'core-ticketaccess',
    prodKey: 'm54o3ocq9li39pnhgvnor8f8nilb0lckfoal',
    stageKey: 'ilt1pjlrnit9ltb8111pelp87tsvu8uo6ssu',
    qaKey: 'm1pa9flrlbtfbg9pj9lq6rq1i6vi1d9d2m3d',
    devKey: 'eun3ejn2e59oi83cepp43orlgg9nnf6ssmuf',
};
