import * as React from 'react';

const CheckChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="check-chunky_svg__eds-icon--check-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="check-chunky_svg__eds-icon--check_base"
            d="M5.5 12.2L9.4 16l9.1-9"
            fill="none"
            stroke="#050505"
            strokeWidth={2}
            strokeMiterlimit={10}
        />
    </svg>
);

CheckChunkySvg.displayName = 'CheckChunkySvg';
export default CheckChunkySvg;
