import { PROGRESS_STYLE_CONFIG } from './constants';

export const getStyleByProgress = (progress) => {
    if (progress < 50) {
        return PROGRESS_STYLE_CONFIG[0];
    } else if (progress < 75) {
        return PROGRESS_STYLE_CONFIG[50];
    }

    return PROGRESS_STYLE_CONFIG[100];
};
