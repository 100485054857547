import * as React from 'react';

const LogoWordmarkBrandSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="logo-wordmark-brand_svg__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 2300 400.8"
        xmlSpace="preserve"
        {...props}
    >
        <style>
            {'\n        .logo-wordmark-brand_svg__st3{fill:#221d19}\n    '}
        </style>
        <g>
            <g>
                <path
                    className="logo-wordmark-brand_svg__st3"
                    d="M794 99.5l-43.2 123H749l-43.1-123h-75.6l73.8 198h85.8l73.8-198zM1204.1 94.1c-29.8 0-53.4 13.3-64 35.1V99.5h-72v198.1h72v-97.3c0-29.8 9.8-49.3 34.2-49.3 21.8 0 29.4 14.2 29.4 41.3v105.2h72V173.2c0-41.3-17.4-79.1-71.6-79.1zM1753.1 134.6V99.5h-72v198.1h72V207c0-33.3 16.5-47.7 43.1-47.7 13.8 0 28.9 2.7 38.7 8.5v-68c-4.9-4-15.6-7.6-27.6-7.6-26.2 0-47.1 20.2-54.2 42.4zM1846.9 99.5h72v198.1h-72z"
                />
                <circle
                    className="logo-wordmark-brand_svg__st3"
                    cx={1882.9}
                    cy={44.9}
                    r={40.7}
                />
                <path
                    className="logo-wordmark-brand_svg__st3"
                    d="M2028.9 221.5v-72.4h51.6V99.4h-51.6V44.8h-43.2c0 30.2-24.5 54.6-54.7 54.6v49.7h26.7v93.2c0 46.7 31.5 60.4 64.9 60.4 27.1 0 44.4-6.7 59.6-17.8v-46.6c-12 4.9-21.8 6.7-30.6 6.7-14.7.1-22.7-6.6-22.7-23.5zM1402 245.1s-.1 0 0 0h-.2c-14.6 0-22.5-6.7-22.5-23.5v-72.4h51.6V99.4h-51.6V44.8h-43.2c0 30.2-24.5 54.6-54.7 54.6h-.1v49.7h26.7v93.2c0 46.7 31.5 60.4 64.9 60.4 27.1 0 44.4-6.7 59.6-17.8v-46.6c-11.9 4.9-21.7 6.8-30.5 6.8zM1402 245.1h-.1.1c-.1 0 0 0 0 0zM543.9 94.2c-61.7 0-107.9 44.9-107.9 107.5 0 63.1 45.3 101.3 108.8 101.3 57.7 0 92.2-30.1 100.4-76h-59.1c-6.7 15.5-19.1 22.2-38.6 22.2-21.8 0-39.5-11.5-41.3-36h139v-20c-.1-53.7-35.2-99-101.3-99zM507 178.6c2.7-18.7 12.9-32.9 36-32.9 21.3 0 32.9 15.1 33.8 32.9H507zM954.1 94.2c-61.7 0-107.9 44.9-107.9 107.5 0 63.1 45.3 101.3 108.8 101.3 57.7 0 92.2-30.1 100.4-76h-59.1c-6.7 15.5-19.1 22.2-38.6 22.2-21.8 0-39.5-11.5-41.3-36h139v-20c0-53.7-35.1-99-101.3-99zm-36.9 84.4c2.7-18.7 12.9-32.9 36-32.9 21.3 0 32.9 15.1 33.8 32.9h-69.8zM2300 193.2c0-53.7-35.1-99-101.3-99-61.7 0-107.9 44.9-107.9 107.5 0 63.1 45.3 101.3 108.8 101.3 57.7 0 92.2-30.1 100.4-76h-59.1c-6.7 15.5-19.1 22.2-38.6 22.2-21.8 0-39.5-11.5-41.3-36h139v-20zm-138.1-14.6c2.7-18.7 12.9-32.9 36-32.9 21.3 0 32.9 15.1 33.8 32.9h-69.8zM1582 93.8c-33.3 0-55.6 16-65.4 39.6v-116h-72v280.1h55.6l12.4-34.2c11.1 24 36 39.6 68 39.6 59.6 0 89.4-45.8 89.4-104.5 0-58.4-29.8-104.6-88-104.6zm-25.3 153.4c-24.5 0-40-18.7-40-47.6V197c0-28.5 15.6-47.1 40-47.1 28 0 40.9 20 40.9 48.5 0 28.8-12.9 48.8-40.9 48.8z"
                />
            </g>
            <path
                className="logo-wordmark-brand_svg__st3"
                d="M302.8 269L170.1 165.6c-2.2-1.7.1-5.1 2.5-3.7l51.8 29c23.5 13.2 53.2 5.5 67.4-17.3 15-24.1 7.1-55.8-17.5-70l-76-43.9c-2.4-1.4-.6-5.1 2-4l34.9 14c.1 0 .9.3 1.2.4 3.7 1.3 7.7 2 11.8 2 18.9 0 34.5-15 35.8-32.8C285.7 16.1 268.1 0 247 0H85.6C64.9 0 47.4 16.9 47.7 37.6c.1 11 5.1 20.8 12.8 27.5 5.8 5.1 25.4 20.7 34.7 28.2 1.7 1.3.7 4-1.4 4H61.5C27.5 97.5 0 125.1 0 159.1c0 17.2 7 32.7 18.4 44l187.1 177.8c13.2 12.3 31 19.9 50.5 19.9 41 0 74.2-33.2 74.2-74.2-.1-23.2-10.7-44-27.4-57.6z"
            />
        </g>
    </svg>
);

LogoWordmarkBrandSvg.displayName = 'LogoWordmarkBrandSvg';
export default LogoWordmarkBrandSvg;
