import de from 'date-fns/locale/de';
import enAU from 'date-fns/locale/en-AU';
import enGB from 'date-fns/locale/en-GB';
import enNZ from 'date-fns/locale/en-NZ';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import nlBE from 'date-fns/locale/nl-BE';
import pt from 'date-fns/locale/pt';
import ptBR from 'date-fns/locale/pt-BR';
import sv from 'date-fns/locale/sv';
import type { LocaleList } from './types';

const Locales: LocaleList = {
    de,
    deAT: de,
    deCH: de,
    deDE: de,
    enNZ,
    enAU: enAU,
    enIE: enGB,
    enSG: enGB,
    enHK: enGB,
    enDK: enGB,
    enFI: enGB,
    enGB: enGB,
    enCA: enUS,
    enUS: enUS,
    en: enUS,
    es,
    esAR: es,
    esCL: es,
    esCO: es,
    esPE: es,
    esES: es,
    esMX: es,
    fr,
    frBE: fr,
    frCH: fr,
    frFR: fr,
    frCA: fr,
    it,
    itIT: it,
    nl,
    nlBE: nlBE,
    nlNL: nl,
    ptBR: ptBR,
    ptPT: pt,
    pt,
    sv,
};

export default Locales;
