import difference from 'lodash/difference';

import { STYLE_BASIC, STYLE_STATIC } from './constants';

/**
 * Check if subset array is contained in superset array.
 *
 * @param  {array} subset   Array you want to know if it is completely included in another.
 * @param  {array} superset Array to compare and check for a full match of the subset.
 * @return {boolean}
 **/
export const arrayContains = (subset, superset) =>
    !!subset.length && difference(subset, superset).length === 0;

/**
 * Get the proper value given defaultValue, value, and the maxLength.
 *
 * If there's a value, we'll default to that and then remove the extra characters
 * to match the maxLength allowed.
 *
 * @param  {object}
 *              {string} value          The value assigned to the input.
 *              {string} defaultValue   The default value assigned to the input.
 *              {Int}    maxLength      The max length for the string.
 * @return {string}
 */
export const getValue = ({ value, defaultValue, maxLength }) => {
    let _value = value || defaultValue;

    if (maxLength) {
        _value = _value.substr(0, maxLength);
    }

    return _value;
};

/**
 * Decide if the input should be shown as the static variation.
 *
 * @param  {object}
 *              {string}  style      The style of the input.
 *              {string}  value      The value assigned to the input.
 *              {boolean} hasError   The input presents errors?.
 *              {boolean} isActive   The input is active at the moment?.
 * @return {boolean}
 */
export const isStatic = ({ style, value, hasError, isActive }) => {
    const _isStatic = style === STYLE_STATIC;
    const isNotBasic = style !== STYLE_BASIC;
    const hasContent = (value || hasError) && isNotBasic;
    const shouldShowAsStatic = _isStatic || hasContent || isActive;

    return shouldShowAsStatic;
};

/**
 * Returns the correct initial values for the multiselect input given:
 * certain default values, the possible options and a placeholder
 *
 * @param  {array}  defaultValues   Array of default values to be passed to the multiselect component.
 * @param  {array}  options         Array of options of the select component. Structure: {value: {string}, display: {string}}
 * @param  {string} placeholder     Placeholder text for the multiselect input. Will default to this if present and no default values are present
 * @return {array}
 */
export const getInitialValues = (defaultValues, options, placeholder) => {
    let initialValues;
    const optionsValues = options.map((option) => option.value);

    if (arrayContains(defaultValues, optionsValues)) {
        initialValues = defaultValues;
    } else if (placeholder) {
        initialValues = [];
    } else if (optionsValues.length) {
        initialValues = [optionsValues[0]];
    }

    return initialValues;
};
