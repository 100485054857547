import { UserShape } from '../types';
import { useIsBrowser } from './dom';

export const LOGGED_OUT_USER: UserShape = {
    isAuthenticated: false,
    publicId: undefined,
    canCreateEvents: false,
};

/**
 * Hook that provides a server-side rendering safe user object.
 *
 * Converts a user object to a logged-out version if not in the browser.
 */
export const useSSRSafeUser = (user?: UserShape) => {
    const isBrowser = useIsBrowser();
    return user && isBrowser ? user : LOGGED_OUT_USER;
};
