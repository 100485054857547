import { snakeToCamel } from '@eventbrite/string-utils';

export enum CaseOptions {
    camel = 'camel',
    dot = 'dot',
    snake = 'snake',
}

const flattenKey = (
    caseOption: CaseOptions,
    key: string,
    nestedKey: string,
) => {
    if (caseOption === CaseOptions.snake) {
        return `${key}_${nestedKey}`;
    }

    if (caseOption === CaseOptions.camel) {
        return snakeToCamel(`${key}_${nestedKey}`);
    }

    if (caseOption === CaseOptions.dot) {
        return `${key}.${nestedKey}`;
    }

    return '';
};

/**
 * Recursively transform nested objects into a single flat object with combined
 * keys. {root: {nested: 'value'}} becomes {rootNested: 'value'}
 */
export const flattenObject = <
    InputObject extends Record<string, unknown> | string | undefined | null,
>(
    obj: InputObject,
    options: { case: CaseOptions } = { case: CaseOptions.camel },
) => {
    if (obj === undefined) return undefined;
    if (obj === null) return null;
    if (typeof obj === 'string') return obj;

    const object: Record<string, unknown> = { ...obj };

    for (const key in object) {
        const originalValue: any = object[key];

        if (
            typeof originalValue !== 'object' ||
            originalValue === null ||
            !Object.prototype.hasOwnProperty.call(object, key)
        ) {
            continue;
        }

        // for multi-level flattening
        const flattenedValue = flattenObject(originalValue, options);

        for (const nestedKey in flattenedValue) {
            if (
                !Object.prototype.hasOwnProperty.call(flattenedValue, nestedKey)
            ) {
                continue;
            }

            object[flattenKey(options.case, key, nestedKey)] =
                flattenedValue[nestedKey];
        }

        delete object[key];
    }

    return object;
};
