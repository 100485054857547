import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React from 'react';
import {
    EventUrgencySignalsData,
    FormattedEvent,
    UrgencySignalsKeys,
} from '../../types/events';
import { getFormattedSalesStatus } from '../EventCardBadge';
import './EventCardUrgencySignals.scss';

export type EventCardUrgencySignalsProps = {
    urgencySignals?: EventUrgencySignalsData;
    className?: string;
};

export function EventCardUrgencySignals({
    urgencySignals,
    className,
}: EventCardUrgencySignalsProps) {
    if (!urgencySignals) {
        return null;
    }
    const urgencySignal = getActiveUrgencySignalData(urgencySignals);

    if (!urgencySignal) {
        return null;
    }

    const { className: containerClassName, label } = urgencySignal;
    return (
        <aside className={className}>
            <div
                className={classNames(
                    containerClassName,
                    'EventCardUrgencySignal',
                )}
            >
                <Typography
                    className="EventCardUrgencySignal__label"
                    variant="body-md-bold"
                >
                    {label}
                </Typography>
            </div>
        </aside>
    );
}

const urgencySignalsData = {
    [UrgencySignalsKeys.fewTickets]: {
        label: gettext('Almost full'),
        className: 'EventCardUrgencySignal--almost-full',
    },
    [UrgencySignalsKeys.salesEndSoon]: {
        label: gettext('Sales end soon'),
        className: 'EventCardUrgencySignal--sales-end-soon',
    },
    [UrgencySignalsKeys.earlyBird]: {
        label: gettext('Early bird'),
        className: 'EventCardUrgencySignal--early-access',
    },
    [UrgencySignalsKeys.popular]: {
        label: gettext('Popular'),
        className: 'EventCardUrgencySignal--popular',
    },
    [UrgencySignalsKeys.new]: {
        label: gettext('Just added'),
        className: 'EventCardUrgencySignal--just-added',
    },
    [UrgencySignalsKeys.goingFast]: {
        label: gettext('Going fast'),
        className: 'EventCardUrgencySignal--going-fast',
    },
};

const urgencySignalPriority = [
    UrgencySignalsKeys.goingFast,
    UrgencySignalsKeys.fewTickets,
    UrgencySignalsKeys.salesEndSoon,
    UrgencySignalsKeys.earlyBird,
    UrgencySignalsKeys.popular,
    UrgencySignalsKeys.new,
];

function getActiveUrgencySignalData(
    currentUrgencySignals: EventUrgencySignalsData,
) {
    const activeUrgencySignal = urgencySignalPriority.find(
        (urgencySignal) => currentUrgencySignals[urgencySignal],
    );
    return activeUrgencySignal
        ? urgencySignalsData[activeUrgencySignal]
        : undefined;
}

export function areUrgencySignalsActive(
    event: Pick<
        FormattedEvent,
        'eventSalesStatus' | 'urgencySignals' | 'repeatingInstanceCount'
    >,
) {
    const formattedSalesStatus = getFormattedSalesStatus(event);

    return !!event.urgencySignals && !formattedSalesStatus;
}

export function getActiveUrgencySignals(
    currentUrgencySignals: EventUrgencySignalsData | undefined,
): UrgencySignalsKeys[] {
    if (!currentUrgencySignals) {
        return [];
    }

    return urgencySignalPriority.filter(
        (urgencySignal) => currentUrgencySignals[urgencySignal],
    );
}
