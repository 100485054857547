export const isSSR = () => {
    return typeof window === 'undefined';
};

export function isMobile() {
    return !isSSR() && !!window.__SERVER_DATA__?.env?.isMobile;
}

// TODO: This does the same as the function in @eventbrite/real-user-monitoring
// But due to how our compiler works, it imports the whole package, adding to the bundle 30kb of code
// Once the bundler is sorted out this can be re-used from @eventbrite/real-user-monitoring
// For now, let's keep here to ensure minimal bundle size
export function addCustomRUMEvent<
    EventName = string,
    Payload = Record<string, unknown>,
>(name: EventName, data: Payload) {
    if (isSSR()) {
        return;
    }

    if (!window?.DD_RUM?.addAction || !window?.DD_RUM?.onReady) {
        return;
    }

    window.DD_RUM.onReady(() => {
        window.DD_RUM.addAction(name, data);
    });

    return true;
}
