import { IconButton } from '@eventbrite/eds-icon-button';
import { CrossChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { BarButton } from './BarButton';
import { ModalType } from './constants';
import './modal.scss';

export interface CloseButtonProps {
    customClassName?: string;
    customIcon?: React.ReactNode;
    hasBackgroundColor?: boolean;
    onClose?: () => void;
    type?: ModalType;
}

const CloseButton: FunctionComponent<CloseButtonProps> = ({
    customClassName,
    customIcon,
    hasBackgroundColor = false,
    onClose,
    type,
}) => {
    if (!onClose) {
        return null;
    }

    const IconType = customIcon ?? CrossChunky;

    const isTakeoverModal = type === ModalType.takeover;
    const takeoverButton = isTakeoverModal ? (
        <div className="eds-show-down-mn">
            <BarButton onClick={onClose} />
        </div>
    ) : null;

    const wrapperClassNames = classNames(
        'eds-modal__close-button',
        {
            'eds-show-up-md': !!takeoverButton,
        },
        customClassName,
    );

    return (
        <>
            {takeoverButton}
            <div className={wrapperClassNames}>
                <IconButton
                    data-automation="modal-close-button"
                    data-spec="close-button"
                    onClick={onClose}
                    iconType={<IconType aria-hidden="true" />}
                    title={gettext('Close')}
                    isOverImage={hasBackgroundColor}
                />
            </div>
        </>
    );
};

export default CloseButton;
