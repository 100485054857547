import { END_DATE, EVENT_PAGE_PARAM, START_DATE } from './search';

/****************************** URLS ********************************/

export const SEARCH_RELEVANT_QUERY_PARAMS = [
    START_DATE,
    END_DATE,
    EVENT_PAGE_PARAM,
    'src',
    'bbox',
    'subcategories',
    'cur',
    'lang',
    'hash',
    'followed_orgs',
    'organization',
];
