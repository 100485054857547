import { camelToSnake } from '@eventbrite/string-utils';
import mapKeys from 'lodash/mapKeys';

/**
 * Uses our specific camelToSnake instead of _.snakeCase
 * this is because lodash makes event.destination => event_destination
 */
export const keysCamelToSnake = <InputObject extends object>(
    obj: InputObject,
) => mapKeys(obj, (_value, key) => camelToSnake(key));
