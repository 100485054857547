import {
    APPEARANCE_EVENTBRITE_FORMAT,
    ATTRACTION_EVENTBRITE_FORMAT,
    AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    BUSINESS_EVENTBRITE_CATEGORY,
    CHARITY_EVENTBRITE_CATEGORY,
    CLASS_EVENTBRITE_FORMAT,
    COMMUNITY_EVENTBRITE_CATEGORY,
    CONFERENCE_EVENTBRITE_FORMAT,
    CONVENTION_EVENTBRITE_FORMAT,
    EVENTBRITE_CATEGORY_MAPPING,
    EVENTBRITE_FORMAT_MAPPING,
    EXPO_EVENTBRITE_FORMAT,
    FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    FASHION_EVENTBRITE_CATEGORY,
    FESTIVAL_EVENTBRITE_FORMAT,
    FILM_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    GALA_EVENTBRITE_FORMAT,
    GAME_EVENTBRITE_FORMAT,
    HEALTH_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    NETWORKING_EVENTBRITE_FORMAT,
    OTHER_EVENTBRITE_CATEGORY,
    PARTY_EVENTBRITE_FORMAT,
    PERFORMANCE_EVENTBRITE_FORMAT,
    PERFORMING_EVENTBRITE_CATEGORY,
    POLITICS_EVENTBRITE_CATEGORY,
    RACE_EVENTBRITE_FORMAT,
    RALLY_EVENTBRITE_FORMAT,
    RELIGION_EVENTBRITE_CATEGORY,
    RETREAT_EVENTBRITE_FORMAT,
    SCHOOL_EVENTBRITE_CATEGORY,
    SCIENCE_EVENTBRITE_CATEGORY,
    SCREENING_EVENTBRITE_FORMAT,
    SEASONAL_EVENTBRITE_CATEGORY,
    SEMINAR_EVENTBRITE_FORMAT,
    SPORTS_EVENTBRITE_CATEGORY,
    TOURNAMENT_EVENTBRITE_FORMAT,
    TOUR_EVENTBRITE_FORMAT,
    TRAVEL_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import {
    CUSTOM_DATE,
    DATES_MAPPING,
    NEXT_MONTH,
    NEXT_WEEK,
    THIS_MONTH,
    THIS_WEEK,
    THIS_WEEKEND,
    TODAY,
    TOMORROW,
} from '@eventbrite/discover-utils';
import {
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import {
    OrganicEventSearchApiResponse,
    OrganicEventSearchFiltersApiPayload,
} from '../api';

/****************************** SEARCH CONSTANTS ********************************/

export const SEARCH_CRITERIA_TYPE_MAP = [
    'event_search',
    'article_search',
    'profile_search',
];
export const SEARCH_TYPE_MAP = ['events', 'articles', 'profiles'];

export const START_DATE = 'start_date';
export const END_DATE = 'end_date';
export const EVENT_PAGE_PARAM = 'page';

export const SEARCH_RADIUS_DEFAULTS = {
    radius: '150km',
    offset: '15km',
    scale: '15km',
};

export const DESTINATION_SEARCH_SLA_MILLISECONDS = 4000; // 2x the P95 of the destination/search/ endpoint

// We want a different order for search with most popular filters at the top
export const EVENTBRITE_CATEGORY_MAPPING_SEARCH = {
    [BUSINESS_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[BUSINESS_EVENTBRITE_CATEGORY],
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
    [HEALTH_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[HEALTH_EVENTBRITE_CATEGORY],
    [MUSIC_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[MUSIC_EVENTBRITE_CATEGORY],
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[AUTO_BOAT_AIR_EVENTBRITE_CATEGORY],
    [CHARITY_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[CHARITY_EVENTBRITE_CATEGORY],
    [COMMUNITY_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[COMMUNITY_EVENTBRITE_CATEGORY],
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[FAMILY_EDUCATION_EVENTBRITE_CATEGORY],
    [FASHION_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[FASHION_EVENTBRITE_CATEGORY],
    [FILM_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[FILM_EVENTBRITE_CATEGORY],
    [HOBBIES_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[HOBBIES_EVENTBRITE_CATEGORY],
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[HOME_LIFESTYLE_EVENTBRITE_CATEGORY],
    [PERFORMING_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[PERFORMING_EVENTBRITE_CATEGORY],
    [POLITICS_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[POLITICS_EVENTBRITE_CATEGORY],
    [RELIGION_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[RELIGION_EVENTBRITE_CATEGORY],
    [SCHOOL_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[SCHOOL_EVENTBRITE_CATEGORY],
    [SCIENCE_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[SCIENCE_EVENTBRITE_CATEGORY],
    [SEASONAL_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[SEASONAL_EVENTBRITE_CATEGORY],
    [SPORTS_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[SPORTS_EVENTBRITE_CATEGORY],
    [TRAVEL_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[TRAVEL_EVENTBRITE_CATEGORY],
    [OTHER_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[OTHER_EVENTBRITE_CATEGORY],
};

// We want a different order for search with most popular filters at the top
export const DATES_MAPPING_SEARCH = {
    [TODAY]: DATES_MAPPING[TODAY],
    [TOMORROW]: DATES_MAPPING[TOMORROW],
    [THIS_WEEKEND]: DATES_MAPPING[THIS_WEEKEND],
    [CUSTOM_DATE]: DATES_MAPPING[CUSTOM_DATE],
    [THIS_WEEK]: DATES_MAPPING[THIS_WEEK],
    [NEXT_WEEK]: DATES_MAPPING[NEXT_WEEK],
    [THIS_MONTH]: DATES_MAPPING[THIS_MONTH],
    [NEXT_MONTH]: DATES_MAPPING[NEXT_MONTH],
};

// We want a different order for search with most popular filters at the top
export const EVENTBRITE_FORMAT_MAPPING_SEARCH = {
    [CLASS_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[CLASS_EVENTBRITE_FORMAT],
    [CONFERENCE_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[CONFERENCE_EVENTBRITE_FORMAT],
    [FESTIVAL_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[FESTIVAL_EVENTBRITE_FORMAT],
    [PARTY_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[PARTY_EVENTBRITE_FORMAT],
    [APPEARANCE_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[APPEARANCE_EVENTBRITE_FORMAT],
    [ATTRACTION_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[ATTRACTION_EVENTBRITE_FORMAT],
    [CONVENTION_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[CONVENTION_EVENTBRITE_FORMAT],
    [EXPO_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[EXPO_EVENTBRITE_FORMAT],
    [GALA_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[GALA_EVENTBRITE_FORMAT],
    [GAME_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[GAME_EVENTBRITE_FORMAT],
    [NETWORKING_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[NETWORKING_EVENTBRITE_FORMAT],
    [PERFORMANCE_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[PERFORMANCE_EVENTBRITE_FORMAT],
    [RACE_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[RACE_EVENTBRITE_FORMAT],
    [RALLY_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[RALLY_EVENTBRITE_FORMAT],
    [RETREAT_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[RETREAT_EVENTBRITE_FORMAT],
    [SCREENING_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[SCREENING_EVENTBRITE_FORMAT],
    [SEMINAR_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[SEMINAR_EVENTBRITE_FORMAT],
    [TOURNAMENT_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[TOURNAMENT_EVENTBRITE_FORMAT],
    [TOUR_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[TOUR_EVENTBRITE_FORMAT],
};

interface SearchResponseData {
    events: FormattedEvent[];
    eventSearch: OrganicEventSearchFiltersApiPayload;
}

export const transformResponse = (
    data: OrganicEventSearchApiResponse,
): SearchResponseData => {
    return {
        events: data.events.results.map(transformDestinationEvent),
        eventSearch: data.event_search as any,
    };
};
