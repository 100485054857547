import { SIZES_JSON } from '@eventbrite/eds-iconography';
import mapValues from 'lodash/mapValues';

export enum AvatarSize {
    xxsmall = 'xxsmall',
    xsmall = 'xsmall',
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export const SIZES = [
    AvatarSize.xxsmall,
    AvatarSize.xsmall,
    AvatarSize.small,
    AvatarSize.medium,
    AvatarSize.large,
];

const AVATAR_SIZE_MAP = SIZES.reduce(
    (memo, size) => ({
        ...memo,
        [size]: SIZES_JSON[`avatar-${size}`],
    }),
    {} as Record<AvatarSize, string>,
);

export const AVATAR_SIZES = mapValues(AVATAR_SIZE_MAP, (pixelSize) =>
    parseInt(pixelSize, 10),
);
