import Cookies from 'cookies-js';
import { HAS_DOCUMENT } from '@eventbrite/eds-utils';
import { LOCATION_COOKIE } from '../constants';

// See https://docs.google.com/document/d/1Vg-kyxM34gw71vw_5dLcfzFRhzM3scQ3FKtYUlWXLSA/edit?usp=sharing
export const setLocationData = (locationData) => {
    if (HAS_DOCUMENT) {
        Cookies.set(LOCATION_COOKIE, JSON.stringify(locationData), {
            path: '/',
            secure: true,
        });
    }
};

// See https://docs.google.com/document/d/1Vg-kyxM34gw71vw_5dLcfzFRhzM3scQ3FKtYUlWXLSA/edit?usp=sharing
export const getLocationData = () => {
    if (HAS_DOCUMENT) {
        const locationData = Cookies.get(LOCATION_COOKIE);

        if (locationData) {
            try {
                return JSON.parse(locationData);
            } catch (e) {
                return null;
            }
        }
    }
    return null;
};
