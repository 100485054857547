import { HAS_WINDOW, getCurrentEnv } from '@eventbrite/feature-detection';
const FALLBACK_QA_KEY = 'AIzaSyAudqgjxOqK9Ek2UL3njdDHWpQOqydiWCI';
const GOOGLE_MAPS_KEY =
    typeof window !== 'undefined'
        ? window?.__SERVER_DATA__?.env?.googleMapsQAKey || FALLBACK_QA_KEY
        : FALLBACK_QA_KEY;
const GOOGLE_MAPS_PROD_KEY = 'AIzaSyC42yQSNEM_G4-F81tgVzgoeCmhJClADHc';

const LOADED = { current: false };

export const isGoogleMapsLoaded = () => {
    if (!HAS_WINDOW) {
        return false;
    }

    if (LOADED.current) {
        return true;
    }

    //Check all script tags on the document for any
    //that match the src of googlemaps api
    const isLoaded = [...document.getElementsByTagName('script')].some(
        (script) => {
            return (
                script.src.includes('maps.googleapis.com/maps/api/js') ||
                script.src.includes('maps.google.com/maps/api/js')
            );
        },
    );

    if (isLoaded && !LOADED.current) {
        LOADED.current = true;
    }

    return isLoaded;
};

export const loadGoogleMaps = ({ onSuccess, onFailure }) => {
    if (!isGoogleMapsLoaded()) {
        try {
            const loadjs = require('loadjs');
            const env = getCurrentEnv();
            const key = env === 'prod' ? GOOGLE_MAPS_PROD_KEY : GOOGLE_MAPS_KEY;

            loadjs(
                `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=Function.prototype`,
                'googleMaps',
                {
                    success: () => {
                        onSuccess?.();
                        LOADED.current = true;
                    },
                    error: () => {
                        onFailure?.();
                    },
                    numRetries: 1,
                },
            );
        } catch (e) {
            onFailure?.();
        }
    }
};
