import { without } from 'lodash';

import {
    SET_EVENT_SAVED_STATE,
    UPDATE_COLLECTION_EVENTS,
    SET_EVENTS,
} from './actionTypes';

export default (state = {}, { type, payload }) => {
    let nextState = state;

    if (type === SET_EVENTS) {
        nextState = {
            ...nextState,
            ...payload.events,
        };
    } else if (type === SET_EVENT_SAVED_STATE) {
        nextState = {
            ...nextState,
            [payload.eventId]: {
                ...nextState[payload.eventId],
                saves: {
                    savedByYou: payload.saveState,
                },
            },
        };
    } else if (type === UPDATE_COLLECTION_EVENTS) {
        nextState = {
            ...nextState,
            [payload.eventId]: {
                ...nextState[payload.eventId],
                eventCollections: payload.shouldAdd
                    ? [
                          ...nextState[payload.eventId].eventCollections,
                          payload.collectionId,
                      ]
                    : without(
                          nextState[payload.eventId].eventCollections,
                          payload.collectionId,
                      ),
            },
        };
    }

    return nextState;
};
