import { EVENT_INTERACTION } from '@eventbrite/event-renderer';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { DatadogInteractionsTracker } from './datadogInteractionsTracker';
import { startEventCardTracking } from './startEventCardTracking';
import {
    EventInteraction,
    EventInteractionContext,
    GetTrackingInformation,
    InteractionCustomEvent,
    PLACE_EVENT_INTERACTION,
} from './types';

type EventHandlerCallback = (
    event: string,
    callback: (e: InteractionCustomEvent) => void,
) => void;

export interface TrackableElement {
    addEventListener: EventHandlerCallback;
    removeEventListener: EventHandlerCallback;
    dispatchEvent: (e: CustomEvent) => void;
}

export function useTracking<T extends TrackableElement>(
    getTrackingInformation: GetTrackingInformation<any>,
) {
    useMemo(() => startEventCardTracking(), []);
    const datadogTracker = useMemo(() => new DatadogInteractionsTracker(), []);

    const logInteractions = useCallback(
        (e: InteractionCustomEvent) => {
            e.preventDefault();

            const detail: EventInteraction<EventInteractionContext> = {
                ...e.detail,
                ...getTrackingInformation(e.detail),
            };

            const placeEventInteractionEvent = new CustomEvent(
                PLACE_EVENT_INTERACTION,
                { detail, bubbles: true },
            );
            e.currentTarget?.dispatchEvent(placeEventInteractionEvent);
            detail.event?.isPromoted &&
                datadogTracker.trackEventCardInteraction(detail);
        },
        [getTrackingInformation, datadogTracker],
    );

    const ref = useRef<T>(null);

    useEffect(() => {
        const container = ref.current;
        container?.addEventListener(EVENT_INTERACTION, logInteractions);
        return () =>
            container?.removeEventListener(EVENT_INTERACTION, logInteractions);
    }, [ref, logInteractions]);

    return { ref };
}
