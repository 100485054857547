import { HAS_WINDOW } from '@eventbrite/feature-detection';

/**
 * Set window location
 * @param {string} url The url path of the page to be navigated
 */
export const setWindowLocation = (url) => {
    if (HAS_WINDOW) {
        window.location.assign(url);
    }
};

/**
 * Reload the page
 *
 * @param forcedReload - if false or unspecified, browser may use its cache. If true, page will always
 * be reloaded from the server.
 */
export const reloadPage = (forcedReload) => {
    if (HAS_WINDOW) {
        window.location.reload(forcedReload);
    }
};

/**
 * Open url in a new tab
 *
 * @param url - the url path of the page to be navigated
 */
export const openInNewTab = (url) => {
    if (HAS_WINDOW) {
        window.open(url, '_blank');
    }
};

// TODO: make this more explicit when other members refactored
export * from './form';
export * from './fetch';
export * from './upload';
export * from './image-upload';
