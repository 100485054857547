import React, { useContext, useEffect, useState } from 'react';
import { OverlayProvider, OverlayVisibilityContext } from '../../../context';
import getMountingPoint from '../../../utils/getMountingPoint';
import './DropdownOverlay.scss';
export interface DropdownOverlayProps {
    /**
     * Boolean controlling the visibility
     * status of the dropdown. The consuming
     * application will need to handle managing
     * this state.
     */
    isVisible: boolean;
    /**
     * Optional callback triggered
     * when the use clicks outside the search bar
     */
    onClose?: () => void;
    /**
     * Location object to be used for the search dropdown
     */
    domNodeId?: string;
    /**
     * DOM Element to mount the modal into.
     *
     * If passed in, will take precedence over
     * the domNodeId argument and render into the
     * element directly.
     *
     * Since it is an overlay, it is recommended
     * to use something close to the root of the
     * DOM tree
     */
    domNode?: Element;
}

interface OverlayProps {
    domNodeId?: string;
    domNode?: Element;
}

interface LazyLoadedLibs {
    ReactDOM: null | any;
}

const RenderViaPortal: React.FunctionComponent<OverlayProps> = (props) => {
    const { isVisible, setVisible } = useContext(OverlayVisibilityContext);
    const [mountPoint, setMountPoint] = useState({});
    const [{ ReactDOM }, setAsyncLibs] = useState<Partial<LazyLoadedLibs>>({
        ReactDOM: null,
    });

    useEffect(() => {
        if (isVisible && !ReactDOM) {
            const reactDomPromise = import('react-dom');
            Promise.all([reactDomPromise]).then((values) => {
                setAsyncLibs({
                    ReactDOM: values[0].default,
                });
            });
        }
    }, [isVisible, ReactDOM]);

    useEffect(() => {
        setMountPoint(getMountingPoint(props.domNodeId, props.domNode));
    }, [props.domNodeId, props.domNode]);

    if (ReactDOM === null || !mountPoint || !isVisible) {
        return null;
    }

    return ReactDOM.createPortal(
        <div
            className="search-dropdown-overlay"
            id="search-dropdown-overlay"
            data-testid="search-dropdown-overlay"
            onClick={() => setVisible && setVisible(false)}
        />,
        mountPoint as Element,
    );
};

export const DropdownOverlay: React.FunctionComponent<DropdownOverlayProps> = ({
    isVisible,
    onClose,
    domNode,
    domNodeId,
}) => {
    return (
        <OverlayProvider isVisible={isVisible} onClose={onClose}>
            <RenderViaPortal domNodeId={domNodeId} domNode={domNode} />
        </OverlayProvider>
    );
};
