import { getWindowObject } from '@eventbrite/feature-detection';
import { getCanonicalSearchUrl } from '@eventbrite/search-utils';
import { CamelCaseLocation, SearchParamsForUrl } from '../types';

export const buildBBox = ({
    latitude,
    longitude,
}: {
    latitude: number;
    longitude: number;
}) => ({
    sw: { lng: longitude - 0.4, lat: latitude - 0.3 },
    ne: { lng: longitude + 0.4, lat: latitude + 0.3 },
});

/**
 * Constructs an event search URL based on the search parameters and location
 */
export const constructSearchUrl = ({
    search,
    location,
}: {
    search: SearchParamsForUrl;
    location?: CamelCaseLocation;
}): string => {
    const slug = location?.slug;
    const params = {
        slug,
        ...search,
    };
    let url;

    // If we have a location but no placeId, we need to use the bbox
    if (!location?.placeId && location?.longitude && location?.latitude) {
        url = getCanonicalSearchUrl({
            ...params,
            slug: 'united-states',
            bbox: buildBBox({
                latitude: location.latitude,
                longitude: location.longitude,
            }),
        });
    } else {
        url = getCanonicalSearchUrl(params);
    }

    return url;
};

export const getUrlPathFirstDirectory = () => {
    const location = getWindowObject('location');

    if (location) {
        const [, firstDir] = location.pathname.split('/');

        return `/${firstDir}`;
    }
};
