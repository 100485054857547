import {
    ProgressIndicator,
    SHAPE_CIRCULAR,
    SIZE_LARGE_CHUNKY,
    SIZE_NAMES,
    STYLE_DARK,
    STYLE_GRADIENT,
    STYLE_LIGHT,
    STYLE_PROGRESS,
    TYPE_INDETERMINATE,
} from '@eventbrite/eds-progress-indicator';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './loadingOverlay.scss';
import classNames from 'classnames';

export default class LoadingOverlay extends PureComponent {
    static propTypes = {
        /**
         * Size of the loading indicator
         */
        size: PropTypes.oneOf(SIZE_NAMES),

        /**
         * Whether or not the loading overlay should be shown
         */
        isShown: PropTypes.bool,

        /**
         * Style of the loader (dark | light | gradient | progress)
         */
        style: PropTypes.oneOf([
            STYLE_DARK,
            STYLE_LIGHT,
            STYLE_GRADIENT,
            STYLE_PROGRESS,
        ]),

        isAppRebrandActive: PropTypes.bool,
    };

    static defaultProps = {
        size: SIZE_LARGE_CHUNKY,
        isShown: false,
    };

    render() {
        const { size, isShown, style, isAppRebrandActive } = this.props;

        if (!isShown) {
            return null;
        }

        return (
            <div
                className={classNames('eds-loading-overlay eds-align--center', {
                    'eds-loading-overlay--rebranding-active':
                        isAppRebrandActive,
                })}
            >
                <div className="eds-loading-overlay__indicator">
                    <ProgressIndicator
                        size={size}
                        shape={SHAPE_CIRCULAR}
                        type={TYPE_INDETERMINATE}
                        style={style || STYLE_GRADIENT}
                    />
                </div>
            </div>
        );
    }
}
