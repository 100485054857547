import { SplitFactory } from '@splitsoftware/splitio';

interface SplitIOClient extends SplitIO.IClient {
    isSDKReady: boolean;
}

export class SplitIOFacade {
    private static getClient(
        apiKey: string,
        workspace: string,
        key?: string,
    ): SplitIOClient {
        if (!window.splitIOClients) {
            window.splitIOClients = new Map<string, SplitIOClient>();
        }
        let client = window.splitIOClients.get(workspace);

        if (!client) {
            client = SplitFactory({
                core: {
                    authorizationKey: apiKey,
                    key:
                        key ||
                        window.__SERVER_DATA__?.guest_id ||
                        window.__SERVER_DATA__?.user.guestId ||
                        window.__SERVER_DATA__?.user.publicId,
                },
                startup: {
                    readyTimeout: 1.5,
                },
            }).client();
            client.isSDKReady = false;
            window.splitIOClients.set(workspace, client);
        }

        return client;
    }

    static async getIsSDKReady(
        apiKey: string,
        workspace: string,
        key?: string,
    ): Promise<boolean> {
        const client = this.getClient(apiKey, workspace, key);

        await new Promise<void>((resolve) => {
            if (client.isSDKReady) {
                resolve();
            }

            client.on(client.Event.SDK_READY, () => {
                client.isSDKReady = true;
                resolve();
            });
        });

        return client.isSDKReady;
    }

    static async isFeatureEnabled(
        apiKey: string,
        workspace: string,
        featureName: string,
        splitAttributes: any,
        key?: string,
    ): Promise<boolean | null> {
        const client = this.getClient(apiKey, workspace, key);
        const isSDKReady = await this.getIsSDKReady(apiKey, workspace, key);

        if (!isSDKReady) {
            return null;
        }

        return client.getTreatment(featureName, splitAttributes) === 'on';
    }
}
