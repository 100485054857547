import PropTypes from 'prop-types';

export const LOCALES_ARRAY_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        locale: PropTypes.string.isRequired,
        locale_name: PropTypes.string.isRequired,
    }),
);

export const LOCALE_INFO_SHAPE_PROP_TYPE = PropTypes.shape({
    tld: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    locale_name: PropTypes.string.isRequired,
    contact_us_path: PropTypes.string.isRequired,
    contact_us_url: PropTypes.string.isRequired,
    sales_contact_landing_page: PropTypes.string.isRequired,
    sales_contact_link_text: PropTypes.string.isRequired,
    twitter_page: PropTypes.string.isRequired,
    facebook_page: PropTypes.string.isRequired,
    linked_in_page: PropTypes.string.isRequired,
    instagram_page: PropTypes.string.isRequired,
    blog_url: PropTypes.string.isRequired,
    help_center_language: PropTypes.string.isRequired,
});

export const LINKS_DATA_SHAPE_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        pk: PropTypes.number.isRequired,
        link: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }),
);

export const COUNTRIES_ARRAY_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        locale: PropTypes.string.isRequired,
        tld: PropTypes.string.isRequired,
        locale_name: PropTypes.string.isRequired,
    }),
);

export const HELP_LINKS_PROP_TYPE = PropTypes.shape({
    home: PropTypes.string.isRequired,
    impressum: PropTypes.string.isRequired,

    suggested: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            gaLabel: PropTypes.string,
        }),
    ).isRequired,
});

export const PRIVACY_REGIME_CPRA = 'CPRA';
export const PRIVACY_SIGNAL_GPC = 'GPC';
