import { Icon } from '@eventbrite/eds-icon';
import classNames from 'classnames';
import React from 'react';
import './avatar.scss';
import { AvatarSize, AVATAR_SIZES } from './constants';
import {
    AvatarProps,
    MainContentProps,
    MainContentProps_Icon,
    MainContentProps_Image,
    MainContentProps_Initials,
} from './interfaces';
import { calculateTextProperties, generateInitials } from './util';
import { Image } from './_internals/Image';
import { Initials } from './_internals/Initials';

const MainContent: React.FunctionComponent<MainContentProps> = (props) => {
    // Prioritize for images
    if ('imageUrl' in props && props.imageUrl) {
        const typedProps = props as MainContentProps_Image;
        return (
            <Image
                imageUrl={typedProps.imageUrl}
                size={typedProps.numericSize}
            />
        );
    }

    // Use icon if no image is available. We need foreignObject to insert HTML into SVG
    if ('iconType' in props && props.iconType) {
        const typedProps = props as MainContentProps_Icon;
        return (
            <foreignObject
                width={typedProps.numericSize}
                height={typedProps.numericSize}
            >
                <div className="eds-avatar__icon-container eds-align--center">
                    <Icon
                        type={typedProps.iconType}
                        isBlockLevel={true}
                        size={typedProps.size}
                        color={typedProps.iconColor}
                    />
                </div>
            </foreignObject>
        );
    }

    // Use text/initials if no image or icon is available
    if ('text' in props && props.text) {
        const typedProps = props as MainContentProps_Initials;
        return (
            <Initials
                x={typedProps.x}
                y={typedProps.y}
                fontSize={typedProps.fontSize}
                textColor={typedProps.textColor}
            >
                {generateInitials(typedProps.text, typedProps.maxChars)}
            </Initials>
        );
    }

    return null;
};

const Avatar: React.FunctionComponent<AvatarProps> = ({
    onClick,
    onHover,
    backgroundColor,
    size = AvatarSize.medium,
    maxChars = 2,
    ...additionalProps
}) => {
    // const { size, backgroundColor, ...additionalProps } = this.props;
    // translate semantic size to numeric size
    const numericSize = AVATAR_SIZES[size];
    const textProperties = calculateTextProperties(numericSize);
    // Add border to avatars that use an image, have no background colour set, or have white background colour
    // See JIRA EB-83954
    const hasBorder =
        ('imageUrl' in additionalProps && additionalProps.imageUrl) ||
        backgroundColor === 'white' ||
        !backgroundColor;
    const backgroundClassName = classNames('eds-avatar__background', {
        [`eds-bg-color--${backgroundColor}`]: backgroundColor,
        'eds-avatar__background--has-border': hasBorder,
    });
    const mainContentProps = {
        ...additionalProps,
        ...textProperties,
        size,
        numericSize,
        maxChars,
        onClick,
        onHover,
    };

    const handleMouseEnter = () => {
        if (onHover) {
            onHover(true);
        }
    };

    const handleMouseLeave = () => {
        if (onHover) {
            onHover(false);
        }
    };

    return (
        <svg
            className={backgroundClassName}
            height={numericSize}
            width={numericSize}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-spec="spec-avatar"
        >
            <MainContent {...mainContentProps} />
        </svg>
    );
};

export default Avatar;
