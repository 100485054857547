import * as React from 'react';

const FlagChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="flag-chunky_svg__eds-icon--flag-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="flag-chunky_svg__eds-icon--flag-chunky_base"
            d="M6.2 19H5v1h3.5v-1H7.3v-5.8h5.9V5h-7z"
        />
        <path
            id="flag-chunky_svg__eds-icon--flag-chunky_corner"
            d="M16.7 10.2l2.1-4h-4.5v8.1H19z"
        />
    </svg>
);

FlagChunkySvg.displayName = 'FlagChunkySvg';
export default FlagChunkySvg;
