import * as React from 'react';

const ChevronLeftChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="chevron-left-chunky_svg__eds-icon--chevron-left-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="chevron-left-chunky_svg__eds-icon--chevron-left-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8 7l-5 5 5 5 1.4-1.4-3.6-3.6 3.6-3.6z"
        />
    </svg>
);

ChevronLeftChunkySvg.displayName = 'ChevronLeftChunkySvg';
export default ChevronLeftChunkySvg;
