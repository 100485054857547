import has from 'lodash/has';
import isUndefined from 'lodash/isUndefined';
import reject from 'lodash/reject';
import {
    MOVE_TO_NEXT,
    MOVE_TO_PREV,
    MOVE_TO_FIRST,
    MOVE_TO_LAST,
    SHOW_CHILD,
} from './constants';

const DIRECTIONS_WITH_WRAP = {
    [MOVE_TO_PREV]: (maxPosition, focusPosition) =>
        focusPosition <= 0 ? maxPosition : focusPosition - 1,
    [MOVE_TO_NEXT]: (maxPosition, focusPosition) =>
        focusPosition >= maxPosition ? 0 : focusPosition + 1,
    [MOVE_TO_FIRST]: () => 0,
    [MOVE_TO_LAST]: (maxPosition) => maxPosition,
};

const DIRECTIONS_NO_WRAP = {
    [MOVE_TO_PREV]: (maxPosition, focusPosition) =>
        focusPosition <= 0 ? -1 : focusPosition - 1,
    [MOVE_TO_NEXT]: (maxPosition, focusPosition) =>
        focusPosition >= maxPosition ? maxPosition : focusPosition + 1,
    [MOVE_TO_FIRST]: () => 0,
    [MOVE_TO_LAST]: (maxPosition) => maxPosition,
};

const calcNextPosition = (
    itemList,
    focusPosition,
    preventFocusWrap,
    direction,
) => {
    let nextPosition = focusPosition;
    const maxPosition = itemList.length - 1;

    const directions = preventFocusWrap
        ? DIRECTIONS_NO_WRAP
        : DIRECTIONS_WITH_WRAP;

    if (has(directions, direction)) {
        nextPosition = directions[direction](maxPosition, focusPosition);
    }

    return nextPosition;
};

const focusPositionHelper = (
    itemList,
    focusPosition,
    preventFocusWrap,
    direction,
) => {
    const nextPosition = calcNextPosition(
        itemList,
        focusPosition,
        preventFocusWrap,
        direction,
    );

    return { focusPosition: nextPosition };
};

/**
 * focusNewPosition 1) prevents default for keyboard events occurred in order for
 * focus to be moved to the next desird position (e.g. 'down' arrow will not
 * move the page downward at the same time as moving focus to the next item within
 * a menu) and 2) returns a new state object with focusPosition set to the new
 * desired position. It Relies on DIRECTIONS, calcMaxPosition, calcNextPosition,
 * and focusPositionHelper, declared above.
 *
 * @summary Function with e.preventDefault() & returns new state obj {focusPosition}
 * @param {Array} itemList Array of strings
 * @param {Number} focusPosition
 * @param {string} direction
 * @param {Object} keyboardEvent
 * @returns {Object} State object with new focusPosition
 */
export const focusNewPosition = (
    itemList,
    focusPosition,
    keyboardEvent,
    preventFocusWrap = true,
    direction = 'none',
) => {
    keyboardEvent.preventDefault();
    return focusPositionHelper(
        itemList,
        focusPosition,
        preventFocusWrap,
        direction,
    );
};

export const isToggledHelper = (
    parentFocusPosition,
    childFocusPosition,
    parentKeyboardAction = 'none',
) => {
    // Condition when we move from parent container to child container
    const isToggledIn =
        parentFocusPosition === 0 &&
        childFocusPosition === -1 &&
        parentKeyboardAction === SHOW_CHILD;
    // // Condition when we move up from out of the TextList to move to & focus on the parent
    const isToggledOut = parentFocusPosition === -1 && childFocusPosition >= 0;

    return isToggledIn || isToggledOut;
};

export const filterNonEmptyItems = (items) =>
    reject(
        items,
        ({ content, value }) => isUndefined(content) && isUndefined(value),
    );
