import some from 'lodash/some';
import { HAS_WINDOW } from '@eventbrite/feature-detection';

const ANIMATION_PROPERTIES = [
    'animationName',
    'webkitAnimationName',
    'mozAnimationName',
    'oAnimationName',
];

export const HAS_DOCUMENT = typeof document !== 'undefined';

/**
 * Determines whether the browser has support for window.matchMedia
 * This requires a window.
 * @returns {boolean}
 */
export const HAS_MATCHMEDIA =
    HAS_WINDOW && typeof window.matchMedia !== 'undefined';

/**
 * Determines whether the browser has support for css animations.
 * This requires a DOM.
 * @returns {boolean}
 */
export const hasAnimationSupport = () => {
    if (!HAS_DOCUMENT) {
        return false;
    }

    const div = document.createElement('div');

    return some(ANIMATION_PROPERTIES, (prop) => div.style[prop] !== undefined);
};

export const HAS_FILE_READER =
    HAS_WINDOW &&
    typeof window.FileReader !== 'undefined' &&
    typeof window.FileReader.prototype.readAsDataURL !== 'undefined';

/**
 * Determines whether the browser has support for dragging and dropping.
 * This does not necessarily imply support for dragging files, since
 * elements can be drag/dropped as well.
 * @returns {boolean}
 */
export const hasDragAndDrop = () => {
    if (!HAS_DOCUMENT) {
        return false;
    }

    // Methodology taken from Modernizr
    const div = document.createElement('div');

    return (
        div && ('draggable' in div || ('ondragstart' in div && 'ondrop' in div))
    );
};

/**
 * Determines whether the browser has support for dragging and dropping files.
 * @returns {boolean}
 */
export const hasDragAndDropFile = () =>
    HAS_WINDOW && hasDragAndDrop() && HAS_FILE_READER;

/**
 * Common functions for getting view port size as 'Small/Medium/Large'
 * TRY TO AVOID DOING THIS IN JAVASCRIPT, MOST OF THE TIME CSS CLASSES WILL
 * PROVIDE SOLUTION. USE THIS ONLY IF ITS A MUST.
 *
 */

export const getViewportClass = () => {
    let viewportWidth;

    try {
        viewportWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
    } catch (err) {
        return 'NA';
    }

    if (!viewportWidth) {
        return 'NA';
    }
    if (viewportWidth >= 800) {
        return 'large';
    }
    if (viewportWidth >= 480) {
        return 'medium';
    }

    return 'small';
};

/**
 * Determines if client suppoerts the native date attribute for input elements.
 */
export const hasNativeDateControl = () => {
    if (!HAS_DOCUMENT) {
        return false;
    }

    const input = document.createElement('input');
    const notADateValue = 'not-a-date';

    input.setAttribute('type', 'date');
    input.setAttribute('value', notADateValue);

    return input.value !== notADateValue;
};

/**
 * Determines if client listens to touch events
 */
export const hasTouchEvents = () =>
    HAS_WINDOW &&
    ('ontouchstart' in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch));

/**
 * Add a new event handler to the window if it exists
 */
export const addWindowListener = (eventName, handler) => {
    if (HAS_WINDOW) {
        window.addEventListener(eventName, handler);
    }
};

/**
 * Remove an event handler from the window if it exists
 */
export const removeWindowListener = (eventName, handler) => {
    if (HAS_WINDOW) {
        window.removeEventListener(eventName, handler);
    }
};
