import { logEvent } from '@eventbrite/statsig';
import {
    CREATE_EVENT_CTA_CLICK,
    FIND_EVENT_CTA_CLICK,
} from '../constants/statsig';

export const logCreateEventCTAClick = (isHomePage: boolean) => {
    logEvent(CREATE_EVENT_CTA_CLICK, null, {
        isHomePage: String(isHomePage),
    });
};

export const logFindEventCTAClick = (isHomePage: boolean) => {
    logEvent(FIND_EVENT_CTA_CLICK, null, {
        isHomePage: String(isHomePage),
    });
};
