import { gettext } from '@eventbrite/i18n';
import React from 'react';

import './SearchButton.scss';
interface SearchButtonProps {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}
export const SearchButton = ({ onClick }: SearchButtonProps) => {
    return (
        <div className="searchButtonContainer">
            <button className="searchButton" onClick={onClick} type="button">
                <svg
                    aria-label={gettext('search button').toString()}
                    xmlns="http://www.w3.org/2000/svg"
                    width={36}
                    height={36}
                    fill="none"
                >
                    <circle cx={18} cy={18} r={18} />
                    <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M20.926 19.426a6 6 0 1 0-1.454 1.468L24.5 26l1.5-1.5-5.074-5.074ZM16 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
        </div>
    );
};
