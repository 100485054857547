import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';

/**
 * Invokes a function against every object in an array or object value of a dictionary
 */
export const deep = <ResultValue extends object, InputValue extends object>(
    obj: InputValue | Array<InputValue>,
    mapper: (input: InputValue) => ResultValue,
): ResultValue | Array<ResultValue> => {
    if (isArray(obj)) {
        const collection = obj as Array<InputValue>;
        const mappedCollection = collection.map((value) => deep(value, mapper));
        return mappedCollection as any;
    }

    if (isPlainObject(obj)) {
        const plainObject = obj as InputValue;
        const mappedObject = mapper(plainObject);
        const deeplyMappedObject = mapValues(mappedObject, (value) =>
            deep(value as any, mapper),
        );
        return deeplyMappedObject as any;
    }

    return obj as any;
};
