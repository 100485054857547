export const SEARCHES_KEY = 'search:autocomplete:history';
export const VIEWS_KEY = 'user_recent_views';

export function getNavigatorLastRecentSearchTerms(num?: number): string[] {
    try {
        const searchTerms = localStorage.getItem(SEARCHES_KEY);
        if (!searchTerms) {
            return [];
        }

        const terms = searchTerms
            .split(',')
            .map((q) => q.trim().toLowerCase())
            .filter((q: string) => !!q);

        if (num) {
            return terms.slice(0, num);
        }
        return terms;
    } catch (e) {
        return [];
    }
}

export function clearNavigatorRecentSearchTerms() {
    if (typeof window === 'undefined') {
        return;
    }

    try {
        localStorage.removeItem(SEARCHES_KEY);
    } catch (e) {
        console.log('Error clearing search terms from localStorage');
    }
}

export const addNavigatorSearchTerm = (search: string) => {
    if (typeof window === 'undefined') {
        return;
    }

    const MAX_SEARCHES_STORED = 40;
    const searches = getNavigatorLastRecentSearchTerms();
    searches.unshift(search.trim());
    const uniqueSearches = Array.from(new Set(searches));
    const nonEmptyUniqueSearches = uniqueSearches.filter((s) => s);
    const lastFortySearches = nonEmptyUniqueSearches.slice(
        0,
        MAX_SEARCHES_STORED,
    );
    try {
        localStorage.setItem(SEARCHES_KEY, lastFortySearches.join(','));
    } catch (e) {
        console.log('Error saving search term to localStorage');
    }
};

export const LOCATION_KEY = 'location:autocomplete:history';

export function getNavigatorLastLocation(): Location | null {
    if (typeof window === 'undefined') {
        return null;
    }

    try {
        const lastLocationEncoded = localStorage.getItem(LOCATION_KEY);

        if (!lastLocationEncoded) {
            return null;
        }
        const lastLocation: Location = JSON.parse(
            lastLocationEncoded.replace(/;/g, ','), // TODO: remove it
        );
        return lastLocation;
    } catch (e) {
        return null;
    }
}

export function setNavigatorLastLocation(location: Location) {
    if (typeof window === 'undefined') {
        return;
    }
    /*
     * Stores the current place data in the localStorage.
     * To store an object we have to replace the `,` with `;`
     * because we later reconstruct the data separating different
     * locations by `,` and the object itself should not be separated
     * until parsed as a JSON object.
     */
    localStorage.setItem(
        LOCATION_KEY,
        JSON.stringify(location).replace(/,/g, ';'), // TODO: remove it
    );
}

export function getNavigatorLastRecentViews(): string {
    try {
        const views = localStorage.getItem(VIEWS_KEY);
        return views || '';
    } catch (e) {
        return '';
    }
}

export function getNavigatorLastRecentViewsList(num?: number): string[] {
    const views = getNavigatorLastRecentViews();
    if (!views) {
        return [];
    }

    const eventIds = views.split(',').map((q) => q.trim().toLowerCase());
    return num ? eventIds.slice(0, num) : eventIds;
}

export interface Location {
    currentPlace?: string;
    currentPlaceParent?: string;
    country?: string;
    content?: string;
    secondaryContent?: string;
    value: string;
    latitude?: number;
    longitude?: number;
    bbox?: any; // TODO: remove any
    placeType?: string;
    placeId?: string;
    slug?: string;
    isHistorySuggestion: boolean;
    isOnline?: boolean;
}
