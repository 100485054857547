import { setWindowLocation } from '@eventbrite/http';
import { addNavigatorSearchTerm } from '@eventbrite/personalization';
import { useLocationContext } from '../context';
import { AnalyticsOptions, SearchParamsForUrl } from '../types';
import { constructSearchUrl, trackSearchTakeoverEvent } from '../utils';

/**
 *
 * @returns callback function to run search
 */
export const useRunSearch = () => {
    const { location, setLocationCookie } = useLocationContext();

    const callback = ({
        url,
        search,
        analytics,
    }: {
        url?: string;
        search?: SearchParamsForUrl;
        analytics?: AnalyticsOptions | AnalyticsOptions[];
    }) => {
        let searchUrl = url;

        if (!url && !!search) {
            searchUrl = constructSearchUrl({ search, location });
        }

        // Update the location cookie, since the user has updated their desired location
        !!location && setLocationCookie?.(location);

        if (search?.q) {
            addNavigatorSearchTerm(search?.q);
        }

        !!analytics && trackSearchTakeoverEvent(analytics);

        setWindowLocation(searchUrl);
    };

    return callback;
};
