import { Icon } from '@eventbrite/eds-icon';
import {
    ChevronDownChunky,
    ChevronUpChunky,
} from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import React, { useRef } from 'react';
import { v4 as UUID } from 'uuid';
import { useGlobalNavContext } from '../../../../../state';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../../utils/heap-data';
import { NestedDropdownItemProps } from '../../../../types';
import { NavLink } from '../../Links';
import { SecondaryDropdown } from '../SecondaryDropdown';
import styles from './NestedDropdownItem.module.scss';

const NestedDropdownItem = ({
    iconType,
    heapIdentifier,
    translatedText,
    dropdownItems,
}: NestedDropdownItemProps) => {
    const { env } = useGlobalNavContext();
    const { Teams, Features, Components, Actions } = HeapAttributes;
    const spanRef = useRef<HTMLSpanElement>(null);

    const pointerDownHandler = (e: React.PointerEvent) => {
        if (spanRef.current) {
            /**
             * This is used to close the entire dropdown
             * working with blur
             */
            if (spanRef.current === document.activeElement) {
                /**
                 * Prevent any default behavior associated with pointer events.
                 * https://developer.mozilla.org/en-US/docs/Web/API/PointerEvent
                 * If you use pointer events, you should call event.preventDefault() to keep the mouse event from being sent as well.
                 */
                e.preventDefault();
                /**
                 * This logic blurs the element closing the dropdown
                 */
                spanRef.current.blur();
                /**
                 * Then focus is set to the main dropdown <ul>
                 */
                spanRef?.current?.parentElement?.parentElement?.parentElement?.focus();
            }
        }
        e.stopPropagation();
    };

    return (
        <li className={styles.nestedDropdownTrigger} tabIndex={-1}>
            <span
                ref={spanRef}
                data-heap-id={getHeapDataAttribute({
                    team: Teams.SEO,
                    feature: Features.GlobalNav,
                    component: Components.Dropdown,
                    name: heapIdentifier.replaceAll(' ', '-').toLowerCase(),
                    action: Actions.Click,
                })}
                className={styles.titleContainer}
                tabIndex={0}
                onPointerDown={pointerDownHandler}
            >
                {iconType ? (
                    <span className={styles.iconContainer}>
                        <Icon type={iconType} />
                    </span>
                ) : null}
                <span>{translatedText}</span>
                <span className={styles.arrowIcons}>
                    <Icon type={<ChevronUpChunky />} />
                    <Icon type={<ChevronDownChunky />} />
                </span>
            </span>

            <ul
                className={styles.submenu}
                aria-label={gettext('submenu').toString()}
            >
                {dropdownItems?.map(
                    ({
                        dropdownItems,
                        heapIdentifier,
                        translatedText,
                        path = '',
                        iconType,
                        textColor,
                        className,
                        iconPosition,
                    }) => {
                        return dropdownItems ? (
                            <SecondaryDropdown
                                key={UUID()}
                                dropdownItems={dropdownItems}
                                path={new URL(path, env?.serverUrl ?? '').href}
                                heapIdentifier={heapIdentifier}
                                translatedText={translatedText}
                                textColor={textColor}
                                iconType={iconType}
                                iconPosition={iconPosition}
                            />
                        ) : (
                            <li
                                key={UUID()}
                                className={styles.navLinkContainer}
                            >
                                <NavLink
                                    path={
                                        new URL(path, env?.serverUrl ?? '').href
                                    }
                                    heapIdentifier={heapIdentifier}
                                    translatedText={translatedText}
                                    textColor={textColor}
                                    iconType={iconType}
                                    iconPosition={iconPosition}
                                    className={
                                        className ?? 'nav-link__dropdown'
                                    }
                                />
                            </li>
                        );
                    },
                )}
            </ul>
        </li>
    );
};

export default NestedDropdownItem;
