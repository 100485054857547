import React from 'react';

export interface ImageProps {
    imageUrl: string;
    size: React.SVGAttributes<SVGImageElement>['width'];
}

export const Image: React.FunctionComponent<ImageProps> = ({
    imageUrl,
    size,
}) => (
    <image
        xlinkHref={imageUrl}
        href={imageUrl}
        height={size}
        width={size}
        preserveAspectRatio="xMidYMin slice"
    />
);
