export enum Teams {
    SEO = 'seo',
}

export enum Features {
    GlobalNav = 'global-nav',
}

export enum Components {
    SearchBar = 'search-bar',
    Logo = 'logo',
    Link = 'link',
    Dropdown = 'dropdown',
}

export enum Actions {
    Start = 'start',
    ChooseFile = 'choose_file',
    Submit = 'submit',
    Create = 'create',
    Manage = 'manage',
    Click = 'click',
    Subscribe = 'click_subscribe',
    Select = 'select',
    Deselect = 'deselect',
}
