import * as React from 'react';

const CrossChunkyRebrandingSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.587 12.001L6 16.588l1.414 1.415 4.587-4.587 4.588 4.587 1.414-1.415-4.588-4.587 4.588-4.587L16.588 6l-4.587 4.587L7.414 6 6 7.414l4.587 4.587z"
            fill="#161719"
        />
    </svg>
);

CrossChunkyRebrandingSvg.displayName = 'CrossChunkyRebrandingSvg';
export default CrossChunkyRebrandingSvg;
