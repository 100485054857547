// TODO: Replace with eds-event-card EventInteraction enum
// This is a re-definition of the EventInteractions
// There is a working enum inside eds-event-card, but is not currently exported due to the lack of types for the rest of the components.
export enum EventInteractions {
    Impression = 'impression',
    Click = 'click',
    Hover = 'hover',
    HideAd = 'hide-ad',
    ReportAd = 'report-ad',
    Mounted = 'mounted',
    Follow = 'follow',
    Unfollow = 'unfollow',
}

export const PLACE_EVENT_INTERACTION = 'PlaceEventInteraction';

export type EventInteraction<ContextData = {}> = {
    id: string;
    action: EventInteractions;
    event: EventTrackingData;
} & ContextData;

// This should be the whole event data, but since we are juggling between deprecated types and new ones, we will leave this as any for now
// TODO: Define a consistent type for events across eb-ui
export type EventTrackingData = any & {
    isPromoted: boolean;
    isFree: boolean;
    isOnline: boolean;
};

export type InteractionCustomEvent<ContextData = {}> = CustomEvent<
    EventInteraction<ContextData>
>;

export type EventInteractionContext = {
    user: {
        id?: string;
        locale?: string;
        guestId?: string;
        sessionId?: string;
    };
    adId?: string;
    place: {
        name: string;
        position: number;
        page: number;
        context: any;
        tabKey?: string;
        placementId?: string;
        absoluteRank?: number;
        subInterface?: {
            name?: string;
            position?: string;
        };
    };
    displayContext?: {
        hasOpenPromotion: boolean;
        hasBOGOLabel: boolean;
        urgencySignals?: string[];
    };
    venueId?: string;
    feedbackContext?: {
        email: string;
        reason: string;
        comment?: string;
    };
};

export type HTMLElementWithEvents = Pick<
    HTMLElement,
    'addEventListener' | 'removeEventListener' | 'dispatchEvent'
>;

//TODO: remove generic
export type GetTrackingInformation<T = {}> = (
    eventInteraction: EventInteraction,
) => T;

export interface Subscribable {
    addEventListener(
        type: string,
        listener: (
            event: InteractionCustomEvent<EventInteractionContext>,
        ) => void,
    ): void;
}
