import React, { Dispatch, SetStateAction, useState } from 'react';

interface SearchDropdownContextValues {
    showOverlay: boolean;
    setShowOverlay: Dispatch<SetStateAction<boolean>>;
    showDropdown: boolean;
    setShowDropdown: Dispatch<SetStateAction<boolean>>;
    initialized: boolean;
}

export const SearchDropdownContext =
    React.createContext<SearchDropdownContextValues>({
        showOverlay: false,
        setShowOverlay: () => null,
        showDropdown: false,
        setShowDropdown: () => null,
        initialized: false,
    });

export const SearchDropdownProvider: React.FunctionComponent = ({
    children,
}) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <SearchDropdownContext.Provider
            value={{
                showOverlay,
                setShowOverlay,
                showDropdown,
                setShowDropdown,
                initialized: true,
            }}
        >
            {children}
        </SearchDropdownContext.Provider>
    );
};
