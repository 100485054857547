import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { FormattedEvent } from '../../types/events';
import './EventCardBadge.scss';

interface EventCardBadgeProps {
    style?: React.CSSProperties;
}

const TICKETS_ON_SALE = 'on_sale';

/**
 * Currently just supports rendering passed back message on the
 * chance that tickets are not on sale. This will eventually
 * need to be expanded to support urgency signal parsing/etc.
 *
 * @param status EventSalesStatus
 * @returns
 */
export function getFormattedSalesStatus({
    eventSalesStatus,
    repeatingInstanceCount = 0,
}: Pick<FormattedEvent, 'eventSalesStatus' | 'repeatingInstanceCount'>):
    | string
    | null {
    const isSeriesEvent = repeatingInstanceCount > 0;

    if (!eventSalesStatus) return null;
    if (isSeriesEvent) return null;
    if (eventSalesStatus.salesStatus === TICKETS_ON_SALE) return null;

    return eventSalesStatus?.message || null;
}

export const EventCardBadge: React.FunctionComponent<EventCardBadgeProps> = (
    props,
) => {
    if (!props.children) {
        return null;
    }

    return (
        <div className="event-card-badge" style={props.style}>
            <Typography variant="body-sm" color={'white'}>
                {props.children}
            </Typography>
        </div>
    );
};
