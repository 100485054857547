import {
    ACTIVATE_ITEM,
    SHOW_CHILD,
    RESET_STATE,
} from '@eventbrite/eds-hot-keys';

// See https://craig.is/killing/mice for available patterns of declaring keyboard sequences.
export const ACTION_KEY_MAP = {
    // action(str) : keyCombination
    [RESET_STATE]: ['tab', 'shift+tab', 'esc', 'alt+tab'],
    [SHOW_CHILD]: 'down',
    [ACTIVATE_ITEM]: 'enter',
};
