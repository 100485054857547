import { Icon } from '@eventbrite/eds-icon';
import {
    ChevronLeftChunky,
    ChevronRightChunky,
} from '@eventbrite/eds-iconography';
import React, { useRef } from 'react';
import { v4 as UUID } from 'uuid';
import { useGlobalNavContext } from '../../../../../state';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../../utils/heap-data';
import { DropdownItem } from '../../../../types';
import { NavLink } from '../../Links';
import styles from './SecondaryDropdown.module.scss';

const SecondaryDropdown = ({
    heapIdentifier,
    translatedText,
    dropdownItems,
}: DropdownItem) => {
    const dropdownTriggerRef = useRef<HTMLLIElement>(null);
    const secondaryDropdownRef = useRef<HTMLUListElement>(null);
    const secondaryDropdownButtonRef = useRef<HTMLButtonElement>(null);
    const { Teams, Features, Components, Actions } = HeapAttributes;

    const { env } = useGlobalNavContext();

    const handleTriggerClick = () => {
        if (secondaryDropdownRef.current) {
            const el = secondaryDropdownButtonRef.current;
            el?.focus();
        }
    };
    const handleButtonClick = () => {
        if (dropdownTriggerRef.current) {
            const el = dropdownTriggerRef.current;
            el?.focus();
        }
    };
    return (
        <li
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.GlobalNav,
                component: Components.Dropdown,
                name: heapIdentifier.replaceAll(' ', '-').toLowerCase(),
                action: Actions.Click,
            })}
            tabIndex={0}
            className={styles.secondaryDropdownTrigger}
            data-globalnav-text={heapIdentifier}
            ref={dropdownTriggerRef}
        >
            <span
                onClick={handleTriggerClick}
                className={styles.titleContainer}
            >
                <span>{translatedText}</span>
                <span className={styles.iconContainer}>
                    <Icon type={<ChevronRightChunky />} size="small" />
                </span>
            </span>
            <ul className={styles.secondaryDropdown} ref={secondaryDropdownRef}>
                <button
                    tabIndex={0}
                    onClick={handleButtonClick}
                    ref={secondaryDropdownButtonRef}
                >
                    <ChevronLeftChunky />
                    {translatedText}
                </button>
                {dropdownItems?.map(
                    ({
                        heapIdentifier,
                        translatedText,
                        textColor,
                        path = '',
                        iconType,
                        iconPosition,
                        className,
                    }) => (
                        <li key={UUID()} className={styles.navLinkContainer}>
                            <NavLink
                                path={new URL(path, env?.serverUrl ?? '').href}
                                heapIdentifier={heapIdentifier}
                                translatedText={translatedText}
                                textColor={textColor}
                                iconType={iconType}
                                iconPosition={iconPosition}
                                className={className ?? 'nav-link__dropdown'}
                            />
                        </li>
                    ),
                )}
            </ul>
        </li>
    );
};
export default SecondaryDropdown;
