import * as React from 'react';

const CheckSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="check_svg__eds-icon--check_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="check_svg__eds-icon--check_base"
            d="M4 12.8l4.8 4.7L20 6.5"
            fill="none"
            stroke="#050505"
            strokeMiterlimit={10}
        />
    </svg>
);

CheckSvg.displayName = 'CheckSvg';
export default CheckSvg;
