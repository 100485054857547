import {
    CATEGORY_TAG_TYPE,
    DATES_TO_REFINEMENT,
    DateType,
    EventSearch,
    FORMAT_TAG_TYPE,
    SUBCATEGORY_TAG_TYPE,
} from '@eventbrite/discover-utils';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import type { $FixMe } from '@eventbrite/ts-utils';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import last from 'lodash/last';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import queryString from 'query-string';
import { formatUrl } from 'url-lib';
import {
    END_DATE,
    EVENT_PAGE_PARAM,
    FOLLOWED_ORGS_FILTER_VALUE,
    SEARCH_RELEVANT_QUERY_PARAMS,
    START_DATE,
} from '../constants';
import { pruneObject } from '../utils';

import {
    EVENTBRITE_CATEGORY_TO_REFINEMENT,
    EVENTBRITE_FORMAT_TO_REFINEMENT,
    EVENTBRITE_SUB_CATEGORY,
    isCategory,
    isSubCategory,
} from '@eventbrite/categories';

const tagCheckByType = {
    [CATEGORY_TAG_TYPE]: isCategory,
    [FORMAT_TAG_TYPE]: (tag: string) => tag.indexOf(FORMAT_TAG_TYPE) > -1,
    [SUBCATEGORY_TAG_TYPE]: isSubCategory,
};
/*
 * @param tagType -- string -- oneOf([CATEGORY_TAG_TYPE, FORMAT_TAG_TYPE])
 * @param tags array
 *
 * Returns first tag that matches passed in type
 */
export const findTagByType = (
    tagType: keyof typeof tagCheckByType,
    tags?: string[],
) => {
    return tags && tags.find(tagCheckByType[tagType]);
};

const _parseTagsForUrl = (tags?: string | string[]) => {
    let category;
    let format;
    let subcategories = '';

    // if tags is an array, take the first one that represents a category
    // if is an string, take it as is if it represents a category
    if (tags && isArray(tags) && size(tags)) {
        category = findTagByType(CATEGORY_TAG_TYPE, tags);
        format = findTagByType(FORMAT_TAG_TYPE, tags);
        subcategories = uniq(tags.filter(isSubCategory))
            .map((tag) => tag.replace(`${EVENTBRITE_SUB_CATEGORY}/`, '')) // FIXME this should be in the categories library
            .sort()
            .join(',');
    } else if (tags && isString(tags)) {
        category = tags.indexOf(CATEGORY_TAG_TYPE) > -1 ? tags : '';
        format = tags.indexOf(FORMAT_TAG_TYPE) > -1 ? tags : '';
    }

    return { category, format, subcategories };
};

const _getDateForUrl = (dates?: DateType | DateType[] | string | string[]) => {
    let date;

    if (dates && isArray(dates) && size(dates)) {
        date = last(dates);
    } else if (dates && isString(dates)) {
        date = dates;
    }

    return date;
};

const _formatNameForUrl = (name?: string | $FixMe) => {
    let parsedName = name || '';

    if (!isString(parsedName)) {
        return parsedName;
    }

    parsedName = parsedName
        .trim()
        .replace('.', '')
        .replace(',', '')
        .replace(' & ', ' and ');

    parsedName = parsedName.replace(/[ +,/]+/g, '-');

    return parsedName.toLowerCase();
};

interface Bbox {
    sw: {
        lat: string | number;
        lng: string | number;
    };
    ne: {
        lat: string | number;
        lng: string | number;
    };
}
interface FormUrlParams {
    slug?: string;
    refinements: string;
    query: string;
    bbox?: string | Bbox;
    moreEventsHomePage: boolean;
    subcategories: string;
    usesLanguageSubdirectory: boolean;
    locale: string;
}
const _formUrl = ({
    slug,
    refinements,
    query,
    bbox,
    moreEventsHomePage,
    subcategories,
    usesLanguageSubdirectory,
    locale,
}: FormUrlParams) => {
    let url = '/d/';
    const escapedQuery = encodeURIComponent(query);

    if (usesLanguageSubdirectory && locale) {
        url = `/${locale}${url}`;
    }

    // When we have a bounding box it's because we couldn't find a valid slug.
    // Default to something generic like "united states" in that case
    url += bbox || isEmpty(slug) ? 'united-states/' : `${slug}/`;

    if (refinements === 'events') {
        url += moreEventsHomePage
            ? escapedQuery
                ? `${escapedQuery}/`
                : 'events/'
            : escapedQuery
            ? `${escapedQuery}/`
            : 'all-events/';
    } else {
        url += escapedQuery
            ? `${refinements}/${escapedQuery}/`
            : `${refinements}/`;
    }

    if (bbox && isObject(bbox)) {
        url += `?bbox=${bbox.sw.lng},${bbox.sw.lat},${bbox.ne.lng},${bbox.ne.lat}`;
    }

    // Put tags into the URL by hand to avoid category & format being included
    if (subcategories) {
        url += url.indexOf('?') > -1 ? `&` : '?';
        url += `subcategories=${subcategories}`;
    }

    return url;
};

interface GetCanonicalSearchUrlParams {
    /**
     * Something like: "ca--san-francisco"
     */
    slug?: string;
    /**
     * The lat/lng bounding box of the location
     */
    bbox?: string | Bbox;
    price?: string;
    /**
     * An array of EVENTBRITE_CATEGORY_TO_REFINEMENT or EVENTBRITE_FORMAT_TO_REFINEMENT keys
     */
    tags?: string | string[];
    /**
     * An array of the dates expected like: "today" or "tomorrow"
     */
    dates?: DateType | DateType[] | string | string[]; // FIXME Remove string types
    /**
     * The custom query made.
     */
    q?: string;
    /**
     * This param returns true if the context is the More Events bucket in the Home Page
     */
    moreEventsHomePage?: boolean;
    /**
     * Whether or not the current locale uses language subdirectory.
     *
     * If the value for usesLanguageSubdirectory is true, the url returned by this function would be prepended with the locale string
     *   For example: /fr_CA/d/location-slug/all-events/
     *
     * At the time of writing, there are 3 active locales that uses language subdirectory: fr_CA, fr_BE, fr_CH
     */
    usesLanguageSubdirectory?: boolean;
    /**
     * The locale name (i.e. en_US, fr_CA, etc)
     */
    locale?: string;
}
/**
 * Given an object with all the search params, it will build the canonical ulr for it.
 * Returns a formatted absolute path with the refinements in place.
 */
export const getCanonicalSearchUrl = ({
    slug,
    bbox,
    price,
    tags,
    dates,
    q,
    moreEventsHomePage = false,
    usesLanguageSubdirectory = false,
    locale = '',
}: GetCanonicalSearchUrlParams) => {
    const refinements: string[] = [];

    // price
    if (price) {
        refinements.push(price);
    }

    // Split the tags array into category, format, and the rest of the tags (aka subcategories)
    const { category, format, subcategories } = _parseTagsForUrl(tags);

    if (category && EVENTBRITE_CATEGORY_TO_REFINEMENT[category]) {
        refinements.push(EVENTBRITE_CATEGORY_TO_REFINEMENT[category]);
    }

    if (format && EVENTBRITE_FORMAT_TO_REFINEMENT[format]) {
        refinements.push(EVENTBRITE_FORMAT_TO_REFINEMENT[format]);
    } else {
        refinements.push('events');
    }

    // dates
    const date = _getDateForUrl(dates);

    if (date && DATES_TO_REFINEMENT[date as keyof typeof DATES_TO_REFINEMENT]) {
        refinements.push(
            DATES_TO_REFINEMENT[date as keyof typeof DATES_TO_REFINEMENT],
        );
    }

    const refinementsString = refinements.join('--');

    // query
    const query = _formatNameForUrl(q);

    return _formUrl({
        slug,
        query,
        refinements: refinementsString,
        bbox,
        moreEventsHomePage,
        subcategories,
        usesLanguageSubdirectory,
        locale,
    });
};
/*
 *
 * @param path {string} -- canonical path
 * @param eventSearch {object} -- state.search.eventSearch
 *
 * Func creates the appropriate URL object with approved and formatted query params. Assumes incoming
 * path is canonical already.
 *
 */
export const constructObjectForUrl = (
    path: string,
    eventSearch: EventSearch,
    currentPage?: number,
) => {
    const {
        dateRange,
        bbox,
        currencies: cur,
        languages: lang,
        special = [],
        organizationsOr: organization,
        hash,
    } = eventSearch;
    const query = pruneObject({
        [EVENT_PAGE_PARAM]: currentPage,
        [START_DATE]: dateRange.from,
        [END_DATE]: dateRange.to,
        bbox,
        cur,
        lang,
        followed_orgs: special.includes(FOLLOWED_ORGS_FILTER_VALUE) ? 1 : '',
        organization,
        hash,
    });

    return {
        query,
        pathname: path,
    };
};

/*
@param eventSearch {object} - takes current application state
@param locationSlug {string} - location slug like ca--san-francisco
Returns a formatted URL with appropriate query params and updated event page value
*/
export const constructNormalizedSearchUrl = (
    page = 1,
    eventSearch: EventSearch,
    locationSlug = '',
    usesLanguageSubdirectory = false,
    locale = '',
) => {
    // Emojis and weird characters can generate problems
    const path = getCanonicalSearchUrl({
        slug: locationSlug,
        ...eventSearch,
        usesLanguageSubdirectory,
        locale,
    });
    const formattedPath = decodeURI(path);

    const { query: constructedQuery } = constructObjectForUrl(
        path,
        eventSearch,
        page,
    );

    return getPathWithSanitizedQueryParams(formattedPath, constructedQuery);
};

/**
 * Returns a formatted URL with appropriate query params and updated event page value
 * @param eventSearch {object} - takes current application state
 * @param locationSlug {string} - location slug like ca--san-francisco
 * @param search {string} - current URL query string
 */
export const constructSearchUrl = (
    page = 1,
    eventSearch: EventSearch,
    locationSlug = '',
    search: string,
    usesLanguageSubdirectory = false,
    locale = '',
) => {
    // Emojis and weird characters can generate problems
    const path = getCanonicalSearchUrl({
        slug: locationSlug,
        ...eventSearch,
        usesLanguageSubdirectory,
        locale,
    });
    const formattedPath = decodeURI(path);

    const currentQueryParams = getCurrentExternalQueryParams(search);
    const { query: constructedQuery } = constructObjectForUrl(
        path,
        eventSearch,
        page,
    );
    const hash = HAS_WINDOW ? window.location.hash : '';

    return (
        formatUrl(formattedPath, {
            ...currentQueryParams,
            ...constructedQuery,
        }) + hash
    );
};

// Return the url used as key in the previousUrlContext state
export function getCurrentNormalizedUrl(
    history: $FixMe, // RouteComponentProps['history'], // TODO Remove react-router-dom
) {
    const queryParams = queryString.parse(history.location.search);
    return getPathWithSanitizedQueryParams(
        history.location.pathname,
        queryParams,
    );
}

// Return query params that do not affect search query
export const getCurrentExternalQueryParams = (
    search: string,
    whiteListedQueryParams = SEARCH_RELEVANT_QUERY_PARAMS,
) => {
    const queryParams = queryString.parse(search);
    return omit(queryParams, whiteListedQueryParams);
};
/*
@param path - String
@param queryParams - Object
Returns url formatted path with sanitized queryParams included
*/
export const getPathWithSanitizedQueryParams = (
    path: string,
    queryParams: Record<string, unknown>,
    whiteListedQueryParams = SEARCH_RELEVANT_QUERY_PARAMS,
) => {
    const normalizedQueryParams = {
        ...queryParams,
        // Remove page=1, since it's the same as not having it
        page: Number(queryParams.page) === 1 ? undefined : queryParams.page,
    };
    /* Query params we care about that will influence the search value */
    const sanitizedQuery: $FixMe = pick(
        normalizedQueryParams,
        whiteListedQueryParams,
    );

    return formatUrl(path, sanitizedQuery);
};
