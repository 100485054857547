import omit from 'lodash/omit';
import React from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import { Link } from 'react-router';
import classNames from 'classnames';
import {
    ANCHOR_LINK,
    ROUTER_LINK,
    EXPAND_LINK,
    DIVIDER,
    SECTION_HEADER,
    TYPE_PROP_TYPE,
} from './constants';
import { ListItem } from '@eventbrite/eds-list-item';
import BaseLink from './_internal/BaseLink';
import {
    ICON_TYPE_PROP_TYPE,
    ICON_SIZE_PROP_TYPE,
    COLOR_PROP_TYPE,
} from '@eventbrite/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { Divider } from '@eventbrite/eds-divider';
import { VERTICAL_ALIGNMENTS_PROP_TYPE } from '@eventbrite/eds-list-item';

import './navListItem.scss';

const AnchorLink = ({ path, isTentativelySelected, ...anchorProps }) => (
    <BaseLink
        {...anchorProps}
        LinkComponent="a"
        href={path}
        data-spec="anchor-list-item"
        isTentativelySelected={isTentativelySelected}
    />
);

const RouterLink = ({ path, ...anchorProps }) => (
    <BaseLink {...anchorProps} LinkComponent={Link} to={path} />
);

const ExpandLink = ({
    onActivate,
    children,
    iconType,
    active,
    useAltTheme,
    isDisabled,
    useSecondaryTheme,
    ...extraProps
}) => {
    const buttonProps = omit(
        extraProps,
        'path',
        'isTentativelySelected',
        'useSecondaryTheme',
    );
    const className = classNames(
        'eds-nav-list-item',
        'eds-nav-list-item--link',
        {
            'eds-nav-list-item--active': active,
            'eds-nav-list-item--alt-theme': useAltTheme,
            'eds-nav-list-item--secondary-theme': useSecondaryTheme,
            'eds-nav-list-item--disabled': isDisabled,
        },
    );

    return (
        <Button
            {...buttonProps}
            __containerClassName={className}
            style="anchor"
            disabled={isDisabled}
            onClick={onActivate}
            data-spec="expand-list-item"
        >
            <ListItem
                iconType={iconType}
                isSelected={active}
                useAltTheme={useAltTheme}
            >
                {children}
            </ListItem>
        </Button>
    );
};

const NavDivider = () => (
    <div className="eds-nav-list-item--divider">
        <Divider />
    </div>
);

const SectionHeader = ({ children }) => (
    <div className="eds-nav-list-item--section-header eds-l-pad-vert-4 eds-l-pad-left-2">
        <h4 className="eds-text-color--ui-800">{children}</h4>
    </div>
);

const TYPES_COMPONENT_MAP = {
    [ROUTER_LINK]: RouterLink,
    [ANCHOR_LINK]: AnchorLink,
    [DIVIDER]: NavDivider,
    [EXPAND_LINK]: ExpandLink,
    [SECTION_HEADER]: SectionHeader,
};

export default class NavListItem extends React.PureComponent {
    static propTypes = {
        /**
         * The type of the nav list item
         */
        type: TYPE_PROP_TYPE.isRequired,
        /**
         * The contents of the nav list item
         */
        children: PropTypes.node,
        /**
         * The URL path to navigate to
         */
        path: PropTypes.string,
        /**
         * Whether or not the nav list item is active
         */
        active: PropTypes.bool,
        /**
         * The icon type to include in the nav list item
         */
        iconType: ICON_TYPE_PROP_TYPE,
        /**
         * Size of included icon
         */
        iconSize: ICON_SIZE_PROP_TYPE,
        /**
         * Color of included icon
         */
        iconColor: COLOR_PROP_TYPE,
        /**
         * Color of included stroke
         */
        iconStrokeColor: COLOR_PROP_TYPE,
        /**
         * URL of image to be displayed with list item content
         */
        imageUrl: PropTypes.string,
        /**
         * Alt text for image to be displayed with list item content
         */
        imageAlt: translationPropType,
        /**
         * Optional square shape image - note you must pass in an imageUrl
         * for an image that is either 1:1 or wider than it is tall.
         */
        isSquareImage: PropTypes.bool,
        /**
         * Vertical alignment of children items
         */
        verticalAlignment: VERTICAL_ALIGNMENTS_PROP_TYPE,
        /*
         * Sets active link state
         */
        onActivate: PropTypes.func,
        /**
         * Whether or not the item should use an alternate theme
         */
        useAltTheme: PropTypes.bool,
        /**
         * Whether or not the item is disabled
         */
        isDisabled: PropTypes.bool,
        /**
         * Whether or not the nav list item is tenatively selected.
         * This comes into play on hover or via arrow key press before an
         * actual selection is made
         */
        isTentativelySelected: PropTypes.bool,
        /**
         * Use secondary alternate theme navList
         */
        useSecondaryTheme: PropTypes.bool,
    };

    static defaultProps = {
        active: false,
        useAltTheme: false,
        isDisabled: false,
        useSecondaryTheme: false,
    };

    render() {
        const { type, ...componentAttrs } = this.props;
        const NavListItemSubComponent = TYPES_COMPONENT_MAP[type];

        return <NavListItemSubComponent {...componentAttrs} />;
    }
}
