import some from 'lodash/some';
import { PLACEHOLDER_DUMMY_VALUE } from '@eventbrite/eds-input-field';

/**
 * Check if list has a value
 **/
export const hasValue = (defaultValue, values) =>
    some(values, (valueInfo) => valueInfo.value === defaultValue);

/**
 * Get the active value given defaultValue, values, and the placeholder. Search
 * values to see if the defaultValue exists. If the defaultValue is not valid
 * it means either it was not specified or it was specified incorrectly. Either way
 * we need to default to something valid.
 *
 * If there's a placeholder, we'll default to that because it will be first
 * in the list. If not, then just pick the first option.
 **/
export const getInitialValue = (defaultValue, values, placeholder) => {
    let initialValue;

    if (hasValue(defaultValue, values)) {
        initialValue = defaultValue;
    } else if (placeholder) {
        initialValue = PLACEHOLDER_DUMMY_VALUE;
    } else if (values.length) {
        initialValue = values[0].value;
    }

    return initialValue;
};
