import { gettext } from '@eventbrite/i18n';

export const STATSIG_EVENT_AUTO_CREATE_NEXT_EVENT =
    'Creator Home - Auto Create button Next Event - Click';

export const NEXT_EVENT_TITLE = gettext('Your next event ');
export const CREATE_EVENT_HREF = '/manage/events/create';
export const AUTO_CREATE_EVENT_HREF = '/manage/events/auto-create';

export const CREATE_EVENT = gettext('Create event');
export const CREATE_EVENT_WITH_AI = gettext('Create with AI');
export const CREATE_NEW_EVENT = gettext('Start from scratch');
export const CREATE_NEW_EVENT_WITH_AI = gettext(
    'Create my event faster with AI',
);
export const CREATE_NEW_EVENT_SUBTITLE = gettext(
    'Add all your event details, create new tickets, and set up recurring events',
);
export const CREATE_NEW_EVENT_WITH_AI_SUBTITLE = gettext(
    "Answer a few quick questions to generate an event that's ready to publish almost instantly",
);
