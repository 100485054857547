import * as React from 'react';

const ArrowChevronDownSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={25} height={24} fill="none" {...props}>
        <path
            d="M5.213 9.414l7.582 7.582 7.582-7.582L18.963 8l-6.168 6.168L6.627 8 5.213 9.414z"
            fill="#161719"
        />
    </svg>
);

ArrowChevronDownSvg.displayName = 'ArrowChevronDownSvg';
export default ArrowChevronDownSvg;
