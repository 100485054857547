import PropTypes from 'prop-types';
import { gettext, translationPropType } from '@eventbrite/i18n';

export const PLACEHOLDER_DUMMY_VALUE = '';

export const STYLE_BASIC = 'basic';
export const STYLE_DYNAMIC = 'dynamic';
export const STYLE_STATIC = 'static';

export const STYLES = [STYLE_BASIC, STYLE_DYNAMIC, STYLE_STATIC];
export const SELECT_STYLES = [STYLE_BASIC, STYLE_STATIC];

export const STYLES_PROP_TYPES = PropTypes.oneOf(STYLES);
export const SELECT_STYLES_PROP_TYPES = PropTypes.oneOf([
    STYLE_BASIC,
    STYLE_STATIC,
]);

export const BORDER_DEFAULT = 'default';
export const BORDER_SIMPLE = 'simple';
export const BORDER_NONE = 'none';
export const BORDER_HIDDEN = 'hidden';
export const BORDER_CONTENT_DRIVEN = 'content';

export const BORDER_TYPES = [
    BORDER_DEFAULT,
    BORDER_SIMPLE,
    BORDER_NONE,
    BORDER_HIDDEN,
    BORDER_CONTENT_DRIVEN,
];

export const BORDER_TYPES_PROP_TYPES = PropTypes.oneOf(BORDER_TYPES);

export const TYPE_DATE = 'date';
export const TYPE_EMAIL = 'email';
export const TYPE_NUMBER = 'number';
export const TYPE_PASSWORD = 'password';
export const TYPE_SEARCH = 'search';
export const TYPE_TEL = 'tel';
export const TYPE_TEXT = 'text';
export const TYPE_URL = 'url';

export const TYPES = [
    TYPE_DATE,
    TYPE_EMAIL,
    TYPE_NUMBER,
    TYPE_PASSWORD,
    TYPE_SEARCH,
    TYPE_TEL,
    TYPE_TEXT,
    TYPE_URL,
];

export const TYPES_PROP_TYPES = PropTypes.oneOf(TYPES);

export const VALUES_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.string.isRequired,
        display: translationPropType.isRequired,
        isDisabled: PropTypes.bool,
        key: PropTypes.string,
    }),
);

/* MultiSelect variables */
export const SELECT_ALL_TEXT = gettext('Select all');
export const APPLY_SELECTION_TEXT = gettext('Apply');
export const CANCEL_SELECTION_TEXT = gettext('Cancel');

/* minAsideSpacing prop */
export const MIN_ASIDE_SPACING_PREFIX = 'prefix';
export const MIN_ASIDE_SPACING_SUFFIX = 'suffix';
export const MIN_ASIDE_SPACING_BOTH = 'both';

export const MIN_ASIDE_SPACING_VALUES = [
    MIN_ASIDE_SPACING_PREFIX,
    MIN_ASIDE_SPACING_SUFFIX,
    MIN_ASIDE_SPACING_BOTH,
];

export const MIN_ASIDE_SPACING_PROP_TYPES = PropTypes.oneOf(
    MIN_ASIDE_SPACING_VALUES,
);
