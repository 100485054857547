export const SHAPE_CIRCULAR = 'circular';
export const SHAPE_LINEAR = 'linear';

export const SHAPES = [SHAPE_CIRCULAR, SHAPE_LINEAR];

export const SIZE_SMALL_THIN = 'small-thin';
export const SIZE_SMALL_CHUNKY = 'small-chunky';

export const SIZE_MEDIUM_CHUNKY = 'medium-chunky';
export const SIZE_MEDIUM_THIN = 'medium-thin';

export const SIZE_LARGE_THIN = 'large-thin';
export const SIZE_LARGE_CHUNKY = 'large-chunky';

export const SIZE_SMALL_DIAMETER = '20px';
export const SIZE_MEDIUM_DIAMETER = '36px';
export const SIZE_LARGE_DIAMETER = '96px';

export const SIZE_LARGE_CHUNKY_STROKE = 4;
export const SIZE_LARGE_THIN_STROKE = 2;
export const SIZE_MEDIUM_CHUNKY_STROKE = 10;
export const SIZE_MEDIUM_THIN_STROKE = 4;
export const SIZE_SMALL_CHUNKY_STROKE = 10;
export const SIZE_SMALL_THIN_STROKE = 4;

export const CIRCULAR_SIZES = {
    [SIZE_SMALL_THIN]: {
        diameter: SIZE_SMALL_DIAMETER,
        strokeWidth: SIZE_SMALL_THIN_STROKE,
    },
    [SIZE_SMALL_CHUNKY]: {
        diameter: SIZE_SMALL_DIAMETER,
        strokeWidth: SIZE_SMALL_CHUNKY_STROKE,
    },
    [SIZE_MEDIUM_THIN]: {
        diameter: SIZE_MEDIUM_DIAMETER,
        strokeWidth: SIZE_MEDIUM_THIN_STROKE,
    },
    [SIZE_MEDIUM_CHUNKY]: {
        diameter: SIZE_MEDIUM_DIAMETER,
        strokeWidth: SIZE_MEDIUM_CHUNKY_STROKE,
    },
    [SIZE_LARGE_THIN]: {
        diameter: SIZE_LARGE_DIAMETER,
        strokeWidth: SIZE_LARGE_THIN_STROKE,
    },
    [SIZE_LARGE_CHUNKY]: {
        diameter: SIZE_LARGE_DIAMETER,
        strokeWidth: SIZE_LARGE_CHUNKY_STROKE,
    },
};

export const LINEAR_STROKE = 4;

export const SIZE_NAMES = [
    SIZE_SMALL_THIN,
    SIZE_SMALL_CHUNKY,
    SIZE_MEDIUM_THIN,
    SIZE_MEDIUM_CHUNKY,
    SIZE_LARGE_THIN,
    SIZE_LARGE_CHUNKY,
];

export const TYPE_INDETERMINATE = 'indeterminate';
export const TYPE_DETERMINATE = 'determinate';

export const TYPES = [TYPE_INDETERMINATE, TYPE_DETERMINATE];

export const STYLE_GRADIENT = 'gradient';
export const STYLE_DARK = 'dark';
export const STYLE_LIGHT = 'light';
export const STYLE_PROGRESS = 'progress';

export const STYLES = [STYLE_DARK, STYLE_LIGHT, STYLE_GRADIENT, STYLE_PROGRESS];

export const CIRCULAR_INDETERMINATE_VIEWBOX = '0 0 100 100';
export const CIRCULAR_DETERMINATE_VIEWBOX = '0 0 104 104';

export const STYLE_CONFIG = {
    // Deprecated: No longer using a gradient
    // Should be changed to color
    // Hex Value eq: vibrant-green
    gradient: {
        stopColor1: '#4BE1A0',
        stopColor2: '#4BE1A0',
        railColor: '#EEEDF2',
    },
    dark: {
        stopColor1: '#000000',
        stopColor2: '#ffffff',
        railColor: 'rgba(40,44,53,0.2)',
    },
    light: {
        stopColor1: '#ffffff',
        stopColor2: '#ffffff',
        railColor: 'rgba(40,44,53,0.5)',
    },
};

export const PROGRESS_STYLE_CONFIG = {
    0: {
        stopColor1: '#E02E46',
        stopColor2: '#E02E46',
        railColor: '#EEEDF2',
    },
    50: {
        stopColor1: '#F05537',
        stopColor2: '#F05537',
        railColor: '#EEEDF2',
    },
    100: {
        stopColor1: '#16A85A',
        stopColor2: '#16A85A',
        railColor: '#EEEDF2',
    },
};
