import PlainSiteStructure from './SiteStructure';
import { enhance as enhanceUtil } from './utils';

export const SiteStructure = enhanceUtil(PlainSiteStructure);
export const enhance = enhanceUtil;

export {
    ENV_SHAPE_PROP_TYPE,
    REQUEST_SHAPE_PROP_TYPE,
    USER_SHAPE_PROP_TYPE,
    FOOTER_LINKS_SHAPE_PROP_TYPE,
} from './constants';

export {
    ENV_PROP_FIXTURE,
    USER_PROP_FIXTURE,
    FOOTER_LINKS_PROP_FIXTURE,
} from './fixtures';
