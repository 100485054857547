import { ShallowObj } from './types';

export default class Store {
    /**
     *  Representation of the current state
     */
    private present: ShallowObj = {};
    /**
     *  A collection of previous states (onlly available after the first publish)
     */
    private past: ShallowObj[] = [];
    /**
     *  A collection of future states (only available when time traveling)
     */
    private future: ShallowObj[] = [];
    /**
     *
     * @param payload:ShallowObj
     */
    constructor(payload: ShallowObj) {
        this.present = payload;
    }
    /**
     *
     * @returns {*}:ShallowObj Current store state
     */
    public getStore(): ShallowObj {
        return this.present;
    }
    /**
     *
     * @param payload:ShallowObj   The data to update the store with
     */
    public update(payload: Partial<ShallowObj>) {
        this.past.push({ ...this.present });
        this.present = {
            ...this.present,
            ...payload,
        };
        //every publish will create a new time line,
        //so future should be wiped
        this.future = [];
    }

    /**
     *  Allows to go to the past in state history
     *  useful to see how the state were changing
     */
    public goBackInTime(): void {
        if (this.past.length) {
            this.future = [{ ...this.present }, ...this.future];
            const [newPresent, ...newPast] = this.past.reverse();
            this.past = [...newPast.reverse()];
            this.present = { ...newPresent };
        }
    }

    /**
     *  Allows to go to the future after moving to past
     *  this wouldn't do anything without going to past before
     */
    public goForthInTime(): void {
        if (this.future.length) {
            this.past = [...this.past, { ...this.present }];
            const [newPresent, ...newFuture] = this.future;
            this.present = { ...newPresent };
            this.future = [...newFuture];
        }
    }
}
