import React from 'react';

interface ItemContentProps {
    content?: string;
    userQuery?: string;
    isRecent?: boolean;
}

export const ItemContent: React.FunctionComponent<ItemContentProps> = (
    props,
) => {
    const { content, userQuery, isRecent } = props;
    let regExp;

    try {
        regExp = new RegExp(userQuery || '', 'gi');
    } catch (e) {
        regExp = new RegExp('', 'gi');
    }

    const dangerousContent = content?.replace(
        regExp,
        `<strong data-testid="query-content">${userQuery}</strong>`,
    );

    return (
        <div
            className={`search-suggestions--item-content ${
                isRecent ? 'recent' : ''
            }`}
        >
            <span
                className="search-suggestions--result"
                dangerouslySetInnerHTML={{ __html: dangerousContent || '' }} //eslint-disable-line
            />
        </div>
    );
};
