import { gettext } from '@eventbrite/i18n';
import { ClockIcon } from '@eventbrite/marmalade';
import React from 'react';
import { useRunSearch } from '../../../../hooks';
import './RecentSearches.scss';

interface RecentSearchProps {
    recentSearch: string;
    handleItemClick: (term: string) => void;
}

interface RecentSearchesProps {
    recentSearches: string[];
    handleClearRecentSearches: () => void;
}

const RecentSearch = ({ recentSearch, handleItemClick }: RecentSearchProps) => {
    return (
        <li>
            <button
                onClick={() => {
                    handleItemClick(recentSearch);
                }}
                type="button"
            >
                <ClockIcon width={24} height={24} />
                <span>{recentSearch}</span>
            </button>
        </li>
    );
};
const RecentSearches = ({
    recentSearches,
    handleClearRecentSearches,
}: RecentSearchesProps) => {
    const handleSearch = useRunSearch();

    const handleItemClick = React.useCallback(
        (term: string) => {
            handleSearch({
                search: { q: term },
                analytics: { action: 'RecentSearchClicked', label: term },
            });
        },
        [handleSearch],
    );

    return (
        <div>
            <div className="recent-searches__header">
                <h1>{gettext('Recent Searches')}</h1>
                <button type="button" onClick={handleClearRecentSearches}>
                    {gettext('Clear')}
                </button>
            </div>
            <ul className="recent-searches__list">
                {recentSearches.map((recentSearch) => (
                    <RecentSearch
                        handleItemClick={handleItemClick}
                        key={recentSearch}
                        recentSearch={recentSearch}
                    />
                ))}
            </ul>
        </div>
    );
};

export default RecentSearches;
