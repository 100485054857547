import {
    ADD_MAIN_CONTROLS_NAMESPACE,
    ADD_FOCUS_DRAWER_CONTROLS_NAMESPACE,
    ADD_OVERLAY_CONTROLS_NAMESPACE,
    MILLISECONDS_TO_DELAY_NOTIFICATION_CLOSING_ANIMATION,
    MILLISECONDS_TO_DELAY_NOTIFICATION_CLOSE,
} from './constants';

const TIMERS = {
    [ADD_MAIN_CONTROLS_NAMESPACE]: {
        waitAnimationTimeout: null,
        waitRemovalTimeout: null,
    },
    [ADD_FOCUS_DRAWER_CONTROLS_NAMESPACE]: {
        waitAnimationTimeout: null,
        waitRemovalTimeout: null,
    },
    [ADD_OVERLAY_CONTROLS_NAMESPACE]: {
        waitAnimationTimeout: null,
        waitRemovalTimeout: null,
    },
};

export const clearNotificationTimeouts = (namespace) => {
    // Need to clear any lingering timeouts
    clearTimeout(TIMERS[namespace].waitAnimationTimeout);
    clearTimeout(TIMERS[namespace].waitRemovalTimeout);
};

export const setupNotificationAutoDelay = (
    onAnimateClose,
    onRemove,
    namespace,
) => {
    // Set a timer for the notification hiding sequence
    // After 7 seconds the animation will play
    // At 8 seconds the notification is removed
    TIMERS[namespace].waitAnimationTimeout = setTimeout(() => {
        onAnimateClose(() => {
            TIMERS[namespace].waitRemovalTimeout = setTimeout(() => {
                onRemove();
            }, MILLISECONDS_TO_DELAY_NOTIFICATION_CLOSE);
        });
    }, MILLISECONDS_TO_DELAY_NOTIFICATION_CLOSING_ANIMATION);
};
