import PropTypes from 'prop-types';
import { SVG_PROP_TYPE } from '@eventbrite/eds-vector-image';
import { COLORS_PROP_TYPE } from '@eventbrite/eds-color';

export const MAX_COUNT = 99;
export const COLOR_PROP_TYPE = COLORS_PROP_TYPE;
export const ICON_PROP_TYPE = SVG_PROP_TYPE;

export const POSITION_LEFT = 'left';
export const POSITION_RIGHT = 'right';

export const POSITIONS = [POSITION_LEFT, POSITION_RIGHT];
export const POSITION_PROP_TYPE = PropTypes.oneOf(POSITIONS);
