import React, { useEffect, useState } from 'react';
import { SplitIOFacade } from './SplitIOFacade';

function getSplitIOKey(
    host = 'dev.evbqa.com',
    apiKeyConfig: SplitIOApiKeyConfig,
) {
    if (host.indexOf('eventbrite') > -1) {
        return apiKeyConfig.prodKey;
    }

    if (host.indexOf('evbqa') > -1) {
        return apiKeyConfig.qaKey;
    }

    if (host.indexOf('dev') > -1) {
        return apiKeyConfig.devKey;
    }

    return apiKeyConfig.devKey;
}

type SplitIOApiKeyConfig = {
    // NOTE: The workspace does not need to match with the one in the SplitIO,
    // it is only used to store the client object. However, we encourage you to write
    // your workspace name to avoid overrides with other teams' clients.
    workspace: string;
    prodKey: string;
    qaKey: string;
    devKey: string;
};

export function useFeatureFlagState(
    ffName: string,
    apiKeyConfig: SplitIOApiKeyConfig,
    additionalSplitAttributes?: {
        [splitAttribute: string]: boolean | string | number;
    },
    key?: string,
) {
    const [ffState, setFFState] = useState<boolean | null>(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const serializedAdditionalSplitAttributes = JSON.stringify(
        additionalSplitAttributes || {},
    );

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        if (process.env.NODE_ENV === 'test') {
            setIsLoaded(true);
            setFFState(false);
            return;
        }

        const apiKey = getSplitIOKey(window.location.host, apiKeyConfig);
        const splitAttributes = {
            guest_id: window.__SERVER_DATA__?.guest_id,
            user_id: window.__SERVER_DATA__?.user.publicId,
            ...JSON.parse(serializedAdditionalSplitAttributes),
        };

        async function fetchData() {
            const isFeatureEnabled = await SplitIOFacade.isFeatureEnabled(
                apiKey,
                apiKeyConfig.workspace,
                ffName,
                splitAttributes,
                key,
            );

            setFFState(isFeatureEnabled);
            setIsLoaded(true);
        }

        fetchData();
    }, [apiKeyConfig, ffName, serializedAdditionalSplitAttributes, key]);

    if (!isLoaded) return null;

    return ffState;
}

interface SplitIOCheckerProps {
    ffName: string;
    renderWithFFState: (ffState: boolean) => React.ReactElement;
    renderLoading: React.ReactElement;
    apiKeyConfig: any;
    additionalSpitAttributes?: any;
    key?: string;
}
export function SplitIOChecker(props: SplitIOCheckerProps) {
    const ffState = useFeatureFlagState(
        props.ffName,
        props.apiKeyConfig,
        props.additionalSpitAttributes,
        props.key,
    );

    if (ffState === null) return props.renderLoading;

    return props.renderWithFFState(ffState);
}
