import { gettext } from '@eventbrite/i18n';
import { SearchBarContainerWithProviders } from '@eventbrite/search-takeover';
import classNames from 'classnames';
import React from 'react';
import { GlobalNavProvider } from '../../../../../state';
import { GlobalNavProps } from '../../../../types';
import styles from './ConsumerHeaderSearchBar.module.scss';

const ConsumerHeaderSearchBar: React.FC<GlobalNavProps> = ({
    searchBar,
    user,
    env,
}) => {
    const searchBarClassnames = classNames(
        styles.consumerHeaderSearchBar,
        styles.withSearchDropdown,
    );
    return (
        <GlobalNavProvider
            positionSticky={{ desktop: false, mobile: false }}
            searchBar={searchBar}
            user={user}
            env={env}
        >
            <div className={searchBarClassnames}>
                <nav
                    aria-label={gettext('Main Navigation').toString()}
                    data-testid="consumer-header-nav"
                >
                    <SearchBarContainerWithProviders
                        location={searchBar?.location}
                        initialQuery={searchBar?.searchQuery}
                    />
                </nav>
            </div>
        </GlobalNavProvider>
    );
};

export default ConsumerHeaderSearchBar;
