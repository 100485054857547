export const GA_HOME_FEED_CATEGORY = 'home-feed';
export const GA_HOME_CATEGORY = 'home';

// Custom Event Actions
export const GA_ONBOARDING_START_ACTION = 'StartUserOnboarding';
export const GA_LOCATION_CHANGE_ACTION = 'LocChange';
export const GA_SEARCH_AUTOCOMPLETE_SUGGESTION_ACTION =
    'AutocompleteSuggestionClicked';
export const GA_RUN_SEARCH_ACTION = 'RunSearch';
export const GA_RUN_SEARCH_HEADER_ACTION = 'RunSearchHeader';
export const GA_RUN_SEARCH_HEADER_RECENT = 'Recent';
export const GA_RUN_SEARCH_HEADER_POPULAR = 'Popular';
export const GA_RUN_MADLIB_SEARCH_ACTION = 'RunMadlibSearch';
export const GA_SEE_MORE_ACTION = 'SeeMore';
export const GA_SHARE_EVENT_ACTION = 'Share';
export const GA_SWITCH_TAB = 'SwitchTab';
export const GA_VIEW_EVENT_ACTION = 'ViewEvent';
export const GA_VIEW_EVENT_AUTOCOMPLETE = 'ViewEventAutocomplete';
export const GA_VIEW_HOMEPAGE_FEED = 'LoggedInHomePageView';
export const GA_VIEW_LOGGEDOUT_HOMEPAGE_FEED = 'LoggedOutHomePageView';
export const GA_EXPERIMENT_EXPOSURE = 'ExperimentExposure';

export const AFF_CODE_PREFIX = 'ebdshpf';

// Bookmark
export const GA_BOOKMARK_EVENT_ACTION = 'Bookmark';
export const GA_BOOKMARK_ATTEMPT_ACTION = 'BookmarkAttempt';
export const GA_UNBOOKMARK_EVENT_ACTION = 'Unbookmark';

// Follow organizer actions
export const GA_DISMISS_ORGANIZER_ACTION = 'DismissOrganizer';
export const GA_FOLLOW_ORGANIZER_ACTION = 'FollowOrganizer';
export const GA_SHOW_ORGANIZERS_ACTION = 'OrganizersShown';
export const GA_VIEW_ORGANIZER_PROFILE_ACTION = 'ViewOrganizerProfile';

// Five to Follow actions
export const GA_BUCKET_LOADED_ACTION = 'BucketLoaded';
export const GA_NEXT_SLIDE_ACTION = 'NextSlide';
export const GA_PREVIOUS_SLIDE_ACTION = 'PreviousSlide';
export const GA_SLIDES_LIMIT_REACHED_ACTION = 'SlidesLimitReached';

// Share
export const TRACKING_CODE = 'abc';
export const GA_SHARE_ATTEMPT = 'ShareAttempt';

// UTM
export const UTM_HOME_FEED_TERM = 'homefeed';
export const UTM_HOME_TERM = 'home';

// Browse Arrow
export const GA_BROWSE_ARROW = 'BrowseArrow';
export const GA_BROWSE_ARROW_LABEL = 'BrowseOtherPopularEvents';

// Seasonal
export const GA_HOME_BANNER_CTA_CLICK = 'HomeBannerCTAClick';

// Editorial collection
export const GA_CLICK_VIEW_ONLINE_COLLECTION_ACTION =
    'ViewEditorialOnlineCollection';
export const GA_TRANSITION_ONLINE_COLLECTION = 'TransitionOnlineCollection';

// Unsure what this constant is for, just adding it to fix an import lint error
// since it was being imported but not defined
export const GA_HOME_V2_LABEL_CUSTOM_DATE = 'CustomDate';

// SEARCH AND BROWSE ACTIONS
import {
    CATEGORY_FILTER,
    CURRENCY_FILTER,
    DATE_FILTER,
    FORMAT_FILTER,
    LANGUAGE_FILTER,
    PRICE_FILTER,
    QUERY_FILTER,
    SPECIAL_FILTER,
} from '@eventbrite/search-utils';

/****************************** GA CONSTANTS ********************************/

export const CITY_BROWSE_SECTION = 'cityBrowse';
export const SEARCH_SECTION = 'search';
export const ONLINE_BROWSE_SECTION = 'onlineBrowse';
export const ONLINE_SEARCH_SECTION = 'onlineSearch';

export const SEARCH_EVENT_NAVIGATION = 'search.event';
export const SEARCH_TOP_MATCH_NAVIGATION = 'search.top_match';
export const SEARCH_PROMOTED_LIST_NAVIGATION = 'search.promoted_result';

export const GA_CITY_BROWSE_CATEGORY = 'city-browse';
export const GA_SEARCH_CATEGORY = 'search';
export const GA_CATEGORY_MAP = {
    cityBrowse: GA_CITY_BROWSE_CATEGORY,
    search: GA_SEARCH_CATEGORY,
};

export const GA_TERM_MAP = {
    cityBrowse: 'citybrowse',
    search: 'destsearch',
};

export const GA_SEARCH_EVENT_CARD_CATEGORY = 'eventcard-search';
export const GA_CB_EVENT_CARD_CATEGORY = 'eventcard-cb';
export const GA_EVENT_CARD_CATEGORY_MAP = {
    cityBrowse: GA_CB_EVENT_CARD_CATEGORY,
    search: GA_SEARCH_EVENT_CARD_CATEGORY,
};

export const GA_EXTERNAL_VIEW_EVENT_ACTION = 'ViewExternal';
export const GA_VIEW_ARTICLE_ACTION = 'ViewArticle';
export const GA_VIEW_PROMOTED_EVENT_ACTION = 'ViewEventPromoted';
export const GA_VIEW_MORE_ACTION = 'ViewMore';
export const GA_SCROLL_DEPTH_ACTION = 'ScrollDepth';
export const GA_VIEW_CATEGORY_ACTION = 'ViewCategory';
export const GA_VIEW_NEARBY_CITY_ACTION = 'ViewNearbyCity';
export const GA_SAVE_EVENT_ACTION = 'Bookmark';
export const GA_UNSAVE_EVENT_ACTION = 'Unbookmark';
export const GA_SHARE_ATTEMPT_ACTION = 'ShareAttempt';
export const GA_SHARE_ACTION = 'Share';
export const GA_CATEGORY_FILTER_ACTION = 'CategoryFilter';
export const GA_EVENT_TYPE_FILTER_ACTION = 'EventTypeFilter';
export const GA_PRICE_FILTER_ACTION = 'PriceFilter';
export const GA_DATE_FILTER_ACTION = 'DateFilter';
export const GA_CURRENCY_FILTER_ACTION = 'CurrencyFilter';
export const GA_LANGUAGE_FILTER_ACTION = 'LanguageFilter';
export const GA_FOLLOWED_ORGANIZERS_FILTER = 'FollowedOrganizersFilter';
export const GA_VIEW_TRENDING_SEARCH_ACTION = 'TrendingSearch';
export const GA_VIEW_ALL_TRENDING_SEARCHES_ACTION = 'AllTrendingSearches';
export const GA_REDO_FROM_MAP = 'RedoFromMap';
export const GA_ENTER_MAP_VIEW = 'EnterMapView';
export const GA_SEARCH_TAG_CLICK_ACTION = 'SearchTagClick';
export const GA_SEARCH_TAG_CLEAR_ACTION = 'SearchTagClear';
export const GA_MORE_FILTERS_PANEL_ACTION = 'MoreFiltersPanel';
export const GA_MORE_FILTERS_CLEARED_ACTION = 'MoreFiltersCleared';
// this is not an actual action, but it's used in analytics logging in the same place where the above actions are used
export const GA_PAGE_LOAD_ACTION = 'PageLoad';

export const GA_SCROLL_COMPLETE_LABEL = '100';
export const GA_SCROLL_NA_LABEL = 'N/A';

export const SHARE_BY_COPY = 'copy';

export const FILTER_TYPE_GA_MAP: { [key: string]: string } = {
    [CATEGORY_FILTER]: GA_CATEGORY_FILTER_ACTION,
    [FORMAT_FILTER]: GA_EVENT_TYPE_FILTER_ACTION,
    [PRICE_FILTER]: GA_PRICE_FILTER_ACTION,
    [DATE_FILTER]: GA_DATE_FILTER_ACTION,
    [QUERY_FILTER]: GA_RUN_SEARCH_ACTION,
    [CURRENCY_FILTER]: GA_CURRENCY_FILTER_ACTION,
    [LANGUAGE_FILTER]: GA_LANGUAGE_FILTER_ACTION,
    [SPECIAL_FILTER]: GA_FOLLOWED_ORGANIZERS_FILTER,
};

/****************************** TRACKING CONSTANTS ********************************/

export const CITY_BROWSE_EVENT_VIEW_AFFILIATE = 'ebdssbcitybrowse';
export const ONLINE_BROWSE_EVENT_VIEW_AFFILIATE = 'ebdssbonlinebrowse';
const SEARCH_EVENT_VIEW_SEARCH_AFFILIATE = 'ebdssbdestsearch';
const ONLINE_SEARCH_EVENT_VIEW_AFFILIATE = 'ebdssbonlinesearch';
export const NEIGHBHOURHOOD_AFFILIATE = 'ebdssbneighborhoodbrowse';
export const NIGHTLIFE_AFFILIATE = 'ebdssbcitybrowsenightlife';

export const EVENT_AFFILIATES_MAP: { [index: string]: string } = {
    [CITY_BROWSE_SECTION]: CITY_BROWSE_EVENT_VIEW_AFFILIATE,
    [SEARCH_SECTION]: SEARCH_EVENT_VIEW_SEARCH_AFFILIATE,
    [ONLINE_BROWSE_SECTION]: ONLINE_BROWSE_EVENT_VIEW_AFFILIATE,
    [ONLINE_SEARCH_SECTION]: ONLINE_SEARCH_EVENT_VIEW_AFFILIATE,
};

export const HEAP_CITY_BROWSE_PAGE_VIEW = 'CityBrowse - All - View - Page';
export const HEAP_SEARCH_PAGE_VIEW = 'Search - All - View - Page';

// Rec Feedback
export const REC_FEEDBACK_CATEGORY = 'home-feed';
export const REC_FEEDBACK_SHOWN = 'RecsFeedbackShown';
export const REC_FEEDBACK_SUBMITTED = 'RecsFeedbackSubmitted';
