import * as React from 'react';

const ClockChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="clock-chunky_svg__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <style>
            {
                '\n        .clock-chunky_svg__st1{clip-path:url(#clock-chunky_svg__SVGID_4_)}\n    '
            }
        </style>
        <g>
            <defs>
                <path
                    id="clock-chunky_svg__SVGID_1_"
                    d="M11 8h2v4h2.6v2H11V8zm1-2c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 14c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
                />
            </defs>
            <clipPath id="clock-chunky_svg__SVGID_2_">
                <use
                    xlinkHref="#clock-chunky_svg__SVGID_1_"
                    overflow="visible"
                />
            </clipPath>
            <g clipPath="url(#clock-chunky_svg__SVGID_2_)">
                <g>
                    <defs>
                        <path
                            id="clock-chunky_svg__SVGID_3_"
                            d="M-1-1h26v26H-1z"
                        />
                    </defs>
                    <clipPath id="clock-chunky_svg__SVGID_4_">
                        <use
                            xlinkHref="#clock-chunky_svg__SVGID_3_"
                            overflow="visible"
                        />
                    </clipPath>
                    <g className="clock-chunky_svg__st1">
                        <defs>
                            <path
                                id="clock-chunky_svg__SVGID_5_"
                                d="M4 4h16v16H4z"
                            />
                        </defs>
                        <clipPath id="clock-chunky_svg__SVGID_6_">
                            <use
                                xlinkHref="#clock-chunky_svg__SVGID_5_"
                                overflow="visible"
                            />
                        </clipPath>
                        <path
                            clipPath="url(#clock-chunky_svg__SVGID_6_)"
                            fill="#45494e"
                            d="M-1-1h26v26H-1z"
                        />
                    </g>
                    <g className="clock-chunky_svg__st1">
                        <defs>
                            <path
                                id="clock-chunky_svg__SVGID_7_"
                                d="M0 0h24v24H0z"
                            />
                        </defs>
                        <clipPath id="clock-chunky_svg__SVGID_8_">
                            <use
                                xlinkHref="#clock-chunky_svg__SVGID_7_"
                                overflow="visible"
                            />
                        </clipPath>
                        <g clipPath="url(#clock-chunky_svg__SVGID_8_)">
                            <defs>
                                <path
                                    id="clock-chunky_svg__SVGID_9_"
                                    d="M4 4h16v16H4z"
                                />
                            </defs>
                            <clipPath id="clock-chunky_svg__SVGID_10_">
                                <use
                                    xlinkHref="#clock-chunky_svg__SVGID_9_"
                                    overflow="visible"
                                />
                            </clipPath>
                            <path
                                clipPath="url(#clock-chunky_svg__SVGID_10_)"
                                d="M-5-5h34v34H-5z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

ClockChunkySvg.displayName = 'ClockChunkySvg';
export default ClockChunkySvg;
