import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { COLORS_PROP_TYPE } from '@eventbrite/eds-color';
import { DIVIDER_DIRECTIONS_PROP_TYPE } from './constants';

import './divider.scss';

export default class Divider extends React.PureComponent {
    static propTypes = {
        /**
         * Color of divider
         */
        color: COLORS_PROP_TYPE,
        /**
         * Direction of divider (horizontal, vertical)
         */
        direction: DIVIDER_DIRECTIONS_PROP_TYPE,
        /**
         * Role of divider
         */
        role: PropTypes.string,
        /**
         * Custom CSS classname to apply to divider
         */
        customClassName: PropTypes.string,
    };

    static defaultProps = {
        color: 'ui-200',
        direction: 'horizontal',
    };

    render() {
        const { color, customClassName, direction, role } = this.props;

        const className = classNames(
            'eds-divider__hr',
            {
                [`eds-bg-color--${color}`]: color,
                'eds-divider--horizontal': direction === 'horizontal',
                'eds-divider--vertical': direction === 'vertical',
            },
            customClassName,
        );

        return (
            <hr
                className={className}
                data-spec="divider-hr"
                aria-hidden="true"
                role={role}
            />
        );
    }
}
