import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { gettext } from '@eventbrite/i18n';
import { CloseIcon } from '@eventbrite/marmalade';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { ComponentProps } from 'react';

export type DialogProps = Omit<
    ComponentProps<typeof DialogPrimitive.Root>,
    'modal'
> & {
    /**
     * An accessible title to be announced when the dialog is opened.
     */
    title?: string;
    onSetShouldShowDialog: Function;
};

export const Dialog: React.FC<DialogProps> = ({
    children,
    onSetShouldShowDialog,
    ...props
}) => {
    return (
        <DialogPrimitive.Root {...props} modal={true}>
            <DialogPrimitive.Portal>
                <Overlay />
                <Content>
                    <Title asChild>
                        <h2>
                            {gettext('How do you want to build your event?')}
                        </h2>
                    </Title>
                    <DialogPrimitive.Description asChild>
                        {children}
                    </DialogPrimitive.Description>
                    <CloseButton onClick={() => onSetShouldShowDialog(false)}>
                        <CloseIcon />
                    </CloseButton>
                </Content>
            </DialogPrimitive.Portal>
        </DialogPrimitive.Root>
    );
};

const OverlayShow = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
`;

const ContentShow = keyframes`
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
`;

const Overlay = styled(DialogPrimitive.Overlay)`
    animation: ${OverlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: #3a3247;
    inset: 0;
    opacity: 0.8;
    position: fixed;
    z-index: 2147483646;
`;

const Title = styled(DialogPrimitive.Title)`
    text-align: center;
    padding-bottom: 40px;
    padding-top: 35px;
`;

const Content = styled(DialogPrimitive.Content)`
    animation: ${ContentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: #f8f7fa;
    left: 50%;
    max-height: 85vh;
    max-width: 860px;
    padding: 50px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    z-index: 2147483647;

    &:focus {
        outline: none;
    }
`;

const CloseButton = styled(DialogPrimitive.Close)`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border-radius: 0;
    border: none;
    display: inline-flex;
    font-family: inherit;
    font: inherit;
    height: 25px;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    text-align: inherit;
    top: 10px;
    width: 25px;
    &:focus-visible {
        outline: none;
    }
    @media screen and (max-width: 960px) {
        padding-right: 40px;
    }
`;
