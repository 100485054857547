import { CoreApplicationContext } from '@eventbrite/context-gen';
import { getNarrowMenuInfo } from '@eventbrite/eds-global-header';
import { Icon } from '@eventbrite/eds-icon';
import { PlusChunky } from '@eventbrite/eds-iconography';
import { HAS_DOCUMENT } from '@eventbrite/eds-utils';
import { useFeatureFlags } from '@eventbrite/feature-flag-utils';
import { gettext } from '@eventbrite/i18n';
import {
    getCreateEventCTA,
    getHelpMenu,
    getNonUserQuickLinks,
    getOrganizeMenu,
    getUserMenuInfo,
    logCreateEventCTAClick,
} from '@eventbrite/site-header';
import React, { useState } from 'react';
import { FeatureFlags, UserShape } from '../../types/index';
import { CreateEventAlternatives } from '../Dialog/CreateEventAlternatives';
import { Dialog } from '../Dialog/Dialog';
import {
    getBugQuickLink,
    getLikesQuickLinks,
    getMyTicketsQuickLinks,
} from './core-js-global-copies';
import {
    GlobalHeaderCallToAction,
    GlobalHeaderMenu,
    GlobalHeaderQuickLinks,
} from './GlobalHeaderNavigationBar';
import { isDetachInterestsEnabled, isEventCreationModalEnabled } from './utils';

interface ConsumerHeaderLinksProps {
    user: UserShape;
    env: CoreApplicationContext['env'] & {
        launchNewSignInSignUpLinks?: string;
        isManageOverviewActive?: boolean;
    };
    featureFlags: FeatureFlags;
    requestPath?: string;
    isHomePage?: boolean;
    onAdditionalMenuTextItemSelect?: Function;
    onClickDropdown?: Function;
}

let organizeEvent: boolean;
if (HAS_DOCUMENT) {
    const params = new URLSearchParams(document?.location?.search || '');
    organizeEvent = params.has('organizeEvent');
}

const _showCreateEventCTA = (
    { canCreateEvents }: UserShape,
    isHomePage?: boolean,
) => {
    // Only show the Create Event Cta if the user can create events, or is on the homepage
    return canCreateEvents || isHomePage;
};

export const ConsumerHeaderMobileLinks: React.FC<ConsumerHeaderLinksProps> = ({
    user,
    env,
    featureFlags,
    requestPath,
    isHomePage,
    onClickDropdown,
}) => {
    // Users only see the ConsumerHeader if they are authenticated. Else they see the GlobalNav
    const { isAuthenticated } = user;
    const {
        adminServerUrl,
        isManageOverviewActive,
        logoutUrl,
        serverUrl,
        isMobile,
        organizeUrls,
        helpUrls,
        signinUrl,
        signupUrl,
        loginUrl,
        launchNewSignInSignUpLinks,
        ebDomain,
    } = env;

    const additionalMenus = [
        getOrganizeMenu(organizeUrls, requestPath),
        getHelpMenu(helpUrls, requestPath),
    ];

    const userMenuInfo = getUserMenuInfo({
        adminServerUrl,
        featureFlags: {
            ...featureFlags,
            isDetachInterestsEnabled: isDetachInterestsEnabled(),
        },
        isManageOverviewActive,
        logoutUrl,
        requestPath,
        serverUrl,
        showBrowseEvents: true,
        user,
        isMobile,
    });

    const signinPath = launchNewSignInSignUpLinks ? signinUrl : loginUrl;
    const nonUserQuickLinks = getNonUserQuickLinks(
        requestPath,
        isAuthenticated,
        signinPath,
        signupUrl,
        { launchNewSignInSignUpLinks },
    );

    const showCreateEventCTA = _showCreateEventCTA(user, isHomePage);

    const callToActionInfo = getCreateEventCTA(
        serverUrl,
        ebDomain,
        requestPath,
        isAuthenticated,
        null,
        { showCreateEventCTA },
        () => logCreateEventCTAClick(isHomePage),
    );

    const narrowMenuInfo = getNarrowMenuInfo(
        userMenuInfo,
        additionalMenus,
        [],
        callToActionInfo,
        [],
        true,
    );

    const narrowMenu = [narrowMenuInfo].map((menuInfo, index) => (
        <span
            key={menuInfo.path + index}
            className="eds-global-header__narrow-menu"
        >
            <GlobalHeaderMenu
                menuInfo={menuInfo}
                data-spec="global-header-narrow-menu"
                noLabel={true}
                onClickDropdown={onClickDropdown}
            />
        </span>
    ));

    const { publicId } = user;

    const likesLinks = getLikesQuickLinks(
        serverUrl,
        ebDomain,
        requestPath || '',
        publicId,
    );

    const myTicketsLinks = getMyTicketsQuickLinks(
        serverUrl,
        ebDomain,
        requestPath || '',
        publicId,
    );

    return (
        <div className="consumer-header__mobile-links eds-align--space-between">
            <div className="consumer-header__links">
                <div className="consumer-header__likes_and_tickets">
                    <GlobalHeaderQuickLinks
                        key="likesLinks"
                        links={likesLinks}
                        shouldShowIcon={true}
                    />
                    <GlobalHeaderQuickLinks
                        key="myTicketsLinks"
                        links={myTicketsLinks}
                        shouldShowIcon={true}
                    />
                </div>
                {narrowMenu}
                <GlobalHeaderQuickLinks
                    links={nonUserQuickLinks}
                    shouldShowIcon={false}
                />
            </div>
        </div>
    );
};

export const ConsumerHeaderDesktopLinks: React.FC<ConsumerHeaderLinksProps> = ({
    user,
    env,
    featureFlags,
    requestPath,
    isHomePage,
}) => {
    const { ebDomain, serverUrl } = env;
    const { isAuthenticated, publicId } = user;
    const [shouldShowDialog, setShouldShowDialog] = useState(false);
    const { launchAutoCreateEventExistingCreators } = useFeatureFlags();
    const showCreateEventCTA = _showCreateEventCTA(user, isHomePage);
    const isExperiment = !!organizeEvent;
    const createEventCTA = getCreateEventCTA(
        serverUrl,
        ebDomain,
        requestPath,
        isAuthenticated,
        isExperiment,
        { showCreateEventCTA },
        () => logCreateEventCTAClick(isHomePage),
    );

    const likesLinks = getLikesQuickLinks(
        serverUrl,
        ebDomain,
        requestPath || '',
        publicId,
    );

    const myTicketsLinks = getMyTicketsQuickLinks(
        serverUrl,
        ebDomain,
        requestPath || '',
        publicId,
    );

    const ctaIcon = <Icon size="small" type={<PlusChunky />} />;

    let linkComponents: any[] = [];

    // on the search page, show britelings a link to report a bug
    if (featureFlags.showReportBugLinkInHeader) {
        linkComponents = [
            <GlobalHeaderQuickLinks
                key="bugLinks"
                links={getBugQuickLink()}
                shouldShowIcon={true}
            />,
        ];
    }

    linkComponents = [
        ...linkComponents,
        launchAutoCreateEventExistingCreators &&
        isEventCreationModalEnabled() ? (
            <React.Fragment key="autoCreateEventWithAIModal">
                <a
                    onClick={() => setShouldShowDialog(true)}
                    data-automation="global-nav-create"
                    data-spec="consumer-header__quick-link"
                    data-testid="auto-create-create-button"
                    className="consumer-header__quick-link consumer-header__quick-link--cta consumer-header__menu-label create-event-link"
                    style={{ cursor: 'pointer' }}
                >
                    {ctaIcon}
                    {gettext('Create an event')}
                </a>
                <Dialog
                    open={shouldShowDialog}
                    onSetShouldShowDialog={setShouldShowDialog}
                >
                    <CreateEventAlternatives />
                </Dialog>
            </React.Fragment>
        ) : (
            <GlobalHeaderCallToAction
                key="createEventLink"
                linkInfo={createEventCTA}
                iconType={ctaIcon}
                shouldShowIcon={true}
                iconColor="grey-800"
            />
        ),
        <React.Fragment key="createEventClassicFlow">
            <GlobalHeaderQuickLinks
                key="likesLinks"
                links={likesLinks}
                shouldShowIcon={true}
            />
            <GlobalHeaderQuickLinks
                key="myTicketsLinks"
                links={myTicketsLinks}
                shouldShowIcon={true}
            />
        </React.Fragment>,
    ];

    return (
        <div
            className="consumer-header__links"
            data-testid="consumer-header-links-test"
        >
            {linkComponents}
        </div>
    );
};
