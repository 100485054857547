import React from 'react';
import PropTypes from 'prop-types';
import { themeToCssVars, setRootTheme, removeRootTheme } from './utils';

import { THEME_PROP_TYPE } from './constants';

export default class Theme extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        theme: THEME_PROP_TYPE,
        isRoot: PropTypes.bool,
    };

    static defaultProps = {
        isRoot: false,
    };

    componentDidMount() {
        const { isRoot, theme } = this.props;

        if (isRoot) {
            setRootTheme(theme);
        }
    }

    componentDidUpdate({ theme: prevTheme, isRoot: prevWasRoot }) {
        const { isRoot: newIsRoot, theme: newTheme } = this.props;

        if (prevWasRoot || newIsRoot) {
            // If at any time we had a root theme (either before or after)
            // we should remove the previous theme just to be safe. This
            // may result in the same root theme being removed and added,
            // but this is safest for all of the edge cases (shown in unit tests)
            removeRootTheme(prevTheme);

            // Now if we want to display a root theme, set it
            if (newIsRoot) {
                setRootTheme(newTheme);
            }
        }
    }

    componentWillUnmount() {
        const { isRoot, theme } = this.props;

        if (isRoot) {
            removeRootTheme(theme);
        }
    }

    render() {
        const { theme, children, isRoot } = this.props;
        const style = isRoot ? {} : themeToCssVars(theme);

        // Call render prop to render UI, passing style as "theme prop"
        return children({ style });
    }
}
