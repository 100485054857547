import { eventsDuck } from '@eventbrite/redux-destination';
import {
    CreatorCollectionsCamel,
    PublicCollectionsCamel,
} from '../../../types';

interface Signal {
    signal?: {
        type: 'follow' | 'collection';
        value: number | CreatorCollectionsCamel;
    };
}

interface SignalCandidate {
    collection?: PublicCollectionsCamel;
    follow: number;
}

export const loggedOutSaveRedirect = (eventId: string) => {
    eventsDuck.api.loggedOutSaveRedirect(eventId, {
        pathname: `${window.location.pathname}${window.location.search}`,
    });
};

export const getSignal = (signalCandidates: SignalCandidate): Signal => {
    if (
        signalCandidates?.collection?.creatorCollections &&
        signalCandidates?.collection?.creatorCollections?.objectCount > 0
    ) {
        return parseCollectionSignal(signalCandidates.collection);
    }

    if (signalCandidates.follow) {
        return parseFollowSignal(signalCandidates.follow);
    }

    return {};
};

export const parseFollowSignal = (organizerFollowCount: number): Signal => {
    if (organizerFollowCount >= 10) {
        return {
            signal: { type: 'follow', value: organizerFollowCount },
        };
    }

    return {};
};

export const parseCollectionSignal = (
    publicCollections: PublicCollectionsCamel,
): Signal => {
    const collections = publicCollections?.creatorCollections || [];

    if (collections.objectCount > 0) {
        return {
            signal: {
                type: 'collection',
                value: collections,
            },
        };
    }

    return {};
};
