import { sdkRequest } from '@eventbrite/http';
import { SearchAutocompleteResponse } from '../types';

const SEARCH_AUTOCOMPLETE_URL = '/api/v3/destination/search/autocomplete/';

export const buildAutocompleteUrl = ({
    query,
    placeId,
    latitude,
    longitude,
}: {
    query: string;
    placeId: string;
    latitude: number | null;
    longitude: number | null;
}) => {
    let url = `${SEARCH_AUTOCOMPLETE_URL}?&q=${query}&completion_types=event,query&expand.destination_event=primary_venue,image`;
    if (!placeId && latitude && longitude) {
        url = `${url}&latitude=${latitude}&longitude=${longitude}`;
    } else {
        url = `${url}&place_id=${placeId}`;
    }

    return url;
};

export const searchAutocomplete = ({
    query,
    placeId,
    latitude,
    longitude,
}: {
    query: string;
    placeId: string;
    latitude: number | null;
    longitude: number | null;
}): Promise<SearchAutocompleteResponse> => {
    return sdkRequest(
        buildAutocompleteUrl({ query, placeId, latitude, longitude }),
    );
};
