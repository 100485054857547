import { gettext } from '@eventbrite/i18n';
import { Icon } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React from 'react';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../../utils/heap-data';
import { navigationLinkProps } from '../../../../types';
import styles from './NavLink.module.scss';

const handlePointerDown = (e: any, path: any) => {
    /**
     * Prevent any additional behavior from pointer down and just change the location path
     */
    e.preventDefault();
    if (typeof window !== 'undefined') {
        window.location = path;
    }
};

const isSignuporloginUrl = (heapIdentifier: string) => {
    return ['signup', 'login'].includes(heapIdentifier);
};

const NavLink = ({
    path,
    heapIdentifier,
    translatedText,
    textColor,
    iconType,
    iconPosition,
    className,
}: navigationLinkProps) => {
    const navLinkClass = classNames(styles.main, className);
    const iconPositionClass = iconPosition === 'right' ? styles.iconRight : '';
    const { Teams, Features, Components, Actions } = HeapAttributes;

    return (
        <a
            className={navLinkClass}
            href={path}
            onPointerDown={(e) => handlePointerDown(e, path)}
            data-testid="navLink"
            aria-label={translatedText.toString() || gettext('link').toString()}
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.GlobalNav,
                component: Components.Link,
                name: heapIdentifier.replaceAll(' ', '-').toLowerCase(),
                action: Actions.Click,
            })}
            {...(isSignuporloginUrl(heapIdentifier) && {
                rel: 'nofollow',
            })}
        >
            {!!iconType && (
                <span data-testid="navLink-icon" className={iconPositionClass}>
                    <Icon>
                        <>{iconType}</>
                    </Icon>
                </span>
            )}
            <span
                style={{ color: textColor }}
                aria-label={translatedText.toString()}
            >
                {translatedText}
            </span>
        </a>
    );
};

export default NavLink;
