import {
    areUrgencySignalsActive,
    FormattedEvent,
    getActiveUrgencySignals,
    isPromoCodeActive,
} from '@eventbrite/event-renderer';

export function getBaseTrackingContext({
    event,
    events,
    userId,
    locale,
    sessionId,
    guestId,
}: {
    event: FormattedEvent;
    events: FormattedEvent[];
    userId?: string;
    locale?: string;
    sessionId?: string;
    guestId?: string;
}) {
    const hasOpenPromotion = isPromoCodeActive(event.openDiscount);
    const hasBOGOLabel = !!event.specialDiscounts?.hasBogoTickets;

    const hasUrgencySignal = areUrgencySignalsActive(event);
    const urgencySignals = hasUrgencySignal
        ? getActiveUrgencySignals(event?.urgencySignals)
        : [];

    const { rank, absoluteRank } = computeEventRanks(event, events);

    return {
        user: {
            id: userId,
            locale,
            guestId,
            sessionId,
        },
        adId: event?.promotedListingMetadata?.adId,
        place: {
            position: rank,
            absoluteRank,
            placementId: event?.promotedListingMetadata?.placementId,
        },
        displayContext: {
            hasOpenPromotion,
            hasBOGOLabel,
            urgencySignals,
        },
        venueId: event?.venue?.id,
    };
}

/* Due to historical reasons we need to compute both the relative position of an ad and its absolute
 * The relative position (rank) is assuming promoted and organic ads are different lists and reset their rank
 * For example, if we had this series of events: 1Promoted - 2Organic - 3Organic - 4Promoted
 * Their positions would be as follows:
 * 1Promoted - rank: 1, absoluteRank: 1
 * 2Organic - rank: 1, absoluteRank: 2
 * 3Organic - rank: 2, absoluteRank: 3
 * 4Promoted - rank 2, absoluteRank: 4
 * TODO: Unify this approach and have only one rank (absoluteRank)
 */
export function computeEventRanks(
    eventToSearch: FormattedEvent | undefined,
    events: FormattedEvent[],
) {
    if (!eventToSearch) {
        return {
            rank: 0,
            absoluteRank: 0,
        };
    }

    const { isPromoted, id } = eventToSearch;
    const eventsGroup = events.filter((e) => isPromoted === e.isPromoted);

    return {
        rank: eventsGroup.findIndex((e) => e.id === id) + 1,
        absoluteRank: events.findIndex((e) => e.id === id) + 1,
    };
}
