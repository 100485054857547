import omitBy from 'lodash/omitBy';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

/*
 Receives an object and removes empty objects, arrays, and strings
 but will leave explicit false declarations.

 @param objectToPrune - object
 {gunter: [], rick: false, morty: ''}

 @return object
 {rick: false}
*/
export const pruneObject = (objectToPrune) =>
    omitBy(
        objectToPrune,
        (value) => (isString(value) || isObject(value)) && isEmpty(value),
    );
