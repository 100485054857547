import { eventsDuck } from '@eventbrite/redux-destination';

export const saveEvent = async (id: string): Promise<{}> => {
    const response = await eventsDuck.api.saveEvent(id);

    return response;
};

export const unSaveEvent = async (id: string): Promise<{}> => {
    const response = await eventsDuck.api.unsaveEvent(id);

    return response;
};
